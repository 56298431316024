import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DeviceTypesComponent } from '../device-types/device-types.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { CustomValidators } from 'src/app/validators/customValidator';
import { CommonConstants } from 'src/app/shared/common-constants';

@Component({
  selector: 'app-add-device-type',
  templateUrl: './add-device-type.component.html',
  styleUrls: ['./add-device-type.component.scss'],
})
export class AddDeviceTypeComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public addDeviceTypeForm: any;
  public isProcessing: boolean = false;
  public firmwares: any = [];
  public selectedFirmwares: any = [];
  public filteredFirmwares: any = [];
  public selectedIsContainSensorBoard: boolean = false;
  public firmwareSearch: FormControl = new FormControl();

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DeviceTypesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddDeviceType();
    this.setPatchValues();
    this.getFirmwares();
    this.firmwareSearch.valueChanges.subscribe((val) => {
      this.filteredFirmwares = this.firmwares.filter(
        (option: any) =>
          option.name.toLowerCase().includes(val?.toLowerCase()) ||
          option.version.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  // convenience getter for easy access to form fields
  get addDeviceControls() {
    return this.addDeviceTypeForm.controls;
  }

  resetAddDeviceType() {
    const groupObj: any = {
      name: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
      version: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
      is_contain_sensor_board: [
        null,
        Validators.compose([Validators.required]),
      ],
      firmwares: [null, Validators.compose([])],
    };
    this.addDeviceTypeForm = this.fb.group(groupObj);
  }

  async getDeviceTypeDetails(device_type_id: string) {
    try {
      this.isProcessing = true;
      const result = await this.deviceService.getDeviceTypeDetails(
        device_type_id
      );
      return result;
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getFirmwares() {
    let params = '';
    if (this.action === 'Update') {
      params += `device_type_id=${this.local_data.id}`;
    }
    const result = await this.deviceService.getFirmwaresForDeviceType(params);
    if (result && result.data) {
      this.firmwares = result.data;
      this.filteredFirmwares = [...this.firmwares];
    }
    return result;
  }

  async setPatchValues() {
    if (this.action === 'Update') {
      const device_type_id = this.local_data.id;
      const { data } = await this.getDeviceTypeDetails(device_type_id);
      if (data.supported_device_type && data.supported_device_type.length) {
        this.selectedFirmwares = data.supported_device_type.map((d: any) => {
          return d.supported_firmware.id;
        });
      }
      this.selectedIsContainSensorBoard = data.is_contain_sensor_board;
      this.addDeviceTypeForm.patchValue({
        name: data.name,
        version: data.version,
      });
    }
  }

  async doAction() {
    await this.submitDeviceTypeForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitDeviceTypeForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const deleteURL = `device_type_id=${this.local_data.id}`;
        const res = await this.deviceService.deleteDeviceType(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else if (this.addDeviceTypeForm.valid) {
      try {
        this.isProcessing = true;
        let res: any;
        const reqData: any = {
          name: this.addDeviceTypeForm.controls.name.value,
          version: this.addDeviceTypeForm.controls.version.value,
          is_contain_sensor_board: this.selectedIsContainSensorBoard,
        };
        if (
          this.addDeviceTypeForm.controls.firmwares.value &&
          this.addDeviceTypeForm.controls.firmwares.value.length
        ) {
          reqData.firmwares = this.addDeviceTypeForm.controls.firmwares.value;
        }
        if (this.action === 'Add') {
          res = await this.deviceService.createDeviceType(reqData);
          this.local_data.id = res.data.id;
          this.local_data.device_type_device = [];
          this.local_data.createdAt = res.data.createdAt;
          this.local_data.updatedAt = res.data.updatedAt;
        } else if (this.action === 'Update') {
          const device_type_id = this.local_data.id;
          reqData.device_type_id = device_type_id;
          res = await this.deviceService.editDeviceType(reqData);
          const { data } = await this.getDeviceTypeDetails(device_type_id);
          this.local_data.device_type_device = data.device_type_device;
          this.local_data.version = data.version;
          this.local_data.updatedAt = data.updatedAt;
        }
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }
}
