// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  CHART_LICENSE:
    '0001-mfa744307311fc3000e327e8e3dbf7ae76a8d461d1762a1440b026f2456efc003-00201d3a8701-30450221009a074cb18b47aa2e7d6b2471037af59123b53deed0d0f97c7ed61c7e99fb141b022041c9c292c4d545ae3c6063df0a7476de723208e142bd19a74b832af399d12172',
  CHART_APP_TITLE: 'Smell Annotator',
  CHART_COMPANY: 'SmartNanotubes Technologies GmbH',
  // API_URL: 'http://0.0.0.0:4000/api/v1',
  // API_URL:
  //   'http://sntstagebackend.eba-pmgw8rnp.eu-central-1.elasticbeanstalk.com/api/v1',
  API_URL: 'https://api.smell-inspector.com/api/v1',
  // API_URL: 'http://18.194.191.247:4000/api/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// Email: hardik.shakya@simformsolutions.com
// Password: Qwe@1234
// Email: gajera@smart-nanotubes.com
// Password: vTcf=3SxyUEe4$mw
