import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private apiService: APIService) { }

  faqAddCategory(body: any): any {
    const url = '/faq/faq-category';
    return this.apiService.postWithAuthHandler(url, body);
  }

  faqGetCategories(params: any) {
    const url = `/faq/faq-category?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'faqGetCategories');
  }

  faqUpdateCategory(body: any): any {
    const url = '/faq/faq-category';
    return this.apiService.putWithAuthHandler(url, body);
  }

  faqDeleteCategory(params: any): any {
    const url = `/faq/faq-category?${params}`;
    return this.apiService.deleteWithAuthHandler(url);
  }

  addFaq(body: any): any {
    const url = '/faq';
    return this.apiService.postWithAuthHandler(url, body);
  }

  editFaq(body: any): any {
    const url = '/faq';
    return this.apiService.putWithAuthHandler(url, body);
  }

  removeFaq(params: any): any {
    const url = `/faq?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  getUsers(params: any) {
    const url = `/admin/users?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getUsers');
  }

  getUserFilters() {
    const url = '/admin/users/filter';
    return this.apiService.getWithAuthHandler(url, true, 'getUserFilters');
  }

  getUserDetails(userId: any) {
    const url = `/admin/user/${userId}`;
    return this.apiService.getWithAuthHandler(url, true, 'getUserDetails');
  }

  userResetPassword(id: any, body: any): any {
    const url = `/admin/user/reset/${id}`;
    return this.apiService.postWithAuthHandler(url, body);
  }

  getUserData(userId: any, params: any) {
    const url = `/admin/user/${userId}/data?${params}`;
    return this.apiService.getWithAuthHandler(url, false, 'getUserData');
  }

  assignStorage(userId: any, params: any) {
    const url = `/admin/user/storage/assign/${userId}`;
    return this.apiService.postWithAuthHandler(url, params);
  }

  getStorageHistory(userId: any, params: any) {
    const url = `/admin/user/storage/history/${userId}?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getStorageHistory');
  }
}
