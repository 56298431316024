<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <div>
                <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <div class="search" *ngIf="showSearch">
                  <mat-form-field class="search-box">
                    <mat-label>Search</mat-label>
                    <input matInput #smellSearch [(ngModel)]="searchedText"
                      [placeholder]="searchOn.length ? searchOn : 'Search'" (keyup)="getSmells()" />
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="filter-item" *ngIf="filterOptions?.categories.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Category</mat-label>
                  <mat-select placeholder="Category" multiple [(ngModel)]="categories">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="categorySearch" placeholderLabel="Search Category..."
                        noEntriesFoundLabel="No matching Category found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredCategories.length > 0" #allCategoryCheckbox
                      (click)="allSelection('category')" [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('category')" *ngFor="let category of filteredCategories"
                      [value]="category.category">
                      {{ category.category }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.parentSubstances.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Base Smell / Substance Name</mat-label>
                  <mat-select placeholder="Smell" multiple [(ngModel)]="parentSubstances">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="baseSmellDropDownSearch"
                        placeholderLabel="Search Base Smell / Substance..."
                        noEntriesFoundLabel="No matching Base Smell / Substance found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredParentSubstances.length > 0" #allBaseSmell
                      (click)="allSelection('baseSmell')" [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('baseSmell')" *ngFor="let smell of filteredParentSubstances"
                      [value]="smell.parent_substance_id">
                      {{ smell.parent_substance_name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Public/Private</mat-label>
                  <mat-select (selectionChange)="getSmells()" placeholder="Public/Private" [(ngModel)]="is_public">
                    <mat-option [value]="null">
                      All
                    </mat-option>
                    <mat-option [value]="true">
                      Public
                    </mat-option>
                    <mat-option [value]="false">
                      Private
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div (click)="picker.open()" class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>


              <!-- <div class="d-flex">
                <button *ngIf="!showSearch" mat-icon-button color="primary" class="m-l-10" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>

                <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                  (click)="clearFilter()">
                  Clear Filters
                </button>
                <button mat-raised-button color="primary" class="m-l-10" (click)="openDialog('Add',{})">Add
                  Smell/Substance</button>
              </div> -->

            </div>
            <div class="d-flex">
              <!-- <button *ngIf="!showSearch" mat-icon-button color="primary" class="m-l-10" (click)="showHideSearch()">
                <mat-icon>search</mat-icon>
              </button> -->
              <button mat-raised-button color="primary" (click)="openDialog('Add',{})">Add
                Smell/Substance</button>
              <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                (click)="clearFilter()">
                Clear Filters
              </button>

            </div>

            <!-- <div class="search" *ngIf="showSearch">
              <mat-form-field class="search-box">
                <mat-label>Search</mat-label>
                <input matInput #smellSearch [(ngModel)]="searchedText"
                  [placeholder]="searchOn.length ? searchOn : 'Search'" (keyup)="getSmells()" />
                <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div> -->

            <div *ngIf='length !== 0' class="example-container ex-c-general">
              <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                [matSortActive]="orderOn" [matSortDirection]="orderBy" class="table employee-list no-wrap">


                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                  <td class="might-overflow" [matTooltip]="element.name" mat-cell *matCellDef="let element">
                    {{ element.name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="total_measurement_count">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Measurements </th>
                  <td class="might-overflow" [matTooltip]="element.total_measurement_count" mat-cell
                    *matCellDef="let element">
                    {{ element.total_measurement_count }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="category">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                  <td class="might-overflow" [matTooltip]="element.category" mat-cell *matCellDef="let element">
                    {{ element.category || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="parent_substance_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Base Smell / Substance Name</th>
                  <td class="might-overflow" [matTooltip]="element.parent_substance_name" mat-cell
                    *matCellDef="let element">
                    {{ element.parent_substance_name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="is_public">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Public/Private</th>
                  <td class="might-overflow" [matTooltip]="element?.is_public ? 'Public' : 'Private'" mat-cell
                    *matCellDef="let element">
                    {{ element?.is_public ? 'Public' : 'Private' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="user_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                  <td class="might-overflow" [matTooltip]="element.user_name" mat-cell *matCellDef="let element">
                    {{ element.user_name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="user_email">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                  <td class="might-overflow" [matTooltip]="element.user_email" mat-cell *matCellDef="let element">
                    {{ element.user_email || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <a [matTooltip]="tooltip.view_substance" class="m-r-10 cursor-pointer eye"
                      (click)="openDialog('View',element)">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                    <a [matTooltip]="tooltip.update_substance" [ngClass]="{'disabled': !element.is_public}"
                      (click)="openDialog('Update',element)" class="m-r-10 cursor-pointer"><i
                        class="fa fa-pencil editFont"></i></a>
                    <a [matTooltip]="tooltip.delete_substance"
                      [ngClass]="{'disabled': !element.is_public,'text-danger': element.is_public}"
                      (click)="openDialog('Delete',element)" class="m-r-10 cursor-pointer">
                      <i class="fa fa-trash deleteFont"></i>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf="length === 0">No record found</div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getSmells($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>