import { FormControl, ValidationErrors, FormGroup } from '@angular/forms';
import { CommonConstants } from '../shared/common-constants';

export class CustomValidators {
  static isNull(c: FormControl): ValidationErrors {
    let isValid = false;
    const value = c.value;
    if (value != null && value.toString().trim() !== '') {
      isValid = true;
    }
    let message: any = {
      isNull: {
        message: "Value can't be empty",
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }

  static checkPatternEmail(c: FormControl): ValidationErrors {
    let isValid = false;
    const value = c.value;
    // tslint:disable-next-line:max-line-length
    const emailPattern = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (value == null || value === '' || emailPattern.test(value)) {
      isValid = true;
    }
    let message: any = {
      email: {
        message: 'Please fill valid email',
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }

  static passwordValidate(c: FormControl): ValidationErrors {
    let isValid = false;
    const value = c.value;
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/;
    const passwordPattern = new RegExp(regex);
    if (value == null || value === '' || passwordPattern.test(value)) {
      isValid = true;
    }
    let message: any = {
      password: {
        message:
          'Please enter a password at least 8 character and contain at least one uppercase, at least one lower case and at least one special character!',
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }

  static noWhitespace(c: FormControl): ValidationErrors {
    let isValid = false;
    const value = c.value;

    if (value) {
      const array = value.split(',');
      // Here we use .every because it will break if validation goes fail
      isValid = array.every((w: any) => {
        return w !== ' ';
      });
    }
    let message: any = {
      email: {
        message: 'No white space allowed',
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }

  static noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  static ConfirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static isValidfunctionalization(c: FormControl): ValidationErrors {
    let isValid = false;
    const value = c.value;
    if (value && typeof value === 'string') {
      const filterdStr = value.replace(/\s/g, ''); // It will remove all the white space from the string
      const arr = filterdStr.split(',');
      // Minimum 16 channels should be present
      if (arr.length >= 16) {
        arr.every((v) => {
          if (!v || v === undefined || !(Number.isInteger(+v) && +v >= 0)) {
            isValid = false;
            return isValid;
          } else {
            isValid = true;
            return isValid;
          }
        });
      }
    }
    let message: any = {
      functionalization: {
        message: 'Please fill valid functionalization',
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }

  static isValidDetectorInfo(c: FormControl): ValidationErrors {
    let isValid = false;
    let value = c.value;
    if (value && typeof value === 'string') {
      value = value.replace(/\s/g, ''); // It will remove all the white space from the string
      if (value.length > 14) {
        value = value.substr(0, 14);
      }
      if (value.length === 14) {
        value.split('').every((v: any) => {
          if (!v || v === undefined || !(Number.isInteger(+v) && +v >= 0)) {
            isValid = false;
            return isValid;
          } else {
            isValid = true;
            return isValid;
          }
        });
      }
    }
    let message: any = {
      functionalization: {
        message: 'Please fill valid isValidDetectorInfo',
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }

  static isValidMacAdderess(c: FormControl): ValidationErrors {
    let isValid = false;
    const value = c.value;
    const pattern = new RegExp(/^([0-9A-Za-z]{2}[:-]){5,6}([0-9A-Za-z]{2})$/);
    if (value == null || value === '' || pattern.test(value)) {
      isValid = true;
    }
    let message: any = {
      email: {
        message: 'Please fill valid mac address',
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }

  static maxIntValidator(control: FormControl) {
    const isInValid =
      control.value < 0 || control.value > CommonConstants.maxIntLength;
    return isInValid ? { invalidInt: true } : null;
  }

  static isValidJSONString(control: FormControl) {
    let isInValid = true;
    try {
      if (control.value) {
        const data = JSON.parse(control.value);
        if (data) {
          isInValid = false;
        }
      }
    } catch (err) {
      isInValid = true;
    }
    return isInValid ? { invalidJSON: true } : null;
  }

  static boundsValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const lowerBound: any = formGroup.controls[controlName]; // Lower bound
      const upperBound: any = formGroup.controls[matchingControlName]; // Upper bound
      if (upperBound.errors && !upperBound.errors.invalidUpperBound) {
        return;
      }
      if (
        lowerBound.value !== undefined &&
        typeof lowerBound.value === 'number' &&
        upperBound.value !== undefined &&
        typeof upperBound.value === 'number'
      ) {
        if (upperBound.value <= lowerBound.value) {
          upperBound.status = 'INVALID';
          upperBound.setErrors({ invalidUpperBound: true });
        } else {
          upperBound.setErrors(null);
          upperBound.status = '';
        }
      }
    };
  }

  static isValidVersion(c: FormControl): ValidationErrors {
    let isValid = false;
    const value = c.value;
    const pattern = new RegExp(/^\d+(?:\.\d+){2}$/);
    if (value == null || value === '' || pattern.test(value)) {
      isValid = true;
    }
    let message: any = {
      email: {
        message: 'Please fill valid version',
      },
    };
    if (isValid) {
      message = null;
    }
    return message;
  }
}
