<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} App Version</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <form [formGroup]="addFaqForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input #version type="text" matInput required id="version" name="version" [(ngModel)]="local_data.version"
              placeholder="Version" formControlName="version">
            <mat-error *ngIf="!addFaqControls.version.value && addFaqForm.get('version').touched" class="text-danger">
              Version is required</mat-error>
            <mat-error
              *ngIf="addFaqForm.get('version').invalid && !addFaqControls.version.errors.required && addFaqForm.get('version').touched"
              class="text-danger">Please enter valid version</mat-error>
            <mat-error *ngIf="addFaqControls.version.value && addFaqForm.get('version').hasError('maxlength')"
              class="text-danger">
              Maximum 250 characters are allowed
            </mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <mat-label>Platform Type</mat-label>
            <mat-select required placeholder="Platform Type" formControlName="platform_type"
              [(ngModel)]="local_data.platform_type">
              <mat-option value="android">
                android
              </mat-option>
              <mat-option value="ios">
                ios
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!addFaqControls.platform_type.value && addFaqForm.get('platform_type').touched"
              class="text-danger">Platform Type is required</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <mat-label>Is Force Update</mat-label>
            <mat-select required placeholder="Is Force Update" formControlName="is_force_update"
              [(ngModel)]="local_data.is_force_update">
              <mat-option value="true">
                Yes
              </mat-option>
              <mat-option value="false">
                No
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!addFaqControls.is_force_update.value && addFaqForm.get('is_force_update').touched"
              class="text-danger">Is Force Update is required</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-20">
            <textarea rows="4" cols="40" [(ngModel)]="local_data.release_notes" #release_notes matInput
              placeholder="Release Notes" formControlName="release_notes"></textarea>
            <!-- <mat-error *ngIf="!addFaqControls.release_notes.value && addFaqForm.get('release_notes').touched"
              class="text-danger">Release Notes is required</mat-error>
            <mat-error
              *ngIf="addFaqControls.release_notes.value && addFaqForm.get('release_notes').touched && addFaqForm.get('release_notes').hasError('whitespace')">
              Please enter valid release notes
            </mat-error>

            <mat-error
              *ngIf="addFaqControls.release_notes.value && addFaqForm.get('release_notes').hasError('maxlength')"
              class="text-danger">
              Maximum 5000 characters are allowed
            </mat-error> -->

            <mat-error
              *ngIf="addFaqForm.controls['release_notes'].invalid && addFaqForm.controls['release_notes'].errors.maxlength"
              class="text-danger">
              Maximum 5000 characters are allowed
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="m-r-15 m-l-15">
          <div class="m-b-15">
            <mat-checkbox color="primary" formControlName="is_force_update" class="m-r-10"
              [(ngModel)]="selectedisForceUpdate">
              Is Force Update</mat-checkbox>
          </div>
        </div> -->
      </div>

      <div class="modal-fs">
        <mat-checkbox color="primary" class="m-r-10" (change)="isAllow = $event.checked">
          <div class="warn"><b> This operation never be undone. are you sure want to proceed further?</b></div>
        </mat-checkbox>
        <br>
        <br>
      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !addFaqForm.valid || !isAllow">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.version}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>