import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { AddDetectorBoardComponent } from '../add-detector-board/add-detector-board.component';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-detector-boards',
  templateUrl: './detector-boards.component.html',
  styleUrls: ['./detector-boards.component.scss'],
})
export class DetectorBoardsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('allFirmwareCheckbox') private allFirmwareCheckbox: MatOption =
    Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'detector_board_id',
    'createdAt',
    'updatedAt',
  ];
  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 10;
  public dataSource: any = [];
  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public firmwareSearch: FormControl = new FormControl();
  public filteredFirmwares: any = [];
  public deviceTypeDropDownSearch: FormControl = new FormControl();
  public filteredDeviceTypes: any = [];
  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = [];

  // Filter mechanism
  public fromDate: any = '';
  public toDate: any = '';
  public firmwares: any = [];
  public deviceTypes: any = [];
  public selectedFirmwares: any = [];
  public selectedDeviceTypes: any = [];
  public futureDate = new Date();

  // Tooltip
  public tooltip: any = {
    view_detector_board: 'View Sensor Board',
    update_detector_board: 'Update Sensor Board',
    delete_detector_board: 'Delete Sensor Board',
  };

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild('detectorBoardSearch') detectorBoardSearch: ElementRef | any;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setDefaultDates();
    this.getDetectorBoards();
    this.getDeviceFilters();
    this.firmwareSearch.valueChanges.subscribe((val) => {
      this.filteredFirmwares = this.firmwares.filter(
        (option: any) =>
          option.name.toLowerCase().includes(val?.toLowerCase()) ||
          option.version.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.deviceTypeDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredDeviceTypes = this.deviceTypes.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
    if (params.value && params.value.device_id) {
      this.openDialog('View', {
        id: params.value.device_id,
      });
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getDetectorBoards();
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getDetectorBoards();
    }
  }

  isFilterApplied() {
    return !(
      this.selectedFirmwares.length ||
      this.selectedDeviceTypes.length ||
      this.fromDate ||
      this.toDate
    );
  }

  async getDeviceFilters() {
    const res = await this.deviceService.getDeviceFilter();
    if (res) {
      this.firmwares = res.data.firmwares;
      this.deviceTypes = res.data.deviceTypes;
      this.filteredDeviceTypes = [...this.deviceTypes];
      this.filteredFirmwares = [...this.firmwares];
    }
  }

  singleSelection(dropDownName: string) {
    if (dropDownName === 'firmware') {
      if (this.allFirmwareCheckbox.selected) {
        this.allFirmwareCheckbox.deselect();
      }

      if (this.selectedFirmwares.length === this.firmwares.length) {
        this.allFirmwareCheckbox.select();
        this.selectedFirmwares = [
          ...this.firmwares.map((item: any) => item.id),
          -1,
        ];
      }
    }
    this.getDetectorBoards();
  }

  allSelection(dropDownName: string) {
    if (dropDownName === 'firmware') {
      if (this.allFirmwareCheckbox.selected) {
        this.selectedFirmwares = [
          ...this.firmwares.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.selectedFirmwares = [];
      }
    }
    this.getDetectorBoards();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getDetectorBoards(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      let detectorBoardsURL = '';
      // SEARCH
      if (this.searchedText) {
        detectorBoardsURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }

      // FILTERS
      // DATE
      if (this.fromDate && this.toDate) {
        detectorBoardsURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      // DEVICE TYPE
      if (this.selectedDeviceTypes.length) {
        detectorBoardsURL += `&deviceTypes=[${this.selectedDeviceTypes}]`;
        this.setDefaultPage(event);
      }
      // FIRMWARE
      if (this.selectedFirmwares.length) {
        detectorBoardsURL += `&firmwares=[${this.selectedFirmwares}]`;
        this.setDefaultPage(event);
      }
      // ORDER
      if (this.orderOn && this.orderBy) {
        detectorBoardsURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }
      detectorBoardsURL += `&page=${this.pageIndex + 1}&perPage=${
        this.pageSize
      }`;
      this.isProcessing = true;
      const res = await this.deviceService.getDetectorBoards(detectorBoardsURL);
      if (res) {
        this.data = res.data.detectorBoards;
        this.dataSource = res.data.detectorBoards;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.detectorBoardSearch && this.detectorBoardSearch.nativeElement) {
        this.detectorBoardSearch.nativeElement.focus();
      }
    });
  }

  searchWithTerm() {
    if (this.searchedText) {
      this.getDetectorBoards();
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.showHideSearch();
    this.getDetectorBoards();
  }

  clearFilter() {
    this.selectedFirmwares = [];
    this.selectedDeviceTypes = [];
    this.fromDate = '';
    this.toDate = '';
    this.getDetectorBoards();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDetectorBoardComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          this.getDetectorBoards();
        }
      }
    });
  }

  addRowData(row_obj: any): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [row_obj.data.boardInfo, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: any): boolean | void {
    this.dataSource = this.dataSource.filter((value: any) => {
      if (value.id === row_obj.id) {
        value.detector_board_id = row_obj.data.boardInfo.detector_board_id;
        value.updatedAt = row_obj.data.boardInfo.updatedAt;
      }
      return true;
    });
    this.table.renderRows();
  }
}
