<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Category</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <form [formGroup]="addCategoryForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">
        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-30">
            <input #category type="text" matInput required id="category" name="category"
              [(ngModel)]="local_data.category" placeholder="Category" formControlName="category">
            <mat-error *ngIf="!addCategoryControls.category.value && addCategoryForm.get('category').touched"
              class="text-danger">Category is required</mat-error>
            <mat-error
              *ngIf="addCategoryControls.category.value && addCategoryForm.get('category').touched && addCategoryForm.get('category').hasError('whitespace')">
              Please enter valid category
            </mat-error>

            <mat-error
              *ngIf="addCategoryControls.category.value && addCategoryForm.get('category').hasError('maxlength')"
              class="text-danger">
              Maximum 60 characters are allowed
            </mat-error>

          </mat-form-field>
        </div>
      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !addCategoryForm.valid ">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.category}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>
