import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppVersionUpdateRoutingModule } from './app-version-update-routing.module';
import { AddAppVersionUpdateComponent } from './add-app-version-update/add-app-version-update.component';
import { AppVersionUpdatesComponent } from './app-version-updates/app-version-updates.component';
import { DemoMaterialModule } from '../demo-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsModule } from 'ng2-charts';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [AddAppVersionUpdateComponent, AppVersionUpdatesComponent],
  imports: [
    CommonModule,
    AppVersionUpdateRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule,
    MatPaginatorModule,
  ],
})
export class AppVersionUpdateModule {}
