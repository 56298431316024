import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HelperService } from '../helper/helper.service';
import { environment } from '../../../../environments/environment';

/*
  Generated class for the ApiServiceProvider provider.
  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular DI.
*/

@Injectable()
export class AppHttpService {
  APP_URL = environment.API_URL;

  constructor(
    public http: HttpClient,
    public helper: HelperService
  ) {
  }

  createAuthorizationHeader(options: any) {
    const headerOptions = {};
    if (options) {
      Object.assign(headerOptions, options);
    }
    const headers = new HttpHeaders(headerOptions);
    return headers;
  }

  get(URL: string) {
    const response = this.http.get(this.APP_URL + URL, { observe: 'response' }).pipe(map(res => res));
    return response;
  }

  getWithHeader(URL: string, headers: any) {
    const response = this.http.get(this.APP_URL + URL, {
      headers: this.createAuthorizationHeader(headers),
      observe: 'response'
    }).pipe(map(res => res));
    return response;
  }

  post(URL: string, data: any) {
    const response = this.http.post(this.APP_URL + URL, data, { observe: 'response' });
    return response;
  }

  postWithHeader(URL: string, data: any, headers: any) {
    const response = this.http.post(this.APP_URL + URL, data, {
      headers: this.createAuthorizationHeader(headers),
      observe: 'response'
    }).pipe(map(res => res));
    return response;
  }

  put(URL: string, data: any) {
    const response = this.http.put(this.APP_URL + URL, data, { observe: 'response' });
    return response;
  }

  putWithHeader(URL: string, data: any, headers: any) {
    const response = this.http.put(this.APP_URL + URL, data, {
      headers: this.createAuthorizationHeader(headers),
      observe: 'response'
    }).pipe(map(res => res));
    return response;
  }

  deleteWithHeader(URL: string, headers: any) {
    const response = this.http.delete(this.APP_URL + URL, {
      headers: this.createAuthorizationHeader(headers),
      observe: 'response'
    }).pipe(map(res => res));
    return response;
  }

  // Third party API calls
  getTP(URL: string) {
    const response = this.http.get(URL).pipe(map(res => res));
    return response;
  }

  getTPBlob(URL: string) {
    const response = this.http.get(URL,{ responseType: 'blob' }).pipe(map(res => res));
    return response;
  }

  postTP(URL: string, data: any) {
    const response = this.http.post(URL, data);
    return response;
  }

  postTPWithHeader(URL: string, data: any, headers: any) {
    const response = this.http.post(URL, data, {
      headers: this.createAuthorizationHeader(headers)
    }).pipe(map(res => res));
    return response;
  }

  putTPWithHeader(URL: string, data: any, headers: any) {
    const response = this.http.put(URL, data, {
      headers: this.createAuthorizationHeader(headers)
    }).pipe(map(res => res));
    return response;
  }

  deleteTPWithHeader(URL: string, headers: any) {
    const response = this.http.delete(URL, {
      headers: this.createAuthorizationHeader(headers)
    }).pipe(map(res => res));
    return response;
  }

}
