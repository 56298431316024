import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Smell } from 'src/app/shared/models/smell';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { SmellService } from 'src/app/shared/services/smell/smell.service';
import { SmellDialogComponent } from '../smell-dialog/smell-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-smells',
  templateUrl: './smells.component.html',
  styleUrls: ['./smells.component.scss'],
})
export class SmellsComponent implements OnInit {
  public data = {};
  public isProcessing = false;
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public searchText: any;
  public is_public = null;
  public baseSmellDropDownSearch: FormControl = new FormControl();
  public categorySearch: FormControl = new FormControl();
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);
  displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'total_measurement_count',
    'category',
    'parent_substance_name',
    'is_public',
    'user_name',
    'user_email',
    'createdAt',
  ];
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('allBaseSmell') private allBaseSmell: MatOption =
    Object.create(null);
  @ViewChild('allCategoryCheckbox') private allCategoryCheckbox: MatOption =
    Object.create(null);
  @ViewChild('smellSearch')
  smellSearch: ElementRef | any;
  // Order
  public orderOn = 'id';
  public orderBy = 'desc';
  public futureDate = new Date();

  // Tooltip
  public tooltip = {
    view_substance: 'View Substance',
    update_substance: 'Update Substance',
    delete_substance: 'Delete Substance',
  };

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = ['name'];
  public searchOn = [];
  public length: number = 0;
  public toDate: any = '';
  public fromDate: any = '';
  // Filter mechanism
  public filterOptions = {
    categories: [],
    parentSubstances: [],
  };
  public categories: any = [];
  public parentSubstances: any = [];
  public filteredParentSubstances: any = [];
  public filteredCategories: any = [];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private smellService: SmellService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setDefaultDates();
    this.getSmellFilters();
    this.getSmells();
    this.baseSmellDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredParentSubstances =
        this.filterOptions.parentSubstances.filter((option: any) =>
          option.parent_substance_name
            .toLowerCase()
            .includes(val?.toLowerCase())
        );
    });
    this.categorySearch.valueChanges.subscribe((val) => {
      this.filteredCategories = this.filterOptions.categories.filter(
        (option: any) =>
          option.category.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
    if (params.value && params.value.substance_id) {
      this.openDialog('View', {
        id: params.value.substance_id,
      });
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getSmells();
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getSmells();
    }
  }

  singleSelection(dropDownName: string) {
    if (dropDownName === 'baseSmell') {
      if (this.allBaseSmell.selected) {
        this.allBaseSmell.deselect();
      }

      if (
        this.parentSubstances.length ===
        this.filterOptions.parentSubstances.length
      ) {
        this.allBaseSmell.select();
        this.parentSubstances = [
          ...this.filterOptions.parentSubstances.map(
            (item: any) => item.parent_substance_id
          ),
          -1,
        ];
      }
    } else if (dropDownName === 'category') {
      if (this.allCategoryCheckbox.selected) {
        this.allCategoryCheckbox.deselect();
      }

      if (this.categories.length === this.filterOptions.categories.length) {
        this.allCategoryCheckbox.select();
        this.categories = [
          ...this.filterOptions.categories.map((item: any) => item.category),
          -1,
        ];
      }
    }
    this.getSmells();
  }

  allSelection(dropDownName: string) {
    if (dropDownName === 'baseSmell') {
      if (this.allBaseSmell.selected) {
        this.parentSubstances = [
          ...this.filterOptions.parentSubstances.map(
            (item: any) => item.parent_substance_id
          ),
          -1,
        ];
      } else {
        this.parentSubstances = [];
      }
    } else if (dropDownName === 'category') {
      if (this.allCategoryCheckbox.selected) {
        this.categories = [
          ...this.filterOptions.categories.map((item: any) => item.category),
          -1,
        ];
      } else {
        this.categories = [];
      }
    }
    this.getSmells();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getSmells(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let smellURL = '';

      // SEARCH
      if (this.searchedText) {
        smellURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      if (this.searchOn.length) {
        smellURL += `&searchOn=${JSON.stringify(this.searchOn)}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        smellURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS
      if (this.categories.length) {
        smellURL += `&category=${this.categories.join(',')}`;
        this.setDefaultPage(event);
      }

      if (this.parentSubstances.length) {
        smellURL += `&parent_substance_id=${this.parentSubstances.join(',')}`;
        this.setDefaultPage(event);
      }
      if (this.is_public != null) {
        smellURL += `&isPublic=${this.is_public}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        smellURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      smellURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.smellService.getSmells(smellURL);
      if (res) {
        this.data = res.data.substances;
        this.dataSource = res.data.substances;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getSmellFilters() {
    try {
      const res = await this.smellService.getSmellFilters();
      if (res) {
        this.filterOptions = res.data;
        this.filteredParentSubstances = [
          ...this.filterOptions.parentSubstances,
        ];
        this.filteredCategories = [...this.filterOptions.categories];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getSmells();
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.smellSearch && this.smellSearch.nativeElement) {
        this.smellSearch.nativeElement.focus();
      }
    });
  }

  isFilterApplied() {
    return !(
      this.categories.length ||
      this.parentSubstances.length ||
      this.is_public != null ||
      this.toDate
    );
  }

  clearFilter() {
    this.categories = [];
    this.fromDate = '';
    this.toDate = '';
    this.parentSubstances = [];
    this.is_public = null;
    this.getSmells();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(SmellDialogComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            this.deleteRowData();
          }
        }
      }
    });
  }

  addRowData(row_obj: Smell): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [
      { ...row_obj, total_measurement_count: 0 },
      ...this.dataSource,
    ];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: Smell): boolean | void {
    const index = this.dataSource.findIndex((d: any) => d.id === row_obj.id);
    if (index != -1) {
      this.dataSource[index] = row_obj;
    }
    this.table.renderRows();
  }

  deleteRowData() {
    this.getSmells();
  }
}
