<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <div>
                <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <ng-container *ngIf="showSearch">
                  <mat-form-field class="search-box m-r-10">
                    <mat-label>Search</mat-label>
                    <input matInput #measurementSearch [(ngModel)]="searchedText" placeholder="Search"
                      (keyup)="getMeasurements()">
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </ng-container>
              </div>
              <div (click)="picker.open()" class="only-date-filter">
                <mat-form-field class="example-full-width m-l-10">
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
            </div>
            <button mat-raised-button color="primary" [disabled]="isFilterApplied()" (click)="clearFilter()">Clear
              Filters</button>

            <div *ngIf='length !== 0' class="example-container ex-c-general">
              <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table [dataSource]="dataSource" matSort
                (matSortChange)="sortData($event)" class="table employee-list no-wrap">

                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="user_id">
                  <th disableClear mat-header-cell *matHeaderCellDef>User Id</th>
                  <td class="might-overflow" [matTooltip]="element.user_id" mat-cell *matCellDef="let element">
                    <u>
                      <a target="_blank" (click)="$event.stopPropagation()"
                        [routerLink]="['/user/detail/', element.user_id]">{{ element.user_id }}</a>
                    </u>
                  </td>
                </ng-container>

                <ng-container matColumnDef="substances">
                  <th disableClear mat-header-cell *matHeaderCellDef>Substances</th>
                  <td class="might-overflow" [matTooltip]="element.substances" mat-cell *matCellDef="let element">
                    {{ element.substances }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="device_type">
                  <th disableClear mat-header-cell *matHeaderCellDef>Device Type</th>
                  <td class="might-overflow"
                    [matTooltip]='element.device_type_info.name + "-" + element.device_type_info.version' mat-cell
                    *matCellDef="let element">
                    {{ element.device_type_info.name || '-' }}-{{ element.device_type_info.version || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Name
                  </th>
                  <td class="might-overflow" [matTooltip]="element.name" mat-cell *matCellDef="let element">
                    {{ element.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="mac_address">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Mac Address
                  </th>
                  <td class="might-overflow" [matTooltip]="element.mac_address" mat-cell *matCellDef="let element">
                    {{ element.mac_address }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="measurement_duration">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
                  <td class="might-overflow" [matTooltip]="element.mst_duration" mat-cell *matCellDef="let element">
                    {{ element.mst_duration || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="measurement_size">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Measurement Size
                  </th>
                  <td class="might-overflow" [matTooltip]="element.measurement_size_mb" mat-cell
                    *matCellDef="let element">
                    {{ element.measurement_size_mb }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="connection_type">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Connection Type
                  </th>
                  <td class="might-overflow" [matTooltip]="element.connection_type" mat-cell *matCellDef="let element">
                    {{ element.connection_type }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="recognize_rating">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Recognize Rating
                  </th>
                  <td class="might-overflow" [matTooltip]="element.recognize_rating" mat-cell *matCellDef="let element">
                    {{ element.recognize_rating || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="comment">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Comment
                  </th>
                  <td class="might-overflow" mat-cell *matCellDef="let element">
                    <div *ngIf="!element.comment"> - </div>
                    <div *ngIf="element.comment && element.comment.length <= 16">
                      {{ element.comment || '-' }}
                    </div>
                    <div *ngIf="element.comment && element.comment.length > 16">
                      <textarea readonly [ngModelOptions]="{standalone: true}" rows="2" cols="40"
                        [(ngModel)]="element.comment" #description matInput class="scrollbar textarea-lg"
                        placeholder="Description"></textarea>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="started_at">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Started At
                  </th>
                  <td class="might-overflow" [matTooltip]="element.started_at | date: 'dd MMM YYY, HH.mm.ss' " mat-cell
                    *matCellDef="let element"> {{ element.started_at | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="stopped_at">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Stopped At
                  </th>
                  <td class="might-overflow" [matTooltip]="element.stopped_at | date: 'dd MMM YYY, HH.mm.ss' " mat-cell
                    *matCellDef="let element"> {{ element.stopped_at | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Created At
                  </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss' " mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th class="text-center" mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let element" class="">
                    <a [matTooltip]="tooltip.measurementDetail" [routerLink]="['/measurements/detail/', element.id]"
                      class="m-r-10 cursor-pointer eye">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf="length === 0">No record found</div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getMeasurements($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>