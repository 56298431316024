import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DetectorsComponent } from '../detectors/detectors.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { CustomValidators } from 'src/app/validators/customValidator';

@Component({
  selector: 'app-add-detector',
  templateUrl: './add-detector.component.html',
  styleUrls: ['./add-detector.component.scss'],
})
export class AddDetectorComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public addDetectorForm: any;
  public isProcessing: boolean = false;
  public detectorTypeIds: any = [];
  public filteredDetectorTypes: any = [];
  public detectorTypeSearch: FormControl = new FormControl();
  public detectorBlock: any = [
    {
      isDate: false,
      isLink: false,
      key: 'Batch ID',
      value: null,
    },
    {
      isDate: false,
      isLink: false,
      key: 'Slot ID',
      value: null,
    },
    {
      isDate: false,
      isLink: false,
      key: 'Production Detector ID',
      value: null,
    },
  ];
  public detectorBlockColumns: any = ['key', 'value'];

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DetectorsComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.getDetectorTypes();
    this.resetAddDetector();
    this.setPatchValues();
    this.detectorTypeSearch.valueChanges.subscribe((val) => {
      this.filteredDetectorTypes = this.detectorTypeIds.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  // convenience getter for easy access to form fields
  get addDetectorControls() {
    return this.addDetectorForm.controls;
  }

  resetAddDetector() {
    const groupObj: any = {
      detector_info: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      detector_type_id: [null, Validators.compose([Validators.required])],
    };
    this.addDetectorForm = this.fb.group(groupObj);
  }

  async getDetectorTypes() {
    try {
      this.isProcessing = true;
      const page = 0;
      const perPage = 0;
      const faqCategoryURL = `page=${page}&perPage=${perPage}`;
      const res = await this.deviceService.getDetectorTypes(faqCategoryURL);
      if (res) {
        this.detectorTypeIds = res.data.detectorTypes;
        this.filteredDetectorTypes = [...this.detectorTypeIds];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getDetectorDetails(detector_id: string) {
    const result = await this.deviceService.getDetectorDetails(detector_id);
    return result;
  }

  async setPatchValues() {
    if (this.action === 'Update') {
      const detector_id = this.local_data.id;
      const { data } = await this.getDetectorDetails(detector_id);
      const { batch_id, slot_id, production_detector_id, detector_type_id } =
        data;
      const detector_info = `${batch_id} ${slot_id} ${production_detector_id}`;
      this.local_data.detector_info = detector_info;
      this.addDetectorForm.patchValue({
        detector_info,
        detector_type_id,
      });
      this.detectorBlock = [
        {
          isDate: false,
          isLink: false,
          key: 'Batch ID',
          value: batch_id,
        },
        {
          isDate: false,
          isLink: false,
          key: 'Slot ID',
          value: slot_id,
        },
        {
          isDate: false,
          isLink: false,
          key: 'Production Detector ID',
          value: production_detector_id,
        },
      ];
    } else if (this.action === 'Delete') {
      const { batch_id, slot_id, production_detector_id, detector_type_id } =
        this.local_data;
      const detector_info = `${batch_id}${slot_id}${production_detector_id}`;
      this.local_data.detector_info = detector_info;
    }
  }

  async doAction() {
    await this.submitDetectorForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitDetectorForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const deleteURL = `detector_id=${this.local_data.id}`;
        const res = await this.deviceService.deleteDetector(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else if (this.addDetectorForm.valid) {
      try {
        this.isProcessing = true;
        let res: any;
        const detector_info =
          this.addDetectorForm.controls.detector_info.value.replace(/\s/g, '');
        const reqData: any = {
          detector_info,
          detector_type_id:
            this.addDetectorForm.controls.detector_type_id.value,
        };
        if (this.action === 'Add') {
          res = await this.deviceService.createDetector(reqData);
          this.local_data.device_detector = [];
        } else if (this.action === 'Update') {
          const detector_id = this.local_data.id;
          reqData.detector_id = detector_id;
          res = await this.deviceService.editDetector(reqData);
        }
        this.local_data.detector_type_name = res.data.detector_type.name;
        this.local_data.id = res.data.id;
        this.local_data.batch_id = res.data.batch_id;
        this.local_data.slot_id = res.data.slot_id;
        this.local_data.production_detector_id =
          res.data.production_detector_id;
        this.local_data.detector_type = res.data.detector_type;
        this.local_data.createdAt = res.data.createdAt;
        this.local_data.updatedAt = res.data.updatedAt;
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }

  validNumber(event: any, key: string) {
    let value: any = +event.target.value;
    if (value) {
      if (value < 0) {
        value = value * -1;
      }
      value = parseInt(value, 10);
      event.target.value = value;
      this.local_data[key] = value;
    }
  }

  async validateDetector(event: any, key: any) {
    if (event && event.target.value) {
      event.target.value = event.target.value.replace(/\s/g, '');
      if (event.target.value.length > 14) {
        event.target.value = event.target.value.substr(0, 14);
        this.addDetectorForm.controls.detector_info.value =
          event.target.value.substr(0, 14);
        event.target.value = `${event.target.value.substr(
          0,
          7
        )} ${event.target.value.substr(7, 2)} ${event.target.value.substr(9)}`;
      } else {
        if (event.target.value.length > 7 && event.target.value.length <= 9) {
          event.target.value = `${event.target.value.substr(
            0,
            7
          )} ${event.target.value.substr(7, 2)}`;
        } else if (
          event.target.value.length > 7 &&
          event.target.value.length <= 14
        ) {
          event.target.value = `${event.target.value.substr(
            0,
            7
          )} ${event.target.value.substr(7, 2)} ${event.target.value.substr(
            9
          )}`;
        }
      }
      this.detectorBlock = [
        {
          isDate: false,
          isLink: false,
          key: 'Batch ID',
          value: event.target.value.substr(0, 7),
        },
        {
          isDate: false,
          isLink: false,
          key: 'Slot ID',
          value: event.target.value.substr(8, 2),
        },
        {
          isDate: false,
          isLink: false,
          key: 'Production Detector ID',
          value: event.target.value.substr(11),
        },
      ];
    } else {
      this.detectorBlock = [
        {
          isDate: false,
          isLink: false,
          key: 'Batch ID',
          value: null,
        },
        {
          isDate: false,
          isLink: false,
          key: 'Slot ID',
          value: null,
        },
        {
          isDate: false,
          isLink: false,
          key: 'Production Detector ID',
          value: null,
        },
      ];
    }
  }
}
