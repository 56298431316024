import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirmwaresComponent } from './firmwares/firmwares.component';

const routes: Routes = [
  {
    path: '',
    component: FirmwaresComponent,
    data: {
      title: 'Firmware',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Firmware' }]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FirmwareRoutingModule { }
