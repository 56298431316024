import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FaqService } from 'src/app/shared/services/faq/faq.service';
import * as moment from 'moment';
import { IssueTypeDialogComponent } from '../issue-type-dialog/issue-type-dialog.component';
import { IssueService } from 'src/app/shared/services/issue/issue.service';
export interface IssueType {
  id: number;
  name: string;
  createdAt: string;
}

@Component({
  selector: 'app-issue-types',
  templateUrl: './issue-types.component.html',
  styleUrls: ['./issue-types.component.scss'],
})
export class IssueTypesComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('issueTypeSearch') issueTypeSearch: ElementRef | any;
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'createdAt',
  ];
  public i: number = 1;
  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public searchedText: string = '';
  public showSearch: boolean = false;
  public toDate: any = '';
  public fromDate: any = '';

  // Tooltip
  public tooltip = {
    view_issue_type: 'View Issue Type',
    update_issue_type: 'Update Issue Type',
    delete_issue_type: 'Delete Issue Type',
  };

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';
  public futureDate = new Date();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private issueService: IssueService
  ) {}

  ngOnInit() {
    this.getIssueTypes();
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getIssueTypes();
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getIssueTypes();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getIssueTypes(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;

      //search
      let issueTypesURL = '';
      if (this.searchedText) {
        issueTypesURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }

      //order
      if (this.orderOn && this.orderBy) {
        issueTypesURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      //filter
      if (this.fromDate && this.toDate) {
        issueTypesURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      issueTypesURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.issueService.getIssueTypes(issueTypesURL);
      if (res) {
        this.data = res.data.issueTypes;
        this.dataSource = res.data.issueTypes;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.issueTypeSearch && this.issueTypeSearch.nativeElement) {
        this.issueTypeSearch.nativeElement.focus();
      }
    });
  }

  clearSearch() {
    this.searchedText = '';
    this.showHideSearch();
    this.getIssueTypes();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  isFilterApplied() {
    return !this.toDate;
  }
  clearFilter() {
    this.fromDate = '';
    this.toDate = '';
    this.getIssueTypes();
  }
  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(IssueTypeDialogComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            this.deleteRowData();
          }
        }
      }
    });
  }

  addRowData(row_obj: IssueType): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [row_obj, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: IssueType): boolean | void {
    const index = this.dataSource.findIndex((d: any) => d.id === row_obj.id);
    if (index != -1) {
      this.dataSource[index] = row_obj;
    }
    this.table.renderRows();
  }

  deleteRowData() {
    this.getIssueTypes();
  }
}
