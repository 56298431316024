import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { ActivatedRoute } from '@angular/router';
import { MeasurementService } from 'src/app/shared/services/measurement/measurement.service';
import * as Papa from 'papaparse';
import { MatTable } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import {
  lightningChart,
  PointShape,
  SolidFill,
  ColorHEX,
  AxisTickStrategies,
  emptyTick,
  Axis,
  AxisScrollStrategies,
  PointLineSeries,
  Themes,
  UILayoutBuilders,
  UIDraggingModes,
  UIBackgrounds,
  LegendBoxBuilders,
  UIElementBuilders,
  LinearGradientFill,
  UIOrigins,
  SolidLine,
  ChartXY,
  Theme,
  PointSeries,
  customTheme,
  ColorRGBA,
  FontSettings,
  ConstantLine,
  LegendBox,
  UILUTCheckBox,
  LineSeries,
} from '@arction/lcjs'; //Note: @arction/lcjs is not needed here, when using IIFE assembly

import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { CommonConstants } from 'src/app/shared/common-constants';
import { AddBaselineComponent } from '../baseline/add-baseline.component';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/customValidator';

@Component({
  selector: 'app-measurement-detail',
  templateUrl: './measurement-detail.component.html',
  styleUrls: ['./measurement-detail.component.scss'],
})
export class MeasurementDetailComponent implements OnInit {
  panelOpenState = true;
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public measurementInfo: any = {};
  // public chart: ChartXY | any;
  public chartId: number | undefined;
  public resetFailedChannelForm: any;
  public licenseNumber = environment.CHART_LICENSE;
  public licenseInfo = {
    appTitle: environment.CHART_APP_TITLE,
    company: environment.CHART_COMPANY,
  };

  // Baseline
  public listBaseValueArr: any = [];
  public baselineColumns: string[] = ['select', 'timestamp'];
  public substancesColumns: string[] = ['id', 'name', 'concentration', 'state'];
  public usecaseColumns: string[] = ['id', 'name'];
  public selection = new SelectionModel<any>(true, []);
  public selectedClassifiers: any = [];
  public userInteraction: boolean = false;

  public isProcessing = false;
  public isDeltaProcessing = false;
  public isFuncProcessing = false;
  public isAllChannelsProcessing = false;
  public serialNumber: string | any;
  public deviceData: any = {};
  public comment: string = '';
  public displayedColumns: any = ['name', 'createdAt'];
  public infoBlockColumns: any = ['key', 'value'];
  public curentTab: any = 'Response';

  // Graph related varibales
  public deltaSeries: PointLineSeries | LineSeries | any;
  public functionSeries: (LineSeries | PointLineSeries | any)[] = [];
  public allChannelsSeries: (LineSeries | PointLineSeries | any)[] = [];
  public legendBox: LegendBox | any;
  public myCustomTheme: Theme | any;
  public maxYInterval: number | any;
  public minYInterval: number | any;
  public maxXInterval: number | any;
  public minXInterval: number | any;
  public dateOrigin = new Date();
  public eventSeries: PointSeries | any;
  public color: any = {
    1: '#E6194B',
    2: '#3CB44B',
    3: '#FFE119',
    4: '#4363D8',
    5: '#F58231',
    6: '#911EB4',
    7: '#42D4F4',
    8: '#F032E6',
    9: '#BFEF45',
    10: '#FABED4',
    11: '#469990',
    12: '#DCBEFF',
    13: '#9A6324',
    14: '#808000',
    15: '#800000',
    16: '#AAFFC3',
    17: '#FFFAC8',
  };

  // CSV Calculation vars
  public baseValue: any = null;
  public baseValueArr: any = [];
  public keepOriginalBaseValueArr: any = []; // It will doesn't change
  public functionalValues: any = [];
  public failureValues: any = [];

  // Measurement data
  public showSmells: any = [];
  public showUsecases: any = [];
  public showMeasurementTypes: any = [];

  public tempGraphData: any = [];
  public functionGraphData: any = [];
  public allChannelsGraphData: any = [];
  public deltaGraphData: any = [];
  public smellInfoArray: any = [];
  public useCaseIds: any = [];
  public HEADERS = [
    'timestamp',
    'ch1',
    'ch2',
    'ch3',
    'ch4',
    'ch5',
    'ch6',
    'ch7',
    'ch8',
    'ch9',
    'ch10',
    'ch11',
    'ch12',
    'ch13',
    'ch14',
    'ch15',
    'ch16',
    'ch17',
    'ch18',
    'ch19',
    'ch20',
    'ch21',
    'ch22',
    'ch23',
    'ch24',
    'ch25',
    'ch26',
    'ch27',
    'ch28',
    'ch29',
    'ch30',
    'ch31',
    'ch32',
    'ch33',
    'ch34',
    'ch35',
    'ch36',
    'ch37',
    'ch38',
    'ch39',
    'ch40',
    'ch41',
    'ch42',
    'ch43',
    'ch44',
    'ch45',
    'ch46',
    'ch47',
    'ch48',
    'ch49',
    'ch50',
    'ch51',
    'ch52',
    'ch53',
    'ch54',
    'ch55',
    'ch56',
    'ch57',
    'ch58',
    'ch59',
    'ch60',
    'ch61',
    'ch62',
    'ch63',
    'ch64',
    'humidity',
    'temperature',
  ];
  public lowerBound = 0;
  public upperBound = 350000;
  public keepOriginalLowerBound = 0;
  public keepOriginalUpperBound = 350000;
  public failureObj: any = {};
  public da: any = [];
  public excludeCol: any = [];
  public includeCol: any = {};
  public channelObj: any = {};
  public variableChannels: any = [];
  public customLegends: any = {};
  public allChannelsCustomLegend: any = {};
  public functionValue: any = [];
  public functionalObj: any = {};

  // MAIN GRAPH VARS
  public tempGraph: ChartXY | any;
  public functionGraph: ChartXY | any;
  public deltaGraph: ChartXY | any;
  public allChannelsGraph: ChartXY | any;
  public tempGraphId: number | any;
  public functionGraphId: number | any;
  public deltaGraphId: number | any;
  public allChannelsGraphId: number | any;
  public functionX: Axis | any;
  public maxYTemp: number | any;
  public minYTemp: number | any;
  public maxXTemp: number | any;
  public minXTemp: number | any;
  public minYFunc: number | any;
  public maxYFunc: number | any;
  public maxXFunc: number | any;
  public minXFunc: number | any;
  public defaultDeltaMin: number | any;
  public defaultDeltaMax: number | any;
  public temperatureLine: PointLineSeries | LineSeries | any;
  public humidityLine: PointLineSeries | LineSeries | any;
  public defaultTempMax: number | any;
  public defaultTempMin: number | any;
  public currentDeltaStartPoint: number | any;
  public currentDeltaEndPoint: number | any;
  public currentTempStartPoint: number | any;
  public currentTempEndPoint: number | any;
  public zoomedOrPanned = false;
  public zoomedOrPannedTemp = false;
  public eventData: any = [];
  public isEventShow = true;
  public allMeasurementScreen = true;
  public toggleFunctionGraph: boolean = false;
  public initializedFunctions: boolean = false;
  public toggleChannelsGraph: boolean = false;
  public initializedChannels: boolean = false;
  public parsedCSVData: any = [];
  public parsedDataToPlot: any = [];
  // Sync Graph Variables
  public isInSync: boolean = true;
  public deltaMouseIn: boolean = true;
  public tempMouseIn: boolean = false;
  public functionIn: boolean = false;
  public allChannelsIn: boolean = false;

  // Graph HTML
  // Set the correct value to use for the data frequency.
  // 1000ms * 60s * 60min * 24h
  public dataFrequency = 1000 * 60 * 60 * 24;
  public xAxis: any;
  public yAxis: any;
  public series: any = [];
  public constantLines: any = [];
  public events: any = [];
  public colors: any = [];
  public graphColors: any = [];
  public legendLayout: any;
  public legendList: any;
  public chart: any;
  // public events: any = [];

  public now = moment();

  stringToDate(s: any) {
    s = s.split(/[-: ]/);
    return new Date(s[0], s[1] - 1, s[2], s[3], s[4], s[5]);
  }

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private measurementService: MeasurementService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    this.functionGraphId = Math.trunc(Math.random() * 1000000);
    this.deltaGraphId = Math.trunc(Math.random() * 1000000);
    this.tempGraphId = Math.trunc(Math.random() * 1000000);
    this.allChannelsGraphId = Math.trunc(Math.random() * 1000000);

    this.myCustomTheme = customTheme(Themes.light, {
      pointMarkerFillStyle: new SolidFill({ color: ColorHEX('#3e3e3e') }),
      resultTableFillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0, 255) }),
      resultTableStrokeStyle: new SolidLine({ thickness: 0 }),
      resultTableTextFillStyle: new SolidFill({ color: ColorHEX('#ffffff') }),
      resultTableTextFont: new FontSettings({
        size: 12,
        family: 'Arial',
      }),
      uiBackgroundFillStyle: new SolidFill({ color: ColorHEX('#ffffff') }),
      uiTextFillStyle: new SolidFill({ color: ColorHEX('#3e3e3e') }),
      uiFont: new FontSettings({ size: 12, family: 'Arial' }),
    });
  }

  ngOnInit() {
    this.getMeasurementDetails();
    this.resetFailedChannelsForm();
  }

  ngAfterViewInit() { }
  // convenience getter for easy access to form fields
  get resetFailedChannelControls() {
    return this.resetFailedChannelForm.controls;
  }

  resetFailedChannelsForm() {
    const groupObj: any = {
      lower_bound: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.maxIntValidator,
        ]),
      ],
      upper_bound: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.maxIntValidator,
        ]),
      ],
    };
    const validateBoundries = {
      validator: CustomValidators.boundsValidator('lower_bound', 'upper_bound'),
    };
    this.resetFailedChannelForm = this.fb.group(groupObj, validateBoundries);
    this.resetFailedChannelForm.patchValue({
      upperBound: this.upperBound,
      lowerBound: this.lowerBound,
    });
  }

  validNumber(event: any, name: any) {
    let value: any = +event.target.value;
    if (value) {
      if (value < 0) {
        value = value * -1;
      }
      value = parseInt(value, 10);
      event.target.value = value;
      name === 'upper_bound'
        ? (this.upperBound = value)
        : (this.lowerBound = value);
    }
  }

  openDialog(action: string): void {
    const obj = {
      action,
      parsedDataToPlot: this.parsedDataToPlot,
    };
    const dialogRef = this.dialog.open(AddBaselineComponent, {
      minWidth: '500px',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            if (result.data && result.data.timestamp_utc) {
              this.setGraphLoader(true);
              setTimeout(() => {
                this.addBaseValue(result.data.timestamp_utc, true);
              }, 0);
            }
          }
        }
      }
    });
  }

  setupGraphs() {
    this.setUpDeltaGraph();
    this.setUpTempGraph();
    // this.setUpFunctionGraph();
  }

  rewriteGraphs(label: string) {
    if (label === 'Features') {
      this.toggleGraph(true);
    } else if (label === 'Channels') {
      this.toggleAllChannelsGraph(true);
    } else if (label === 'Response') {
      this.allChannelsIn = false;
      this.functionIn = false;
      this.tempMouseIn = false;
      this.deltaMouseIn = true;
    }
  }

  toggleGraph(value: boolean) {
    this.toggleFunctionGraph = value;
    this.functionIn = true;
    this.allChannelsIn = false;
    this.tempMouseIn = false;
    this.deltaMouseIn = false;
    if (this.toggleFunctionGraph && !this.initializedFunctions) {
      this.isFuncProcessing = true;
      this.cd.detectChanges();
      setTimeout(() => {
        this.setUpFunctionGraph();
        this.setFunctionData(this.functionGraphData, true);
        this.initializedFunctions = true;
      });
    }
  }

  validateGraphExistency() {
    const obj = {
      functionGraph: !!document.getElementById(this.functionGraphId),
      deltaGraph: !!document.getElementById(this.deltaGraphId),
      tempGraph: !!document.getElementById(this.tempGraphId),
      allChannelsGraph: !!document.getElementById(this.allChannelsGraphId),
      isExistTempGraph: !!this.tempGraph,
      isExistFunctionGraph: !!this.functionGraph,
      isExistDeltaGraph: !!this.deltaGraph,
      isExistAllChannelsGraph: !!this.allChannelsGraph,
    };
    return obj;
  }

  resetBaseValues() {
    this.setGraphLoader(true);
    this.baseValueArr = [];
    this.baseValueArr = [...this.keepOriginalBaseValueArr];
    this.baseValueArr.sort((a: any, b: any) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
    this.baseValue = this.baseValueArr[0];
    this.listBaseValueArr = [...this.baseValueArr];
    this.selectedClassifiers = [...this.baseValueArr].map((m: any) => {
      this.selection.toggle(m);
      return m.timestamp;
    });
    this.selection.select(...this.baseValueArr);
    this.findAndResetGraphs();
    const { isExistFunctionGraph } = this.validateGraphExistency();
    this.plotGraph(this.parsedDataToPlot, 'all');
    if (isExistFunctionGraph) {
      setTimeout(() => {
        this.setFunctionData(this.functionGraphData, true);
      }, 0);
    }

    setTimeout(() => {
      this.setGraphLoader();
    }, 0);
  }

  sortBaseValues() {
    this.baseValueArr.sort((a: any, b: any) => {
      return new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
    });
    this.baseValue = this.baseValueArr[0];
  }

  refreshGraph(label: any) {
    if (label === 'Response') {
      // Delta Graph
      if (this.isInSync) {
        this.deltaMouseIn = true;
        this.tempMouseIn = false;
      }
      // this.resetBaseValues();
      this.sortBaseValues();
      this.deltaGraph.dispose();
      this.setUpDeltaGraph();
      const allowDelta = true;
      const allowDeltaEvents = true;
      const allowTemp = false;
      this.plotGraph(
        this.parsedDataToPlot,
        'all',
        allowDelta,
        allowDeltaEvents,
        allowTemp
      );
    } else if (label === 'Features') {
      // All channels Graph
      // this.resetBaseValues();
      this.sortBaseValues();
      this.functionGraph.dispose();
      this.setUpFunctionGraph();
      const allowDelta = false;
      const allowDeltaEvents = false;
      const allowTemp = false;
      this.plotGraph(
        this.parsedDataToPlot,
        'all',
        allowDelta,
        allowDeltaEvents,
        allowTemp
      );
      setTimeout(() => {
        this.setFunctionData(this.functionGraphData, true);
      }, 0);
    } else if (label === 'Channels') {
      // All channels with absolute Graph
      // this.resetBaseValues();
      this.sortBaseValues();
      this.allChannelsGraph.dispose();
      this.setUpAllChannelsGraph();
      const allowDelta = false;
      const allowDeltaEvents = false;
      const allowTemp = false;
      this.plotGraph(
        this.parsedDataToPlot,
        'all',
        allowDelta,
        allowDeltaEvents,
        allowTemp
      );
      setTimeout(() => {
        this.setAllChannelsData(this.allChannelsGraphData, true);
      }, 0);
    } else if (label === 'Temp') {
      // Temprature/Humidity graph
      if (this.isInSync) {
        this.tempMouseIn = true;
        this.deltaMouseIn = false;
        this.functionIn = false;
        this.allChannelsIn = false;
      }
      // this.resetBaseValues();
      this.sortBaseValues();
      this.tempGraph.dispose();
      this.setUpTempGraph();
      const allowDelta = false;
      const allowDeltaEvents = false;
      const allowTemp = true;
      this.plotGraph(
        this.parsedDataToPlot,
        'all',
        allowDelta,
        allowDeltaEvents,
        allowTemp
      );
    }
  }

  toggleAllChannelsGraph(value: boolean) {
    this.toggleChannelsGraph = value;
    this.allChannelsIn = true;
    this.functionIn = false;
    this.tempMouseIn = false;
    this.deltaMouseIn = false;
    if (this.toggleChannelsGraph && !this.initializedChannels) {
      this.isAllChannelsProcessing = true;
      this.cd.detectChanges();
      setTimeout(() => {
        this.setUpAllChannelsGraph();
        this.setAllChannelsData(this.allChannelsGraphData, true);
        this.initializedChannels = true;
      });
    }
  }

  setUpDeltaGraph() {
    this.deltaGraph = lightningChart({
      license: this.licenseNumber,
      licenseInformation: this.licenseInfo,
    })
      .ChartXY({
        container: `${this.deltaGraphId}`,
        theme: this.myCustomTheme,
        defaultAxisX: { type: 'linear' },
        defaultAxisY: { type: 'linear' },
      })
      .setAnimationsEnabled(false)
      .setTitleFont(
        new FontSettings({
          size: 16,
          family: 'Arial',
        })
      );
    this.deltaGraph.setTitle('');
    this.eventSeries = this.deltaGraph.getDefaultAxisX();
    this.deltaGraph
      .getDefaultAxisY()
      .setInterval(this.minYInterval, this.maxYInterval, false, false)
      .setScrollStrategy(AxisScrollStrategies.expansion)
      .setTickStrategy(AxisTickStrategies.Numeric, (ticks: any) =>
        ticks.setMajorTickStyle((major: any) =>
          major.setLabelFont((font: any) => font.setFamily('Arial').setSize(12))
        )
      );
    this.deltaGraph
      .getDefaultAxisX()
      .setInterval(this.minXInterval, this.maxXInterval, false, false)
      .setScrollStrategy(AxisScrollStrategies.progressive)
      .setTickStrategy(AxisTickStrategies.DateTime, (timeTicks: any) =>
        timeTicks
          .setDateOrigin(this.dateOrigin)
          .setGreatTickStyle(emptyTick)
          .setMajorTickStyle((major: any) =>
            major.setLabelFont((font: any) =>
              font.setFamily('Arial').setSize(12)
            )
          )
      );
    this.deltaSeries = this.deltaGraph.addPointLineSeries({
      pointShape: PointShape.Circle,
    });
    this.deltaSeries
      .setPointSize(6)
      .setPointFillStyle(new SolidFill({ color: ColorHEX('#80D9FF') }))
      .setStrokeStyle((style: any) =>
        style
          .setThickness(1)
          .setFillStyle(new SolidFill({ color: ColorHEX('#80D9FF') }))
      )
      .setMouseInteractions(false);
    this.deltaGraph.onSeriesBackgroundMouseDrag(() => {
      this.zoomedOrPanned = true;
    });
    this.deltaGraph.onSeriesBackgroundMouseWheel(() => {
      this.zoomedOrPanned = true;
    });
    this.deltaGraph.getDefaultAxisX().onScaleChange((start: any, end: any) => {
      if (this.isInSync && !this.tempMouseIn) {
        this.tempGraph.getDefaultAxisX().setInterval(start, end, false, true);
      }
    });
    const delta: any = document.getElementById(this.deltaGraphId);
    delta.addEventListener('mousemove', () => {
      this.deltaGraph.getDefaultAxisX().setTitle('');
    });
    delta.addEventListener('mouseenter', () => {
      this.deltaMouseIn = true;
      this.tempMouseIn = false;
    });
    delta.addEventListener('mouseleave', () => {
      this.deltaMouseIn = false;
      if (!this.tempMouseIn) {
        this.tempMouseIn = true;
      }
    });
  }

  setUpFunctionGraph() {
    this.functionGraph = lightningChart({
      license: this.licenseNumber,
      licenseInformation: this.licenseInfo,
    })
      .ChartXY({
        container: `${this.functionGraphId}`,
        theme: this.myCustomTheme,
        defaultAxisX: { type: 'linear' },
        defaultAxisY: { type: 'linear' },
      })
      .setAnimationsEnabled(false)
      .setTitleFont(
        new FontSettings({
          size: 16,
          family: 'Arial',
        })
      );
    this.functionGraph.setTitle('');
    this.functionX = this.functionGraph.getDefaultAxisX();
    this.functionGraph
      .getDefaultAxisY()
      .setInterval(this.minYInterval, this.maxYInterval, false, false)
      .setTickStrategy(AxisTickStrategies.Numeric, (ticks: any) =>
        ticks.setMajorTickStyle((major: any) =>
          major.setLabelFont((font: any) => font.setFamily('Arial').setSize(12))
        )
      );
    this.functionGraph
      .getDefaultAxisX()
      .setInterval(this.minXFunc, this.maxXFunc, false, false)
      .setTickStrategy(AxisTickStrategies.DateTime, (timeTicks: any) =>
        timeTicks
          .setDateOrigin(this.dateOrigin)
          .setGreatTickStyle(emptyTick)
          .setMajorTickStyle((major: any) =>
            major.setLabelFont((font: any) =>
              font.setFamily('Arial').setSize(12)
            )
          )
      );
    const functionGraph: any = document.getElementById(this.functionGraphId);
    functionGraph.addEventListener('mousemove', () => {
      this.functionGraph.getDefaultAxisX().setTitle('');
    });
    functionGraph.addEventListener('mouseenter', () => {
      this.functionIn = true;
      this.tempMouseIn = false;
    });
    functionGraph.addEventListener('mouseleave', () => {
      this.functionIn = true;
      this.tempMouseIn = false;
    });
    this.functionGraph
      .getDefaultAxisX()
      .onScaleChange((start: any, end: any) => {
        if (this.isInSync && !this.tempMouseIn) {
          this.tempGraph.getDefaultAxisX().setInterval(start, end, false, true);
        }
      });
  }

  async setFunctionData(data: any, isFirst: boolean) {
    if (data.length) {
      if (
        this.functionSeries &&
        Array.isArray(this.functionSeries) &&
        this.functionSeries.length > 0
      ) {
        this.functionSeries.map((m: any) => {
          return m.clear();
        });
      }
      const first = data[0];
      const last = data[data.length - 1];
      const measurementLength = (last.x - first.x) / 1000;
      const xPadding = measurementLength * 0.0277 * 1000;
      const minXFunc =
        this.stringToDate(
          moment(first.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() - xPadding;
      const maxXFunc =
        this.stringToDate(
          moment(last.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() + xPadding;
      this.functionGraph
        .getDefaultAxisX()
        .setInterval(minXFunc, maxXFunc, false, true);
      const dynamicFuncArray: any = [];
      Object.keys(data[0].y).map((_, index) => {
        const color = this.color[index + 1];
        this.customLegends[_] = { color: `color${index + 1}`, isShow: true }; // Show all legends
        // change here
        Object.keys(data[0].y[_]).map((rr) => {
          if (data.length > 1800 * 6) {
            return dynamicFuncArray.push(
              this.functionGraph
                .addLineSeries({
                  dataPattern: {
                    pattern: 'ProgressiveX',
                  },
                })
                .setName(rr)
                .setStrokeStyle((stroke: any) =>
                  stroke.setThickness(2).setFillStyle(new SolidFill({ color: ColorHEX(color) }))
                )
                .setCursorInterpolationEnabled(false)
            );
          } else {
            return dynamicFuncArray.push(
              this.functionGraph
                .addPointLineSeries({
                  dataPattern: {
                    pattern: 'ProgressiveX',
                  },
                  pointShape: PointShape.Circle,
                })
                .setName(rr)
                .setStrokeStyle((stroke: any) =>
                  stroke
                    .setThickness(1)
                    .setFillStyle(new SolidFill({ color: ColorHEX(color) }))
                )
                .setPointSize(6)
                .setPointFillStyle(new SolidFill({ color: ColorHEX(color) }))
            );
          }
        });
      });
      this.functionSeries = dynamicFuncArray;
      const seriesArray: any = [];
      Object.keys(data[0].y).map((rr) => {
        Object.keys(data[0].y[rr]).map((item) => {
          seriesArray.push(item.split('ch')[1]);
          return item;
        });
      });
      setTimeout(() => {
        const seriesValue = seriesArray;
        this.functionSeries.forEach((series: any, index: any) => {
          const dataset: { x: number; y: number }[] = [];
          for (const item of data) {
            if (item) {
              let xTime: number;
              if (String(item.x).length === 13) {
                xTime = this.stringToDate(
                  moment(item.x).format('YYYY-MM-DD HH:mm:ss')
                ).getTime();
              } else {
                xTime = item.x;
              }
              // eslint-disable-next-line @typescript-eslint/ban-types
              const y = item?.y as any;
              const newY: any = {};
              Object.keys(y).map((d: any) => {
                Object.keys(y[d]).map((r: any) => {
                  newY[r.split('ch')[1]] = y[d][r];
                });
              });
              const yVal = newY[seriesValue[index]];
              if (xTime && yVal !== undefined) {
                dataset.push({ x: xTime, y: yVal });
              }
            }
          }
          series.add(dataset);
          series.setCursorResultTableFormatter(
            (tableBuilder: any, _series: any, x: any, y: any) => {
              const cond = this.events.some((eventItem: any) => {
                const maxBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() -
                  1500;
                const minBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() +
                  1500;
                if (maxBound < x && minBound > x) {
                  return true;
                }
              });
              if (cond) {
                const event_name = this.events.filter((eventItem: any) => {
                  const maxBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() -
                    1500;
                  const minBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() +
                    1500;
                  if (maxBound < x && minBound > x) {
                    return eventItem;
                  }
                });
                return tableBuilder
                  .addRow(`${event_name[0]?.name}`)
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`);
              } else {
                return tableBuilder
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`)
                  .addRow(_series.getName());
              }
            }
          );
        });
        setTimeout(() => {
          const start = this.functionGraph.getDefaultAxisY().getInterval().start;
          const end = this.functionGraph.getDefaultAxisY().getInterval().end;
          if (end <= 2 || start >= -2) {
            this.functionGraph.getDefaultAxisY().setInterval(start - 1, end + 1, false, true);
          } else if (end > 2 || start < 2) {
            this.functionGraph.getDefaultAxisY().setInterval(start * 1.2, end * 1.2, false, true);
          }
        }, 100);
        this.setFunctionGraphEvent();
        this.isFuncProcessing = false;
      }, 0);
    }
  }

  setFunctionGraphEvent() {
    if (this.events.length && this.isEventShow) {
      this.events = this.events.map((i: any) => {
        const xTime = (i.event_time as number) - this.dateOrigin.getTime();
        const line2 = this.functionGraph
          .getDefaultAxisX()
          .addConstantLine(true)
          .setMouseInteractions(false)
          .setValue(xTime)
          .setStrokeStyle(
            new SolidLine({
              thickness: 2,
              fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
            })
          );
        return { ...i, line2Event: { line2 } };
      });
    }
  }

  setUpAllChannelsGraph() {
    this.allChannelsGraph = lightningChart({
      license: this.licenseNumber,
      licenseInformation: this.licenseInfo,
    })
      .ChartXY({
        container: `${this.allChannelsGraphId}`,
        theme: this.myCustomTheme,
        defaultAxisX: { type: 'linear' },
        defaultAxisY: { type: 'linear' },
      })
      .setAnimationsEnabled(false)
      .setTitleFont(
        new FontSettings({
          size: 16,
          family: 'Arial',
        })
      );
    this.allChannelsGraph.setTitle('');
    this.functionX = this.allChannelsGraph.getDefaultAxisX();
    this.allChannelsGraph
      .getDefaultAxisY()
      .setInterval(this.minYInterval, this.maxYInterval, false, false)
      .setTickStrategy(AxisTickStrategies.Numeric, (ticks: any) =>
        ticks.setMajorTickStyle((major: any) =>
          major.setLabelFont((font: any) => font.setFamily('Arial').setSize(12))
        )
      );
    this.allChannelsGraph
      .getDefaultAxisX()
      .setInterval(this.minXFunc, this.maxXFunc, false, false)
      .setTickStrategy(AxisTickStrategies.DateTime, (timeTicks: any) =>
        timeTicks
          .setDateOrigin(this.dateOrigin)
          .setGreatTickStyle(emptyTick)
          .setMajorTickStyle((major: any) =>
            major.setLabelFont((font: any) =>
              font.setFamily('Arial').setSize(12)
            )
          )
      );
    this.allChannelsGraph
      .getDefaultAxisX()
      .onScaleChange((start: any, end: any) => {
        if (this.isInSync && !this.tempMouseIn) {
          this.tempGraph.getDefaultAxisX().setInterval(start, end, false, true);
        }
      });
    const allChannelsGraph: any = document.getElementById(
      this.allChannelsGraphId
    );
    allChannelsGraph.addEventListener('mousemove', () => {
      this.allChannelsGraph.getDefaultAxisX().setTitle('');
    });
    allChannelsGraph.addEventListener('mouseenter', () => {
      this.allChannelsIn = true;
      this.tempMouseIn = false;
    });
    allChannelsGraph.addEventListener('mouseleave', () => {
      this.allChannelsIn = true;
      this.tempMouseIn = false;
    });
  }

  async setAllChannelsData(data: any, isFirst: boolean) {
    if (data.length) {
      if (
        this.allChannelsSeries &&
        Array.isArray(this.allChannelsSeries) &&
        this.allChannelsSeries.length > 0
      ) {
        this.allChannelsSeries.map((m: any) => {
          return m.clear();
        });
      }
      const first = data[0];
      const last = data[data.length - 1];
      const measurementLength = (last.x - first.x) / 1000;
      const xPadding = measurementLength * 0.0277 * 1000;
      const minXFunc =
        this.stringToDate(
          moment(first.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() - xPadding;
      const maxXFunc =
        this.stringToDate(
          moment(last.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() + xPadding;
      this.allChannelsGraph
        .getDefaultAxisX()
        .setInterval(minXFunc, maxXFunc, false, true);
      const dynamicFuncArray: any = [];
      Object.keys(data[0].y).map((_, index) => {
        const color = this.color[index + 1];
        this.allChannelsCustomLegend[_] = {
          color: `color${index + 1}`,
          isShow: true,
        }; // Show all legends
        Object.keys(data[0].y[_]).map((rr) => {
          if (data.length > 1800 * 6) {
            return dynamicFuncArray.push(
              this.allChannelsGraph
                .addLineSeries({
                  dataPattern: {
                    pattern: 'ProgressiveX',
                  },
                })
                .setName(rr)
                .setStrokeStyle((stroke: any) =>
                  stroke
                    .setThickness(2)
                    .setFillStyle(new SolidFill({ color: ColorHEX(color) }))
                ).
                setCursorInterpolationEnabled(false)
            );
           } else {
            return dynamicFuncArray.push(
              this.allChannelsGraph
                .addPointLineSeries({
                  dataPattern: {
                    pattern: 'ProgressiveX',
                  },
                  pointShape: PointShape.Circle,
                })
                .setName(rr)
                .setStrokeStyle((stroke: any) =>
                  stroke
                    .setThickness(1)
                    .setFillStyle(new SolidFill({ color: ColorHEX(color) }))
                )
                .setPointSize(6)
                .setPointFillStyle(new SolidFill({ color: ColorHEX(color) }))
            );
          }
        });
      });
      this.allChannelsSeries = dynamicFuncArray;
      const seriesArray: any = [];
      Object.keys(data[0].y).map((rr) => {
        Object.keys(data[0].y[rr]).map((item) => {
          seriesArray.push(item.split('ch')[1]);
          return item;
        });
      });
      const seriesValue = seriesArray;
      this.allChannelsSeries.forEach((series: any, index: any) => {
        for (const item of data) {
          if (item) {
            let xTime: number;
            if (String(item.x).length === 13) {
              xTime = this.stringToDate(
                moment(item.x).format('YYYY-MM-DD HH:mm:ss')
              ).getTime();
            } else {
              xTime = item.x;
            }
            // eslint-disable-next-line @typescript-eslint/ban-types
            const y = item?.y as any;
            const newY: any = {};
            Object.keys(y).map((d: any) => {
              Object.keys(y[d]).map((r: any) => {
                newY[r.split('ch')[1]] = y[d][r];
              });
            });
            const yVal = newY[seriesValue[index]];
            if (xTime && yVal !== undefined) {
              series.add({ x: xTime, y: yVal });
            }
          }
        }
        series.setCursorResultTableFormatter(
          (tableBuilder: any, _series: any, x: any, y: any) => {
            const cond = this.events.some((eventItem: any) => {
              const maxBound =
                Number(eventItem.event_time) - this.dateOrigin.getTime() - 1500;
              const minBound =
                Number(eventItem.event_time) - this.dateOrigin.getTime() + 1500;
              if (maxBound < x && minBound > x) {
                return true;
              }
            });
            if (cond) {
              const event_name = this.events.filter((eventItem: any) => {
                const maxBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() -
                  1500;
                const minBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() +
                  1500;
                if (maxBound < x && minBound > x) {
                  return eventItem;
                }
              });
              return tableBuilder
                .addRow(`${event_name[0]?.name}`)
                .addRow(
                  `X: ${moment(
                    new Date(x).getTime() + this.dateOrigin.getTime()
                  ).format('DD MMM YYYY, HH.mm.ss')}`
                )
                .addRow(`Y ${Number(y).toFixed(2)}`);
            } else {
              return tableBuilder
                .addRow(
                  `X: ${moment(
                    new Date(x).getTime() + this.dateOrigin.getTime()
                  ).format('DD MMM YYYY, HH.mm.ss')}`
                )
                .addRow(`Y ${Number(y).toFixed(2)}`)
                .addRow(_series.getName());
            }
          }
        );
      });
      this.setAllChannelsGraphEvent();
      this.isAllChannelsProcessing = false;
    }
  }

  setAllChannelsGraphEvent() {
    if (this.events.length && this.isEventShow) {
      this.events = this.events.map((i: any) => {
        const xTime = (i.event_time as number) - this.dateOrigin.getTime();
        const line3 = this.allChannelsGraph
          .getDefaultAxisX()
          .addConstantLine(true)
          .setMouseInteractions(false)
          .setValue(xTime)
          .setStrokeStyle(
            new SolidLine({
              thickness: 2,
              fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
            })
          );
        return { ...i, line3Event: { line3 } };
      });
    }
  }

  setUpTempGraph() {
    this.tempGraph = lightningChart({
      license: this.licenseNumber,
      licenseInformation: this.licenseInfo,
    })
      .ChartXY({
        container: `${this.tempGraphId}`,
        theme: this.myCustomTheme,
        defaultAxisX: { type: 'linear' },
        defaultAxisY: { type: 'linear' },
      })
      .setAnimationsEnabled(false)
      .setTitleFont(
        new FontSettings({
          size: 16,
          family: 'Arial',
        })
      );
    this.tempGraph.setTitle('');
    this.tempGraph
      .getDefaultAxisY()
      .setInterval(this.minYTemp, this.maxYTemp, false, false)
      .setScrollStrategy(AxisScrollStrategies.expansion)
      .setTickStrategy(AxisTickStrategies.Numeric, (ticks: any) =>
        ticks.setMajorTickStyle((major: any) =>
          major.setLabelFont((font: any) => font.setFamily('Arial').setSize(12))
        )
      )
      .setScrollStrategy(AxisScrollStrategies.regressive);
    this.tempGraph
      .getDefaultAxisX()
      .setInterval(this.minXTemp, this.maxXTemp, false, false)
      .setScrollStrategy(AxisScrollStrategies.progressive)
      .setTickStrategy(AxisTickStrategies.DateTime, (timeTicks: any) =>
        timeTicks
          .setDateOrigin(this.dateOrigin)
          .setGreatTickStyle(emptyTick)
          .setMajorTickStyle((major: any) =>
            major.setLabelFont((font: any) =>
              font.setFamily('Arial').setSize(12)
            )
          )
      );
    this.temperatureLine = this.tempGraph
      .addPointLineSeries({
        dataPattern: {
          pattern: 'ProgressiveX',
        },
        pointShape: PointShape.Circle,
      })
      .setName('Temperature(°C)')
      .setStrokeStyle((stroke: any) =>
        stroke
          .setThickness(1)
          .setFillStyle(new SolidFill({ color: ColorHEX('#F70002') }))
      )
      .setPointSize(6)
      .setPointFillStyle(new SolidFill({ color: ColorHEX('#F70002') }))
      .setCursorInterpolationEnabled(false)
      .setCursorResultTableFormatter(
        (tableBuilder: any, _series: any, x: any, y: any, dataPoint: any) =>
          tableBuilder
            .addRow(
              `X: ${moment(
                new Date(x).getTime() + this.dateOrigin.getTime()
              ).format('DD MMM YYYY, HH.mm.ss')}`
            )
            .addRow(`Y ${Number(y).toFixed(2)}`)
      );
    this.humidityLine = this.tempGraph
      .addPointLineSeries({
        dataPattern: {
          pattern: 'ProgressiveX',
        },
        pointShape: PointShape.Circle,
      })
      .setName('Humidity(%)')
      .setStrokeStyle((stroke: any) =>
        stroke
          .setThickness(1)
          .setFillStyle(new SolidFill({ color: ColorHEX('#4BC0C0') }))
      )
      .setPointSize(6)
      .setPointFillStyle(new SolidFill({ color: ColorHEX('#4BC0C0') }))
      .setCursorInterpolationEnabled(false)
      .setCursorResultTableFormatter(
        (tableBuilder: any, _series: any, x: any, y: any, dataPoint: any) =>
          tableBuilder
            .addRow(
              `X: ${moment(
                new Date(x).getTime() + this.dateOrigin.getTime()
              ).format('DD MMM YYYY, HH.mm.ss')}`
            )
            .addRow(`Y ${Number(y).toFixed(2)}`)
      );
    this.tempGraph.onSeriesBackgroundMouseDrag(() => {
      this.zoomedOrPannedTemp = true;
    });
    this.tempGraph.onSeriesBackgroundMouseWheel(() => {
      this.zoomedOrPannedTemp = true;
    });
    this.tempGraph.getDefaultAxisX().onScaleChange((start: any, end: any) => {
      if (
        this.isInSync &&
        !this.deltaMouseIn &&
        this.curentTab === 'Response'
      ) {
        this.deltaGraph.getDefaultAxisX().setInterval(start, end, false, true);
      }
      if (this.isInSync && !this.functionIn && this.curentTab === 'Features') {
        this.functionGraph
          .getDefaultAxisX()
          .setInterval(start, end, false, true);
      }
      if (
        this.isInSync &&
        !this.allChannelsIn &&
        this.curentTab === 'Channels'
      ) {
        this.allChannelsGraph
          .getDefaultAxisX()
          .setInterval(start, end, false, true);
      }
    });
    const tempGraph: any = document.getElementById(this.tempGraphId);
    tempGraph.addEventListener('mousemove', () => {
      this.tempGraph.getDefaultAxisX().setTitle('');
    });
    tempGraph.addEventListener('mouseenter', () => {
      this.tempMouseIn = true;
      this.deltaMouseIn = false;
      this.functionIn = false;
      this.allChannelsIn = false;
    });
    tempGraph.addEventListener('mouseleave', () => {
      this.tempMouseIn = false;
      if (!this.deltaMouseIn && this.curentTab === 'Response') {
        this.deltaMouseIn = true;
      }
      if (!this.functionIn && this.curentTab === 'Features') {
        this.functionIn = true;
      }
      if (!this.allChannelsIn && this.curentTab === 'Channels') {
        this.allChannelsIn = true;
      }
    });
  }

  arrayMin(arr: any) {
    if (arr && Array.isArray(arr) && arr.length) {
      return arr.reduce((p: any, v: any) => {
        return p < v ? p : v;
      });
    }
    return '0';
  }

  arrayMax(arr: any) {
    if (arr && Array.isArray(arr) && arr.length) {
      return arr.reduce((p: any, v: any) => {
        return p > v ? p : v;
      });
    }
    return '0';
  }

  addData(
    tempHumidData: any,
    deltaData: any,
    channelData: any,
    sepearateChannelData?: any,
    allChannelsData?: any,
    allowDelta: boolean = true,
    allowDeltaEvents: boolean = true,
    allowTemp: boolean = true
  ) {
    if (allowDelta) {
      if (deltaData?.length) {
        this.deltaGraphData = [];
        this.deltaGraphData = deltaData.map((data: any) => ({
          ...data,
          x: moment(data.x).valueOf() - this.dateOrigin.getTime(),
        }));
        this.setDeltaData(this.deltaGraphData, false);
      } else {
        this.setDeltaData(deltaData, false);
      }
    }
    if (allowDeltaEvents) {
      this.eventData.sort((a: any, b: any) => a.event_time - b.event_time);
      this.setEventData(this.eventData, allowDeltaEvents);
    }
    if (sepearateChannelData?.length) {
      this.functionGraphData = [];
      this.functionGraphData = sepearateChannelData.map((data: any) => ({
        ...data,
        x: moment(data.x).valueOf() - this.dateOrigin.getTime(),
      }));
    }
    if (allChannelsData?.length) {
      this.allChannelsGraphData = [];
      this.allChannelsGraphData = allChannelsData.map((data: any) => ({
        ...data,
        x: moment(data.x).valueOf() - this.dateOrigin.getTime(),
      }));
    }
    if (allowTemp) {
      if (tempHumidData?.length) {
        this.tempGraphData = [];
        this.tempGraphData = tempHumidData;
        this.setTempGraphData(this.tempGraphData, false);
      } else {
        this.setTempGraphData(tempHumidData, false);
      }
    }
  }

  flat(arr: any, target: any) {
    arr.forEach((el: any) => {
      if (Array.isArray(el)) {
        this.flat(el, target);
      } else {
        target.push(el);
      }
    });
  }

  flatten(arr: any) {
    const flattened: any = [];
    this.flat(arr, flattened);
    return flattened;
  }

  setTempGraphData(data: any[] | any, reset: boolean) {
    if (reset) {
      this.tempGraph
        .getDefaultAxisY()
        .setInterval(this.minYTemp, this.maxYTemp);
      this.tempGraph
        .getDefaultAxisX()
        .setInterval(this.minXTemp, this.maxXTemp);
      for (const item of data) {
        if (item) {
          let xTime = item?.x;
          if (String(xTime).length === 13) {
            xTime =
              this.stringToDate(
                moment(xTime).format('YYYY-MM-DD HH:mm:ss')
              ).getTime() - this.dateOrigin.getTime();
          }
          // eslint-disable-next-line @typescript-eslint/ban-types
          const y = item?.y as { humidity: number; temperature: number };
          if (xTime && y) {
            this.temperatureLine.add({ x: xTime, y: y.temperature });
            this.humidityLine.add({ x: xTime, y: y.humidity });
          }
        }
      }
      if (this.tempGraphData.length >= 11) {
        this.tempGraph
          .getDefaultAxisX()
          .setInterval(
            this.tempGraphData[this.tempGraphData.length - 11].x,
            this.tempGraphData[this.tempGraphData.length - 11].x + 120000
          );
      } else {
        this.tempGraph
          .getDefaultAxisX()
          .setInterval(
            this.tempGraphData[0].x,
            this.tempGraphData[0].x + 120000
          );
      }
      // this.zoomedOrPannedTemp = true;
      return;
    }
    if (data.length) {
      const array = this.flatten(
        data.map((item: any) => Object.keys(item.y).map((key) => item.y[key]))
      );
      const min = this.arrayMin(array);
      const max = this.arrayMax(array);
      if (data.length > 1800 * 6) {
        this.temperatureLine.dispose();
        this.humidityLine.dispose();
        this.temperatureLine = this.tempGraph
          .addLineSeries({
            dataPattern: {
              pattern: 'ProgressiveX',
            },
          })
          .setName('Temperature(°C)')
          .setStrokeStyle((stroke: any) =>
            stroke.setThickness(2).setFillStyle(new SolidFill({ color: ColorHEX('#F70002') }))
          )
          .setCursorInterpolationEnabled(false);
        this.humidityLine = this.tempGraph
          .addLineSeries({
            dataPattern: {
              pattern: 'ProgressiveX',
            },
          })
          .setName('Humidity(%)')
          .setStrokeStyle((stroke: any) =>
            stroke.setThickness(2).setFillStyle(new SolidFill({ color: ColorHEX('#4BC0C0') }))
          )
          .setCursorInterpolationEnabled(false);
      }
      setTimeout(() => {
        this.tempGraph
          .addLegendBox(LegendBoxBuilders.HorizontalLegendBox)
          .setTitle('')
          .setDraggingMode(0)
          .add(this.tempGraph)
          .setEntries((entry: any) => {
            entry.setPadding(0);
            if (entry.getText() === 'Constant Line') {
              entry.dispose();
            }
          });
      }, 500);
      const first = data[0];
      const last = data[data.length - 1];
      const measurementLength = (last.x - first.x) / 1000;
      const xPadding = measurementLength * 0.0277 * 1000;
      this.temperatureLine.clear();
      this.humidityLine.clear();
      const minXTemp =
        this.stringToDate(
          moment(first.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() -
        this.dateOrigin.getTime() -
        xPadding;
      const maxXTemp =
        this.stringToDate(
          moment(last.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() -
        this.dateOrigin.getTime() +
        xPadding;
      this.tempGraph
        .getDefaultAxisY()
        .setInterval(Math.floor(min) - 5, Math.floor(max) + 5, false, false);
      this.tempGraph
        .getDefaultAxisX()
        .setInterval(minXTemp, maxXTemp, false, true);
      const temArray: { x: number; y: number }[] = [];
      const humArray: { x: number; y: number }[] = [];
      for (const item of data) {
        if (item) {
          const xTime = this.stringToDate(
            moment(item?.x).format('YYYY-MM-DD HH:mm:ss')
          );
          // eslint-disable-next-line @typescript-eslint/ban-types
          const y = item?.y as { humidity: number; temperature: number };
          if (xTime && y) {
            temArray.push({
              x: xTime.getTime() - this.dateOrigin.getTime(),
              y: y.temperature,
            });
            this.temperatureLine.setCursorResultTableFormatter(
              (
                tableBuilder: any,
                series: any,
                x: any,
                _y: any,
                dataPoint: any
              ) =>
                tableBuilder
                  .addRow(
                    `${moment(
                      new Date(dataPoint.x).getTime() +
                      this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Temperature(℃) ${Number(dataPoint.y).toFixed(2)} `)
            );
            humArray.push({
              x: xTime.getTime() - this.dateOrigin.getTime(),
              y: y.humidity,
            });
            this.humidityLine.setCursorResultTableFormatter(
              (
                tableBuilder: any,
                series: any,
                x: any,
                _y: any,
                dataPoint: any
              ) =>
                tableBuilder
                  .addRow(
                    `${moment(
                      new Date(dataPoint.x).getTime() +
                      this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Humidity(%) ${Number(dataPoint.y).toFixed(2)}`)
            );
          }
        }
      }
      this.temperatureLine.add(temArray);
      this.humidityLine.add(humArray);
      // Set events for the temp graph
      this.setTempEventsData();
    } else {
      const xTime = this.stringToDate(
        moment(data?.x).format('YYYY-MM-DD HH:mm:ss')
      );
      // eslint-disable-next-line @typescript-eslint/ban-types
      const x = xTime.getTime() - this.dateOrigin.getTime();
      const y = data?.y as { humidity: number; temperature: number };
      if (x && y) {
        this.updateTempGraph(x, y);
        this.temperatureLine.setCursorResultTableFormatter(
          (tableBuilder: any, series: any, _x: any, _y: any, dataPoint: any) =>
            tableBuilder
              .addRow(
                `${moment(
                  new Date(dataPoint.x).getTime() + this.dateOrigin.getTime()
                ).format('DD MMM YYYY, HH.mm.ss')}`
              )
              .addRow(`Temperature(℃) ${Number(dataPoint.y).toFixed(2)}`)
        );
        this.humidityLine.setCursorResultTableFormatter(
          (tableBuilder: any, series: any, _x: any, _y: any, dataPoint: any) =>
            tableBuilder
              .addRow(
                `${moment(
                  new Date(dataPoint.x).getTime() + this.dateOrigin.getTime()
                ).format('DD MMM YYYY, HH.mm.ss')}`
              )
              .addRow(`Humidity(%) ${Number(dataPoint.y).toFixed(2)} `)
        );
      }
    }
  }

  addBaseValue(timestamp: any, addInList: boolean = false) {
    const diffdate: any = new Date(timestamp);
    const plotedData: any = [...this.parsedDataToPlot];
    // Get the nearest one
    const baseValueData = plotedData.sort((a: any, b: any) => {
      var distancea = Math.abs(
        diffdate.getTime() - new Date(a.timestamp).getTime()
      );
      var distanceb = Math.abs(
        diffdate.getTime() - new Date(b.timestamp).getTime()
      );
      return distancea - distanceb; // sort a before b when the distance is smaller
    })[0];
    // Validate existency
    const presentIndex = this.baseValueArr.findIndex((b: any) => {
      return b.timestamp === baseValueData.timestamp;
    });
    if (presentIndex === -1) {
      this.baseValueArr.push(baseValueData);
      this.baseValueArr.sort((a: any, b: any) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });
      this.baseValue = this.baseValueArr[0];
      if (addInList) {
        const basevalueIndex = this.listBaseValueArr.findIndex((b: any) => {
          return b.timestamp === baseValueData.timestamp;
        });
        if (basevalueIndex === -1) {
          this.listBaseValueArr.push(baseValueData);
          this.listBaseValueArr.sort((a: any, b: any) => {
            return (
              new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
            );
          });
          this.listBaseValueArr = [...this.listBaseValueArr];
          this.selection.toggle(baseValueData);
          this.table.renderRows();
        } else {
          this.selection.select(this.listBaseValueArr[basevalueIndex]);
          this.table.renderRows();
        }
      }
      this.findAndResetGraphs();
      const { isExistFunctionGraph } = this.validateGraphExistency();
      this.plotGraph(this.parsedDataToPlot, 'all');
      if (isExistFunctionGraph) {
        setTimeout(() => {
          this.setFunctionData(this.functionGraphData, true);
        }, 0);
      }
    }
    setTimeout(() => {
      this.setGraphLoader();
    }, 0);
  }

  removeBaseValue(timestamp: any) {
    const i = this.baseValueArr.findIndex((l: any) => {
      return l.timestamp === timestamp;
    });
    if (i > -1) {
      this.baseValueArr.splice(i, 1);
      // Sort it here
      this.baseValueArr.sort((a: any, b: any) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });
      this.baseValue = this.baseValueArr[0];
      this.findAndResetGraphs();
      const { isExistFunctionGraph } = this.validateGraphExistency();
      this.plotGraph(this.parsedDataToPlot, 'all');
      if (isExistFunctionGraph) {
        setTimeout(() => {
          this.setFunctionData(this.functionGraphData, true);
        }, 0);
      }
    }
    setTimeout(() => {
      this.setGraphLoader();
    }, 0);
  }

  findAndResetGraphs() {
    const { functionGraph, deltaGraph, tempGraph } =
      this.validateGraphExistency();
    if (deltaGraph) {
      this.deltaGraph.dispose();
      this.setUpDeltaGraph();
    }
    if (tempGraph) {
      this.tempGraph.dispose();
      this.setUpTempGraph();
    }
    if (functionGraph) {
      this.functionGraph.dispose();
      this.setUpFunctionGraph();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    this.selectedClassifiers = this.selection.selected.map((c) => c.timestamp);
    const numSelected = this.selection.selected.length;
    const numRows = this.baseValueArr.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.selectedClassifiers = this.selection.selected.map((c) => c.timestamp);
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.baseValueArr);
  }

  userInterference() {
    this.userInteraction = true;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  selectBaseline(row: any, eve: any) {
    const { timestamp } = row;
    if (eve) {
      this.setGraphLoader(true);
      setTimeout(() => {
        this.addBaseValue(timestamp);
      }, 0);
    } else {
      this.setGraphLoader(true);
      setTimeout(() => {
        this.removeBaseValue(timestamp);
      }, 0);
    }
  }

  setGraphLoader(allowLoading: boolean = false) {
    const { functionGraph, deltaGraph } = this.validateGraphExistency();
    if (deltaGraph) {
      this.isDeltaProcessing = allowLoading;
    } else if (functionGraph) {
      this.isFuncProcessing = allowLoading;
    } else {
      this.isProcessing = allowLoading;
    }
  }

  updateTempGraph(x: any, y: any) {
    if (
      this.maxYTemp - 10 < Math.floor(y.humidity) ||
      this.maxYTemp - 10 < Math.floor(y.temperature)
    ) {
      this.maxYTemp = this.maxYTemp + 10;
      this.minYTemp = this.minYTemp - 10;
      this.tempGraph
        .getDefaultAxisY()
        .setInterval(this.minYTemp, this.maxYTemp);
    }
    if (0 > Math.floor(y.humidity) || 0 > Math.floor(y.temperature)) {
      this.minYTemp = this.minYTemp - 10;
      this.maxXTemp = this.maxXTemp + 10;
      this.tempGraph
        .getDefaultAxisY()
        .setInterval(this.minYTemp, this.maxYTemp);
    }
    if (this.tempGraphData.length === 0) {
      const minXTemp = x;
      const maxXTemp = x + 120000;
      this.tempGraph.getDefaultAxisX().setInterval(minXTemp, maxXTemp);
      this.minXTemp = minXTemp;
      this.maxXTemp = maxXTemp;
      this.defaultTempMin = this.minXTemp;
      this.defaultTempMax = this.maxXTemp;
      this.temperatureLine.add({ x, y: y.temperature });
      this.humidityLine.add({ x, y: y.humidity });
      this.tempGraphData.push({ x, y });
    } else if (this.tempGraphData.length >= 55 && !this.zoomedOrPannedTemp) {
      const difference =
        x - this.tempGraphData[this.tempGraphData.length - 1]?.x;
      this.minXTemp = this.minXTemp + difference;
      this.maxXTemp = this.maxXTemp + difference;
      this.tempGraph
        .getDefaultAxisX()
        .setInterval(this.minXTemp, this.maxXTemp);
      this.temperatureLine.add({ x, y: y.temperature });
      this.humidityLine.add({ x, y: y.humidity });
      this.tempGraphData.push({ x, y });
    } else if (this.zoomedOrPannedTemp) {
      const timeslot = Math.floor(
        (this.currentTempEndPoint - this.currentTempStartPoint) / 1000
      );
      const dataFitIn = (timeslot * 1) / 1.8;
      const dataSize = Math.floor((dataFitIn * 87) / 100);
      const labels = this.tempGraphData.filter((item: any) => {
        if (
          this.currentTempStartPoint < item.x &&
          item.x < this.currentTempEndPoint
        ) {
          return item;
        }
      });
      if (
        this.currentTempEndPoint <
        this.tempGraphData[this.tempGraphData.length - 1]?.x
      ) {
        this.temperatureLine.add({ x, y: y.temperature });
        this.humidityLine.add({ x, y: y.humidity });
        this.tempGraphData.push({ x, y });
      } else if (labels.length >= dataSize) {
        const difference =
          x - this.tempGraphData[this.tempGraphData.length - 1]?.x;
        const minXTemp = this.currentTempStartPoint + difference;
        const maxXTemp = this.currentTempEndPoint + difference;
        this.tempGraph.getDefaultAxisX().setInterval(minXTemp, maxXTemp);
        this.temperatureLine.add({ x, y: y.temperature });
        this.humidityLine.add({ x, y: y.humidity });
        this.tempGraphData.push({ x, y });
      } else if (labels.length < dataSize) {
        this.temperatureLine.add({ x, y: y.temperature });
        this.humidityLine.add({ x, y: y.humidity });
        this.tempGraphData.push({ x, y });
      }
    } else {
      this.temperatureLine.add({ x, y: y.temperature });
      this.humidityLine.add({ x, y: y.humidity });
      this.tempGraphData.push({ x, y });
    }
  }

  setDeltaData(data: any[] | any, reset: boolean) {
    if (reset) {
      this.deltaGraph
        .getDefaultAxisY()
        .setInterval(this.minYInterval, this.maxYInterval);
      for (const item of data) {
        const xTime = item.x;
        const yVal = item.y;
        if (yVal !== undefined && yVal !== null) {
          this.deltaSeries.add({ x: xTime, y: yVal });
          this.deltaSeries.setCursorResultTableFormatter(
            (
              tableBuilder: any,
              series: any,
              x: any,
              y: any,
              dataPoint: any
            ) => {
              const cond = this.events.some((eventItem: any) => {
                const maxBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() -
                  1500;
                const minBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() +
                  1500;
                if (maxBound < x && minBound > x) {
                  return true;
                }
              });
              if (cond) {
                const event_name = this.events.filter((eventItem: any) => {
                  const maxBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() -
                    1500;
                  const minBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() +
                    1500;
                  if (maxBound < x && minBound > x) {
                    return eventItem;
                  }
                });
                return tableBuilder
                  .addRow(`${event_name[0]?.name}`)
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`);
              } else {
                return tableBuilder
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`);
              }
            }
          );
        }
      }
      if (this.deltaGraphData.length >= 11) {
        this.deltaGraph
          .getDefaultAxisX()
          .setInterval(
            this.deltaGraphData[this.deltaGraphData.length - 11].x,
            this.deltaGraphData[this.deltaGraphData.length - 11].x + 120000
          );
      } else {
        this.deltaGraph
          .getDefaultAxisX()
          .setInterval(
            this.deltaGraphData[0].x,
            this.deltaGraphData[0].x + 120000
          );
      }
      this.zoomedOrPanned = true;
      return;
    }
    if (data.length) {
      if (data.length > 1800 * 6) {
        this.deltaSeries = this.deltaGraph.addLineSeries({
          dataPattern: {
            pattern: 'ProgressiveX',
          },
        });
        this.deltaSeries
          .setName('Delta')
          .setStrokeStyle((style: any) =>
            style.setThickness(2).setFillStyle(new SolidFill({ color: ColorHEX('#80D9FF') }))
          )
          .setMouseInteractions(false)
          .setCursorInterpolationEnabled(false);
      }
      this.deltaGraph.getDefaultAxisY().setInterval(0, 0);
      const first = data[0];
      const last = data[data.length - 1];
      const measurementLength = (last.x - first.x) / 1000;
      const xPadding = measurementLength * 0.0277 * 1000;
      this.deltaSeries.clear();
      const minXInterval =
        this.stringToDate(
          moment(first.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() - xPadding;
      const maxXInterval =
        this.stringToDate(
          moment(last.x).format('YYYY-MM-DD HH:mm:ss')
        ).getTime() + xPadding;
      this.deltaGraph
        .getDefaultAxisX()
        .setInterval(minXInterval, maxXInterval, false, true);
      const dataset: { x: number; y: number }[] = [];
      for (const item of data) {
        let xTime: number;
        if (String(item.x).length === 13) {
          xTime = this.stringToDate(
            moment(item.x).format('YYYY-MM-DD HH:mm:ss')
          ).getTime();
        } else {
          xTime = item.x;
        }
        const yVal = item.y;
        if (yVal !== undefined) {
          dataset.push({ x: xTime, y: yVal });
          this.deltaSeries.setCursorResultTableFormatter(
            (
              tableBuilder: any,
              series: any,
              x: any,
              y: any,
              dataPoint: any
            ) => {
              const cond = this.events.some((eventItem: any) => {
                const maxBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() -
                  1500;
                const minBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() +
                  1500;
                if (maxBound < x && minBound > x) {
                  return true;
                }
              });
              if (cond) {
                const event_name = this.events.filter((eventItem: any) => {
                  const maxBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() -
                    1500;
                  const minBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() +
                    1500;
                  if (maxBound < x && minBound > x) {
                    return eventItem;
                  }
                });
                return tableBuilder
                  .addRow(`${event_name[0]?.name}`)
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`);
              } else {
                return tableBuilder
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`);
              }
            }
          );
        }
      }
      this.deltaSeries.add(dataset);
      setTimeout(() => {
        const { start, end } = this.deltaGraph.getDefaultAxisY().getInterval();
        if (end <= 2 || start >= -2) {
          this.deltaGraph.getDefaultAxisY().setInterval(start - 1, end + 1, false, true);
        } else if (end > 2 || start < 2) {
          this.deltaGraph.getDefaultAxisY().setInterval(start * 1.2, end * 1.2, false, true);
        }
      }, 100);
    }
  }

  async getMeasurementDetails() {
    try {
      const measurementId = this.route.snapshot.paramMap.get('measurementId');
      this.isProcessing = true;
      const res = await this.measurementService.getMeasurementDetails(
        measurementId
      );
      if (res) {
        this.measurementInfo = res.data;
        // Set upper_bound & lower_bound
        this.setBounds();
        // Serial NUmber
        if (this.measurementInfo.serial_number) {
          this.serialNumber = this.measurementInfo.serial_number;
        }

        if (this.measurementInfo.started_at) {
          this.dateOrigin = new Date(this.measurementInfo.started_at);
        }

        // Size
        this.measurementInfo.mst_size_mb = this.helperService.kbToMBConvertor(
          this.measurementInfo.measurement_size
        );

        // Duration
        this.measurementInfo.mst_duration = this.helperService.secondsToDhms(
          this.measurementInfo.measurement_duration
        );

        // Serial NUmber
        if (this.measurementInfo.comment) {
          this.comment = this.measurementInfo.comment;
        }
        // Device Data
        if (this.measurementInfo.serial_number) {
          const detectorInfo: any = [];
          this.measurementInfo.detectors_info.map((detector: any) => {
            if (
              detector?.detector?.batch_id &&
              detector?.detector?.slot_id &&
              detector?.detector?.production_detector_id
            ) {
              detectorInfo.push(detector);
            }
          });
          this.deviceData = {
            detectorInfo,
            deviceInfo: this.measurementInfo.device_info,
            firmwareInfo: this.measurementInfo.device_firmware_info,
          };
        }

        // Events Info
        if (
          this.measurementInfo.measurement_events &&
          this.measurementInfo.measurement_events.length > 0
        ) {
          this.measurementInfo.measurement_events.sort((a: any, b: any) => {
            const aIndex = new Date(a.graph_x_position).getTime();
            const bIndex = new Date(b.graph_x_position).getTime();
            return aIndex - bIndex;
          });
          this.measurementInfo.measurement_events.map((item: any) => {
            this.eventData.push({
              name: item.event_name,
              event_time: moment(item.graph_x_position).valueOf(),
              y_pos: item.graph_y_position,
              index: item.id,
            });
          });
        }
        // Smells Info
        if (
          this.measurementInfo.measurement_substance_info &&
          this.measurementInfo.measurement_substance_info.length > 0
        ) {
          this.showSmells = this.measurementInfo.measurement_substance_info.map(
            (i: any) => {
              if (i.concentration_value) {
                i.substance_info.concentration =
                  i.concentration_value + ' ' + i.concentration_unit;
              }
              if (i.state) {
                i.substance_info.state = i.state;
              }
              return i.substance_info;
            }
          );
          this.showSmells.map((item: any) => {
            this.smellInfoArray.push({
              id: item.id,
              name: item.name,
              is_public: item.is_public,
              concentration_value: item.concentration
                ? item.concentration.split(' ')[0]
                : null,
              concentration_unit: item.concentration
                ? item.concentration.split(' ')[1]
                : null,
              state: item.state ? item.state : null,
            });
          });
        }
        // Usecase Info
        if (
          this.measurementInfo.measurement_usecase_info &&
          this.measurementInfo.measurement_usecase_info.length > 0
        ) {
          this.showUsecases = this.measurementInfo.measurement_usecase_info.map(
            (i: any) => i.usecase_info
          );
          this.showUsecases.map((item: any) => {
            this.useCaseIds.push({
              id: item.id,
              name: item.name,
              is_public: item.is_public,
            });
          });
        }
        // Measurement Types Info
        if (this.measurementInfo.measurement_setup_info) {
          this.showMeasurementTypes.push({
            name: this.measurementInfo.measurement_setup_info.name,
            id: this.measurementInfo.measurement_setup_info.id,
            is_public: this.measurementInfo.measurement_setup_info.is_public,
          });
        }

        if (
          this.measurementInfo.measurement_substance_info &&
          this.measurementInfo.measurement_substance_info.length
        ) {
          this.measurementInfo.substances =
            this.measurementInfo.measurement_substance_info
              .map((m: any) => {
                return m.substance_info.name;
              })
              .join('; ');
        }

        const infoBlock: any = [
          {
            isDate: false,
            isLink: false,
            key: 'ID',
            value: this.measurementInfo.id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Name',
            value: this.measurementInfo.name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Size',
            value: this.measurementInfo.mst_size_mb,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Duration',
            value: this.measurementInfo.mst_duration,
          },
          {
            isDate: false,
            isLink: true,
            key: 'CSV Path',
            value: this.measurementInfo.cloud_csv,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Taken By',
            value: this.measurementInfo?.measurement_taken_by?.displayed_email,
          },
          {
            isDate: false,
            isLink: false,
            isRedirection: true,
            key: 'User Id',
            path: '/user/detail/',
            value: this.measurementInfo?.measurement_taken_by?.id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Rating',
            value: this.measurementInfo?.recognize_rating,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Connection Type',
            value: this.measurementInfo?.connection_type,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Fan State',
            value: this.measurementInfo?.fan_state,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: this.measurementInfo.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: this.measurementInfo.updatedAt,
          },
        ];
        this.measurementInfo.infoBlock = infoBlock;

        const deviceInfoBlock: any = [
          {
            isDate: false,
            isLink: false,
            key: 'Device Type',
            value: `${this.measurementInfo.device_type_info.name}-${this.measurementInfo.device_type_info.version}`,
          },
          {
            isDate: false,
            isLink: false,
            key: 'MAC Address',
            value: this.measurementInfo.device_info.mac_address,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Serial Number',
            value: this.measurementInfo.device_info.serial_number,
          },
        ];
        if (
          this.measurementInfo.board_info &&
          this.measurementInfo.board_info.detector_board_id
        ) {
          deviceInfoBlock.push({
            isDate: false,
            isLink: false,
            key: 'Sensor Board ID',
            value: this.measurementInfo.board_info.detector_board_id,
          });
        }
        this.measurementInfo.deviceInfoBlock = deviceInfoBlock;

        if (this.measurementInfo.cloud_csv) {
          this.isDeltaProcessing = true;
          this.downloadFile(this.measurementInfo.cloud_csv);
        }
        // Setup graphs here once we get the response
        this.setupGraphs();
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  setBounds() {
    this.measurementInfo.current_detectors_info.sort((a: any, b: any) =>
      a.detector_position > b.detector_position
        ? 1
        : b.detector_position > a.detector_position
          ? -1
          : 0
    );
    let functionalization_string = '';
    let functionalValues: any = [];
    let lowerBound: number = 0;
    let upperBound: number = 0;
    this.measurementInfo.current_detectors_info.forEach((item: any) => {
      if (!!item.detector?.detector_type?.functionalization) {
        functionalization_string +=
          ',' + item.detector?.detector_type?.functionalization;
      }
      if (
        item.detector?.detector_type?.lower_bound !== null ||
        item.detector?.detector_type?.lower_bound !== undefined
      ) {
        lowerBound = item.detector?.detector_type?.lower_bound;
      }
      if (item.detector?.detector_type?.upper_bound) {
        upperBound = item.detector?.detector_type?.upper_bound;
      }
    });
    functionalization_string = functionalization_string.substring(
      1,
      functionalization_string.length
    );
    functionalization_string = functionalization_string.split(',').join(';');
    functionalValues = functionalization_string.split(';');
    this.functionValue = [...new Set(functionalValues)];
    const functionData: any = { functionalValues, lowerBound, upperBound };
    functionalValues =
      functionData?.functionalValues &&
        functionData?.functionalValues?.length === 64
        ? functionData?.functionalValues
        : CommonConstants.FUNCTIONALISATIONVALUES;
    this.HEADERS.forEach((item, index) => {
      this.functionalObj[item] = functionalValues[index - 1];
    });
    this.lowerBound = functionData?.lowerBound;
    this.upperBound = functionData?.upperBound || this.upperBound;
    this.keepOriginalLowerBound = this.lowerBound;
    this.keepOriginalUpperBound = this.upperBound;
    // Set dynamic lower_bound/upper_bound from here
  }

  tabChanged(event: any) {
    if (event && event.tab && event.tab.textLabel) {
      // this.setupGraphs();
      const { textLabel: label } = event.tab;
      this.curentTab = label;
      return this.rewriteGraphs(label);
      // if (label === 'Features') {
      //   this.setUpDeltaGraph();
      // } else if (label === 'Response') {
      //   this.setUpFunctionGraph();
      // }
      // setupGraphs() {
      //   this.setUpTempGraph();
      // }
    }
  }

  downloadFile(url: string | any) {
    const config: Papa.ParseConfig | any = {
      delimiter: '', // auto-detect
      newline: '', // auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: false,
      transformHeader: undefined,
      dynamicTyping: false,
      preview: 0,
      encoding: '',
      worker: false,
      comments: false,
      step: undefined,
      complete: undefined,
      error: undefined,
      downloadRequestHeaders: undefined,
      downloadRequestBody: undefined,
      skipEmptyLines: false,
      chunk: undefined,
      chunkSize: undefined,
      fastMode: undefined,
      beforeFirstChunk: undefined,
      withCredentials: undefined,
      transform: undefined,
      download: true,
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
    };
    Papa.parse(url, {
      ...config,
      complete: (results: any) => {
        return this.handleCSV(results);
      },
    });
  }

  handleCSV(results: any) {
    if (results.data) {
      const readData: any = [];
      for (let i = 0; i < results.data.length; i += 1) {
        const element: any = results.data[i];
        if (Array.isArray(element) && element.length > 1) {
          const csvObj: any = {
            timestamp: element[0] || '',
            ch1: element[1] || '',
            ch2: element[2] || '',
            ch3: element[3] || '',
            ch4: element[4] || '',
            ch5: element[5] || '',
            ch6: element[6] || '',
            ch7: element[7] || '',
            ch8: element[8] || '',
            ch9: element[9] || '',
            ch10: element[10] || '',
            ch11: element[11] || '',
            ch12: element[12] || '',
            ch13: element[13] || '',
            ch14: element[14] || '',
            ch15: element[15] || '',
            ch16: element[16] || '',
            ch17: element[17] || '',
            ch18: element[18] || '',
            ch19: element[19] || '',
            ch20: element[20] || '',
            ch21: element[21] || '',
            ch22: element[22] || '',
            ch23: element[23] || '',
            ch24: element[24] || '',
            ch25: element[25] || '',
            ch26: element[26] || '',
            ch27: element[27] || '',
            ch28: element[28] || '',
            ch29: element[29] || '',
            ch30: element[30] || '',
            ch31: element[31] || '',
            ch32: element[32] || '',
            ch33: element[33] || '',
            ch34: element[34] || '',
            ch35: element[35] || '',
            ch36: element[36] || '',
            ch37: element[37] || '',
            ch38: element[38] || '',
            ch39: element[39] || '',
            ch40: element[40] || '',
            ch41: element[41] || '',
            ch42: element[42] || '',
            ch43: element[43] || '',
            ch44: element[44] || '',
            ch45: element[45] || '',
            ch46: element[46] || '',
            ch47: element[47] || '',
            ch48: element[48] || '',
            ch49: element[49] || '',
            ch50: element[50] || '',
            ch51: element[51] || '',
            ch52: element[52] || '',
            ch53: element[53] || '',
            ch54: element[54] || '',
            ch55: element[55] || '',
            ch56: element[56] || '',
            ch57: element[57] || '',
            ch58: element[58] || '',
            ch59: element[59] || '',
            ch60: element[60] || '',
            ch61: element[61] || '',
            ch62: element[62] || '',
            ch63: element[63] || '',
            ch64: element[64] || '',
            humidity: element[65] || '',
            temperature: element[66] || '',
          };
          Object.keys(csvObj).map((obj) => {
            const val = csvObj[obj];
            if (!val) {
              delete csvObj[obj];
            }
            return val;
          });
          readData.push(csvObj);
        }
      }
      if (readData.length > 0) {
        this.parsedCSVData = [...readData];
        this.processData(readData);
        this.isDeltaProcessing = false;
      } else {
        this.isDeltaProcessing = false;
      }
    }
  }

  processData(data: any) {
    const dataToPlot: any = [];
    let baseValues = [];
    let functionalObj: any = [];
    for (const item of data) {
      if (item?.timestamp?.includes('#')) {
        switch (item?.timestamp) {
          case '#failures':
            this.failureValues = Object.values(item).filter((failedChannel) => {
              if (failedChannel !== '#failures' && failedChannel !== '') {
                return true;
              }
            });
            this.HEADERS.forEach((value, index) => {
              this.failureObj[value] = this.failureValues[index - 1];
            });
            break;
          case '#functionalisation':
            this.functionalValues = Object.values(item).filter(
              (functionValue) => {
                if (
                  functionValue !== '#functionalisation' &&
                  functionValue !== ''
                ) {
                  return true;
                }
              }
            );
            this.HEADERS.forEach((value, index) => {
              functionalObj[value] = this.functionalValues[index - 1];
            });

            break;
          case item?.timestamp?.match(/^#baseLevel.*$/)?.input:
            baseValues.push(item);
            break;
        }
      } else {
        dataToPlot.push(item);
      }
    }
    baseValues = baseValues.map((item) => ({
      ...item,
      timestamp: item.timestamp.split('|')[1],
    }));
    if (baseValues.length === 1 && !baseValues[0]?.timeStamp) {
      baseValues = [];
      baseValues.push(dataToPlot[0]);
    }
    this.baseValue = baseValues[0];
    this.baseValueArr = [...baseValues];
    this.keepOriginalBaseValueArr = [...baseValues];
    this.listBaseValueArr = [...baseValues];
    this.selectedClassifiers = [...this.baseValueArr].map((m: any) => {
      this.selection.toggle(m);
      return m.timestamp;
    });
    this.checkFailures(dataToPlot.slice(0, 5), false);
    // if (!isLocal) {
    //   checkFailures(dataToPlot.slice(0, 5), false);
    // }
    this.parsedDataToPlot = [...dataToPlot];
    return this.plotGraph(dataToPlot, 'all');
  }

  checkFailures(data: any, plot: boolean) {
    let failureChannels: { channel: string; counters: number }[] = [];
    const failureChannelsIds: string[] = [];
    let updatedFailureValues = '';
    data.forEach((item: any) => {
      for (const key in item) {
        if (
          key !== 'timestamp' &&
          key !== 'temperature' &&
          key !== 'humidity'
        ) {
          if (
            Number(item[key]) >= this.upperBound ||
            Number(item[key]) <= this.lowerBound
          ) {
            if (failureChannels.some((i) => i.channel === key)) {
              failureChannels = failureChannels.map((obj) => {
                if (obj.channel === key) {
                  const counter = obj.counters + 1;
                  return { ...obj, counters: counter };
                } else {
                  return obj;
                }
              });
            } else {
              failureChannels.push({ channel: key, counters: 0 });
            }
          }
        }
      }
    });

    failureChannels.forEach((item) => {
      if (item.counters >= CommonConstants.TOTALCHECKINMAXFAILUREATTEMPT - 1) {
        failureChannelsIds.push(item.channel);
      }
    });

    for (let i = 1; i <= 64; i++) {
      if (
        failureChannelsIds.includes(`ch${i}`) ||
        this.functionalValues[i - 1] === '999'
      ) {
        updatedFailureValues += '1 ';
      } else {
        updatedFailureValues += '0 ';
      }
    }

    updatedFailureValues = updatedFailureValues.split(' ').join(';');
    updatedFailureValues = updatedFailureValues.substring(
      0,
      updatedFailureValues.length - 1
    );
    this.failureValues = updatedFailureValues.split(';');
    this.HEADERS.forEach((item, index) => {
      this.failureObj[item] = this.failureValues[index - 1];
    });
  }

  plotGraph(
    data: any,
    type?: string,
    allowDelta: boolean = true,
    allowDeltaEvents: boolean = true,
    allowTemp: boolean = true
  ) {
    let row: any;
    if (type === 'all') {
      let j = 0;
      const allRow: any = {
        channels: [],
        delta: [],
        humidity: [],
        temperature: [],
        timestamp: [],
      };
      allRow.timestamp = data.map((item: any) => item.timestamp);
      const channelData = [];
      const sepearateChannelData = [];
      const deltaData = [];
      const tempHumidData = [];
      const allChannelsData = [];
      for (let i = 0; i < data.length; i++) {
        if (
          j + 1 < this.baseValueArr.length &&
          new Date(data[i].timestamp).getTime() >
          new Date(this.baseValue.timestamp).getTime()
        ) {
          if (
            new Date(data[i].timestamp).getTime() ===
            new Date(this.baseValueArr[j + 1]?.timestamp).getTime()
          ) {
            j++;
            this.baseValue = this.baseValueArr[j];
          }
        }
        const tempData = {
          humidity: data[i]?.humidity,
          temperature: data[i]?.temperature,
        };
        const channel: any = this.deltaCalculation(data[i]);

        if (channel) {
          channelData.push({
            x: moment(allRow.timestamp[i]).valueOf(),
            y: channel.functionObj,
          });
          sepearateChannelData.push({
            x: moment(allRow.timestamp[i]).valueOf(),
            y: channel.channelValues,
          });
          allChannelsData.push({
            x: moment(allRow.timestamp[i]).valueOf(),
            y: channel.allChannelValues,
          });
          deltaData.push({
            x: moment(allRow.timestamp[i]).valueOf(),
            y: channel.funcAvg,
          });
          tempHumidData.push({
            x: moment(allRow.timestamp[i]).valueOf(),
            y: tempData,
          });
        }
      }
      return this.addData(
        tempHumidData,
        deltaData,
        channelData,
        sepearateChannelData,
        allChannelsData,
        allowDelta,
        allowDeltaEvents,
        allowTemp
      );
    } else {
      row = data[data.length - 1];
      const result: any = this.deltaCalculation(row);
      const functionAvg = result.funcAvg;
      const channels = result.functionObj;
      const tempHumidityData = {
        x: moment(row?.timestamp).valueOf(),
        y: { temperature: row.temperature, humidity: row.humidity },
      };
      const tempHumidData = [
        tempHumidityData,
        { x: moment(row?.timestamp).valueOf(), y: functionAvg },
        { x: moment(row?.timestamp).valueOf(), y: channels },
      ];
      return this.addData(tempHumidData, [], []);
    }
  }

  deltaCalculation(row: any) {
    if (row) {
      const forDeletion = ['999'];
      const functionObj: any = {};
      let uniqueFunc: string[] = [];
      let functionTotal = 0;
      let functionAvg = 0;
      let deltaTotal = 0;
      if (!this.excludeCol.length) {
        uniqueFunc = Array.from(new Set(this.functionalValues));
        uniqueFunc = uniqueFunc.filter((item) => !forDeletion.includes(item));
        for (let i = 0; i < 64; i++) {
          if (
            this.failureValues[i] === '1' ||
            this.functionalValues[i] === '999'
          ) {
            this.excludeCol.push(`ch${i + 1}`);
          } else {
            this.includeCol[`ch${i + 1}`] = `${this.functionalValues[i]}`;
          }
        }
        for (const item of uniqueFunc) {
          const matched = Object.keys(this.includeCol).filter(
            (key) => this.includeCol[key] === item
          );
          if (matched?.length) {
            this.channelObj[item] = matched;
          }
        }
        this.variableChannels = Object.keys(this.channelObj);
      }
      const channelValues: any = {};
      const allChannelValues: any = {};
      let temp: number[] = [];
      for (const i of this.variableChannels) {
        functionTotal = 0;
        channelValues[i] = {};
        allChannelValues[i] = {};
        for (const j of this.channelObj[i]) {
          const funcTotal =
            ((parseFloat(row[j]) - parseFloat(this.baseValue[j])) /
              parseFloat(this.baseValue[j])) *
            100;
          functionTotal += funcTotal;
          if (channelValues[i]) {
            channelValues[i][j] = funcTotal;
          }
          if (allChannelValues[i]) {
            allChannelValues[i][j] = parseFloat(row[j]);
          }
          functionObj[i] = functionTotal / this.channelObj[i].length;
        }
        temp.push(functionObj[i]);
      }
      temp = temp.sort((a, b) => a - b);
      if (temp.length > 8) {
        temp = temp.slice(2, temp.length - 2);
      }

      // eslint-disable-next-line guard-for-in
      // for (const item in this.includeCol) {
      //   const percentage =
      //     ((parseFloat(row[item]) - parseFloat(this.baseValue[item])) /
      //       parseFloat(this.baseValue[item])) *
      //     100;
      //   deltaTotal += percentage;
      // }
      deltaTotal = temp.reduce((a, b) => a + b, 0);
      functionAvg = deltaTotal / temp.length;
      const delta = isNaN(functionAvg) ? 0 : functionAvg;
      this.da.push(`row ${JSON.stringify(row)}`);
      this.da.push(`result ${JSON.stringify({ funcAvg: delta, functionObj })}`);
      return { funcAvg: delta, functionObj, channelValues, allChannelValues };
    }
  }

  toggleEvent() {
    this.cd.detectChanges();
    if (this.isEventShow) {
      this.events = this.events.map((i: any) => {
        const xTime = (i.event_time as number) - this.dateOrigin.getTime();
        let line2: ConstantLine | any;
        let line3: ConstantLine | any;
        let line4: ConstantLine | any;
        const line1 = this.deltaGraph
          .getDefaultAxisX()
          .addConstantLine(true)
          .setMouseInteractions(false)
          .setValue(xTime)
          .setStrokeStyle(
            new SolidLine({
              thickness: 2,
              fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
            })
          );
        if (this.functionGraph) {
          line2 = this.functionGraph
            .getDefaultAxisX()
            .addConstantLine(true)
            .setMouseInteractions(false)
            .setValue(xTime)
            .setStrokeStyle(
              new SolidLine({
                thickness: 2,
                fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
              })
            );
        }
        if (this.allChannelsGraph) {
          line3 = this.allChannelsGraph
            .getDefaultAxisX()
            .addConstantLine(true)
            .setMouseInteractions(false)
            .setValue(xTime)
            .setStrokeStyle(
              new SolidLine({
                thickness: 2,
                fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
              })
            );
        }
        if (this.tempGraph) {
          line4 = this.tempGraph
            .getDefaultAxisX()
            .addConstantLine(true)
            .setMouseInteractions(false)
            .setValue(xTime)
            .setStrokeStyle(
              new SolidLine({
                thickness: 2,
                fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
              })
            );
        }
        return {
          ...i,
          line1Event: { line1 },
          line2Event: line2 ? { line2 } : undefined,
          line3Event: line3 ? { line3 } : undefined,
          line4Event: line4 ? { line4 } : undefined,
        };
      });
    } else {
      this.events.forEach((event: any) => {
        event.line1Event.line1.dispose();
        if (event.line2Event && event.line2Event.line2) {
          event.line2Event.line2.dispose();
        }
        if (event.line3Event && event.line3Event.line3) {
          event.line3Event.line3.dispose();
        }
        if (event.line4Event && event.line4Event.line4) {
          event.line4Event.line4.dispose();
        }
      });
      this.cd.detectChanges();
    }
  }

  setEventData(eventData: any, isRefresh: boolean = false) {
    if (eventData.length && this.isEventShow) {
      if (isRefresh && this.events.length > 0) {
        this.events = this.events.map((i: any) => {
          const xTime = (i.event_time as number) - this.dateOrigin.getTime();
          const line1 = this.deltaGraph
            .getDefaultAxisX()
            .addConstantLine(true)
            .setMouseInteractions(false)
            .setValue(xTime)
            .setStrokeStyle(
              new SolidLine({
                thickness: 2,
                fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
              })
            );
          return {
            ...i,
            line1Event: { line1 },
            line2Event: i.line2Event || undefined,
            line3Event: i.line3Event || undefined,
            line4Event: i.line4Event || undefined,
          };
        });
      } else {
        this.events = eventData.map((i: any) => {
          const xTime = (i.event_time as number) - this.dateOrigin.getTime();
          const line1 = this.deltaGraph
            .getDefaultAxisX()
            .addConstantLine(true)
            .setMouseInteractions(false)
            .setValue(xTime)
            .setStrokeStyle(
              new SolidLine({
                thickness: 2,
                fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
              })
            );
          return { ...i, line1Event: { line1 } };
        });
      }
    }
  }

  setTempEventsData() {
    if (this.events.length && this.isEventShow) {
      this.events = this.events.map((i: any) => {
        const xTime = (i.event_time as number) - this.dateOrigin.getTime();
        const line4 = this.tempGraph
          .getDefaultAxisX()
          .addConstantLine(true)
          .setMouseInteractions(false)
          .setValue(xTime)
          .setStrokeStyle(
            new SolidLine({
              thickness: 2,
              fillStyle: new SolidFill({ color: ColorHEX('#F70002') }),
            })
          );
        return { ...i, line4Event: { line4 } };
      });
    }
  }

  toggleFunctionGraphLegend(eve: any, key: string) {
    if (eve && key) {
      const channels = Object.keys(this.functionGraphData[0].y[key]);
      if (eve.checked) {
        const seriesArray: any = [];
        Object.keys(this.functionGraphData[0].y).map((rr) => {
          Object.keys(this.functionGraphData[0].y[rr]).map((item) => {
            seriesArray.push(item.split('ch')[1]);
            return item;
          });
        });
        const seriesValue = seriesArray;
        this.functionSeries.forEach((series: any, index: any) => {
          for (const item of this.functionGraphData) {
            if (item) {
              let xTime: number;
              if (String(item.x).length === 13) {
                xTime = this.stringToDate(
                  moment(item.x).format('YYYY-MM-DD HH:mm:ss')
                ).getTime();
              } else {
                xTime = item.x;
              }
              // eslint-disable-next-line @typescript-eslint/ban-types
              const y = item?.y as any;
              const newY: any = {};
              Object.keys(y).map((d: any) => {
                if (d === key) {
                  Object.keys(y[d]).map((r: any) => {
                    newY[r.split('ch')[1]] = y[d][r];
                  });
                }
              });
              const yVal = newY[seriesValue[index]];
              if (xTime && yVal !== undefined) {
                series.add({ x: xTime, y: yVal });
              }
            }
          }
          series.setCursorResultTableFormatter(
            (tableBuilder: any, _series: any, x: any, y: any) => {
              const cond = this.events.some((eventItem: any) => {
                const maxBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() -
                  1500;
                const minBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() +
                  1500;
                if (maxBound < x && minBound > x) {
                  return true;
                }
              });
              if (cond) {
                const event_name = this.events.filter((eventItem: any) => {
                  const maxBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() -
                    1500;
                  const minBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() +
                    1500;
                  if (maxBound < x && minBound > x) {
                    return eventItem;
                  }
                });
                return tableBuilder
                  .addRow(`${event_name[0]?.name}`)
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`);
              } else {
                return tableBuilder
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`)
                  .addRow(_series.getName());
              }
            }
          );
        });
      } else {
        this.functionGraph.getSeries().map((series: any) => {
          if (channels.includes(series.getName())) {
            setTimeout(() => {
              // series.dispose();
              series.clear();
            }, 0);
          }
        });
      }
    }
  }

  toggleAllChannelsGraphLegend(eve: any, key: string) {
    if (eve && key) {
      const channels = Object.keys(this.allChannelsGraphData[0].y[key]);
      if (eve.checked) {
        const seriesArray: any = [];
        Object.keys(this.allChannelsGraphData[0].y).map((rr) => {
          Object.keys(this.allChannelsGraphData[0].y[rr]).map((item) => {
            seriesArray.push(item.split('ch')[1]);
            return item;
          });
        });
        const seriesValue = seriesArray;
        this.allChannelsSeries.forEach((series: any, index: any) => {
          for (const item of this.allChannelsGraphData) {
            if (item) {
              let xTime: number;
              if (String(item.x).length === 13) {
                xTime = this.stringToDate(
                  moment(item.x).format('YYYY-MM-DD HH:mm:ss')
                ).getTime();
              } else {
                xTime = item.x;
              }
              // eslint-disable-next-line @typescript-eslint/ban-types
              const y = item?.y as any;
              const newY: any = {};
              Object.keys(y).map((d: any) => {
                if (d === key) {
                  Object.keys(y[d]).map((r: any) => {
                    newY[r.split('ch')[1]] = y[d][r];
                  });
                }
              });
              const yVal = newY[seriesValue[index]];
              if (xTime && yVal !== undefined) {
                series.add({ x: xTime, y: yVal });
              }
            }
          }
          series.setCursorResultTableFormatter(
            (tableBuilder: any, _series: any, x: any, y: any) => {
              const cond = this.events.some((eventItem: any) => {
                const maxBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() -
                  1500;
                const minBound =
                  Number(eventItem.event_time) -
                  this.dateOrigin.getTime() +
                  1500;
                if (maxBound < x && minBound > x) {
                  return true;
                }
              });
              if (cond) {
                const event_name = this.events.filter((eventItem: any) => {
                  const maxBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() -
                    1500;
                  const minBound =
                    Number(eventItem.event_time) -
                    this.dateOrigin.getTime() +
                    1500;
                  if (maxBound < x && minBound > x) {
                    return eventItem;
                  }
                });
                return tableBuilder
                  .addRow(`${event_name[0]?.name}`)
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`);
              } else {
                return tableBuilder
                  .addRow(
                    `X: ${moment(
                      new Date(x).getTime() + this.dateOrigin.getTime()
                    ).format('DD MMM YYYY, HH.mm.ss')}`
                  )
                  .addRow(`Y ${Number(y).toFixed(2)}`)
                  .addRow(_series.getName());
              }
            }
          );
        });
      } else {
        this.allChannelsGraph.getSeries().map((series: any) => {
          if (channels.includes(series.getName())) {
            setTimeout(() => {
              series.clear();
            }, 0);
          }
        });
      }
    }
  }

  resetBound() {
    this.lowerBound = this.keepOriginalLowerBound;
    this.upperBound = this.keepOriginalUpperBound;
    this.resetFailedChannel();
  }

  resetFailedChannel() {
    this.isProcessing = true;
    setTimeout(() => {
      this.baseValue = this.baseValueArr[0];
      this.excludeCol = [];
      this.includeCol = [];
      this.channelObj = [];
      this.variableChannels = [];
      this.checkFailures(this.parsedDataToPlot.slice(0, 5), false);
      const isAllFailure = this.failureValues.filter((bb: string) => {
        return bb === '1';
      }).length;
      if (isAllFailure === 64) {
        // No further graph operation will execute.
        this.isProcessing = false;
        this.helperService.showAlert(
          'No graph data will be plot using this bounds, Please re-choose bounds',
          'error'
        );
        return true;
      }
      this.plotGraph(this.parsedDataToPlot, 'all');
      if (!!this.functionGraph) {
        setTimeout(() => {
          this.setFunctionData(this.functionGraphData, true);
        }, 0);
      }
      if (!!this.allChannelsGraph) {
        setTimeout(() => {
          this.setAllChannelsData(this.allChannelsGraphData, true);
        }, 0);
      }
      this.isProcessing = false;
    }, 0);
  }

  syncOptionChange() {
    if (this.curentTab === 'Response') {
      this.deltaMouseIn = true;
      this.tempMouseIn = false;
      this.functionIn = false;
      this.allChannelsIn = false;
    } else if (this.curentTab === 'Features') {
      this.functionIn = true;
      this.deltaMouseIn = false;
      this.tempMouseIn = false;
      this.allChannelsIn = false;
    } else if (this.curentTab === 'Channels') {
      this.allChannelsIn = true;
      this.functionIn = false;
      this.deltaMouseIn = false;
      this.tempMouseIn = false;
    }
  }

  ngOnDestroy() {
    // "dispose" should be called when the component is unmounted to free all the resources used by the chart
    // this.chart.dispose();
  }
}
