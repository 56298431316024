import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: []
})

export class VerticalAppSidebarComponent implements OnInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  public userDetails: any = {};
  public defaultImage = "assets/images/users/default-profile.png";
  mobileQuery: MediaQueryList;


  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>()

  ngOnInit(): void {
    this.hasLoggedIn();
  }


  private _mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;

  setClickedRow(i: number, j: number): void {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent(): void {
    this.status = true;
  }
  scrollToTop(): void {
    const scroll: any = document.querySelector('.page-wrapper');
    if (scroll) {
      scroll.scroll({
        top: 0,
        left: 0
      })
    }
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  hasLoggedIn() {
    const userDetails = this.localStorageService.getUserDetails();

    if (userDetails) {
      this.userDetails = JSON.parse(userDetails);
      this.userDetails.profileName = this.userDetails.first_name + " " + this.userDetails.last_name;
    }
    return !!this.userDetails;
  }

  onLoggedout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  handleNotify() {
    if (window.innerWidth < 1024) {
      this.notify.emit(!this.showClass);
    }
  }
}
