import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/validators/customValidator';

import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../shared/services/login/login.service';
import { HelperService } from '../../shared/services/helper/helper.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss'],
})
export class ResetpasswordComponent implements OnInit {
  public isProcessing = false;
  public errorReason = false;
  public showError = false;
  public resetPwdForm: any;
  public isSubmitted = false;
  public IsResetFormValid = true;
  public passwordUpdated = false;
  constructor(
    public fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private loginService: LoginService,
    private helperService: HelperService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.resetForm();
  }

  // convenience getter for easy access to form fields
  get resetPwdControls() {
    return this.resetPwdForm.controls;
  }

  resetForm() {
    this.resetPwdForm = this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.noWhitespace,
            CustomValidators.passwordValidate,
          ]),
        ],
        confPassword: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.noWhitespace,
            CustomValidators.passwordValidate,
          ]),
        ],
      },
      {
        validator: CustomValidators.ConfirmedValidator(
          'password',
          'confPassword'
        ),
      }
    );
  }

  async submitResetPwdForm(value: any) {
    this.errorReason = false;
    this.isSubmitted = true;
    if (this.resetPwdForm.valid && value.confPassword === value.password) {
      this.IsResetFormValid = true;
      try {
        this.isProcessing = true;
        const token = this.route.snapshot.paramMap.get('resetToken');
        const reqObj = {
          token,
          password: value.password,
          password_confirmation: value.confPassword,
        };
        const headers = { 'x-access-token': token };
        const res = await this.loginService.resetPassword(reqObj, headers);
        if (res) {
          this.passwordUpdated = true;
          //this.helperService.showAlert(res.message || 'Success', 'success');
          //this.router.navigate(['/authentication/login'], { queryParams: { resetPWD: 'true' }});
        }
      } catch (err: any) {
        this.errorReason = err.error || 'Something went wrong';
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.showError = true;
      } finally {
        this.resetForm();
        this.isSubmitted = true;
        this.isProcessing = false;
      }
    } else {
      this.IsResetFormValid = false;
    }
  }
}
