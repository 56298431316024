import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IssueDialogComponent } from './issue-dialog/issue-dialog.component';
import { IssueTypesComponent } from './issue-types/issue-types.component';
import { IssuesComponent } from './issues/issues.component';

const routes: Routes = [
  {
    path: 'detail/:issueId',
    component: IssueDialogComponent,
    data: {
      title: 'Issue Detail',
      urls: [
        { title: 'Dashboard', url: '/' },
        { title: 'Issue', url: '/issue/list' },
        { title: 'Issue Detail' },
      ],
    },
  },
  {
    path: 'issue-type',
    component: IssueTypesComponent,
    data: {
      title: 'Issue Type',
      icon: 'issue-types',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Issue Type' }],
    },
  },
  {
    path: 'list',
    component: IssuesComponent,
    data: {
      title: 'Issue',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Issue' }],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IssueRoutingModule {}
