import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { ClassifiersService } from 'src/app/shared/services/classifier/classifier.service';
import { AddClassifierComponent } from '../add-classifier/add-classifier.component';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  profile_picture: string;
  user_type: string;
  available_storage_space: number;
  total_storage_space: number;
  createdAt: any;
}

@Component({
  selector: 'app-classifiers',
  templateUrl: './classifiers.component.html',
  styleUrls: ['./classifiers.component.scss'],
})
export class ClassifiersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public filteredSmells: any = [];
  public smellDropDownSearch: FormControl = new FormControl();
  @ViewChild('allSmellCheckBox') private allSmellCheckBox: MatOption =
    Object.create(null);
  public filteredUsecases: any = [];
  public usecaseDropDownSearch: FormControl = new FormControl();
  @ViewChild('allUsecaseCheckBox') private allUsecaseCheckBox: MatOption =
    Object.create(null);
  public filteredFirmwares: any = [];
  public firmwareSearch: FormControl = new FormControl();
  @ViewChild('allFirmwareCheckBox') private allFirmwareCheckBox: MatOption =
    Object.create(null);
  public filteredDetectorTypes: any = [];
  public detectorTypeSearch: FormControl = new FormControl();
  @ViewChild('allDetectorTypeCheckBox')
  private allDetectorTypeCheckBox: MatOption = Object.create(null);
  @ViewChild('classifierSearch') classifierSearch: ElementRef | any;

  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'version',
    'is_public',
    'createdAt',
    'updatedAt',
  ];

  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  public dataSource: any = [];
  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public futureDate = new Date();

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = [];
  public searchOn = [];

  // Tooltip
  public tooltip = {
    view_classifier: 'View Classifier',
    update_classifier: 'Update Classifier',
    delete_classifier: 'Delete Classifier',
  };

  // Filter mechanism
  public filterOptions = {
    smells: [],
    usecases: [],
    detectorTypes: [],
    measurementTypes: [],
    firmwares: [],
    accessModes: [],
  };
  public smells: any = [];
  public usecases: any = [];
  public detectorTypes: any = [];
  public firmwares: any = [];
  public measurementTypes: any = [];
  public accessMode = '';
  public toDate: any = '';
  public fromDate: any = '';
  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private classifiersService: ClassifiersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setDefaultDates();
    this.getMLClassifierFilters();
    this.getMLClassifiers();

    this.smellDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredSmells = this.filterOptions.smells.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.usecaseDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredUsecases = this.filterOptions.usecases.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.firmwareSearch.valueChanges.subscribe((val) => {
      this.filteredFirmwares = this.filterOptions.firmwares.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.detectorTypeSearch.valueChanges.subscribe((val) => {
      this.filteredDetectorTypes = this.filterOptions.detectorTypes.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getMLClassifiers();
    }
  }
  singleSelection(dropDownName: string) {
    if (dropDownName === 'smell') {
      if (this.allSmellCheckBox.selected) {
        this.allSmellCheckBox.deselect();
      }

      if (this.smells.length === this.filterOptions.smells.length) {
        this.allSmellCheckBox.select();
        this.smells = [
          ...this.filterOptions.smells.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'usecase') {
      if (this.allUsecaseCheckBox.selected) {
        this.allUsecaseCheckBox.deselect();
      }

      if (this.usecases.length === this.filterOptions.usecases.length) {
        this.allUsecaseCheckBox.select();
        this.usecases = [
          ...this.filterOptions.usecases.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'firmware') {
      if (this.allFirmwareCheckBox.selected) {
        this.allFirmwareCheckBox.deselect();
      }

      if (this.firmwares.length === this.filterOptions.firmwares.length) {
        this.allFirmwareCheckBox.select();
        this.firmwares = [
          ...this.filterOptions.firmwares.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'detectorType') {
      if (this.allDetectorTypeCheckBox.selected) {
        this.allDetectorTypeCheckBox.deselect();
      }

      if (
        this.detectorTypes.length === this.filterOptions.detectorTypes.length
      ) {
        this.allDetectorTypeCheckBox.select();
        this.detectorTypes = [
          ...this.filterOptions.detectorTypes.map((item: any) => item.id),
          -1,
        ];
      }
    }
    this.getMLClassifiers();
  }

  allSelection(dropDownName: string) {
    if (dropDownName === 'smell') {
      if (this.allSmellCheckBox.selected) {
        this.smells = [
          ...this.filterOptions.smells.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.smells = [];
      }
    } else if (dropDownName === 'usecase') {
      if (this.allUsecaseCheckBox.selected) {
        this.usecases = [
          ...this.filterOptions.usecases.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.usecases = [];
      }
    } else if (dropDownName === 'firmware') {
      if (this.allFirmwareCheckBox.selected) {
        this.firmwares = [
          ...this.filterOptions.firmwares.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.firmwares = [];
      }
    } else if (dropDownName === 'detectorType') {
      if (this.allDetectorTypeCheckBox.selected) {
        this.detectorTypes = [
          ...this.filterOptions.detectorTypes.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.detectorTypes = [];
      }
    }
    this.getMLClassifiers();
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getMLClassifiers();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getMLClassifiers(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let userURL = '';

      // SEARCH
      if (this.searchedText) {
        userURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      if (this.searchOn.length) {
        userURL += `&searchOn=${JSON.stringify(this.searchOn)}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        userURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS
      if (this.smells.length) {
        userURL += `&substanceIds=${this.smells.join(',')}`;
        this.setDefaultPage(event);
      }
      if (this.usecases.length) {
        userURL += `&usecaseIds=${this.usecases.join(',')}`;
        this.setDefaultPage(event);
      }
      if (this.detectorTypes.length) {
        userURL += `&detectorTypeIds=${this.detectorTypes.join(',')}`;
        this.setDefaultPage(event);
      }
      if (this.firmwares.length) {
        userURL += `&firmwareIds=${this.firmwares.join(',')}`;
        this.setDefaultPage(event);
      }
      if (this.measurementTypes.length) {
        userURL += `&monitoringShortMeasurement=${this.measurementTypes.join(
          ','
        )}`;
        this.setDefaultPage(event);
      }
      if (this.accessMode) {
        userURL += `&isPublic=${!!(this.accessMode === 'public')}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        userURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      userURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.classifiersService.getMLClassifiers(userURL);
      if (res) {
        this.data = res.data.mlClassifiers;
        this.dataSource = res.data.mlClassifiers;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getMLClassifierFilters() {
    try {
      const res = await this.classifiersService.getMLClassifierFilters();
      if (res) {
        this.filterOptions = res.data.filterTerms;
        this.filteredDetectorTypes = this.filterOptions.detectorTypes;
        this.filteredSmells = this.filterOptions.smells;
        this.filteredUsecases = this.filterOptions.usecases;
        this.filteredFirmwares = this.filterOptions.firmwares;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.classifierSearch && this.classifierSearch.nativeElement) {
        this.classifierSearch.nativeElement.focus();
      }
    });
  }

  searchWithTerm() {
    if (this.searchedText) {
      this.getMLClassifiers();
    }
  }

  isFilterApplied() {
    return !(
      this.smells.length ||
      this.usecases.length ||
      this.detectorTypes.length ||
      this.firmwares.length ||
      this.measurementTypes.length ||
      this.accessMode ||
      this.toDate
    );
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getMLClassifiers();
  }

  clearFilter() {
    this.smells = [];
    this.usecases = [];
    this.detectorTypes = [];
    this.firmwares = [];
    this.fromDate = '';
    this.toDate = '';
    this.measurementTypes = [];
    this.accessMode = '';
    this.getMLClassifiers();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(AddClassifierComponent, {
      minWidth: '700px',
      height: '854px',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        }
      }
    });
  }

  addRowData(row_obj: any): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [row_obj, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: any): boolean | void {
    this.dataSource = this.dataSource.filter((value: any) => {
      if (value.id === row_obj.id) {
        value.name = row_obj.data.name;
        value.version = row_obj.data.version;
        value.is_public = row_obj.data.is_public;
        value.updatedAt = row_obj.data.updatedAt;
      }
      return true;
    });
    this.table.renderRows();
  }
}
