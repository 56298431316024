import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IssueRoutingModule } from './issue-routing.module';
import { IssuesComponent } from './issues/issues.component';
import { IssueDialogComponent } from './issue-dialog/issue-dialog.component';
import { DemoMaterialModule } from '../demo-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsModule } from 'ng2-charts';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IssueTypeDialogComponent } from './issue-type-dialog/issue-type-dialog.component';
import { IssueTypesComponent } from './issue-types/issue-types.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DeleteIssueComponent } from './delete-issue/delete-issue.component';

@NgModule({
  declarations: [
    IssuesComponent,
    IssueDialogComponent,
    IssueTypeDialogComponent,
    IssueTypesComponent,
    DeleteIssueComponent,
  ],
  imports: [
    CommonModule,
    IssueRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule,
    MatPaginatorModule,
    AngularEditorModule,
  ],
})
export class IssueModule {}
