<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Firmware</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <form [formGroup]="firmwareForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">
        <div class="overlay" *ngIf="isProcessing">
          <mat-spinner class="loader"></mat-spinner>
        </div>
        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-15">
            <mat-label>Firmware Name</mat-label>
            <input required [readonly]="action !=='Add'" type="text" matInput formControlName="name" name="name"
              class="form-control" id="name" />
            <mat-error *ngIf="
                        firmwareForm.controls['name'].invalid &&
                        (firmwareForm.controls['name'].errors.required || firmwareForm.controls['name'].errors?.isItBlank) &&
                        (firmwareForm.controls['name'].dirty || firmwareForm.controls['name'].touched)"
              class="text-danger">
              Firmware Name is required</mat-error>
            <mat-error *ngIf="firmwareForm.controls['name'].invalid && firmwareForm.controls['name'].errors.maxlength"
              class="text-danger">
              Maximum 60 characters are allowed
            </mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-15">
            <mat-label>Firmware Version</mat-label>
            <input required [readonly]="action !=='Add'" type="text" matInput formControlName="version" name="version"
              class="form-control" id="version" />
            <mat-error *ngIf="
                          firmwareForm.controls['version'].invalid &&
                          (firmwareForm.controls['version'].errors.required || firmwareForm.controls['version'].errors?.isItBlank) &&
                          (firmwareForm.controls['version'].dirty || firmwareForm.controls['version'].touched)"
              class="text-danger">
              Firmware Version is required</mat-error>
            <mat-error
              *ngIf="firmwareForm.controls['version'].invalid && firmwareForm.controls['version'].errors.maxlength"
              class="text-danger">
              Maximum 60 characters are allowed
            </mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <mat-label>Device Type</mat-label>
            <mat-select required placeholder="Device Type" multiple formControlName="deviceTypeIds">
              <mat-option>
                <ngx-mat-select-search [formControl]="deviceTypeDropDownSearch"
                  placeholderLabel="Search Device Type ..." noEntriesFoundLabel="No matching Device Type found">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option [disabled]="isOptionDisabled(deviceType.id)" *ngFor="let deviceType of filteredDeviceTypes"
                [value]="deviceType.id">
                {{ deviceType.name }}-{{ deviceType.version }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="firmwareForm.controls['deviceTypeIds'].invalid &&
                  firmwareForm.controls['deviceTypeIds'].errors.required &&
                  (firmwareForm.controls['deviceTypeIds'].dirty || firmwareForm.controls['deviceTypeIds'].touched)"
              class="text-danger">Choose atleast one device type</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="action === 'Add'" class="m-r-15 m-l-15">
          <input class="m-b-30" type="file" accept=".bin" (change)="onSelect($event.target.files)"
            placeholder="Firmware Binary File" formControlName="file">
          <mat-error style="font-size: 12px" *ngIf="firmwareForm.controls['file'].invalid &&
          firmwareForm.controls['file'].errors.required &&
          (firmwareForm.controls['file'].dirty || firmwareForm.controls['file'].touched)" class="text-danger m-b-20 ">
            Firmware Binary File is required</mat-error>
          <!-- <mat-error
            *ngIf="addClassifierControls.mlModel.value && addClassifierControls.mlModel.status === 'INVALID' && addClassifierForm.get('mlModel').touched"
            class="text-danger">Please provide valid file</mat-error>       -->
        </div>


        <div *ngIf="action === 'View'" class="m-r-15 m-l-15 m-b-15">
          <p class="might-overflow" [matTooltip]="local_data.file_link">
            File path:
            <u>
              <a href="{{local_data.file_link}}" target="_blank">{{local_data.file_link}}</a>
            </u>
          </p>
        </div>
      </div>
    </div>
    <button *ngIf="action != 'View'" mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !firmwareForm.valid || invalidFile">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()"> {{action != 'View' ? 'Cancel' : 'Close'}}</button>
  </form>
</mat-dialog-content>

<ng-template #elseTemplate>
  <p>Sure to delete Firmware <b>{{local_data.name}} </b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>