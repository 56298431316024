<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <div>
                <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <div class="search" *ngIf="showSearch">
                  <mat-form-field class="search-box">
                    <mat-label>Search</mat-label>
                    <input matInput #detectorBoardSearch [(ngModel)]="searchedText" placeholder="Search"
                      (keyup)="getDetectorBoards()" />
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>

              <div (click)="picker.open()" class="only-date-filter">
                <mat-form-field class="example-full-width">
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>

            </div>
            <div class="d-flex">
              <button mat-raised-button color="primary" (click)="openDialog('Add',{})">Add
                Sensor Board</button>
              <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                (click)="clearFilter()">
                Clear Filters
              </button>
            </div>

            <div *ngIf='length !== 0' class="example-container ex-c-general">
              <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                [matSortActive]="orderOn" [matSortDirection]="orderBy" class="table employee-list no-wrap">

                <ng-container matColumnDef="index">
                  <th disableClear mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="detector_board_id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Sensor Board Id </th>
                  <td class="might-overflow" [matTooltip]="element.detector_board_id" mat-cell
                    *matCellDef="let element">
                    {{ element.detector_board_id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated At </th>
                  <td class="might-overflow" [matTooltip]="element.updatedAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.updatedAt | date: 'dd MMM YYY, HH.mm.ss'}} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <a [matTooltip]="tooltip.view_detector_board" class="m-r-10 cursor-pointer eye"
                      (click)="openDialog('View',element)">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                    <a [matTooltip]="tooltip.update_detector_board" (click)="openDialog('Update',element)"
                      [ngClass]="element.is_associated ? 'disabled' : ''" class="m-r-10 cursor-pointer"><i
                        class="fa fa-pencil editFont"></i></a>
                    <a [matTooltip]="tooltip.delete_detector_board"
                      [ngClass]="element.is_associated ? 'disabled' : 'text-danger'"
                      (click)="openDialog('Delete',element)" class="m-r-10 cursor-pointer">
                      <i class="fa fa-trash deleteFont"></i>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf='length === 0'> No record found </div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getDetectorBoards($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>