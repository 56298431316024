<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>Import Sensor Boards</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content style="max-height: inherit;" class="pb-3">
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <div>
    <form [formGroup]="importDeviceForm">
      <div fxLayout="row wrap" class="align-items-center">
        <div fxFlex="100" fxFlex.gt-md="100">

          <div class="m-b-15 file-upload">
            <div>
              <input class="m-b-5" type="file" accept=".csv" (change)="onSelect($event.target.files)"
                placeholder="Upload csv file" formControlName="devices">
            </div>
            <mat-error
              *ngIf="!importDeviceControls.devices.value && importDeviceForm.get('devices').touched && action === 'Add'"
              class="text-danger input-err">CSV file is required</mat-error>
            <mat-error
              *ngIf="importDeviceControls.devices.value && importDeviceControls.devices.status === 'INVALID' && importDeviceForm.get('devices').touched"
              class="text-danger input-err">Please provide valid csv file</mat-error>
            <mat-error
              *ngIf="importDeviceControls.devices.value && maxLimitReached && importDeviceForm.get('devices').touched"
              class="text-danger input-err">The file exceeds the 5 MB attachment limit.</mat-error>
          </div>

        </div>
      </div>

      <button mat-button (click)="doAction()" mat-flat-button color="primary"
        [disabled]="isProcessing || !importDeviceForm.valid || invalidFile">Import</button>
      <button mat-button class="m-l-10" (click)="closeDialog()"> Cancel</button>
    </form>
  </div>
</mat-dialog-content>