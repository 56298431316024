import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsecaseRoutingModule } from './usecase-routing.module';
import { UsecasesComponent } from './usecases/usecases.component';
import { UsecaseDialogComponent } from './usecase-dialog/usecase-dialog.component';
import { DemoMaterialModule } from '../demo-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsModule } from 'ng2-charts';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [UsecasesComponent, UsecaseDialogComponent],
  imports: [
    CommonModule,
    UsecaseRoutingModule,    
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    NgSelectModule 
  ]
})
export class UsecaseModule { }
