import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private apiService: APIService) {}

  login(body: any): any {
    const url = '/user/login';
    return this.apiService.postWithoutAuthHandler(url, body);
  }

  logout(): any {
    const url = '/logout';
    return this.apiService.getWithAuthHandler(url);
  }

  forget(body: any): any {
    const url = '/user/admin-forgot-password';
    return this.apiService.postWithoutAuthHandler(url, body);
  }

  resetPassword(body: any, headers: any): any {
    const url = '/user/reset-password';
    return this.apiService.postWithAuthHandler(url, body, headers);
  }
}
