import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetectorsComponent } from './detectors/detectors.component';

const routes: Routes = [
  {
    path: '',
    component: DetectorsComponent,
    data: {
      title: 'Detector',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Detector' }]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DetectorRoutingModule {

}
