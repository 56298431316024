<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="forgetForm" (ngSubmit)="submitForgetForm(forgetForm.value)">
          <div class="text-center">
            <img alt="homepage" class="login-logo" src="assets/images/snt_dark.svg">
            <h4 class="m-t-0">Recover Password</h4>
          </div>
          <p class="text-center font-14">Enter your email and we'll send you the password reset link.</p>

          <div fxLayout="column" fxLayoutAlign="space-around">
            <div class="pb-1">
              <mat-form-field class="m-b-15">
                <input required matInput placeholder="Email address" type="email" email="true" formControlName="email">
                <mat-error *ngIf="!forgetControls.email.value && forgetForm.get('email').touched" class="text-danger">
                  Email address is required</mat-error>
                <mat-error
                  *ngIf="forgetForm.get('email').invalid && !forgetControls.email.errors.required && forgetForm.get('email').touched"
                  class="text-danger">Email address is invalid</mat-error>
              </mat-form-field>
            </div>

            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-spinner *ngIf="isProcessing" class="m-auto" [diameter]="30"></mat-spinner>
              <button mat-raised-button color="primary" type="submit" class="btn-block btn-lg m-t-20"
                [disabled]="!forgetForm.valid || isProcessing">Submit</button>
            </div>

            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="text-center m-t-20">
              <a [routerLink]="['/authentication/login']" class="link">Back To Login</a>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
