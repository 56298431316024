<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Issue</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<p class="might-overflow-popup" [matTooltip]="local_data.issue_title">Sure to delete <b>{{local_data.issue_title}}</b>
  ?</p>
<div mat-dialog-actions align="end" class="pt-3">
  <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
  <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
</div>

<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>