import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonConstants } from 'src/app/shared/common-constants';
import { IssueNote } from 'src/app/shared/models/issueNote';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { IssueService } from 'src/app/shared/services/issue/issue.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-issue-dialog',
  templateUrl: './issue-dialog.component.html',
  styleUrls: ['./issue-dialog.component.scss'],
})
export class IssueDialogComponent implements OnInit {
  public displayedColumns: string[] = ['username', 'note', 'createdAt'];
  public dataSource = [];
  public issueForm: FormGroup;
  public deviceTypes: any = [];
  public action: string = 'View';
  public local_data: any = {};
  public isProcessing: boolean = false;
  public deviceTypeDropDownSearch: FormControl = new FormControl();
  public filteredDeviceTypes: any = [];
  public noteColumns: any = ['added_by', 'note', 'createdAt'];
  public issueStatus: any;
  public selectedIssueStatus: any = '';
  public note = '';
  public APP_URL = environment.API_URL;
  public issueId: any = '';
  public htmlContent: any = '';
  public config: any = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter solution here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
    ],
    upload: (res: any) => {
      alert(res);
    },
    uploadUrl: `${this.APP_URL}/issue/upload-media`,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'customClasses',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode',
        'unlink',
      ],
    ],
  };
  public issueBlock: any = [];
  public issueBlockColumns: any = ['key', 'value'];

  constructor(
    private helperService: HelperService,
    private route: ActivatedRoute,
    private issueService: IssueService,
    private router: Router
  ) {
    this.issueId = this.route.snapshot.paramMap.get('issueId');
    this.issueStatus = {
      open: 'Open',
      in_progress: 'In Progress',
      closed: 'Closed',
    };

    if (this.local_data && this.local_data.status) {
      this.issueForm = new FormGroup({
        status: new FormControl(this.local_data.status, Validators.required),
      });
    } else {
      this.issueForm = new FormGroup({
        status: new FormControl(null, Validators.required),
      });
    }
  }

  ngOnInit(): void {
    this.getIssueInfo();
  }

  private async getIssueInfo() {
    try {
      this.isProcessing = true;
      const res = await this.issueService.getIssueInfo(this.issueId);
      if (res) {
        this.local_data = res.data.issueInfo;
        this.issueBlock = [
          {
            isDate: false,
            isLink: false,
            key: 'Issue ID',
            value: this.local_data.issue_identifier,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Issue Title',
            value: this.local_data.issue_title,
          },
          {
            isDate: false,
            isLink: false,
            isTextArea: true,
            key: 'Description',
            value: this.local_data.issue_description,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Issue Type',
            value: this.local_data?.issue_type?.name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Issue Status',
            value: this.issueStatus[this.local_data.status],
          },
          {
            isDate: false,
            isLink: false,
            key: 'Reported By',
            value: this.local_data?.issue_user?.email,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Device ID',
            value: this.local_data.device_id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Mac Address',
            value: this.local_data.mac_address,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Serial Number',
            value: this.local_data.serial_number,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Device Firmware Id',
            value: this.local_data.device_firmware_id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'App Version',
            value: this.local_data.app_version,
          },
          {
            isDate: false,
            isLink: false,
            key: 'App Platform',
            value: this.local_data.app_platform,
          },
          {
            isDate: false,
            isLink: false,
            key: 'System OS',
            value: this.local_data.system_os,
          },
          {
            isDate: false,
            isLink: false,
            key: 'System Version',
            value: this.local_data.system_version,
          },
          {
            isDate: false,
            isLink: false,
            key: 'System Device',
            value: this.local_data.system_device,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: this.local_data.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: this.local_data.updatedAt,
          },
          {
            isDate: false,
            isLink: false,
            isTabular:
              this.local_data.issue_attachments &&
              this.local_data.issue_attachments.length > 0,
            key: 'Issue Attachements',
            value:
              this.local_data.issue_attachments &&
              this.local_data.issue_attachments.length > 0
                ? this.local_data.issue_attachments
                : null,
          },
        ];
        this.selectedIssueStatus = res.data.issueInfo.status;
        if (res.data.issueInfo.status === CommonConstants.issueStatus.CLOSED) {
          this.issueForm.disable();
        }
        if (this.local_data && this.local_data.status === 'closed') {
          this.htmlContent =
            this.local_data?.issue_resolution_info[0]?.solution;
          if (
            this.local_data.issue_resolution_info &&
            this.local_data.issue_resolution_info.length === 0
          ) {
            this.config.placeholder = 'No solution provided.';
          }
          this.config.editable = false;
          this.config.enableToolbar = false;
          this.config.showToolbar = false;
        }
        if (
          this.local_data.issue_title &&
          this.local_data.issue_title.length > 50
        ) {
          this.local_data.issue_title_truncated = true;
        } else {
          this.local_data.issue_title_truncated = false;
        }
        this.initDataSource();
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
      this.router.navigate(['/issue/list']);
    } finally {
      this.isProcessing = false;
    }
  }

  initDataSource() {
    this.local_data.issue_note_info = this.local_data.issue_note_info.map(
      (noteInfo: any) => {
        return {
          ...noteInfo,
          note_truncated: !!(noteInfo.note && noteInfo.note.length > 17),
        };
      }
    );
    this.local_data.issue_note_info.sort((a: any, b: any) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }

  async doAction() {
    await this.submitIssueForm();
  }

  async addNote() {
    const issueNote = {} as IssueNote;
    issueNote.issue_id = this.issueId;
    issueNote.note = this.note;
    const res = await this.issueService.addIssueNote(issueNote);
    this.local_data.issue_note_info = [
      ...this.local_data.issue_note_info,
      res.data,
    ];
    this.initDataSource();
    this.note = '';
  }

  downloadFile(url: string): void {
    this.isProcessing = true;
    this.issueService.downloadFile(url).subscribe(
      (response: any) => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(response);
        a.href = objectUrl;
        const tmpFileName: any = url.split('/').pop();
        a.download = tmpFileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.isProcessing = false;
      },
      (err: any) => {
        this.helperService.showAlert(
          err.message || 'Something went wrong',
          'error'
        );
        this.isProcessing = false;
      }
    );
  }

  closeDialog(): void {
    // this.dialogRef.close({ event: 'Cancel' });
  }

  async submitIssueForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const res = await this.issueService.removeIssue(this.issueId);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    } else {
      if (this.issueForm.valid) {
        try {
          this.isProcessing = true;
          const issue: any = {
            status: this.issueForm.value.status,
            id: this.issueId,
          };
          if (this.issueForm.value.status === 'closed' && this.htmlContent) {
            issue.solution = this.htmlContent;
          }
          const res: any = await this.issueService.updateIssue(issue);
          if (res) {
            this.helperService.showAlert(res.msg || 'Success', 'success');
          }
          this.getIssueInfo();
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        } finally {
          this.isProcessing = false;
        }
      }
    }
  }
}
