<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content class="outer">
        <div>
          <div class="overlay" *ngIf="isProcessing">
            <mat-spinner class="loader"></mat-spinner>
          </div>

          <div fxLayout="row wrap" class="content-wrapper">
            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" class="div-fix-height content-wrapper__left" fxFlex="100">
              <mat-card>
                <mat-card-header class="course-header Web card-auto-height">
                  <mat-card-title>
                    <span> Info</span>
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                  <div class="example-container">
                    <table mat-table [dataSource]="issueBlock" class="table employee-list td-ht-sm no-wrap">
                      <ng-container matColumnDef="key">
                        <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
                          {{ element.key }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="value">
                        <td class="might-overflow" mat-cell *matCellDef="let element">
                          <div *ngIf="element.isDate" [matTooltip]="element.value | date: 'dd MMM YYY, HH.mm.ss'">
                            {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                          </div>
                          <div class="td-lg" *ngIf="element.isTextArea">
                            <div class="td-lg" *ngIf="element.value && element.value.length > 48">
                              <textarea readonly [ngModelOptions]="{standalone: true}" rows="3" cols="40"
                                [(ngModel)]="element.value" #description matInput class="scrollbar"
                                placeholder="Description"></textarea>
                            </div>
                            <div *ngIf="element.value && element.value.length < 49" [matTooltip]="element.value || '-'">
                              {{ element.value || '-' }}
                            </div>
                          </div>
                          <div *ngIf="!element.isDate && !element.isLink && !element.isTextArea && !element.isTabular"
                            [matTooltip]="element.value || '-'">
                            {{ element.value || '-' }}
                          </div>
                          <div class="td-lg" *ngIf="element.isTabular">
                            <div *ngFor="let d of element.value" [matTooltip]="d.url">
                              <u>
                                <a href="{{d.url}}" target="_blank">{{d.url}}</a>
                              </u>
                            </div>
                          </div>
                          <div *ngIf="element.isLink" [matTooltip]="element.value">
                            <a href="{{element.value}}">{{element.value}}</a>
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-row *matRowDef="let row; columns: issueBlockColumns"></tr>
                    </table>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" class="div-fix-height content-wrapper__left" fxFlex="100">

              <div class="div-fix-height" fxLayout="row wrap">
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                  <mat-card class="mat-card-fix-height div-fix-height">
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header class="no-mep">
                        <mat-card-header class="course-header Web card-auto-height">
                          <mat-card-title>
                            <span class="course-name"> Notes (For Intertnal Purpose Only)</span>
                          </mat-card-title>
                        </mat-card-header>
                      </mat-expansion-panel-header>
                      <mat-card-content class="list-events">
                        <div>
                          <div *ngIf='local_data?.issue_note_info?.length !== 0' class="example-container">
                            <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table
                              [dataSource]="local_data.issue_note_info" class="table employee-list no-wrap td-ht-sm">

                              <ng-container matColumnDef="added_by">
                                <th disableClear mat-header-cell *matHeaderCellDef>Added By</th>
                                <td class="might-overflow" [matTooltip]="element.issue_note_user.email" mat-cell
                                  *matCellDef="let element">
                                  {{ element.issue_note_user.first_name }} {{ element.issue_note_user.last_name }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="note">
                                <th disableClear mat-header-cell *matHeaderCellDef>Note</th>
                                <td class="might-overflow" [matTooltip]="element.note" mat-cell
                                  *matCellDef="let element">
                                  <div *ngIf="!element.note_truncated">{{element.note}}</div>
                                  <div class="td-lg" *ngIf="element.note_truncated"><textarea readonly
                                      [ngModelOptions]="{standalone: true}" rows="3" cols="40"
                                      [(ngModel)]="element.note" matInput placeholder="Note"></textarea></div>
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="createdAt">
                                <th disableClear mat-header-cell *matHeaderCellDef>
                                  Created At
                                </th>
                                <td class="might-overflow"
                                  [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss' " mat-cell
                                  *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss'
                                  }}
                                </td>
                              </ng-container>

                              <tr mat-header-row *matHeaderRowDef="noteColumns; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: noteColumns"></tr>
                            </table>
                          </div>

                          <div *ngIf="!local_data?.issue_note_info?.length" class="no-data">
                            <img src="assets/images/no-data.svg" alt="no-data" />
                            <p class="m-0">No Data Available</p>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-expansion-panel>
                  </mat-card>
                </div>
              </div>
              <div class="div-fix-height" *ngIf="local_data.status && local_data.status !== 'closed' ">
                <mat-card class="div-fix-height">
                  <mat-expansion-panel expanded>
                    <mat-expansion-panel-header class="no-mep">
                      <mat-card-header class="course-header Web card-auto-height">
                        <mat-card-title>
                          <span class="course-name"> Add Note</span>
                        </mat-card-title>
                      </mat-card-header>
                    </mat-expansion-panel-header>
                    <mat-card-content>
                      <div fxLayout="row wrap" fxFlexAlign="center">
                        <div fxFlex.gt-sm="100" fxFlex="100">
                          <mat-form-field class="p-t-10 p-r-5">
                            <textarea [ngModelOptions]="{standalone: true}" rows="4" cols="40" [(ngModel)]="note"
                              #description matInput placeholder="Note"></textarea>
                          </mat-form-field>
                          <mat-error *ngIf="note && note.length > 5000" class="text-danger">
                            Maximum 5000 characters are allowed
                          </mat-error>
                        </div>

                        <div *ngIf="action === 'View' && local_data.status !=='closed'" class="d-flex">
                          <button class="m-r-10" mat-button mat-flat-button color="primary" (click)="addNote()"
                            [disabled]="isProcessing || !note || note.length > 5000">Add</button>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-expansion-panel>
                </mat-card>
              </div>

              <div>
                <mat-card class="div-fix-height">
                  <mat-expansion-panel expanded>
                    <mat-expansion-panel-header class="no-mep">
                      <mat-card-header class="course-header Web card-auto-height">
                        <mat-card-title>
                          <span class="course-name"> Update Status</span>
                        </mat-card-title>
                      </mat-card-header>
                    </mat-expansion-panel-header>
                    <mat-card-content>
                      <div fxLayout="row wrap" fxFlexAlign="center">
                        <div fxFlex.gt-sm="100" fxFlex="100">
                          <form [formGroup]="issueForm">
                            <mat-form-field class="example-full-width">
                              <mat-label>Issue Status</mat-label>
                              <mat-select placeholder="Issue Status" [(ngModel)]="selectedIssueStatus"
                                formControlName="status" name="status">
                                <mat-option value="open" [disabled]="local_data.status === 'in_progress'">
                                  Open
                                </mat-option>
                                <mat-option value="in_progress">
                                  In Progress
                                </mat-option>
                                <mat-option value="closed">
                                  Closed
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <div *ngIf="selectedIssueStatus === 'closed' ">
                              <p *ngIf="local_data.status !== 'closed'" class="m-0"> Enter Your Solution
                                here... </p>
                              <div class="d-flex align-items-end m-b-15">
                                <angular-editor style="width: 100%" [ngModelOptions]=" {standalone: true}"
                                  [(ngModel)]="htmlContent" [config]="config">
                                </angular-editor>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div>
                        </div>
                        <div *ngIf="local_data.status !=='closed'">
                          <button class="d-flex" mat-button (click)="doAction()" mat-flat-button color="primary"
                            [disabled]="isProcessing || !selectedIssueStatus 
                          || selectedIssueStatus && selectedIssueStatus === 'closed' && !htmlContent">Update
                            Status</button>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-expansion-panel>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>