import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { DashboardService } from 'src/app/shared/services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard2',
  templateUrl: './dashboard2.component.html',
  styleUrls: ['./dashboard2.component.scss'],
})
export class Dashboard2Component implements OnInit {
  public data: any = {};
  public isProcessing = false;
  public toDate: any = '';
  public fromDate: any = '';
  public chartOptions: any = {
    series: [],
    chart: {
      id: 'area-datetime',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    markers: {
      size: 3,
    },
    colors: [],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.6,
        opacityTo: 0.8,
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        formatter: function (val: any) {
          return moment(new Date(val)).format('DD MMMM YYYY'); // formats to 01 Sep 2022
        },
      },
    },
    yaxis: {
      type: 'value',
      labels: {
        formatter: function (value: any) {
          return `${parseInt(value, 10)}`;
        },
      },
    },
    tooltip: {
      theme: 'light',
      x: {
        format: 'dd/MM/yyyy',
      },
    },
  };
  public userOptions: any = {
    ...this.chartOptions,
    chart: {
      id: 'users',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    colors: ['#008FFB'],
  };
  public measurementsOptions: any = {
    ...this.chartOptions,
    chart: {
      id: 'measurements',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    colors: ['#12FB29'],
  };
  public smellsOptions: any = {
    ...this.chartOptions,
    chart: {
      id: 'substances',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    colors: ['#FBE308'],
  };
  public usecasesOptions: any = {
    ...this.chartOptions,
    chart: {
      id: 'usecases',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    colors: ['#0AFBDB'],
  };
  public measurementSetupsOptions: any = {
    ...this.chartOptions,
    chart: {
      id: 'measurementsetups',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    colors: ['#FB2859'],
  };
  public issuesOptions: any = {
    ...this.chartOptions,
    chart: {
      id: 'issues',
      toolbar: {
        show: true,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          reset: true,
        },
      },
      type: 'area',
      height: 350,
      stacked: true,
      zoom: {
        autoScaleYaxis: true,
        autoScaleXaxis: true,
      },
    },
    colors: ['#FFE0E0'],
  };
  public futureDate = new Date();

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private dashbaordService: DashboardService
  ) {}

  ngOnInit(): void {
    this.setDefaultDate();
    this.getDashboardData();
  }

  setDefaultDate() {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate() - 6);
    this.fromDate = startDate;
    this.toDate = endDate;
  }

  getDate(dateTYpe: string) {
    return dateTYpe === 'fromDate'
      ? moment(this.fromDate).format('YYYY-MM-DD')
      : moment(this.toDate).format('YYYY-MM-DD');
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getDashboardData();
    }
  }

  getDaysArray(startDate: any, endDate: any) {
    for (
      var days = [], date = new Date(startDate);
      date <= new Date(endDate);
      date.setDate(date.getDate() + 1)
    ) {
      days.push(new Date(date));
    }
    return days;
  }

  async getDashboardData() {
    try {
      this.isProcessing = true;
      let mTypeURL = '';
      let totalDays: any = [];
      if (this.fromDate && this.toDate) {
        const fDate = moment(this.fromDate).format('YYYY-MM-DD');
        const tDate = moment(this.toDate).format('YYYY-MM-DD');
        mTypeURL += `&fromDate=${fDate}&toDate=${tDate}`;
        totalDays = this.getDaysArray(this.fromDate, this.toDate).map(
          (t: any) => {
            return moment(t).format('YYYY-MM-DD');
          }
        );
        // Set x-axis to fromDate & toDate for all graphs
        this.userOptions.xaxis = {
          categories: totalDays,
        };
        this.measurementsOptions.xaxis = {
          categories: totalDays,
        };
        this.smellsOptions.xaxis = {
          categories: totalDays,
        };
        this.usecasesOptions.xaxis = {
          categories: totalDays,
        };
        this.measurementSetupsOptions.xaxis = {
          categories: totalDays,
        };
        this.issuesOptions.xaxis = {
          categories: totalDays,
        };
      }
      const res = await this.dashbaordService.getDashboardStatistics(mTypeURL);
      if (res) {
        this.data = res.data;
        this.setGraphData({
          ...res.data,
          totalDays,
        });
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  setGraphData(obj: any) {
    const {
      userStaticstics,
      measurementStaticstics,
      smellStaticstics,
      usecaseStaticstics,
      measurementSetupStaticstics,
      issueStaticstics,
      totalDays,
    } = obj;
    const userStaticsticsArr: any = [];
    const measurementStaticsticsArr: any = [];
    const smellStaticsticsArr: any = [];
    const usecaseStaticsticsArr: any = [];
    const measurementSetupStaticsticsArr: any = [];
    const issueStaticsticsArr: any = [];

    totalDays.map((formatedDay: any) => {
      const obj = { created_at: formatedDay, count: '0' };
      const userIsPresent = userStaticstics.findIndex((u: any) => {
        return u.created_at === formatedDay;
      });
      if (userIsPresent > -1) {
        userStaticsticsArr.push(
          parseInt(userStaticstics[userIsPresent].count, 10)
        );
      } else {
        userStaticsticsArr.push(parseInt(obj.count, 10));
      }
      const measurementIsPresent = measurementStaticstics.findIndex(
        (u: any) => {
          return u.created_at === formatedDay;
        }
      );
      if (measurementIsPresent > -1) {
        measurementStaticsticsArr.push(
          parseInt(measurementStaticstics[measurementIsPresent].count, 10)
        );
      } else {
        measurementStaticsticsArr.push(parseInt(obj.count, 10));
      }
      const smellIsPresent = smellStaticstics.findIndex((u: any) => {
        return u.created_at === formatedDay;
      });
      if (smellIsPresent > -1) {
        smellStaticsticsArr.push(
          parseInt(smellStaticstics[smellIsPresent].count, 10)
        );
      } else {
        smellStaticsticsArr.push(parseInt(obj.count, 10));
      }
      const usecaseIsPresent = usecaseStaticstics.findIndex((u: any) => {
        return u.created_at === formatedDay;
      });
      if (usecaseIsPresent > -1) {
        usecaseStaticsticsArr.push(
          parseInt(usecaseStaticstics[usecaseIsPresent].count, 10)
        );
      } else {
        usecaseStaticsticsArr.push(parseInt(obj.count, 10));
      }
      const measurementSetupIsPresent = measurementSetupStaticstics.findIndex(
        (u: any) => {
          return u.created_at === formatedDay;
        }
      );
      if (measurementSetupIsPresent > -1) {
        measurementSetupStaticsticsArr.push(
          parseInt(
            measurementSetupStaticstics[measurementSetupIsPresent].count,
            10
          )
        );
      } else {
        measurementSetupStaticsticsArr.push(parseInt(obj.count, 10));
      }
      const issueIsPresent = issueStaticstics.findIndex((u: any) => {
        return u.created_at === formatedDay;
      });
      if (issueIsPresent > -1) {
        issueStaticsticsArr.push(
          parseInt(issueStaticstics[issueIsPresent].count, 10)
        );
      } else {
        issueStaticsticsArr.push(parseInt(obj.count, 10));
      }
    });
    // Set series data
    this.userOptions.series = [
      {
        name: 'Users',
        data: userStaticsticsArr,
      },
    ];
    this.measurementsOptions.series = [
      {
        name: 'Measurements',
        data: measurementStaticsticsArr,
      },
    ];
    this.smellsOptions.series = [
      {
        name: 'Substances',
        data: smellStaticsticsArr,
      },
    ];
    this.usecasesOptions.series = [
      {
        name: 'Use Cases',
        data: usecaseStaticsticsArr,
      },
    ];
    this.measurementSetupsOptions.series = [
      {
        name: 'Measurement Setups',
        data: measurementSetupStaticsticsArr,
      },
    ];
    this.issuesOptions.series = [
      {
        name: 'Issues',
        data: issueStaticsticsArr,
      },
    ];
    // Set max value for y-axis
    this.userOptions.yaxis = {
      type: 'value',
      labels: {
        formatter: function (value: any) {
          return `${parseInt(value, 10)}`;
        },
      },
      max: () => {
        return (
          Math.max(
            ...userStaticstics.map((aa: any) => {
              return aa.count;
            })
          ) + 1
        );
      },
    };
    this.measurementsOptions.yaxis = {
      type: 'value',
      labels: {
        formatter: function (value: any) {
          return `${parseInt(value, 10)}`;
        },
      },
      max: () => {
        return (
          Math.max(
            ...measurementStaticstics.map((aa: any) => {
              return aa.count;
            })
          ) + 1
        );
      },
    };
    this.smellsOptions.yaxis = {
      type: 'value',
      labels: {
        formatter: function (value: any) {
          return `${parseInt(value, 10)}`;
        },
      },
      max: () => {
        return (
          Math.max(
            ...smellStaticstics.map((aa: any) => {
              return aa.count;
            })
          ) + 1
        );
      },
    };
    this.usecasesOptions.yaxis = {
      type: 'value',
      labels: {
        formatter: function (value: any) {
          return `${parseInt(value, 10)}`;
        },
      },
      max: () => {
        return (
          Math.max(
            ...usecaseStaticstics.map((aa: any) => {
              return aa.count;
            })
          ) + 1
        );
      },
    };
    this.measurementSetupsOptions.yaxis = {
      type: 'value',
      labels: {
        formatter: function (value: any) {
          return `${parseInt(value, 10)}`;
        },
      },
      max: () => {
        return (
          Math.max(
            ...measurementSetupStaticstics.map((aa: any) => {
              return aa.count;
            })
          ) + 1
        );
      },
    };
    this.issuesOptions.yaxis = {
      type: 'value',
      labels: {
        formatter: function (value: any) {
          return `${parseInt(value, 10)}`;
        },
      },
      max: () => {
        return (
          Math.max(
            ...issueStaticstics.map((aa: any) => {
              return aa.count;
            })
          ) + 1
        );
      },
    };
  }

  isFilterApplied() {
    return !(this.fromDate && this.toDate);
  }

  clearFilter() {
    this.fromDate = '';
    this.toDate = '';
    this.setDefaultDate();
    this.getDashboardData();
  }
}
