import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';
@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(private apiService: APIService) {}

  getDetectorTypes(params: string) {
    const url = `/detector/detector-type?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDetectorTypes');
  }

  getDetectorTypeDetails(detectortypeId: string) {
    const url = `/detector/detector-type/${detectortypeId}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getDetectorTypeDetails'
    );
  }

  createDetectorType(params: object) {
    const url = '/detector/detector-type';
    return this.apiService.postWithAuthHandler(url, params);
  }

  editDetectorType(params: object) {
    const url = `/detector/detector-type`;
    return this.apiService.putWithAuthHandler(url, params);
  }

  deleteDetectorType(params: string) {
    const url = `/detector/detector-type?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  getDetectors(params: string) {
    const url = `/detector?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDetectors');
  }

  getDetectorFilters() {
    const url = '/detector/filters';
    return this.apiService.getWithAuthHandler(url, true, 'getDetectorFilters');
  }

  getDetectorDetails(detectorId: string) {
    const url = `/detector/${detectorId}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDetectorDetails');
  }

  createDetector(params: object) {
    const url = '/detector';
    return this.apiService.postWithAuthHandler(url, params);
  }

  editDetector(params: object) {
    const url = `/detector/edit`;
    return this.apiService.putWithAuthHandler(url, params);
  }

  deleteDetector(params: string) {
    const url = `/detector?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  getDeviceTypes(params: string) {
    const url = `/device/device-type?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDeviceTypes');
  }

  getDeviceTypeDetails(detectortypeId: string) {
    const url = `/device/device-type/${detectortypeId}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getDeviceTypeDetails'
    );
  }

  getDeviceDetails(deviceId: string) {
    const url = `/device?deviceId=${deviceId}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDeviceDetails');
  }

  createDeviceType(params: object) {
    const url = '/device/device-type';
    return this.apiService.postWithAuthHandler(url, params);
  }

  editDeviceType(params: object) {
    const url = `/device/device-type`;
    return this.apiService.putWithAuthHandler(url, params);
  }

  deleteDeviceType(params: string) {
    const url = `/device/device-type?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  getDevices(params: string) {
    const url = `/device/list?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDevices');
  }

  getDeviceTypeFilter(params?: string) {
    let url = '/device/device-type/filters';
    if (params) {
      url += `?${params}`;
    }
    return this.apiService.getWithAuthHandler(url, true, 'getDeviceTypeFilter');
  }

  getFirmwaresForDeviceType(params?: string) {
    const url = `/device/device-type/firmwares?${params}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getFirmwaresForDeviceType'
    );
  }

  getDeviceFilter() {
    const url = `/device/filters`;
    return this.apiService.getWithAuthHandler(url, true, 'getDeviceFilter');
  }

  createDevice(params: object) {
    const url = '/device';
    return this.apiService.postWithAuthHandler(url, params);
  }

  editDevice(params: object) {
    const url = '/device/edit';
    return this.apiService.postWithAuthHandler(url, params);
  }

  deleteDevice(params: string) {
    const url = `/device?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  validateDetectorExistency(params: string) {
    const url = `/detector/validate?${params}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'validateDetectorExistency'
    );
  }

  async importDevices(params: object) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = '/device/upload';
        const res = await this.apiService.postWithAuthHandler(url, params);
        return resolve(res);
      } catch (err: any) {
        return reject(err);
      }
    });
  }

  getImportedDevices(params: string) {
    const url = `/device/list-imported-csv?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDevices');
  }

  getDetectorBoards(params: string) {
    const url = `/board/list?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getDetectorBoards');
  }

  getDetectorBoardDetails(params: string) {
    const url = `/board?${params}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getDetectorBoardDetails'
    );
  }

  validateDetectorBoardExistency(params: string) {
    const url = `/detector/board/validate?${params}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'validateDetectorBoardExistency'
    );
  }

  createDetectorBoard(params: object) {
    const url = '/board';
    return this.apiService.postWithAuthHandler(url, params);
  }

  editDetectorBoard(params: object) {
    const url = '/board';
    return this.apiService.putWithAuthHandler(url, params);
  }

  deleteDetectorBoard(params: string) {
    const url = `/board?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  getImportedDetectorBoards(params: string) {
    const url = `/board/list-imported-csv?${params}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getImportedDetectorBoards'
    );
  }

  async importDetectorBoards(params: object) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = '/board/import';
        const res = await this.apiService.postWithAuthHandler(url, params);
        return resolve(res);
      } catch (err: any) {
        return reject(err);
      }
    });
  }
}
