import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DemoMaterialModule } from '../demo-material-module';

import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DetectorRoutingModule } from './detector-routing.module';

import { MatSelectModule } from '@angular/material/select';
import { DetectorsComponent } from './detectors/detectors.component';
import { AddDetectorComponent } from './add-detector/add-detector.component';

@NgModule({
  declarations: [
    DetectorsComponent,
    AddDetectorComponent
  ],
  imports: [
    CommonModule,
    DetectorRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule,
  ]
})
export class DetectorModule {

}
