import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { Usecase } from 'src/app/shared/models/usecase';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UsecaseService } from 'src/app/shared/services/usecase/usecase.service';
import { UsecaseDialogComponent } from '../usecase-dialog/usecase-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-usecases',
  templateUrl: './usecases.component.html',
  styleUrls: ['./usecases.component.scss'],
})
export class UsecasesComponent implements OnInit {
  public data = {};
  public isProcessing = false;
  public pageIndex: number = 0;
  public pageSize: number = 15;
  displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'total_measurement_count',
    'unknown_substance',
    'monitoring_short_measurement',
    'is_public',
    'user_name',
    'user_email',
    'createdAt',
  ];
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  measurementTypes: any = {};
  public searchText: any;
  public is_public = null;
  public unknownSubstance = null;
  public monitoringShortMeasurement = null;
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('usecaseSearch') usecaseSearch: ElementRef | any;
  public toDate: any = '';
  public fromDate: any = '';
  // Order
  public orderOn = 'id';
  public orderBy = 'desc';
  public futureDate = new Date();

  // Tooltip
  public tooltip = {
    view_usecase: 'View Use Case',
    update_usecase: 'Update Use Case',
    delete_usecase: 'Delete Use Case',
  };

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = ['name'];
  public searchOn = [];
  public length: number = 0;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private usecaseService: UsecaseService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) {
    this.measurementTypes = {
      short_measurement: 'Short Measurement',
      monitoring: 'Monitoring Measurement',
    };
  }

  ngOnInit(): void {
    this.setDefaultDates();
    this.getUsecases();
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
    if (params.value && params.value.usecase_id) {
      this.openDialog('View', {
        id: params.value.usecase_id,
      });
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getUsecases();
    }
  }
  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getUsecases();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getUsecases(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let usecaseURL = '';

      // SEARCH
      if (this.searchedText) {
        usecaseURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      if (this.searchOn.length) {
        usecaseURL += `&searchOn=${JSON.stringify(this.searchOn)}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        usecaseURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS

      if (this.is_public != null) {
        usecaseURL += `&isPublic=${this.is_public}`;
        this.setDefaultPage(event);
      }

      if (this.unknownSubstance != null) {
        usecaseURL += `&unknownSubstance=${this.unknownSubstance}`;
        this.setDefaultPage(event);
      }

      if (this.monitoringShortMeasurement != null) {
        usecaseURL += `&monitoringShortMeasurement=${this.monitoringShortMeasurement}`;
        this.setDefaultPage(event);
      }

      if (this.fromDate && this.toDate) {
        usecaseURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      usecaseURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.usecaseService.getUsecases(usecaseURL);
      if (res) {
        this.data = res.data.usecases;
        this.dataSource = res.data.usecases.map((u: any) => {
          let isUnknown = '-';
          if (typeof u.unknown_substance === 'boolean') {
            isUnknown = u.unknown_substance ? 'Yes' : 'No';
          }
          u.unknown_substance = isUnknown;
          return u;
        });
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getUsecases();
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.usecaseSearch && this.usecaseSearch.nativeElement) {
        this.usecaseSearch.nativeElement.focus();
      }
    });
  }

  isFilterApplied() {
    return !(
      this.unknownSubstance != null ||
      this.monitoringShortMeasurement != null ||
      this.is_public != null ||
      this.toDate
    );
  }

  clearFilter() {
    this.is_public = null;
    this.fromDate = '';
    this.toDate = '';
    this.unknownSubstance = null;
    this.monitoringShortMeasurement = null;
    this.getUsecases();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(UsecaseDialogComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            this.deleteRowData(result.data);
          }
        }
      }
    });
  }

  addRowData(row_obj: Usecase): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [
      { ...row_obj, total_measurement_count: 0 },
      ...this.dataSource,
    ];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: any): boolean | void {
    const index = this.dataSource.findIndex((d: any) => d.id === row_obj.id);
    if (index != -1) {
      let isUnknown = '-';
      if (typeof row_obj.unknown_substance === 'boolean') {
        isUnknown = row_obj.unknown_substance ? 'Yes' : 'No';
      }
      row_obj.unknown_substance = isUnknown;
      this.dataSource[index] = row_obj;
    }
    this.table.renderRows();
  }

  deleteRowData(row_obj: Usecase) {
    this.getUsecases();
  }
}
