import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SmellsComponent } from './smells/smells.component';

const routes: Routes = [
  {
    path: '',
    component: SmellsComponent,
    data: {
      title: 'Smell/Substance',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Smell/Substance' }],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SmellRoutingModule {}
