import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DevicesComponent } from './devices/devices.component';

const routes: Routes = [
  {
    path: '',
    component: DevicesComponent,
    data: {
      title: 'Device',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Device' }]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceRoutingModule {

}
