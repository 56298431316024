import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ImportedDevicesComponent } from '../imported-devices/imported-devices.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-import-devices',
  templateUrl: './import-devices.component.html',
  styleUrls: ['./import-devices.component.scss'],
})
export class ImportDevicesComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public action: string = 'Add';
  public local_data: any;
  public importDeviceForm: any;
  public isProcessing: boolean = false;
  public fileName: string = '';
  public fileSize: number = 0;
  public invalidFile: boolean = false;
  public maxLimitReached: boolean = false;
  public allowedExtension = ['csv'];
  public csvFile: any = null;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ImportedDevicesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }

  ngOnInit() {
    this.resetImportDevice();
  }

  // convenience getter for easy access to form fields
  get importDeviceControls() {
    return this.importDeviceForm.controls;
  }

  async onSelect(data: any) {
    this.csvFile = null;
    this.fileName = '';
    this.invalidFile = false;
    this.maxLimitReached = false;
    this.fileSize = 0;
    const fileObj = data[0];
    if (fileObj) {
      this.csvFile = fileObj;
      const fileExtension: any = fileObj.name.split('.').pop();
      if (+(fileObj.size / 1024).toFixed(2) > 5120) {
        this.importDeviceControls.devices.status = 'LIMIT_EXCEEDED';
        this.invalidFile = true;
        this.maxLimitReached = true;
      } else if (!this.allowedExtension.includes(fileExtension)) {
        this.importDeviceControls.devices.status = 'INVALID';
        this.invalidFile = true;
      }
      this.fileName = fileObj.name;
      this.fileSize = +(fileObj.size / 1024).toFixed(2);
    }
  }

  resetImportDevice() {
    const groupObj: any = {
      devices: [null, Validators.compose([Validators.required])],
    };
    this.importDeviceForm = this.fb.group(groupObj);
  }

  async doAction() {
    await this.submitDeviceTypeForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitDeviceTypeForm() {
    if (this.importDeviceForm.valid && this.csvFile) {
      try {
        this.isProcessing = true;
        const formData: FormData = new FormData();
        formData.append('file', this.csvFile);
        let hasInCatch = false;
        this.deviceService.importDevices(formData).catch((err) => {
          hasInCatch = true;
          this.isProcessing = false;
          return this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        });
        await this.sleep(1000);
        if (!hasInCatch) {
          this.helperService.showAlert(
            'Your file is under processing, you can safely navigate to other pages',
            'success'
          );
          this.local_data.data = {
            id: null,
            csv_file_name: this.fileName,
            csv_s3_file_name: null,
            status: 'InProgress',
            output_csv_file_link: null,
            uploaded_by: null,
            createdAt: null,
            updatedAt: null,
          };
          this.dialogRef.close({
            event: this.action,
            data: this.local_data,
            success: true,
          });
        }
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
