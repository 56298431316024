import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppHttpService } from '../app-http/app-http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ResponseService } from '../response/response.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  APP_URL = environment.API_URL;
  private runningRequest: any = null;
  private runningPostRequest: any = null;
  private runningGetWithAuthReq: any = {};
  private runningPostWithAuthReq: any = null;

  constructor(
    private httpService: AppHttpService,
    private localStorageService: LocalStorageService,
    private responseService: ResponseService,
    private authService: AuthService
  ) {}

  getWithAuthHandler(
    url: string,
    preventPreviousCall?: boolean,
    name?: string
  ): any {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        return reject(this.responseService.noInternet());
      }
      if (preventPreviousCall) {
        if (
          name &&
          this.runningGetWithAuthReq &&
          this.runningGetWithAuthReq.name === name
        ) {
          if (this.runningGetWithAuthReq.promise) {
            this.runningGetWithAuthReq.promise.unsubscribe();
          }
        }
        const token = this.localStorageService.getUserToken();
        const headers = { Authorization: token || '' };
        this.runningGetWithAuthReq = {};
        this.runningGetWithAuthReq.name = name;
        this.runningGetWithAuthReq.promise = this.httpService
          .getWithHeader(url, headers)
          .subscribe(
            (res: any) => {
              this.runningGetWithAuthReq = null;
              const result = this.responseService.responseBuilder(res.body);
              return result.hasError ? reject(result) : resolve(result);
            },
            (err) => {
              this.authenticateUser(err);
              if (
                err.status &&
                err.status === 401 &&
                err &&
                err.error &&
                err.error.message &&
                (err.error.message === 'jwt expired' ||
                  err.error.message === 'invalid signature')
              ) {
                err.error.message = 'Session expired! Please login again';
              }
              this.runningGetWithAuthReq = null;
              const errorObj = this.responseService.errorResponse(err);
              reject(errorObj);
            }
          );
      } else {
        const token = this.localStorageService.getUserToken();
        const headers = { Authorization: token || '' };
        this.httpService.getWithHeader(url, headers).subscribe(
          (res: any) => {
            const result = this.responseService.responseBuilder(res.body);
            return result.hasError ? reject(result) : resolve(result);
          },
          (err) => {
            this.authenticateUser(err);
            const error = this.setSessionError(err);
            const errorObj = this.responseService.errorResponse(error);
            reject(errorObj);
          }
        );
      }
    });
  }

  getTPBlob(url: string) {
    return this.httpService.getTPBlob(url);
  }

  getWithoutAuthHandler(url: string, preventPreviousCall?: boolean): any {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        return reject(this.responseService.noInternet());
      }
      if (preventPreviousCall) {
        if (this.runningRequest) {
          this.runningRequest.unsubscribe();
        }
        this.runningRequest = this.httpService.get(url).subscribe(
          (res: any) => {
            this.runningRequest = null;
            const result = this.responseService.responseBuilder(res);
            return result.hasError ? reject(result) : resolve(result);
          },
          (err) => {
            this.runningRequest = null;
            const errorObj = this.responseService.errorResponse(err);
            reject(errorObj);
          }
        );
      } else {
        this.httpService.get(url).subscribe(
          (res: any) => {
            const result = this.responseService.responseBuilder(res);
            return result.hasError ? reject(result) : resolve(result);
          },
          (err) => {
            const errorObj = this.responseService.errorResponse(err);
            reject(errorObj);
          }
        );
      }
    });
  }

  postWithAuthHandler(
    url: string,
    body: any,
    customHeaders?: any,
    preventPreviousCall?: boolean
  ): any {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        return reject(this.responseService.noInternet());
      }
      if (preventPreviousCall) {
        if (this.runningPostWithAuthReq) {
          this.runningPostWithAuthReq.unsubscribe();
        }
        const token = this.localStorageService.getUserToken();
        const headers = { ...customHeaders, Authorization: token || '' };
        this.runningPostWithAuthReq = this.httpService
          .postWithHeader(url, body, headers)
          .subscribe(
            (res: any) => {
              const result = this.responseService.responseBuilder(res.body);
              return result.hasError ? reject(result) : resolve(result);
            },
            (err) => {
              this.authenticateUser(err);
              const error = this.setSessionError(err);
              const errorObj = this.responseService.errorResponse(error);
              reject(errorObj);
            }
          );
      } else {
        const token = this.localStorageService.getUserToken();
        const headers = { ...customHeaders, Authorization: token || '' };
        this.httpService.postWithHeader(url, body, headers).subscribe(
          (res: any) => {
            const result = this.responseService.responseBuilder(res.body);
            return result.hasError ? reject(result) : resolve(result);
          },
          (err) => {
            this.authenticateUser(err);
            const error = this.setSessionError(err);
            const errorObj = this.responseService.errorResponse(error);
            reject(errorObj);
          }
        );
      }
    });
  }

  postWithoutAuthHandler(
    url: string,
    body: any,
    preventPreviousCall?: boolean
  ): any {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        return reject(this.responseService.noInternet());
      }
      if (preventPreviousCall) {
        if (this.runningPostRequest) {
          this.runningPostRequest.unsubscribe();
        }
        this.runningPostRequest = this.httpService.post(url, body).subscribe(
          (res: any) => {
            const result = this.responseService.responseBuilder(res.body);
            return result.hasError ? reject(result) : resolve(result);
          },
          (err) => {
            this.authenticateUser(err);
            const error = this.setSessionError(err);
            const errorObj = this.responseService.errorResponse(error);
            reject(errorObj);
          }
        );
      } else {
        this.httpService.post(url, body).subscribe(
          (res: any) => {
            const result = this.responseService.responseBuilder(res.body);
            return result.hasError ? reject(result) : resolve(result);
          },
          (err) => {
            this.authenticateUser(err);
            const error = this.setSessionError(err);
            const errorObj = this.responseService.errorResponse(error);
            reject(errorObj);
          }
        );
      }
    });
  }

  putWithAuthHandler(url: string, body?: any, headers?: any): any {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        return reject(this.responseService.noInternet());
      }
      const token = this.localStorageService.getUserToken();
      const customHeaders = { Authorization: token || '' };
      Object.assign(customHeaders, headers);
      this.httpService.putWithHeader(url, body, customHeaders).subscribe(
        (res: any) => {
          const result = this.responseService.responseBuilder(res.body);
          return result.hasError ? reject(result) : resolve(result);
        },
        (err) => {
          this.authenticateUser(err);
          const error = this.setSessionError(err);
          const errorObj = this.responseService.errorResponse(error);
          reject(errorObj);
        }
      );
    });
  }

  putTPWithHeader(url: string, body?: any, headers?: any): any {
    return this.httpService.putTPWithHeader(url, body, headers);
  }

  putWithoutAuthHandler(url: string, body: any, headers: any): any {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        return reject(this.responseService.noInternet());
      }
      this.httpService.putWithHeader(url, body, headers).subscribe(
        (res: any) => {
          const result = this.responseService.responseBuilder(res);
          return result.hasError ? reject(result) : resolve(result);
        },
        (err) => {
          const errorObj = this.responseService.errorResponse(err);
          reject(errorObj);
        }
      );
    });
  }

  deleteWithAuthHandler(url: string, headers?: any): any {
    return new Promise((resolve, reject) => {
      if (!navigator.onLine) {
        return reject(this.responseService.noInternet());
      }
      const token = this.localStorageService.getUserToken();
      const customHeaders = { Authorization: token || '' };
      Object.assign(customHeaders, headers);
      this.httpService.deleteWithHeader(url, customHeaders).subscribe(
        (res: any) => {
          const result = this.responseService.responseBuilder(res.body);
          return result.hasError ? reject(result) : resolve(result);
        },
        (err) => {
          this.authenticateUser(err);
          const error = this.setSessionError(err);
          const errorObj = this.responseService.errorResponse(error);
          reject(errorObj);
        }
      );
    });
  }

  authenticateUser(err: any) {
    if (
      err &&
      err.status &&
      err.status === 401 &&
      err.error &&
      err.error.message &&
      err.error.message === 'User is invalid.'
    ) {
      // Make API call here and fetch the new access token based on stored refresh token
    } else if (err && err.status && err.status === 401) {
      // Redirect to login page
      return this.authService.logout();
    } else if (err && err.status && err.status === 419) {
      // Redirect to login page
      return this.authService.logout();
    } else if (
      err &&
      err.status &&
      err.status === 403 &&
      err.error &&
      err.error.message &&
      err.error.message ===
        `You don't have enough permission to do this operation.`
    ) {
      // Redirect to login page
      return this.authService.logout();
    }
  }

  setSessionError(err: any) {
    if (
      err &&
      err.status &&
      err.status === 401 &&
      err &&
      err.error &&
      err.error.message &&
      err.error.message === `jwt expired`
    ) {
      err.error.message = 'Session expired! Please login again';
      return err;
    } else {
      return err;
    }
  }
}
