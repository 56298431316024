import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { Issue } from 'src/app/shared/models/issue';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { IssueService } from 'src/app/shared/services/issue/issue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteIssueComponent } from '../delete-issue/delete-issue.component';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss'],
})
export class IssuesComponent implements OnInit {
  displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'issue_identifier',
    'issue_title',
    'status',
    'issue_type',
    'issue_user',
    'createdAt',
  ];
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('issueSearch') issueSearch: ElementRef | any;
  public issueTypeDropDownSearch: FormControl = new FormControl();
  public data = {};
  public isProcessing = false;
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public searchText: any;
  public toDate: any = '';
  public fromDate: any = '';
  public issue_id: any = '';
  public issueStatus: any;
  public futureDate = new Date();

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  // Tooltip
  public tooltip = {
    view_issue: 'View Issue',
    update_issue: 'Update Issue',
    delete_issue: 'Delete Issue',
  };

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = ['name'];
  public searchOn = [];
  public length: number = 0;
  public selectedStatus: any = [];
  public issueTypes: any = [];
  public selectedIssueTypes: any = [];
  public filteredIssueTypes: any = [];

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private issueService: IssueService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.issueStatus = {
      open: 'Open',
      in_progress: 'In Progress',
      closed: 'Closed',
    };
  }

  ngOnInit(): void {
    this.setDefaultDates();
    this.getIssues();
    this.getIssueTypes();
    this.issueTypeDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredIssueTypes = this.issueTypes.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
    if (params.value && params.value.issue_id) {
      this.issue_id = params.value.issue_id;
      this.router.navigate([`/issue/detail/${params.value.issue_id}`]);
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getIssues();
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getIssues();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getIssues(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let issueURL = '';

      // SEARCH
      if (this.searchedText) {
        issueURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      if (this.searchOn.length) {
        issueURL += `&searchOn=${JSON.stringify(this.searchOn)}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        issueURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS
      if (this.selectedStatus.length) {
        issueURL += `&status=${this.selectedStatus.join(',')}`;
        this.setDefaultPage(event);
      }
      if (this.selectedIssueTypes.length) {
        issueURL += `&category=${this.selectedIssueTypes.join(',')}`;
        this.setDefaultPage(event);
      }

      if (this.fromDate && this.toDate) {
        issueURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      issueURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.issueService.getIssues(issueURL);
      if (res) {
        this.data = res.data.issuesInfo;
        this.dataSource = res.data.issuesInfo;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getIssueTypes() {
    try {
      const res = await this.issueService.getIssueTypes(null);
      if (res) {
        this.issueTypes = res.data.issueTypes;
        this.filteredIssueTypes = [...this.issueTypes];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getIssues();
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.issueSearch && this.issueSearch.nativeElement) {
        this.issueSearch.nativeElement.focus();
      }
    });
  }

  isFilterApplied() {
    return !(
      this.selectedStatus.length ||
      this.selectedIssueTypes.length ||
      this.toDate
    );
  }

  clearFilter() {
    this.fromDate = '';
    this.toDate = '';
    this.selectedStatus = [];
    this.selectedIssueTypes = [];
    this.getIssues();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(DeleteIssueComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Delete') {
          if (result.success) {
            this.getIssues();
          }
        }
      }
    });
  }

  updateRowData(row_obj: Issue): boolean | void {
    const index = this.dataSource.findIndex((d: any) => d.id === row_obj.id);
    if (index != -1) {
      this.dataSource[index] = row_obj;
    }
    this.table.renderRows();
  }
}
