import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {

  constructor(private apiService: APIService) { }

  getFirmwares(params: any) {
    const url = `/firmware/?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getFirmwares');
  }

  getFirmware(params: any): any {
    const url = `/firmware/${params}`;
    return this.apiService.getWithAuthHandler(url);
  }

  addFirmware(body: any): any {
    const url = '/firmware';
    return this.apiService.postWithAuthHandler(url, body);
  }

  updateFirmware(body: any): any {
    const url = `/firmware/${body.id}`;
    return this.apiService.putWithAuthHandler(url, body);
  }

  removeFirmware(params: any): any {
    const url = `/firmware/${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  getPreSignedURL(params: any): any {
    const url = `/firmware/presigned?${params}`;
    return this.apiService.getWithAuthHandler(url);
  }

  uploadFiles3Sync(fileuploadurl: string, file: any): any {
    return new Promise((resolve, reject) => {
      const url = fileuploadurl;
      const headers = {};
      this.apiService.putTPWithHeader(url, file, headers)
        .subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          reject(err);
        });
    });
  }

}
