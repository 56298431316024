import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidators } from 'src/app/validators/customValidator';

import { FormBuilder, Validators } from '@angular/forms';
import { HelperService } from '../../shared/services/helper/helper.service';
import { LoginService } from '../../shared/services/login/login.service';
import { CommonConstants } from 'src/app/shared/common-constants';
import { CommonMessages } from 'src/app/shared/common-messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public isProcessing = false;
  public showError = false;
  public showPassword = false;
  public isSubmitted = false;
  public loginForm: any;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private loginService: LoginService,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.resetLoginForm();
  }

  // convenience getter for easy access to form fields
  get loginControls() {
    return this.loginForm.controls;
  }

  resetLoginForm() {
    this.loginForm = this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespace,
          CustomValidators.checkPatternEmail,
          Validators.maxLength(CommonConstants.maxLength62),
        ]),
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          // CustomValidators.passwordValidate,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
    });
  }

  async submitLoginForm(value: any) {
    this.isSubmitted = true;
    if (this.loginForm.valid) {
      try {
        this.isProcessing = true;
        const reqObj = {
          email: value.email,
          password: value.password,
        };
        const res = await this.loginService.login(reqObj);
        if (res && res.data?.user?.user_type === 'admin') {
          this.helperService.setPREF('token', res.data.accessToken);
          this.helperService.setPREF('refreshToken', res.data.refreshToken);
          this.helperService.setPREF(
            'userDetails',
            JSON.stringify(res.data.user)
          );
          this.helperService.setPREF('isUserLogged', true);
          const params: any = this.route.queryParams;
          const redirectURL =
            params.value && params.value.redirectURL
              ? params.value.redirectURL
              : '';
          this.helperService.showAlert('Success', 'success');
          if (redirectURL) {
            this.router.navigate([redirectURL]);
          } else {
            this.router.navigate(['/']);
          }
        } else {
          this.showError = true;
          this.helperService.showAlert(CommonMessages.NO_ADMIN_USER, 'error');
        }
      } catch (err: any) {
        this.showError = true;
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isSubmitted = true;
        this.isProcessing = false;
      }
    }
  }
}
