<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
    <div class="login-register-box">
        <mat-card>
            <mat-card-content>
                <form [formGroup]="resetPwdForm">
                    <div class="text-center">
                        <img alt="homepage" class="login-logo" src="assets/images/snt_dark.svg">
                        <h4 *ngIf="!passwordUpdated" class="m-t-0">Reset Password</h4>
                        <h4 *ngIf="passwordUpdated" class="m-t-0 success-msg">Password has been changed successfully
                        </h4>
                    </div>

                    <div *ngIf="!passwordUpdated" fxLayout="row wrap">


                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field class="m-b-30">
                                <input required id="password" matInput type="password" placeholder="New Password"
                                    formControlName="password">
                                <mat-error
                                    *ngIf="!resetPwdControls.password.value && resetPwdForm.get('password').touched"
                                    class="text-danger">Password is required</mat-error>
                                <mat-error
                                    *ngIf="resetPwdForm.get('password').invalid && !resetPwdControls.password.errors.required && resetPwdForm.get('password').touched"
                                    class="text-danger">Please enter a password at least 8 character and contain at
                                    least one uppercase, at least one lower case and at least one special character!
                                </mat-error>
                            </mat-form-field>
                        </div>


                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field class="m-b-30">
                                <input required id="confPassword" matInput type="password"
                                    placeholder="Confirm New Password" formControlName="confPassword">
                                <mat-error
                                    *ngIf="!resetPwdControls.confPassword.value && resetPwdForm.get('confPassword').touched"
                                    class="text-danger">Confirm password is required</mat-error>
                                <mat-error
                                    *ngIf="resetPwdForm.get('confPassword')?.invalid && resetPwdControls.confPassword.value  && resetPwdForm.get('confPassword')?.touched"
                                    class="text-danger">Password and Confirm Password must be match.</mat-error>
                            </mat-form-field>
                        </div>


                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-spinner *ngIf="isProcessing" class="m-auto" [diameter]="30"></mat-spinner>
                            <button (click)="submitResetPwdForm(resetPwdForm.value)" mat-raised-button color="primary"
                                class="btn-block btn-lg m-t-20 m-b-20" type="submit"
                                [disabled]="!resetPwdForm.valid">Submit</button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>