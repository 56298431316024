<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Classifier</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" style="max-height: inherit;" *ngIf="action != 'Delete'; else elseTemplate">
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <form [formGroup]="addClassifierForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input #title type="text" matInput placeholder="ML_XXX" required id="name" [(ngModel)]="local_data.name"
                formControlName="name">
              <mat-error *ngIf="!addClassifierControls.name.value && addClassifierForm.get('name').touched"
                class="text-danger">Name is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.name.value && addClassifierForm.get('name').touched && addClassifierForm.get('name').hasError('whitespace')">
                Please enter valid name
              </mat-error>

              <mat-error *ngIf="addClassifierControls.name.value && addClassifierForm.get('name').hasError('maxlength')"
                class="text-danger">
                Maximum 60 characters are allowed
              </mat-error>

            </mat-form-field>
          </div>
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>Version</mat-label>
              <input #title type="text" matInput required [(ngModel)]="local_data.version" placeholder="ML_INT_TXXX"
                formControlName="version">
              <mat-error *ngIf="!addClassifierControls.version.value && addClassifierForm.get('version').touched"
                class="text-danger">Version is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.version.value && addClassifierForm.get('version').touched && addClassifierForm.get('version').hasError('whitespace')">
                Please enter valid version
              </mat-error>
              <mat-error
                *ngIf="addClassifierControls.version.value && addClassifierForm.get('version').hasError('maxlength')"
                class="text-danger">
                Maximum 60 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>SG Endpoint</mat-label>
              <input #title type="text" matInput required [(ngModel)]="local_data.sg_endpoint" placeholder="mxnet-inference-XXXX
              " formControlName="sg_endpoint">
              <mat-error
                *ngIf="!addClassifierControls.sg_endpoint.value && addClassifierForm.get('sg_endpoint').touched"
                class="text-danger">SG Endpoint is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.sg_endpoint.value && addClassifierForm.get('sg_endpoint').touched && addClassifierForm.get('sg_endpoint').hasError('whitespace')">
                Please enter valid SG Endpoint
              </mat-error>
              <mat-error
                *ngIf="addClassifierControls.sg_endpoint.value && addClassifierForm.get('sg_endpoint').hasError('maxlength')"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>Smell/Substance</mat-label>
              <mat-select required placeholder="Substance" multiple formControlName="substanceIds"
                [(ngModel)]="selectedSubstanceIds">
                <mat-option>
                  <ngx-mat-select-search [formControl]="smellDropDownSearch"
                    placeholderLabel="Search Substance/Smell ..."
                    noEntriesFoundLabel="No matching Substance/Smell found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option (onSelectionChange)="onSelectChange($event, 'substanceIds', substance.id)"
                  *ngFor="let substance of filteredSmells; let i = index" [value]="substance.id">
                  <div *ngIf="selectedOrderedSubstanceIds.includes(substance.id)">{{i}}: {{ substance.name }}
                    [{{ substance.id }}]</div>
                  <div *ngIf="!selectedOrderedSubstanceIds.includes(substance.id)">{{ substance.name
                    }} [{{ substance.id }}]</div>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectedSubstanceIds.length === 0 && addClassifierForm.get('substanceIds').touched"
                class="text-danger">Choose atleast one substance</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>Model Type</mat-label>
              <input #title type="text" matInput required [(ngModel)]="local_data.model_type" placeholder="XXXX Format"
                formControlName="model_type">
              <mat-error *ngIf="!addClassifierControls.model_type.value && addClassifierForm.get('model_type').touched"
                class="text-danger">Model Type is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.model_type.value && addClassifierForm.get('model_type').touched && addClassifierForm.get('model_type').hasError('whitespace')">
                Please enter valid Model Type
              </mat-error>
              <mat-error
                *ngIf="addClassifierControls.model_type.value && addClassifierForm.get('model_type').hasError('maxlength')"
                class="text-danger">
                Maximum 60 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>Model Requirement Information</mat-label>
              <input type="text" matInput required [(ngModel)]="local_data.model_requirement_info_str"
                placeholder='{"onnxruntime":"1.10.0"}' formControlName="model_requirement_info">
              <mat-error
                *ngIf="!addClassifierControls.model_requirement_info.value && addClassifierForm.get('model_requirement_info').touched"
                class="text-danger">Model Requirement Information is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.model_requirement_info.value && addClassifierForm.get('model_requirement_info').touched && addClassifierForm.get('model_requirement_info').hasError('invalidJSON')">
                Please enter valid Model Requirement Information
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="33" class="m-l-15">
            <mat-form-field>
              <mat-label>Device Type</mat-label>
              <mat-select required placeholder="Device Type" multiple formControlName="deviceTypeIds"
                [(ngModel)]="selectedDeviceTypeIds" (selectionChange)="getFirmwares($event)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="deviceTypeDropDownSearch"
                    placeholderLabel="Search Device Type ..." noEntriesFoundLabel="No matching Device Type found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option (onSelectionChange)="onSelectChange($event, 'deviceTypeIds')"
                  *ngFor="let deviceType of filteredDeviceTypes" [value]="deviceType.id">
                  {{ deviceType.name }}-{{ deviceType.version }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectedDeviceTypeIds.length === 0 && addClassifierForm.get('deviceTypeIds').touched"
                class="text-danger">Choose atleast one device type</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33" class="m-l-15">
            <mat-form-field>
              <mat-label>Firmware</mat-label>
              <mat-select required placeholder="Firmware" multiple formControlName="firmwareIds"
                [(ngModel)]="selectedFirmwareIds">
                <mat-option>
                  <ngx-mat-select-search [formControl]="firmwareSearch" placeholderLabel="Search Firmware ..."
                    noEntriesFoundLabel="No matching Firmware found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option (onSelectionChange)="onSelectChange($event, 'firmwareIds')"
                  *ngFor="let firmware of filteredFirmwares" [value]="firmware.supported_firmware.id">
                  {{ firmware.supported_firmware.name }}-{{ firmware.supported_firmware.version }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectedFirmwareIds.length === 0 && addClassifierForm.get('firmwareIds').touched"
                class="text-danger">Choose atleast one firmware</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33" class="m-l-15">
            <mat-form-field>
              <mat-label>Detector Type</mat-label>
              <mat-select required placeholder="Detector Type" multiple formControlName="detectorTypeIds"
                [(ngModel)]="selectedDetectorTypeIds">
                <mat-option>
                  <ngx-mat-select-search [formControl]="detectorTypeSearch" placeholderLabel="Search Detector Type..."
                    noEntriesFoundLabel="No matching Detector Type found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option (onSelectionChange)="onSelectChange($event, 'detectorTypeIds', detectorType.id)"
                  *ngFor="let detectorType of filteredDetectorTypes; let i = index" [value]="detectorType.id">
                  <div *ngIf="selectedOrderedDetectorTypeIds.includes(detectorType.id)">{{i}}: {{ detectorType.name }}
                    [{{ detectorType.id }}]</div>
                  <div *ngIf="!selectedOrderedDetectorTypeIds.includes(detectorType.id)">{{ detectorType.name
                    }} [{{ detectorType.id }}]</div>
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="selectedDetectorTypeIds.length === 0 && addClassifierForm.get('detectorTypeIds').touched"
                class="text-danger">Choose atleast one detector type</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="33" class="m-l-15">
            <mat-form-field>
              <mat-label>Window Size</mat-label>
              <input min="0" type="number" (keyup)="validNumber($event)" matInput required name="window_size"
                placeholder="1" formControlName="window_size" [(ngModel)]="local_data.window_size">
              <mat-error
                *ngIf="!addClassifierControls.window_size.value && addClassifierForm.get('window_size').touched"
                class="text-danger">Window Size is required</mat-error>
              <mat-error *ngIf="addClassifierForm.get('window_size').hasError('invalidInt')" class="text-danger">
                Please provide valid Window Size</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33" class="m-l-15">
            <mat-form-field>
              <mat-label>Model Informations</mat-label>
              <input #title type="text" matInput required [(ngModel)]="local_data.model_information_str"
                placeholder='{"input_shape":"(n,66)","output_shape":"(n,2)"}' formControlName="model_information">
              <mat-error
                *ngIf="!addClassifierControls.model_information.value && addClassifierForm.get('model_information').touched"
                class="text-danger">Model Information is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.model_information.value && addClassifierForm.get('model_information').touched && addClassifierForm.get('model_information').hasError('invalidJSON')">
                Please enter valid Model Information
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33" class="m-l-15">
            <mat-form-field>
              <mat-label>Model Functionalization</mat-label>
              <input #title type="text" matInput required [(ngModel)]="local_data.model_functionalization"
                placeholder="[999,69...,59,999]" formControlName="model_functionalization">
              <mat-error
                *ngIf="!addClassifierControls.model_functionalization.value && addClassifierForm.get('model_functionalization').touched"
                class="text-danger">Functionalization is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.model_functionalization.value && addClassifierForm.get('model_functionalization').touched && addClassifierForm.get('model_functionalization').hasError('whitespace')">
                Please enter valid Functionalization
              </mat-error>
              <mat-error
                *ngIf="addClassifierControls.model_functionalization.value && addClassifierForm.get('model_functionalization').hasError('maxlength')"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>Category</mat-label>
              <mat-select required placeholder="Category" multiple formControlName="category"
                [(ngModel)]="selectedCategory">
                <mat-option>
                  <ngx-mat-select-search [formControl]="categorySearch" placeholderLabel="Search Category..."
                    noEntriesFoundLabel="No matching Category found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option (onSelectionChange)="onSelectChange($event, 'category')"
                  *ngFor="let category of filteredCategories" [value]="category">
                  {{ category }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectedCategory.length === 0 && addClassifierForm.get('category').touched"
                class="text-danger">Choose atleast one category</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>Monitoring Type</mat-label>
              <mat-select placeholder="monitoring type" formControlName="monitoringShortMeasurement"
                [(ngModel)]="selectedMeasurementType">
                <mat-option *ngFor="let measurementType of listMeasurementTypes" [value]="measurementType">
                  {{ measurementType }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="50" class="m-l-15">
            <mat-form-field>
              <mat-label>PB Model Path</mat-label>
              <input #title type="text" matInput required [(ngModel)]="local_data.pb_formatted_model_path" placeholder="s3://sagemaker-XXX/.../XXX.tar.gz/
                " formControlName="pb_formatted_model_path">
              <mat-error
                *ngIf="!addClassifierControls.pb_formatted_model_path.value && addClassifierForm.get('pb_formatted_model_path').touched"
                class="text-danger">PB Model Path is required</mat-error>
              <mat-error
                *ngIf="addClassifierControls.pb_formatted_model_path.value && addClassifierForm.get('pb_formatted_model_path').touched && addClassifierForm.get('pb_formatted_model_path').hasError('whitespace')">
                Please enter valid PB Model Path
              </mat-error>
              <mat-error
                *ngIf="addClassifierControls.pb_formatted_model_path.value && addClassifierForm.get('pb_formatted_model_path').hasError('maxlength')"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="50" class="m-l-15">
            <div
              [ngClass]="!addClassifierControls.mlModel.value && addClassifierForm.get('mlModel').touched ? 'error-file' : 'file-upload'">
              <div
                [ngClass]="!addClassifierControls.mlModel.value && addClassifierForm.get('mlModel').touched ? 'text-danger' : ''">
                Model Path *
                <a *ngIf="action == 'Update'" class="upload-model" (click)="cancelFileUpload()">{{ showFile ? "Cancel" :
                  "Upload"}}</a>
              </div>
              <div style="height: 41px;" *ngIf="action == 'Add' || (action == 'Update' && showFile)">
                <input class="m-b-15" type="file" (change)="onSelect($event.target.files)" placeholder="Upload ML Model"
                  formControlName="mlModel">
              </div>
              <div *ngIf="action == 'Update' && !showFile">
                <a href="{{local_data.model_path}}" [matTooltip]="local_data.model_path" target="_blank">
                  <div class="might-overflow">
                    {{local_data.model_path}}
                  </div>
                </a>
              </div>
            </div>
            <div class="custom-mat-err">
              <mat-error *ngIf="!addClassifierControls.mlModel.value && addClassifierForm.get('mlModel').touched"
                class="text-danger">ML Model is required</mat-error>
            </div>
            <div class="custom-mat-err">
              <mat-error
                *ngIf="addClassifierControls.mlModel.value && addClassifierControls.mlModel.status === 'INVALID' && addClassifierForm.get('mlModel').touched"
                class="text-danger">Please provide valid file</mat-error>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="33" class="m-l-15">
            <mat-form-field>
              <mat-label>Use Case</mat-label>
              <mat-select placeholder="Usecase" multiple formControlName="usecaseIds" [(ngModel)]="selectedUsecaseIds">
                <mat-option>
                  <ngx-mat-select-search [formControl]="usecaseDropDownSearch" placeholderLabel="Search Use Case..."
                    noEntriesFoundLabel="No matching Use Case found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option (onSelectionChange)="onSelectChange($event, 'usecaseIds')"
                  *ngFor="let usecase of filteredUsecases" [value]="usecase.id">
                  {{ usecase.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="selectedUsecaseIds.length === 0 && addClassifierForm.get('usecaseIds').touched"
                class="text-danger">Choose atleast one usecase</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="33" class="m-l-15">
            <div class="m-t-15">
              <mat-checkbox color="primary" formControlName="isPublic" class="m-r-10" [(ngModel)]="selectedisPublic">
                isPublic</mat-checkbox>
            </div>
          </div>
          <div fxFlex="33" class="m-l-15">
            <div class="m-t-15">
              <mat-checkbox color="primary" formControlName="is_active" class="m-r-10" [(ngModel)]="selectedIsActive">
                isActive</mat-checkbox>
            </div>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
          <div fxFlex="100" class="m-l-15">
            <mat-form-field>
              <mat-label>Description</mat-label>
              <textarea (change)="validDescription($event, 'description')" rows="4" cols="40"
                [(ngModel)]="local_data.description" #description matInput placeholder="Description"
                formControlName="description"></textarea>
              <mat-error
                *ngIf="addClassifierControls.description.value && addClassifierForm.get('description').hasError('maxLength')"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
              <mat-error
                *ngIf="addClassifierForm.controls['description'].invalid && addClassifierForm.controls['description'].errors.maxlength"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !addClassifierForm.valid || invalidFile">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.title}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>