import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DemoMaterialModule } from '../demo-material-module';

import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { UserRoutingModule } from './user-routing.module';
import { UsersComponent } from './users/users.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { MatSelectModule } from '@angular/material/select';
import { UserStorageComponent } from './user-storage/user-storage.component';
import { AssignUserStorageComponent } from './assign-user-storage/assign-user-storage.component';
import { AssignClassifiersComponent } from './assign-classifiers/assign-classifiers.component';

@NgModule({
  declarations: [
    UsersComponent,
    UserDialogComponent,
    UserDetailComponent,
    UserResetPasswordComponent,
    AssignClassifiersComponent,
    UserStorageComponent,
    AssignUserStorageComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule
  ]
})
export class UserModule { }
