import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class SmellService {
  constructor(private apiService: APIService) {}

  getSmells(params: any) {
    const url = `/substance?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getSmells');
  }

  getSmellInfo(params: any): any {
    const url = `/substance/${params}`;
    return this.apiService.getWithAuthHandler(url);
  }

  getBaseSmells(params: any) {
    const url = `/substance?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getSmells');
  }

  getSmellFilters() {
    const url = '/substance/filters';
    return this.apiService.getWithAuthHandler(url, true, 'getSmellFilters');
  }

  addSmell(body: any): any {
    const url = '/substance';
    return this.apiService.postWithAuthHandler(url, body);
  }

  updateSmell(body: any): any {
    const url = `/substance/${body.id}`;
    return this.apiService.putWithAuthHandler(url, body);
  }

  removeSmell(params: any): any {
    const url = `/substance/${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }
}
