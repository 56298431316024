import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UsersComponent } from '../users/users.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {
  action: string = 'Add';
  local_data: any;
  selectedImage: any = '';
  joiningDate: any = '';
  addFaqForm: any;
  public categories: any = [];

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private userService: UserService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: UsersComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddContentForm();
    this.faqGetCategories();
    this.setPatchValues();
  }

  // convenience getter for easy access to form fields
  get addFaqControls() {
    return this.addFaqForm.controls;
  }

  resetAddContentForm() {
    this.addFaqForm = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      category_id: [null, Validators.compose([Validators.required])],
    });
  }

  setPatchValues() {
    if (this.action === 'Update') {
      this.addFaqForm.patchValue({
        title: this.local_data.title,
        description: this.local_data.description,
        category_id: this.local_data.faq_category.id,
      });
    }
  }

  async doAction() {
    await this.submitFaqForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async faqGetCategories() {
    try {
      const page = 1;
      const perPage = 100;
      const faqCategoryURL = `page=${page}&perPage=${perPage}`;
      const res = await this.userService.faqGetCategories(faqCategoryURL);
      if (res) {
        this.categories = res.data.faqCategories;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  async submitFaqForm() {
    if (this.action === 'Delete') {
      try {
        const deleteURL = `faqId=${this.local_data.id}`;
        const res = await this.userService.removeFaq(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      }
    } else {
      if (this.addFaqForm.valid) {
        try {
          const category_id = this.addFaqForm.controls.category_id.value;
          const reqData: any = {
            title: this.local_data.title,
            description: this.local_data.description,
            category_id,
          };
          if (this.action === 'Update') {
            reqData['faqId'] = this.local_data.id;
          }
          let res: any;
          if (this.action === 'Add') {
            res = await this.userService.addFaq(reqData);
            this.local_data.id = res.data.id;
            this.local_data.faq_category = res.data.faq_category;
            this.local_data.createdAt = res.data.createdAt;
          } else if (this.action === 'Update') {
            res = await this.userService.editFaq(reqData);
            this.local_data.category = this.categories.find((c: any) => {
              return c.id === category_id;
            });
          }
          if (res) {
            this.helperService.showAlert(res.msg || 'Success', 'success');
          }
          this.dialogRef.close({
            event: this.action,
            data: this.local_data,
            success: true,
          });
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
          this.dialogRef.close({
            event: this.action,
            data: this.local_data,
            success: false,
          });
        }
      }
    }
  }
}
