<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                <mat-icon>search</mat-icon>
              </button>
              <div class="search" *ngIf="showSearch">
                <mat-form-field class="search-box">
                  <mat-label>Search</mat-label>
                  <input matInput #faqSearch [(ngModel)]="searchedText" placeholder="Search" (keyup)="getFaqs()">
                  <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="filter-item">
                <mat-form-field>
                  <mat-select placeholder="Category" multiple [(ngModel)]="selectedCategories">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="categorySearch" placeholderLabel="Search Category..."
                        noEntriesFoundLabel="No matching Category found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredCategories.length > 0" #allCategoryCheckbox
                      (click)="allSelection('category')" [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('category')" *ngFor="let category of filteredCategories"
                      [value]="category.id">
                      {{ category.category }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>


              <div (click)="picker.open()" class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>

            </div>
            <div class="d-flex">
              <button mat-raised-button color="primary" (click)="openDialog('Add',{})">Add
                FAQ</button>
              <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                (click)="clearFilter()">Clear Filters</button>

               
            </div>

            <!-- <div class="search" *ngIf="showSearch">
              <mat-form-field class="search-box">
                <mat-label>Search</mat-label>
                <input matInput #faqSearch [(ngModel)]="searchedText" placeholder="Search" (keyup)="getFaqs()">
                <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div> -->
            <div *ngIf='length !== 0' class="example-container ex-c-general">

              <table mat-table [dataSource]="dataSource" [matSortActive]="orderOn" [matSortDirection]="orderBy"
                class="table employee-list no-wrap" matSort (matSortChange)="sortData($event)">

                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>
                <ng-container matColumnDef="id">
                  <th disableClear mat-sort-header mat-header-cell *matHeaderCellDef> Id </th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="title">
                  <th disableClear mat-sort-header mat-header-cell *matHeaderCellDef> Title </th>
                  <td class="might-overflow" [matTooltip]="element.title" mat-cell *matCellDef="let element">
                    {{ element.title }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="description">
                  <th disableClear mat-sort-header mat-header-cell *matHeaderCellDef> Description </th>
                  <td class="might-overflow" [matTooltip]="element.description" mat-cell *matCellDef="let element">
                    <p class="font m-b-0 m-t-0">{{ element.description }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="category">
                  <th disableClear mat-sort-header mat-header-cell *matHeaderCellDef> Category </th>
                  <td class="might-overflow" [matTooltip]="element.faq_category.category" mat-cell
                    *matCellDef="let element">
                    {{ element.faq_category.category }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-sort-header mat-header-cell *matHeaderCellDef> Created At </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <a [matTooltip]="tooltip.update_faq" (click)="openDialog('Update',element)"
                      class="m-r-10 cursor-pointer"><i class="fa fa-pencil editFont"></i></a>
                    <a [matTooltip]="tooltip.delete_faq" (click)="openDialog('Delete',element)"
                      class="m-r-10 cursor-pointer">
                      <i class="fa fa-trash deleteFont text-danger"></i>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf="length === 0">No record found</div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getFaqs($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>