import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { MeasurementDetailComponent } from '../measurement-detail/measurement-detail.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FaqService } from 'src/app/shared/services/faq/faq.service';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-add-baseline',
  templateUrl: './add-baseline.component.html',
  styleUrls: ['./add-baseline.component.scss'],
})
export class AddBaselineComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public joiningDate: any = '';
  public addBaselineForm: any;
  public isProcessing = false;
  public eventRangeError: boolean = false;
  public min: any = '';
  public max: any = '';
  public minLocal: any;
  public maxLocal: any;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private faqService: FaqService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: MeasurementDetailComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddContentForm();
    this.setData();
  }

  // convenience getter for easy access to form fields
  get addBaselineControls() {
    return this.addBaselineForm.controls;
  }

  resetAddContentForm() {
    this.addBaselineForm = this.fb.group({
      timestamp: [null, Validators.compose([Validators.required])],
    });
  }

  setData() {
    this.min = moment
      .utc(this.local_data.parsedDataToPlot[0].timestamp)
      .local()
      .format()
      .split('+')[0];
    this.max = moment
      .utc(
        this.local_data.parsedDataToPlot[
          this.local_data.parsedDataToPlot.length - 1
        ].timestamp
      )
      .local()
      .format()
      .split('+')[0];
    this.minLocal = moment(this.min).format('DD/MM/YYYY, hh:mm:ss A');
    this.maxLocal = moment(this.max).format('DD/MM/YYYY, hh:mm:ss A');
    this.local_data.timestamp = this.min;
    this.addBaselineForm.controls.timestamp.patchValue(this.min);
  }

  timeChanged(eve: any) {
    const val = this.addBaselineForm.get('timestamp').value;
    const valueInUTC = moment(val).utc().format();
    if (this.min === val) {
      this.eventRangeError = false;
      this.addBaselineControls['timestamp'].setErrors(null);
      return true;
    }
    const isBefore = moment(valueInUTC).isBefore(
      moment(this.local_data.parsedDataToPlot[0].timestamp)
    );
    const isAfter = moment(valueInUTC).isAfter(
      moment(
        this.local_data.parsedDataToPlot[
          this.local_data.parsedDataToPlot.length - 1
        ].timestamp
      )
    );
    if (isBefore || isAfter) {
      this.eventRangeError = true;
      this.addBaselineControls['timestamp'].setErrors({ incorrect: true });
    } else {
      this.eventRangeError = false;
      this.addBaselineControls['timestamp'].setErrors(null);
    }
  }

  async doAction() {
    await this.addBaseline();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async addBaseline() {
    if (this.addBaselineForm.valid) {
      const { value } = this.addBaselineControls.timestamp;
      if (value) {
        this.dialogRef.close({
          event: this.action,
          data: {
            ...this.local_data,
            timestamp_utc: new Date(value).toISOString(),
          },
          success: true,
        });
      }
    }
  }
}
