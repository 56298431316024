<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Detector</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <form [formGroup]="addDetectorForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input (keyup)="validateDetector($event)" type="text" matInput required id="detector_info"
              name="detector_info" class="detector-info" [(ngModel)]="local_data.detector_info"
              placeholder="Detector Info" formControlName="detector_info">

            <div class="detector-disp">
              <div class="detector-disp__batch">
                <div class="empty-bx"></div>
                Batch
              </div>
              <div class="detector-disp__slot">
                <div class="empty-bx"></div>
                Slot
              </div>
              <div class="detector-disp__detector">
                <div class="empty-bx"></div>
                Detector
              </div>
            </div>
            <!-- <div>
              <table mat-table [dataSource]="detectorBlock" class="table employee-list td-ht-sm b-all no-wrap my-5">

                <ng-container matColumnDef="key">
                  <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
                    {{ element.key }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="value">
                  <td class="might-overflow-link"
                    [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                    mat-cell *matCellDef="let element">
                    <div *ngIf="element.isDate">
                      {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                    </div>
                    <div *ngIf="element.isTextArea">
                      <div *ngIf="element.value.length > 15">
                        <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                          [(ngModel)]="element.value" #description matInput class="scrollbar"
                          placeholder="Description"></textarea>
                      </div>
                      <div *ngIf="element.value.length < 16">
                        {{ element.value }}
                      </div>
                    </div>
                    <div *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                      {{ element.value }}
                    </div>
                    <div *ngIf="element.isLink">
                      <a href="{{element.value}}">{{element.value}}</a>
                    </div>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: detectorBlockColumns"></tr>
              </table>
            </div> -->
            <mat-error *ngIf="!addDetectorControls.detector_info.value && addDetectorForm.get('detector_info').touched"
              class="text-danger">Detector Info is required</mat-error>
            <mat-error
              *ngIf="addDetectorForm.get('detector_info').invalid && !addDetectorControls.detector_info.errors.required && addDetectorForm.get('detector_info').touched"
              class="text-danger">Detector Info is invalid</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <mat-label>Detector Type</mat-label>
            <mat-select required placeholder="Detector Type" formControlName="detector_type_id">
              <mat-option>
                <ngx-mat-select-search [formControl]="detectorTypeSearch" placeholderLabel="Search Detector Type..."
                  noEntriesFoundLabel="No matching Detector Type found">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType.id">
                {{ detectorType.name | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="!addDetectorControls.detector_type_id.value && addDetectorForm.get('detector_type_id').touched"
              class="text-danger">Detector Type is required</mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !addDetectorForm.valid">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.detector_info}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>