import { Routes } from '@angular/router';

import { ErrorComponent } from './error/error.component';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { AuthService } from '../shared/services/auth/auth.service';

export const AuthenticationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '404',
        component: ErrorComponent
      },
      {
        path: 'forgot',
        component: ForgotComponent,
        resolve: [AuthService]
      },
      {
        path: 'login',
        component: LoginComponent,
        resolve: [AuthService]
      },
      {
        path: 'user-reset/:resetToken',
        component: ResetpasswordComponent,
        // resolve: [AuthService]
      },
      {
        path: '**',
        component: LoginComponent
      },
    ]
  }
];
