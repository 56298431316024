import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassifiersComponent } from './classifiers/classifiers.component';
import { ClassifierDetailComponent } from './classifier-detail/classifier-detail.component';

const routes: Routes = [
  {
    path: '',
    component: ClassifiersComponent,
    data: {
      title: 'Classifier',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Classifier' }]
    }
  },
  {
    path: 'detail/:classifierId',
    component: ClassifierDetailComponent,
    data: {
      title: 'Classifier Detail',
      urls: [
        { title: 'Dashboard', url: '/' },
        { title: 'Classifier', url: '/classifier' },
        { title: 'Classifier Detail' }
      ]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClassifierRoutingModule {

}
