<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Smell/Substance</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3 smell-dialog-content" *ngIf="action != 'Delete'; else elseTemplate">
  <div *ngIf="action !== 'View'">
    <form [formGroup]="smellForm">
      <div fxLayout="row wrap" class="align-items-center form-wrapper">
        <div fxFlex="100" fxFlex.gt-md="100">
          <div class="overlay" *ngIf="isProcessing">
            <mat-spinner class="loader"></mat-spinner>
          </div>
          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label> Smell / Substance Name</mat-label>
              <input type="text" required matInput formControlName="name" name="name" class="form-control" id="name" />
              <mat-error *ngIf="
                          smellForm.controls['name'].invalid &&
                          (smellForm.controls['name'].errors.required || smellForm.controls['name'].errors?.isItBlank)&&
                          (smellForm.controls['name'].dirty || smellForm.controls['name'].touched)"
                class="text-danger">
                Smell / Substance Name is required</mat-error>
              <mat-error *ngIf="smellForm.controls['name'].invalid && smellForm.controls['name'].errors.maxlength"
                class="text-danger">
                Maximum 60 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label>Category</mat-label>
              <mat-select placeholder="Category" formControlName="category" name="category">
                <mat-option>
                  <ngx-mat-select-search [formControl]="categorySearch" placeholderLabel="Search Category..."
                    noEntriesFoundLabel="No matching Category found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let category of filteredCategories" [value]="category.id">
                  {{ category.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="action !== 'View'" class="right-header-actions">
              <a (click)="clearDropDown('category')" style="cursor: pointer;" class="link">Clear</a>
            </div>
          </div>

          <div *ngIf="action !== 'View'" class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label>Base Smell / Substance Name</mat-label>
              <mat-select placeholder=">Base Smell / Substance Name" formControlName="baseSmell" name="baseSmell">
                <mat-option>
                  <ngx-mat-select-search [formControl]="baseSmellSearch"
                    placeholderLabel="Search Base Smell / Substance..."
                    noEntriesFoundLabel="No matching Base Smell / Substance found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let baseSmell of filteredBaseSmells" [value]="baseSmell">
                  {{baseSmell.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="action !== 'View'" class="right-header-actions">
              <a (click)="clearDropDown('baseSmell')" style="cursor: pointer;" class="link">Clear</a>
            </div>
          </div>

          <div *ngIf="action === 'View'" class="m-r-15 m-l-15 m-b-15">
            <h5 *ngIf="local_data.parent_substance_name">Base Smell / Substance Name :
              {{ local_data.parent_substance_name}}</h5>
            <h5 *ngIf="!local_data.parent_substance_name">Base Smell / Substance Name : : No Base Smell / Substance
              Available</h5>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label> Odor Description</mat-label>
              <input type="text" matInput formControlName="odorName" name="odorName" class="form-control"
                id="odorName" />
              <mat-error
                *ngIf="smellForm.controls['odorName'].invalid && smellForm.controls['odorName'].errors.maxlength"
                class="text-danger">
                Maximum 100 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label> Taste Description</mat-label>
              <input type="text" matInput formControlName="tasteName" name="tasteName" class="form-control"
                id="tasteName" />
              <mat-error
                *ngIf="smellForm.controls['tasteName'].invalid && smellForm.controls['tasteName'].errors.maxlength"
                class="text-danger">
                Maximum 100 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <textarea rows="4" cols="40" formControlName="additionalInformation" name="additionalInformation"
                #description matInput placeholder="Additional Information"></textarea>
              <mat-error
                *ngIf="smellForm.controls['additionalInformation'].invalid && smellForm.controls['additionalInformation'].errors.maxlength"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Public/Private : {{ local_data?.is_public ? 'Public' : 'Private' }}</h5>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Created By : {{local_data?.user_name || 'NA' }}</h5>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Email : {{local_data?.user_email || 'NA' }}</h5>
          </div>

        </div>
      </div>
      <button *ngIf="action != 'View'" mat-button (click)="doAction()" mat-flat-button color="primary"
        [disabled]="isProcessing || !smellForm.valid">{{action}}</button>
      <button mat-button class="m-l-10" (click)="closeDialog()"> {{action != 'View' ? 'Cancel' : 'Close'}}</button>
    </form>
  </div>
  <div *ngIf="action === 'View'">
    <div class="">
      <table mat-table [dataSource]="smellBlock" class="table employee-list td-ht-sm no-wrap">

        <ng-container matColumnDef="key">
          <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
            {{ element.key }}
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <td class="might-overflow-link"
            [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value" mat-cell
            *matCellDef="let element">
            <div *ngIf="element.isDate">
              {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
            </div>
            <div class="td-lg" *ngIf="element.isTextArea">
              <div class="td-lg" *ngIf="element.value.length > 64">
                <textarea readonly [ngModelOptions]="{standalone: true}" rows="3" cols="40" [(ngModel)]="element.value"
                  #description matInput class="scrollbar" placeholder="Description"></textarea>
              </div>
              <div *ngIf="element.value.length < 65">
                {{ element.value }}
              </div>
            </div>
            <div *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
              {{ element.value }}
            </div>
            <div *ngIf="element.isLink">
              <a href="{{element.value}}">{{element.value}}</a>
            </div>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: smellBlockColumns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<ng-template #elseTemplate>
  <p>Sure to delete smell <b>{{local_data.name}} </b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>