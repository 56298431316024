<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="overlay" *ngIf="isProcessing || isDeltaProcessing || isFuncProcessing || isAllChannelsProcessing">
            <mat-spinner class="loader"></mat-spinner>
          </div>
          <div fxLayout="row wrap" class="content-wrapper">
            <!-- Left side corner -->
            <div fxFlex.gt-sm="70" fxFlex.gt-xs="100" class="div-fix-height content-wrapper__left" fxFlex="100">
              <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">
                <mat-tab label="Response">
                  <div class="chart-parent">
                    <div class="chart" [id]="deltaGraphId"></div>
                    <div class="refresh" style="top: 7px; left: 51px;">
                      <button type="button" (click)="refreshGraph('Response')">
                        <img src="assets/images/reload_graph.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Features">
                  <div class="chart-parent">
                    <div class="chart" [id]="functionGraphId"></div>
                    <div class="refresh" style="left: 44px;">
                      <button type="button" (click)="refreshGraph('Features')">
                        <img src="assets/images/reload_graph.svg" alt="" />
                      </button>
                    </div>
                    <div class="chart-child">
                      <div class="chart-child-item" *ngFor="let c of customLegends | keyvalue">
                        <mat-checkbox class="event-checkbox" [class]="c.value.color"
                          (change)="toggleFunctionGraphLegend($event, c.key)" [(ngModel)]="c.value.isShow">f{{
                          c.key }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Channels">
                  <div class="chart-parent">
                    <div class="chart" [id]="allChannelsGraphId"></div>
                    <div class="refresh" style="left: 64px;">
                      <button type="button" (click)="refreshGraph('Channels')">
                        <img src="assets/images/reload_graph.svg" alt="" />
                      </button>
                    </div>
                    <div class="chart-child">
                      <div class="chart-child-item" *ngFor="let c of allChannelsCustomLegend | keyvalue">
                        <mat-checkbox class="event-checkbox" [class]="c.value.color"
                          (change)="toggleAllChannelsGraphLegend($event, c.key)" [(ngModel)]="c.value.isShow">f{{
                          c.key }}
                        </mat-checkbox>
                      </div>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab disabled>
                  <ng-template mat-tab-label>
                    <mat-checkbox class="event-checkbox" (change)="toggleEvent()" [(ngModel)]="isEventShow">
                    </mat-checkbox>
                    <span class="event-margin">Event</span>
                  </ng-template>
                </mat-tab>
                <mat-tab disabled>
                  <ng-template mat-tab-label>
                    <div class="border-start">
                      <button class="btn btn-secondary btn-sm px-1 h-100" [ngClass]="isInSync ? 'btn-on' : 'btn-noton'"
                        (click)="isInSync = !isInSync; syncOptionChange()"
                        style="margin: 0px 10px; pointer-events: visible">
                        <img [src]="isInSync ? 'assets/images/sync_white.svg' : 'assets/images/sync.svg'" class="me-2"
                          alt="" style="width: 13px; height: 11.5px" />Sync Graphs
                      </button>
                    </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
              <div>
                <div class="chart-parent">
                  <div class="chart" [id]="tempGraphId"></div>
                  <div class="refresh" style="top: 8px; left: 47px; width: fit-content;">
                    <button type="button" (click)="refreshGraph('Temp')">
                      <img src="assets/images/reload_graph.svg" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Right side corner -->
            <div fxFlex.gt-sm="30" fxFlex.gt-xs="100" class="content-wrapper__right" fxFlex="100">
              <mat-accordion multi>
                <div class="div-fix-height">
                  <mat-card>
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header class="no-mep course-header">
                        <mat-card-header class="course-header Web card-auto-height">
                          <mat-card-title>
                            <span class="course-name">Reset Bounds</span>
                          </mat-card-title>
                        </mat-card-header>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div class="m-t-20">
                          <form [formGroup]="resetFailedChannelForm">
                            <div fxLayout="row wrap" fxFlexAlign="center">
                              <div fxFlex.gt-sm="50" fxFlex="100">
                                <mat-form-field class="p-t-10 p-r-5">
                                  <input min="0" type="number" (keyup)="validNumber($event, 'lower_bound')" matInput
                                    required name="lower_bound" placeholder="Lower Bound" formControlName="lower_bound"
                                    [(ngModel)]="lowerBound">
                                  <mat-error
                                    *ngIf="!resetFailedChannelControls.lower_bound.value && resetFailedChannelForm.get('lower_bound').touched"
                                    class="text-danger">Lower Bound is required</mat-error>
                                  <mat-error *ngIf="resetFailedChannelForm.get('lower_bound').hasError('invalidInt')"
                                    class="text-danger">Please
                                    provide valid lower bound</mat-error>
                                </mat-form-field>
                              </div>

                              <div fxFlex.gt-sm="50" fxFlex="100">
                                <mat-form-field class="p-t-10 p-l-5">
                                  <input min="0" type="number" (keyup)="validNumber($event, 'upper_bound')" matInput
                                    required name="upper_bound" placeholder="Upper Bound" formControlName="upper_bound"
                                    [(ngModel)]="upperBound">
                                  <mat-error
                                    *ngIf="!resetFailedChannelControls.upper_bound.value && resetFailedChannelForm.get('upper_bound').touched && !resetFailedChannelForm.get('upper_bound').hasError('invalidUpperBound')"
                                    class="text-danger" class="error-msg-padding">Upper Bound is required</mat-error>
                                  <mat-error *ngIf="resetFailedChannelForm.get('upper_bound').hasError('invalidInt')"
                                    class="text-danger" class="error-msg-padding">Please provide valid upper bound
                                  </mat-error>
                                  <mat-error
                                    *ngIf="resetFailedChannelForm.get('upper_bound').hasError('invalidUpperBound') && resetFailedChannelForm.controls.upper_bound.status === 'INVALID'"
                                    class="text-danger" class="error-msg-padding">
                                    Upper bound should be greater than lower bound
                                  </mat-error>
                                </mat-form-field>
                              </div>
                              <div class="d-flex" style="padding-top: 12px;">

                                <button class="m-r-10" mat-button mat-flat-button color="primary"
                                  (click)="resetFailedChannel()"
                                  [disabled]="!resetFailedChannelForm.valid">Calculate</button>
                                <button mat-button mat-flat-button color="primary" (click)="resetBound()"
                                  [disabled]="!resetFailedChannelForm.valid || (lowerBound === keepOriginalLowerBound && upperBound === keepOriginalUpperBound)">Reset</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </mat-card-content>
                    </mat-expansion-panel>
                  </mat-card>
                </div>


                <div fxLayout="row wrap">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="div-fix-height" fxFlex="100">
                    <div>
                      <mat-card>
                        <mat-expansion-panel expanded>
                          <mat-expansion-panel-header class="no-mep">
                            <mat-card-header class="course-header Web card-auto-height baselines">

                              <mat-card-title class="course-header__title">
                                <span class="course-name"> Baselines</span>
                                <div>
                                  <a (click)="$event.stopPropagation()" (click)="openDialog('Add')"
                                    class="m-r-10 cursor-pointer">
                                    <mat-icon>add</mat-icon>
                                  </a>
                                  <a (click)="$event.stopPropagation()" (click)="resetBaseValues()"
                                    class="cursor-pointer">
                                    <mat-icon>cached</mat-icon>
                                  </a>
                                </div>
                              </mat-card-title>


                              <div fxFlex></div>

                            </mat-card-header>
                          </mat-expansion-panel-header>
                          <mat-card-content class="list-baselines">
                            <div class="example-container">
                              <table mat-table [dataSource]="listBaseValueArr"
                                class="table employee-list td-ht-sm no-wrap">

                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="select">
                                  <th mat-header-cell *matHeaderCellDef>Select</th>
                                  <td (click)="userInterference()" mat-cell *matCellDef="let row; let i = index">
                                    <mat-checkbox class="classifier-padding" (click)="$event.stopPropagation()"
                                      (change)="$event ? selection.toggle(row) : null; userInterference(); selectBaseline(row, $event.checked)"
                                      [checked]="selection.isSelected(row)" [disabled]="i == 0">
                                    </mat-checkbox>
                                  </td>
                                </ng-container>

                                <ng-container matColumnDef="timestamp">
                                  <th disableClear mat-header-cell *matHeaderCellDef>Time Stamp</th>
                                  <td class="might-overflow"
                                    [matTooltip]="element.timestamp | date: 'dd MMM YYY, HH.mm.ss' " mat-cell
                                    *matCellDef="let element"> {{ element.timestamp | date: 'dd MMM YYY, HH.mm.ss' }}
                                  </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="baselineColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: baselineColumns"></tr>
                              </table>
                            </div>
                          </mat-card-content>
                        </mat-expansion-panel>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="div-fix-height" fxFlex="100">
                    <div>
                      <mat-card>
                        <mat-expansion-panel expanded>
                          <mat-expansion-panel-header class="no-mep">
                            <mat-card-header class="course-header Web card-auto-height">
                              <mat-card-title>
                                <span class="course-name"> Info</span>
                              </mat-card-title>
                            </mat-card-header>
                          </mat-expansion-panel-header>
                          <mat-card-content class="list-baselines">
                            <div class="example-container">
                              <table mat-table [dataSource]="measurementInfo.infoBlock"
                                class="table employee-list td-ht-sm no-wrap">

                                <ng-container matColumnDef="key">
                                  <td class="might-overflow" mat-cell *matCellDef="let element">
                                    {{ element.key }}
                                  </td>
                                </ng-container>

                                <ng-container matColumnDef="value">
                                  <td class="might-overflow-link"
                                    [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                                    mat-cell *matCellDef="let element">
                                    <div *ngIf="element.isDate">
                                      {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                                    </div>
                                    <div *ngIf="!element.isDate && !element.isLink && !element.isRedirection">
                                      {{ element.value || '-' }}
                                    </div>
                                    <div *ngIf="element.isLink">
                                      <a href="{{element.value}}">{{element.value}}</a>
                                    </div>
                                    <div *ngIf="element.isRedirection">
                                      <u>
                                        <a target="_blank" (click)="$event.stopPropagation()"
                                          [routerLink]="[element.path, element.value]">{{ element.value }}</a>
                                      </u>
                                    </div>
                                  </td>
                                </ng-container>

                                <tr mat-row *matRowDef="let row; columns: infoBlockColumns"></tr>
                              </table>
                            </div>
                          </mat-card-content>
                        </mat-expansion-panel>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="div-fix-height" fxFlex="100">
                    <div>
                      <mat-card>
                        <mat-expansion-panel expanded>
                          <mat-expansion-panel-header class="no-mep">
                            <mat-card-header class="course-header Web card-auto-height">
                              <mat-card-title>
                                <span class="course-name"> Smell/Substance Information</span>
                              </mat-card-title>
                            </mat-card-header>
                          </mat-expansion-panel-header>
                          <div *ngIf="showSmells?.length">
                            <mat-card-content class="list-baselines">
                              <div class="example-container">
                                <table mat-table [dataSource]="showSmells" class="table employee-list td-ht-sm no-wrap">

                                  <ng-container matColumnDef="id">
                                    <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
                                      <u>
                                        <a target="_blank" (click)="$event.stopPropagation()"
                                          [routerLink]="['/measurement/smell']"
                                          [queryParams]="{substance_id: element.id}">{{ element.id }}</a>
                                      </u>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="name">
                                    <td class="might-overflow" [matTooltip]="element.name" mat-cell
                                      *matCellDef="let element">
                                      {{ element.name }}
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="concentration">
                                    <td class="might-overflow" [matTooltip]="element.concentration" mat-cell
                                      *matCellDef="let element">
                                      <div *ngIf="element.concentration">
                                        {{ element.concentration }}
                                      </div>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="state">
                                    <td class="might-overflow" [matTooltip]="element.state" mat-cell
                                      *matCellDef="let element">
                                      <div *ngIf="element.state">
                                        {{ element.state }}
                                      </div>
                                    </td>
                                  </ng-container>

                                  <tr mat-row *matRowDef="let row; columns: substancesColumns"></tr>
                                </table>
                              </div>
                            </mat-card-content>
                          </div>
                          <div *ngIf="!showSmells?.length" class="no-data">
                            <img src="assets/images/no-data.svg" alt="no-data" />
                            <p class="m-0">No Data Available</p>
                          </div>

                        </mat-expansion-panel>
                      </mat-card>
                    </div>
                  </div>
                </div>
                <div fxLayout="row wrap">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="div-fix-height" fxFlex="100">
                    <div>
                      <mat-card>
                        <mat-expansion-panel expanded>
                          <mat-expansion-panel-header class="no-mep">
                            <mat-card-header class="course-header Web card-auto-height">
                              <mat-card-title>
                                <span class="course-name"> Use Case</span>
                              </mat-card-title>
                            </mat-card-header>
                          </mat-expansion-panel-header>
                          <div *ngIf="showUsecases?.length">
                            <mat-card-content class="list-baselines">
                              <div class="example-container">
                                <table mat-table [dataSource]="showUsecases"
                                  class="table employee-list td-ht-sm no-wrap">

                                  <ng-container matColumnDef="id">
                                    <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
                                      <u>
                                        <a target="_blank" (click)="$event.stopPropagation()"
                                          [routerLink]="['/measurement/usecase']"
                                          [queryParams]="{usecase_id: element.id}">{{ element.id }}</a>
                                      </u>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="name">
                                    <td class="might-overflow-link" [matTooltip]="element.name" mat-cell
                                      *matCellDef="let element">
                                      <div *ngIf="element.name">
                                        {{ element.name }}
                                      </div>
                                    </td>
                                  </ng-container>

                                  <tr mat-row *matRowDef="let row; columns: usecaseColumns"></tr>
                                </table>
                              </div>
                            </mat-card-content>
                          </div>
                          <div *ngIf="!showUsecases?.length" class="no-data">
                            <img src="assets/images/no-data.svg" alt="no-data" />
                            <p class="m-0">No Data Available</p>
                          </div>

                        </mat-expansion-panel>
                      </mat-card>
                    </div>
                  </div>
                </div>

                <div fxLayout="row wrap">
                  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="div-fix-height" fxFlex="100">
                    <div>
                      <mat-card>
                        <mat-expansion-panel expanded>
                          <mat-expansion-panel-header class="no-mep">
                            <mat-card-header class="course-header Web card-auto-height">
                              <mat-card-title>
                                <span class="course-name"> Measurement Setup</span>
                              </mat-card-title>
                            </mat-card-header>
                          </mat-expansion-panel-header>
                          <div *ngIf="showMeasurementTypes?.length">
                            <mat-card-content class="list-baselines">
                              <div class="example-container">
                                <table mat-table [dataSource]="showMeasurementTypes"
                                  class="table employee-list td-ht-sm no-wrap">

                                  <ng-container matColumnDef="id">
                                    <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
                                      <u>
                                        <a target="_blank" (click)="$event.stopPropagation()"
                                          [routerLink]="['/measurement/measurement-setup']"
                                          [queryParams]="{measurement_setup_id: element.id}">{{ element.id }}</a>
                                      </u>
                                    </td>
                                  </ng-container>

                                  <ng-container matColumnDef="name">
                                    <td class="might-overflow-link" [matTooltip]="element.name" mat-cell
                                      *matCellDef="let element">
                                      <div *ngIf="element.name">
                                        {{ element.name }}
                                      </div>
                                    </td>
                                  </ng-container>

                                  <tr mat-row *matRowDef="let row; columns: usecaseColumns"></tr>
                                </table>
                              </div>
                            </mat-card-content>
                          </div>
                          <div *ngIf="!showMeasurementTypes?.length" class="no-data">
                            <img src="assets/images/no-data.svg" alt="no-data" />
                            <p class="m-0">No Data Available</p>
                          </div>
                        </mat-expansion-panel>
                      </mat-card>
                    </div>
                  </div>
                </div>

                <div class="div-fix-height">
                  <mat-card class="mat-card-fix-height">
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header class="no-mep">
                        <mat-card-header class="course-header Web card-auto-height">
                          <mat-card-title>
                            <span class="course-name"> Device Info</span>
                          </mat-card-title>
                        </mat-card-header>
                      </mat-expansion-panel-header>
                      <mat-card-content class="list-baselines">
                        <div class="example-container">
                          <table mat-table [dataSource]="measurementInfo.deviceInfoBlock"
                            class="table employee-list td-ht-sm no-wrap">

                            <ng-container matColumnDef="key">
                              <td class="might-overflow" mat-cell *matCellDef="let element">
                                {{ element.key }}
                              </td>
                            </ng-container>

                            <ng-container matColumnDef="value">
                              <td class="might-overflow-link"
                                [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                                mat-cell *matCellDef="let element">
                                <div *ngIf="element.isDate">
                                  {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                                </div>
                                <div *ngIf="!element.isDate && !element.isLink && !element.isRedirection">
                                  {{ element.value || '-' }}
                                </div>
                                <div *ngIf="element.isLink">
                                  <a href="{{element.value}}">{{element.value}}</a>
                                </div>
                                <div *ngIf="element.isRedirection">
                                  <u>
                                    <a target="_blank" (click)="$event.stopPropagation()"
                                      [routerLink]="[element.path, element.value]">{{ element.value }}</a>
                                  </u>
                                </div>
                              </td>
                            </ng-container>

                            <tr mat-row *matRowDef="let row; columns: infoBlockColumns"></tr>
                          </table>
                        </div>
                      </mat-card-content>
                    </mat-expansion-panel>
                  </mat-card>
                </div>

                <div class="div-fix-height">
                  <mat-card class="mat-card-fix-height">
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header class="no-mep">
                        <mat-card-header class="course-header Web card-auto-height">
                          <mat-card-title>
                            <span class="course-name"> Detector IDs</span>
                          </mat-card-title>
                        </mat-card-header>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div>
                          <ul class="list-group">
                            <li *ngFor="let detectorInfo of deviceData?.detectorInfo" class="list-group-item">
                              <span>{{ detectorInfo?.detector_position }}:
                                {{
                                detectorInfo.detector?.batch_id +
                                detectorInfo.detector?.slot_id +
                                detectorInfo.detector?.production_detector_id
                                }}</span>
                            </li>
                          </ul>
                        </div>
                      </mat-card-content>
                    </mat-expansion-panel>
                  </mat-card>
                </div>

                <div class="div-fix-height">
                  <mat-card class="mat-card-fix-height">
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header class="no-mep">
                        <mat-card-header class="course-header Web card-auto-height">
                          <mat-card-title>
                            <span class="course-name"> Events</span>
                          </mat-card-title>
                        </mat-card-header>
                      </mat-expansion-panel-header>
                      <mat-card-content class="list-baselines">
                        <div class="td-lg">
                          <div *ngIf='measurementInfo?.measurement_events?.length !== 0' class="example-container">
                            <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table
                              [dataSource]="measurementInfo.measurement_events"
                              class="table employee-list td-ht-sm no-wrap">

                              <ng-container matColumnDef="name">
                                <th disableClear mat-header-cell *matHeaderCellDef>Name</th>
                                <td class="might-overflow" [matTooltip]="element.event_name" mat-cell
                                  *matCellDef="let element">
                                  {{ element.event_name }}
                                </td>
                              </ng-container>

                              <ng-container matColumnDef="createdAt">
                                <th disableClear mat-header-cell *matHeaderCellDef>Time</th>
                                <td class="might-overflow"
                                  [matTooltip]="element.graph_x_position | date: 'dd MMM YYY, HH.mm.ss' " mat-cell
                                  *matCellDef="let element"> {{ element.graph_x_position | date: 'dd MMM YYY, HH.mm.ss'
                                  }}
                                </td>
                              </ng-container>

                              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                            </table>
                          </div>

                          <div *ngIf="!measurementInfo?.measurement_events?.length" class="no-data">
                            <img src="assets/images/no-data.svg" alt="no-data" />
                            <p class="m-0">No Data Available</p>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-expansion-panel>
                  </mat-card>
                </div>

                <div class="div-fix-height">
                  <mat-card class="mat-card-fix-height">
                    <mat-expansion-panel expanded>
                      <mat-expansion-panel-header class="no-mep">
                        <mat-card-header class="course-header Web card-auto-height">
                          <mat-card-title>
                            <span class="course-name"> Comment</span>
                          </mat-card-title>
                        </mat-card-header>
                      </mat-expansion-panel-header>
                      <mat-card-content>
                        <div *ngIf="comment?.length">
                          <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                            [(ngModel)]="comment" matInput class="scrollbar" placeholder="Comment"
                            placeholder></textarea>
                        </div>
                        <div *ngIf="!comment?.length" class="no-data">
                          <img src="assets/images/no-data.svg" alt="no-data" />
                          <p class="m-0">No Data Available</p>
                        </div>
                      </mat-card-content>
                    </mat-expansion-panel>
                  </mat-card>
                </div>
              </mat-accordion>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>