import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { ActivatedRoute } from '@angular/router';
import { ClassifiersService } from 'src/app/shared/services/classifier/classifier.service';

@Component({
  selector: 'app-classifier-detail',
  templateUrl: './classifier-detail.component.html',
  styleUrls: ['./classifier-detail.component.scss'],
})
export class ClassifierDetailComponent implements OnInit {
  public classifierInfo: any = {};
  public defaultImage = 'assets/images/users/default-profile.png';
  public isProcessing = false;
  public classifierBlock: any = [];
  public classifierBlockColumns: any = ['key', 'value'];

  // Table Columns
  public detectorColumns: string[] = [
    'id',
    'name',
    'functionalization',
    'lower_bound',
    'upper_bound',
  ];
  public deviceTypeColumns: string[] = ['id', 'name'];
  public firmwareColumns: string[] = ['id', 'name', 'version'];
  public categoryColumns: string[] = ['category'];
  public substanceColumns: string[] = [
    'id',
    'name',
    'category',
    'odor_name',
    'taste_name',
  ];
  public usecaseColumns: string[] = [
    'id',
    'name',
    'description',
    'unknown_substance',
    'monitoring_short_measurement',
  ];
  public userColumns: string[] = ['id', 'first_name', 'last_name', 'email'];

  // Panels
  public detectorPanel = false;
  public deviceTypePanel = false;
  public firmwarePanel = false;
  public categoryPanel = false;
  public substancePanel = false;
  public usecasePanel = false;
  public userPanel = false;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private classifierService: ClassifiersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getUserDetails();
  }

  async getUserDetails() {
    try {
      const classifierId = this.route.snapshot.paramMap.get('classifierId');
      this.isProcessing = true;
      const res = await this.classifierService.getMLClassifierDetail(
        classifierId
      );
      if (res) {
        this.classifierInfo = res.data.mlClassifierInfo;
        this.classifierInfo.isDescTruncate =
          this.classifierInfo &&
          this.classifierInfo.description &&
          this.classifierInfo.description.length > 100;
        this.classifierBlock = [
          {
            isDate: false,
            isLink: false,
            key: 'ID',
            value: this.classifierInfo.id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Name',
            value: this.classifierInfo.name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Version',
            value: this.classifierInfo.version,
          },
          {
            isDate: false,
            isLink: false,
            isTextArea:
              this.classifierInfo.description &&
              this.classifierInfo.description.length > 150,
            key: 'Description',
            value: this.classifierInfo.description || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'File Size',
            value: this.classifierInfo.file_size,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Model Type',
            value: this.classifierInfo.model_type,
          },
          {
            isDate: false,
            isLink: true,
            key: 'Model Path',
            value: this.classifierInfo.model_path,
          },
          {
            isDate: false,
            isLink: false,
            key: 'PB Model Path',
            value: this.classifierInfo.pb_formatted_model_path,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Model Functionalization',
            value: this.classifierInfo.model_functionalization,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Model Informations',
            value: JSON.stringify(this.classifierInfo.model_information),
          },
          {
            isDate: false,
            isLink: false,
            key: 'SG Endpoint',
            value: this.classifierInfo.sg_endpoint,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Window Size',
            value: this.classifierInfo?.model_information?.window_size || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Model Requirement Information',
            value: JSON.stringify(this.classifierInfo.model_requirement_info),
          },
          {
            isDate: false,
            isLink: false,
            key: 'Monitoring Type',
            value: this.classifierInfo.monitoring_short_measurement,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Public / Private',
            value: this.classifierInfo.is_public ? 'Public' : 'Private',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Active / Deactive',
            value: this.classifierInfo.is_active ? 'Active' : 'Deactive',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Created By',
            value: this.classifierInfo.ml_classifier_creator_user_info.email,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: this.classifierInfo.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: this.classifierInfo.updatedAt,
          },
        ];
        this.classifierInfo.ml_classifier_usecases =
          this.classifierInfo.ml_classifier_usecases.map((u: any) => {
            let isUnknown = '-';
            if (
              u.ml_classifier_usecase_info &&
              typeof u.ml_classifier_usecase_info.unknown_substance ===
                'boolean'
            ) {
              isUnknown = u.ml_classifier_usecase_info.unknown_substance
                ? 'Yes'
                : 'No';
            }
            u.ml_classifier_usecase_info.unknown_substance = isUnknown;
            return u;
          });
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }
}
