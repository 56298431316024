import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { AddDetectorComponent } from '../add-detector/add-detector.component';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { MatOption } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-detectors',
  templateUrl: './detectors.component.html',
  styleUrls: ['./detectors.component.scss'],
})
export class DetectorsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('allDetectorTypeCheckbox')
  private allDetectorTypeCheckbox: MatOption = Object.create(null);
  @ViewChild('allBatchIdCheckbox') private allBatchIdCheckbox: MatOption =
    Object.create(null);
  @ViewChild('allSlotIdCheckbox') private allSlotIdCheckbox: MatOption =
    Object.create(null);
  @ViewChild('allProdDetectorIdCheckbox')
  private allProdDetectorIdCheckbox: MatOption = Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'batch_id',
    'slot_id',
    'production_detector_id',
    'detector_type',
    'createdAt',
    'updatedAt',
  ];
  public detectorTypeSearch: FormControl = new FormControl();
  public batchIdSearch: FormControl = new FormControl();
  public slotIdSearch: FormControl = new FormControl();
  public prodDetectorIdSearch: FormControl = new FormControl();
  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  public dataSource: any = [];
  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public filteredDetectorTypes: any = [];
  public filteredBatchIds: any = [];
  public filteredSlotIds: any = [];
  public filteredProdDetectorIds: any = [];
  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = [];

  // Filter
  public detectorTypes = [];
  public slotIds = [];
  public batchIds = [];
  public productionDetectorIds = [];
  public selectedDetectorTypes: any = [];
  public selectedBatchIds: any = [];
  public selectedSlotIds: any = [];
  public selectedProdDetectorIds: any = [];
  public toDate: any = '';
  public fromDate: any = '';
  // Order
  public orderOn = 'id';
  public orderBy = 'desc';
  public futureDate = new Date();

  // Tooltip
  public tooltip = {
    view_detector: 'View Detector',
    update_detector: 'Update Detector',
    delete_detector: 'Delete Detector',
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild('detectorSearch') detectorSearch: ElementRef | any;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.getDetectorFilters();
    this.getDetectors();
    this.detectorTypeSearch.valueChanges.subscribe((val) => {
      this.filteredDetectorTypes = this.detectorTypes.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.batchIdSearch.valueChanges.subscribe((val) => {
      this.filteredBatchIds = this.batchIds.filter((option: any) =>
        option.batch_id.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.slotIdSearch.valueChanges.subscribe((val) => {
      this.filteredSlotIds = this.slotIds.filter((option: any) =>
        option.slot_id.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.prodDetectorIdSearch.valueChanges.subscribe((val) => {
      this.filteredProdDetectorIds = this.productionDetectorIds.filter(
        (option: any) =>
          option.production_detector_id
            .toLowerCase()
            .includes(val?.toLowerCase())
      );
    });
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getDetectors();
  }

  singleSelection(dropDownName: string) {
    if (dropDownName === 'detectorType') {
      if (this.allDetectorTypeCheckbox.selected) {
        this.allDetectorTypeCheckbox.deselect();
      }

      if (this.selectedDetectorTypes.length === this.detectorTypes.length) {
        this.allDetectorTypeCheckbox.select();
        this.selectedDetectorTypes = [
          ...this.detectorTypes.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'slotId') {
      if (this.allSlotIdCheckbox.selected) {
        this.allSlotIdCheckbox.deselect();
      }

      if (this.selectedSlotIds.length === this.slotIds.length) {
        this.allSlotIdCheckbox.select();
        this.selectedSlotIds = [
          ...this.batchIds.map((item: any) => item.slot_id),
          '-1',
        ];
      }
    } else if (dropDownName === 'batchId') {
      if (this.allBatchIdCheckbox.selected) {
        this.allBatchIdCheckbox.deselect();
      }

      if (this.selectedBatchIds.length === this.batchIds.length) {
        this.allBatchIdCheckbox.select();
        this.selectedBatchIds = [
          ...this.batchIds.map((item: any) => item.batch_id),
          '-1',
        ];
      }
    } else if (dropDownName === 'prodDetectorId') {
      if (this.allProdDetectorIdCheckbox.selected) {
        this.allProdDetectorIdCheckbox.deselect();
      }

      if (
        this.selectedProdDetectorIds.length ===
        this.productionDetectorIds.length
      ) {
        this.allProdDetectorIdCheckbox.select();
        this.selectedProdDetectorIds = [
          ...this.productionDetectorIds.map(
            (item: any) => item.production_detector_id
          ),
          '-1',
        ];
      }
    }
    this.getDetectors();
  }

  allSelection(dropDownName: string) {
    if (dropDownName === 'detectorType') {
      if (this.allDetectorTypeCheckbox.selected) {
        this.selectedDetectorTypes = [
          ...this.detectorTypes.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.selectedDetectorTypes = [];
      }
    } else if (dropDownName === 'slotId') {
      if (this.allSlotIdCheckbox.selected) {
        this.selectedSlotIds = [
          ...this.slotIds.map((item: any) => item.slot_id),
          '-1',
        ];
      } else {
        this.selectedSlotIds = [];
      }
    } else if (dropDownName === 'batchId') {
      if (this.allBatchIdCheckbox.selected) {
        this.selectedBatchIds = [
          ...this.batchIds.map((item: any) => item.batch_id),
          '-1',
        ];
      } else {
        this.selectedBatchIds = [];
      }
    } else if (dropDownName === 'prodDetectorId') {
      if (this.allProdDetectorIdCheckbox.selected) {
        this.selectedProdDetectorIds = [
          ...this.productionDetectorIds.map(
            (item: any) => item.production_detector_id
          ),
          '-1',
        ];
      } else {
        this.selectedProdDetectorIds = [];
      }
    }
    this.getDetectors();
  }

  async getDetectorTypes() {
    try {
      let detectorsURL = `page=${1}&perPage=${1000}`;
      const res = await this.deviceService.getDetectorTypes(detectorsURL);
      if (res) {
        this.detectorTypes = res.data.detectorTypes;
        this.filteredDetectorTypes = this.detectorTypes;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getDetectors();
    }
  }

  async getDetectorFilters() {
    try {
      const res = await this.deviceService.getDetectorFilters();
      if (res) {
        this.detectorTypes = res.data.detectorTypes;
        this.filteredDetectorTypes = [...this.detectorTypes];
        this.slotIds = res.data.slotIds;
        this.filteredSlotIds = [...this.slotIds];
        this.batchIds = res.data.batchIds;
        this.filteredBatchIds = [...this.batchIds];
        this.productionDetectorIds = res.data.productionDetectorIds;
        this.filteredProdDetectorIds = [...this.productionDetectorIds];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getDetectors(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      let detectorsURL = '';
      // SEARCH
      if (this.searchedText) {
        detectorsURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      // FILTERS
      if (this.selectedDetectorTypes.length) {
        detectorsURL += `&detectorTypes=[${this.selectedDetectorTypes}]`;
        this.setDefaultPage(event);
      }
      if (this.selectedBatchIds.length) {
        detectorsURL += `&batchIds=${JSON.stringify(this.selectedBatchIds)}`;
        this.setDefaultPage(event);
      }
      if (this.selectedSlotIds.length) {
        detectorsURL += `&slotIds=${JSON.stringify(this.selectedSlotIds)}`;
        this.setDefaultPage(event);
      }
      if (this.selectedProdDetectorIds.length) {
        detectorsURL += `&productionDetectorIds=${JSON.stringify(
          this.selectedProdDetectorIds
        )}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        detectorsURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      // ORDER
      if (this.orderOn && this.orderBy) {
        detectorsURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }
      detectorsURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      this.isProcessing = true;
      const res = await this.deviceService.getDetectors(detectorsURL);
      if (res) {
        this.data = res.data.detectors;
        this.dataSource = res.data.detectors;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.detectorSearch && this.detectorSearch.nativeElement) {
        this.detectorSearch.nativeElement.focus();
      }
    });
  }

  isFilterApplied() {
    return !(
      this.selectedDetectorTypes.length ||
      this.selectedBatchIds.length ||
      this.selectedProdDetectorIds.length ||
      this.selectedSlotIds.length ||
      this.toDate
    );
  }

  clearFilter() {
    this.selectedDetectorTypes = [];
    this.selectedBatchIds = [];
    this.selectedSlotIds = [];
    this.selectedProdDetectorIds = [];
    this.toDate = '';
    this.fromDate = '';
    this.getDetectors();
  }

  clearSearch() {
    this.searchedText = '';
    this.showHideSearch();
    this.getDetectors();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDetectorComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            this.getDetectors();
          }
        }
      }
    });
  }

  addRowData(row_obj: any): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    row_obj.detector_type_name = row_obj.detector_type.name;
    this.dataSource = [row_obj, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: any): boolean | void {
    this.dataSource = this.dataSource.filter((value: any) => {
      if (value.id === row_obj.id) {
        value.detector_type_name = row_obj.detector_type_name;
        value.batch_id = row_obj.batch_id;
        value.slot_id = row_obj.slot_id;
        value.production_detector_id = row_obj.production_detector_id;
        value.updatedAt = row_obj.updatedAt;
      }
      return true;
    });
    this.table.renderRows();
  }
}
