import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { AddDeviceTypeComponent } from '../add-device-type/add-device-type.component';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import * as moment from 'moment';

@Component({
  selector: 'app-device-types',
  templateUrl: './device-types.component.html',
  styleUrls: ['./device-types.component.scss'],
})
export class DeviceTypesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'version',
    'createdAt',
    'updatedAt',
  ];

  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  public dataSource: any = [];
  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public fromDate: any = '';
  public toDate: any = '';
  public futureDate = new Date();

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = [];

  // Tooltip
  public tooltip = {
    view_device_type: 'View Device Type',
    update_device_type: 'Update Device Type',
    delete_device_type: 'Delete Device Type',
  };

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild('deviceTypeSearch') deviceTypeSearch: ElementRef | any;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.getDeviceTypes();
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getDeviceTypes();
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getDeviceTypes();
    }
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getDeviceTypes(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      let deviceTypeURL = '';
      // SEARCH
      if (this.searchedText) {
        deviceTypeURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      // DATE FILTER
      if (this.fromDate && this.toDate) {
        deviceTypeURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      // ORDER
      if (this.orderOn && this.orderBy) {
        deviceTypeURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }
      deviceTypeURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      this.isProcessing = true;
      const res = await this.deviceService.getDeviceTypes(deviceTypeURL);
      if (res) {
        this.data = res.data.deviceTypes;
        this.dataSource = res.data.deviceTypes;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.deviceTypeSearch && this.deviceTypeSearch.nativeElement) {
        this.deviceTypeSearch.nativeElement.focus();
      }
    });
  }

  searchWithTerm() {
    if (this.searchedText) {
      this.getDeviceTypes();
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.showHideSearch();
    this.getDeviceTypes();
  }

  clearFilter() {
    this.fromDate = '';
    this.toDate = '';
    this.getDeviceTypes();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDeviceTypeComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          this.getDeviceTypes();
        }
      }
    });
  }

  addRowData(row_obj: any): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [row_obj, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: any): boolean | void {
    this.dataSource = this.dataSource.filter((value: any) => {
      if (value.id === row_obj.id) {
        value.name = row_obj.name;
        value.version = row_obj.version;
        value.updatedAt = row_obj.updatedAt;
      }
      return true;
    });
    this.table.renderRows();
  }
}
