import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { Firmware } from 'src/app/shared/models/firmware';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { FirmwareService } from 'src/app/shared/services/firmware/firmware.service';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FirmwareDialogComponent } from '../firmware-dialog/firmware-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-firmwares',
  templateUrl: './firmwares.component.html',
  styleUrls: ['./firmwares.component.scss'],
})
export class FirmwaresComponent implements OnInit {
  public data = {};
  public isProcessing = false;
  public pageIndex: number = 0;
  public pageSize: number = 15;
  displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'version',
    'createdAt',
  ];
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public searchText: any;
  public selectedDeviceTypes: any = [];
  public deviceTypes: any = [];
  public toDate: any = '';
  public fromDate: any = '';
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('firmwareSearch') firmwareSearch: ElementRef | any;
  public deviceTypeDropDownSearch: FormControl = new FormControl();
  public filteredDeviceTypes: any = [];
  // Order
  public orderOn = 'id';
  public orderBy = 'desc';
  public futureDate = new Date();

  // Tooltip
  public tooltip = {
    view_firmware: 'View Firmware',
    update_firmware: 'Update Firmware',
    delete_firmware: 'Delete Firmware',
  };

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = ['name'];
  public searchOn = [];
  public length: number = 0;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private firmwareService: FirmwareService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.setDefaultDates();
    this.getFirmwares();
    this.getDeviceTypes();
    this.deviceTypeDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredDeviceTypes = this.deviceTypes.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getFirmwares();
    }
  }

  async getDeviceTypes() {
    try {
      this.isProcessing = true;
      const res = await this.deviceService.getDeviceTypes('page=0&perPage=0');
      this.isProcessing = false;
      if (!!res && res.data.deviceTypes) {
        this.deviceTypes = res.data.deviceTypes;
        this.filteredDeviceTypes = [...this.deviceTypes];
      }
    } catch (err: any) {
      this.isProcessing = false;
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getFirmwares();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getFirmwares(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let mTypeURL = '';

      // SEARCH
      if (this.searchedText) {
        mTypeURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      if (this.searchOn.length) {
        mTypeURL += `&searchOn=${JSON.stringify(this.searchOn)}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        mTypeURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS

      if (this.selectedDeviceTypes.length > 0) {
        mTypeURL += `&deviceType=${this.selectedDeviceTypes}`;
        this.setDefaultPage(event);
      }

      if (this.fromDate && this.toDate) {
        mTypeURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      mTypeURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.firmwareService.getFirmwares(mTypeURL);
      if (res) {
        this.data = res.data.firmwares;
        this.dataSource = res.data.firmwares;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getFirmwares();
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.firmwareSearch && this.firmwareSearch.nativeElement) {
        this.firmwareSearch.nativeElement.focus();
      }
    });
  }

  isFilterApplied() {
    return !(this.selectedDeviceTypes.length > 0 || this.toDate);
  }

  clearFilter() {
    this.selectedDeviceTypes = [];
    this.fromDate = '';
    this.toDate = '';
    this.getFirmwares();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(FirmwareDialogComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            this.deleteRowData(result.data);
          }
        }
      }
    });
  }

  addRowData(row_obj: Firmware): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [row_obj, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: Firmware): boolean | void {
    const index = this.dataSource.findIndex((d: any) => d.id === row_obj.id);
    if (index != -1) {
      this.dataSource[index] = row_obj;
    }
    this.table.renderRows();
  }

  deleteRowData(row_obj: Firmware) {
    this.getFirmwares();
  }
}
