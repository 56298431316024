import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AppVersionUpdatesComponent } from '../app-version-updates/app-version-updates.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/customValidator';
import { CommonConstants } from 'src/app/shared/common-constants';
import { AppVersionUpdateService } from 'src/app/shared/services/app-version-update/app-version-update.service';

@Component({
  selector: 'app-add-app-version-update',
  templateUrl: './add-app-version-update.component.html',
  styleUrls: ['./add-app-version-update.component.scss'],
})
export class AddAppVersionUpdateComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public selectedImage: any = '';
  public joiningDate: any = '';
  public addFaqForm: any;
  public categories: any = [];
  public isProcessing: boolean = false;
  public selectedisForceUpdate: boolean = false;
  public categorySearch: FormControl = new FormControl();
  public filteredCategories: any = [];
  public isAllow: boolean = false;
  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private appVersionUpdateService: AppVersionUpdateService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AppVersionUpdatesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddContentForm();
    this.setPatchValues();
    this.categorySearch.valueChanges.subscribe((val) => {
      this.filteredCategories = this.categories.filter((option: any) =>
        option.category.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  // convenience getter for easy access to form fields
  get addFaqControls() {
    return this.addFaqForm.controls;
  }

  resetAddContentForm() {
    this.addFaqForm = this.fb.group({
      version: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          CustomValidators.isValidVersion,
        ]),
      ],
      release_notes: new FormControl(
        this.local_data.release_notes,
        Validators.maxLength(CommonConstants.maxLength5000)
      ),
      // release_notes: [
      //   null,
      //   Validators.compose([
      //     Validators.required,
      //     CustomValidators.noWhitespaceValidator,
      //     Validators.maxLength(CommonConstants.maxLength5000),
      //   ]),
      // ],
      platform_type: [null, Validators.compose([Validators.required])],
      is_force_update: [null, Validators.compose([Validators.required])],
    });
  }

  setPatchValues() {
    if (this.action === 'Update') {
      this.addFaqForm.patchValue({
        version: this.local_data.version,
        release_notes: this.local_data.release_notes,
        platform_type: this.local_data.faq_category.id,
      });
    }
  }

  async doAction() {
    await this.submitFaqForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitFaqForm() {
    if (this.addFaqForm.valid) {
      try {
        this.isProcessing = true;
        const reqData: any = {
          version: this.local_data.version,
          platform_type: this.local_data.platform_type,
          is_force_update: this.local_data.is_force_update === 'true',
        };
        if (
          this.local_data.release_notes &&
          this.local_data.release_notes.length > 0
        ) {
          reqData.release_notes = this.local_data.release_notes;
        }
        const res: any = await this.appVersionUpdateService.addAppVersionUpdate(
          reqData
        );
        this.local_data = res.data;
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }
}
