<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Sensor Board</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content style="max-height: inherit;" class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <div *ngIf="action != 'View'">
    <form [formGroup]="addDeviceForm">
      <div fxLayout="row wrap" class="align-items-center">
        <div fxFlex="100" fxFlex.gt-md="100">
          <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
            <div fxFlex="50" class="m-l-15">
              <mat-form-field>
                <input type="text" matInput required id="detector_board_id" [(ngModel)]="local_data.detector_board_id"
                  placeholder="Sensor Board Id" formControlName="detector_board_id">
                <mat-error
                  *ngIf="!addDeviceControls.detector_board_id.value && addDeviceForm.get('detector_board_id').touched"
                  class="text-danger">Sensor Board Id is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <mat-label>Detector Positions</mat-label>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label>X1</mat-label>
              <input [readonly]="action ==='View'" #X1 class="detector-info" (keyup)="validateDetector($event, 'X1')"
                type="text" matInput required id="X1" [(ngModel)]="detectorPositions[0].detector_info" placeholder="X1"
                formControlName="X1">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X1').invalid && !positionErrors['X1'].error && detectorPositions[0].detector_info && detectorPositions[0].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[0].isExist" (selectionChange)="change($event, 'X1')"
                    placeholder="select detector type" formControlName="X1_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[0].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <mat-error *ngIf="!addDeviceControls.X1.value && addDeviceForm.get('X1').touched" class="text-danger">
                Detector for position X1 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X1').invalid && !positionErrors['X1'].error && !addDeviceControls.X1.errors.required && addDeviceForm.get('X1').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error *ngIf="addDeviceForm.get('X1').invalid && positionErrors['X1'].error" class="text-danger">
                {{ positionErrors.X1.error }}</mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label>X2</mat-label>
              <input [readonly]="action ==='View'" #X2 class="detector-info" (keyup)="validateDetector($event, 'X2')"
                type="text" matInput required id="X2" [(ngModel)]="detectorPositions[1].detector_info" placeholder="X2"
                formControlName="X2">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X2').invalid && !positionErrors['X2'].error && detectorPositions[1].detector_info && detectorPositions[1].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[1].isExist" (selectionChange)="change($event, 'X2')"
                    placeholder="select detector type" formControlName="X2_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[1].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <mat-error *ngIf="!addDeviceControls.X2.value && addDeviceForm.get('X2').touched" class="text-danger">
                Detector for position X2 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X2').invalid && !positionErrors['X2'].error && !addDeviceControls.X2.errors.required && addDeviceForm.get('X2').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error *ngIf="addDeviceForm.get('X2').invalid && positionErrors['X2'].error" class="text-danger">
                {{ positionErrors.X2.error }}</mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label>X3</mat-label>
              <input [readonly]="action ==='View'" #X3 class="detector-info" (keyup)="validateDetector($event, 'X3')"
                type="text" matInput required id="X3" [(ngModel)]="detectorPositions[2].detector_info" placeholder="X3"
                formControlName="X3">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X3').invalid && !positionErrors['X3'].error && detectorPositions[2].detector_info && detectorPositions[2].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[2].isExist" (selectionChange)="change($event, 'X3')"
                    placeholder="select detector type" formControlName="X3_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[2].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <mat-error *ngIf="!addDeviceControls.X3.value && addDeviceForm.get('X3').touched" class="text-danger">
                Detector for position X3 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X3').invalid && !positionErrors['X3'].error && !addDeviceControls.X3.errors.required && addDeviceForm.get('X3').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error *ngIf="addDeviceForm.get('X3').invalid && positionErrors['X3'].error" class="text-danger">
                {{ positionErrors.X3.error }}</mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15 m-b-20">
            <mat-form-field>
              <mat-label>X4</mat-label>
              <input [readonly]="action ==='View'" #X4 class="detector-info" (keyup)="validateDetector($event, 'X4')"
                type="text" matInput required id="X4" [(ngModel)]="detectorPositions[3].detector_info" placeholder="X4"
                formControlName="X4">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X4').invalid && !positionErrors['X4'].error && detectorPositions[3].detector_info && detectorPositions[3].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[3].isExist" (selectionChange)="change($event, 'X4')"
                    placeholder="select detector type" formControlName="X4_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[3].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <mat-error *ngIf="!addDeviceControls.X4.value && addDeviceForm.get('X4').touched" class="text-danger">
                Detector for position X4 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X4').invalid && !positionErrors['X4'].error && !addDeviceControls.X4.errors.required && addDeviceForm.get('X4').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error *ngIf="addDeviceForm.get('X4').invalid && positionErrors['X4'].error" class="text-danger">
                {{ positionErrors.X4.error }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <button *ngIf="action != 'View'" mat-button (click)="doAction()" mat-flat-button color="primary"
        [disabled]="isProcessing || !addDeviceForm.valid || !isAllDetectorSelected()">{{action}}</button>
      <button *ngIf="action != 'View'" mat-button mat-flat-button color="primary" class="m-l-10"
        (click)="resetPositions()" [disabled]="isPositionPlaced()">Reset Position</button>
      <button mat-button class="m-l-10" (click)="closeDialog()"> {{action != 'View' ? 'Cancel' : 'Close'}}</button>
    </form>
  </div>
  <div *ngIf="action === 'View'">
    <div class="">
      <table mat-table [dataSource]="deviceBlock" class="table employee-list td-ht-sm no-wrap">

        <ng-container matColumnDef="key">
          <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
            {{ element.key }}
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <td class="might-overflow-link"
            [matTooltip]="element.showTooltip ? (element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value) : null"
            mat-cell *matCellDef="let element">
            <div *ngIf="element.isDate">
              {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
            </div>
            <div *ngIf="element.isTextArea">
              <div *ngIf="element.value.length > 15">
                <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40" [(ngModel)]="element.value"
                  #description matInput class="scrollbar" placeholder="Description"></textarea>
              </div>
              <div *ngIf="element.value.length < 16">
                {{ element.value }}
              </div>
            </div>
            <div
              *ngIf="!element.isDate && !element.isLink && !element.isTextArea && !element.isNested && !element.isObjectValue">
              {{ element.value }}
            </div>
            <div *ngIf="element.isObjectValue" class="detector-info td-lg">
              {{ element.value.detectorInfo }}
              <div class="last-text">
                <span>Type: {{ element.value.detectorType }}</span>
              </div>


              <div class="detector-disp ">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
            </div>
            <div *ngIf="element.isLink">
              <a href="{{element.value}}">{{element.value}}</a>
            </div>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: deviceBlockColumns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.detector_board_id}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>