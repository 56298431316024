import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeviceTypesComponent } from './device-types/device-types.component';

const routes: Routes = [
  {
    path: '',
    component: DeviceTypesComponent,
    data: {
      title: 'Device Type',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Device Type' }]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeviceTypeRoutingModule {

}
