import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MeasurementSetupRoutingModule } from './measurement-setup-routing.module';
import { MeasurementSetupsComponent } from './measurement-setups/measurement-setups.component';
import { DemoMaterialModule } from '../demo-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsModule } from 'ng2-charts';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MeasurementSetupDialogComponent } from './measurement-setup-dialog/measurement-setup-dialog.component';


@NgModule({
  declarations: [MeasurementSetupsComponent, MeasurementSetupDialogComponent],
  imports: [
    CommonModule,
    MeasurementSetupRoutingModule,    
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule,
    MatPaginatorModule,
  ]
})
export class MeasurementSetupModule { }
