<div fxLayout="row wrap">
    <div fxFlex="100">
        <mat-card>
            <mat-card-content>
                <div class="table-responsive">
                    <div class="filter-section">

                        <div (click)="picker.open()" class="only-date-filter">
                            <mat-form-field class="example-full-width">
                                <mat-label>Select a date range</mat-label>
                                <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                                        (dateChange)="onDateRangeChange()">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>

                        <div class="d-flex">
                            <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                                (click)="clearFilter()">
                                Clear Filters
                            </button>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div fxLayout="row wrap">
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/user']" mat-fab mat-card-icon
                                                class="shadow-none bg-inverse  bg-success">
                                                <mat-icon>group</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.userCount }}</h3>
                                            <h6 class="text-muted m-0">Users</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/measurements']" mat-fab color="warn" mat-card-icon
                                                class="shadow-none  bg-warning">
                                                <mat-icon>stacked_bar_chart</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.measurementCount }}</h3>
                                            <h6 class="text-muted m-0">Measurements</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/measurement/smell']" mat-fab color="accent"
                                                mat-card-icon class="shadow-none">
                                                <mat-icon>air</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.smellCount }}</h3>
                                            <h6 class="text-muted m-0">Substances</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/measurement/usecase']" mat-fab color="warn"
                                                mat-card-icon class="shadow-none bg-danger">
                                                <mat-icon>developer_board</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.usecaseCount }}</h3>
                                            <h6 class="text-muted m-0">Use Cases</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <br>
                    <div fxLayout="row wrap">
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/measurement/measurement-setup']" mat-fab mat-card-icon
                                                class="shadow-none bg-inverse  bg-success">
                                                <mat-icon>add_chart</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.measurementSetupCount }}</h3>
                                            <h6 class="text-muted m-0">Measurement Setups</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/firmware']" mat-fab color="warn" mat-card-icon
                                                class="shadow-none  bg-warning">
                                                <mat-icon>memory</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.firmwareCount }}</h3>
                                            <h6 class="text-muted m-0">Firmwares</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/classifier']" mat-fab color="accent" mat-card-icon
                                                class="shadow-none">
                                                <mat-icon>psychology</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.MLModelCount }}</h3>
                                            <h6 class="text-muted m-0">ML Models</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <div class="d-flex no-block">
                                        <div class="m-r-10 no-shrink">
                                            <button
                                                [queryParams]="{fromDate: getDate('fromDate'), toDate: getDate('toDate')}"
                                                [routerLink]="['/issue/list']" mat-fab color="warn" mat-card-icon
                                                class="shadow-none bg-danger">
                                                <mat-icon>report_problem</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <h3 class="m-0">{{ data.issueCount }}</h3>
                                            <h6 class="text-muted m-0">Issues</h6>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>

                    <br>
                    <div fxLayout="row wrap">
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <mat-card-title>Users</mat-card-title>
                                    <div id="newsletter-chart" class="d-flex flex-column">
                                        <apx-chart [series]="userOptions.series" [chart]="userOptions.chart"
                                            [xaxis]="userOptions.xaxis" [yaxis]="userOptions.yaxis"
                                            [stroke]="userOptions.stroke" [tooltip]="userOptions.tooltip"
                                            [dataLabels]="userOptions.dataLabels" [legend]="userOptions.legend"
                                            [colors]="userOptions.colors" [markers]="userOptions.markers">
                                        </apx-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <mat-card-title>Measurements</mat-card-title>
                                    <div id="newsletter-chart" class="d-flex flex-column">
                                        <apx-chart [series]="measurementsOptions.series"
                                            [chart]="measurementsOptions.chart" [xaxis]="measurementsOptions.xaxis"
                                            [yaxis]="measurementsOptions.yaxis" [stroke]="measurementsOptions.stroke"
                                            [tooltip]="measurementsOptions.tooltip"
                                            [dataLabels]="measurementsOptions.dataLabels"
                                            [legend]="measurementsOptions.legend" [colors]="measurementsOptions.colors"
                                            [markers]="measurementsOptions.markers">
                                        </apx-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <mat-card-title>Substances</mat-card-title>
                                    <div id="newsletter-chart" class="d-flex flex-column">
                                        <apx-chart [series]="smellsOptions.series" [chart]="smellsOptions.chart"
                                            [xaxis]="smellsOptions.xaxis" [yaxis]="smellsOptions.yaxis"
                                            [stroke]="smellsOptions.stroke" [tooltip]="smellsOptions.tooltip"
                                            [dataLabels]="smellsOptions.dataLabels" [legend]="smellsOptions.legend"
                                            [colors]="smellsOptions.colors" [markers]="smellsOptions.markers">
                                        </apx-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <mat-card-title>Use Cases</mat-card-title>
                                    <div id="newsletter-chart" class="d-flex flex-column">
                                        <apx-chart [series]="usecasesOptions.series" [chart]="usecasesOptions.chart"
                                            [xaxis]="usecasesOptions.xaxis" [yaxis]="usecasesOptions.yaxis"
                                            [stroke]="usecasesOptions.stroke" [tooltip]="usecasesOptions.tooltip"
                                            [dataLabels]="usecasesOptions.dataLabels" [legend]="usecasesOptions.legend"
                                            [colors]="usecasesOptions.colors" [markers]="usecasesOptions.markers">
                                        </apx-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <mat-card-title>Measurement Setups</mat-card-title>
                                    <div id="newsletter-chart" class="d-flex flex-column">
                                        <apx-chart [series]="measurementSetupsOptions.series"
                                            [chart]="measurementSetupsOptions.chart"
                                            [xaxis]="measurementSetupsOptions.xaxis"
                                            [yaxis]="measurementSetupsOptions.yaxis"
                                            [stroke]="measurementSetupsOptions.stroke"
                                            [tooltip]="measurementSetupsOptions.tooltip"
                                            [dataLabels]="measurementSetupsOptions.dataLabels"
                                            [legend]="measurementSetupsOptions.legend"
                                            [colors]="measurementSetupsOptions.colors"
                                            [markers]="measurementSetupsOptions.markers">
                                        </apx-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxFlex.gt-sm="50" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-card>
                                <mat-card-content>
                                    <mat-card-title>Issues</mat-card-title>
                                    <div id="newsletter-chart" class="d-flex flex-column">
                                        <apx-chart [series]="issuesOptions.series" [chart]="issuesOptions.chart"
                                            [xaxis]="issuesOptions.xaxis" [yaxis]="issuesOptions.yaxis"
                                            [stroke]="issuesOptions.stroke" [tooltip]="issuesOptions.tooltip"
                                            [dataLabels]="issuesOptions.dataLabels" [legend]="issuesOptions.legend"
                                            [colors]="issuesOptions.colors" [markers]="issuesOptions.markers">
                                        </apx-chart>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <br />

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>
<div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
</div>