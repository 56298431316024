import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { IssueService } from 'src/app/shared/services/issue/issue.service';
import { IssuesComponent } from '../issues/issues.component';

@Component({
  selector: 'app-delete-issue',
  templateUrl: './delete-issue.component.html',
  styleUrls: ['./delete-issue.component.scss'],
})
export class DeleteIssueComponent implements OnInit {
  public action: string = 'Delete';
  public local_data: any = {};
  public isProcessing: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private issueService: IssueService,
    @Inject(MAT_DIALOG_DATA) public data: IssuesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Delete';
  }

  ngOnInit(): void {}

  async doAction() {
    await this.submitIssueForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitIssueForm() {
    try {
      this.isProcessing = true;
      const res = await this.issueService.removeIssue(this.local_data.id);
      if (res) {
        this.helperService.showAlert(res.msg || 'Success', 'success');
      }
      this.dialogRef.close({
        event: this.action,
        data: this.local_data,
        success: true,
      });
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
      this.dialogRef.close({
        event: this.action,
        data: this.local_data,
        success: false,
      });
    } finally {
      this.isProcessing = false;
    }
  }
}
