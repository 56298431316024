<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Issue Type</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <form [formGroup]="addIssueTypeForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">
        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-30">
            <input #name type="text" matInput required id="name" name="name" [(ngModel)]="local_data.name"
              placeholder="Issue Type Name" formControlName="name">
            <mat-error *ngIf="!addIssyeTypeControls.name.value && addIssueTypeForm.get('name').touched"
              class="text-danger">Issue Type Name is required</mat-error>
            <mat-error *ngIf="addIssyeTypeControls.name.value && addIssueTypeForm.get('name').hasError('maxlength')"
              class="text-danger">Maximum 60 characters are allowed</mat-error>
            <mat-error
              *ngIf="addIssyeTypeControls.name.value && addIssueTypeForm.get('name').touched && addIssueTypeForm.get('name').hasError('whitespace')">
              Please enter valid Issue Type Name
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !addIssueTypeForm.valid ">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p class="might-overflow-popup" [matTooltip]="local_data.name">Sure to delete <b>{{local_data.name}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>