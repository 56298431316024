import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MeasurementsComponent } from './measurements/measurements.component';
import { MeasurementDetailModule } from './measurement-detail/measurement-detail.module';

const routes: Routes = [
  {
    path: '',
    component: MeasurementsComponent,
    data: {
      title: 'Measurements',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Measurements' }],
    },
  },
  {
    path: 'detail/:measurementId',
    loadChildren: () => MeasurementDetailModule,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeasurementRoutingModule {}
