<h2 class="font-medium" mat-dialog-title><strong>{{action}} FAQ</strong></h2>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <form [formGroup]="addFaqForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">

        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-30">
            <input #title type="text" matInput required id="title" name="title" [(ngModel)]="local_data.title"
              placeholder="Title" formControlName="title">
            <mat-error *ngIf="!addFaqControls.title.value && addFaqForm.get('title').touched" class="text-danger">Title
              is required</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-30">
            <textarea rows="4" cols="40" [(ngModel)]="local_data.description" #description matInput
              placeholder="Description" formControlName="description"></textarea>
            <mat-error *ngIf="!addFaqControls.description.value && addFaqForm.get('description').touched"
              class="text-danger">Description is required</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-30">
            <mat-label>Category</mat-label>
            <mat-select placeholder="Category" formControlName="category_id">
              <mat-option *ngFor="let category of categories" [value]="category.id">
                {{ category.category | titlecase }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!addFaqControls.category_id.value && addFaqForm.get('category_id').touched"
              class="text-danger">Category is required</mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="!addFaqForm.valid">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.title}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>
