<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
  <div class="login-register-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="loginForm">
          <div class="text-center">
            <img alt="homepage" class="login-logo" src="assets/images/snt_dark.svg">
            <h4 class="m-t-0">Login to App</h4>
          </div>

          <div fxLayout="row wrap">
            <!-- Email-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field class="m-b-15">
                <input required matInput placeholder="Email" formControlName="email" type="email" email="true">
                <mat-error *ngIf="!loginControls.email.value && loginForm.get('email').touched" class="text-danger">
                  Email address is required</mat-error>
                <mat-error
                  *ngIf="loginForm.get('email').invalid && !loginControls.email.errors.required && loginForm.get('email').touched"
                  class="text-danger">Email address is invalid</mat-error>
              </mat-form-field>
            </div>

            <!-- Passsword-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field class="m-b-15">
                <input required matInput [type]="showPassword ? 'text' : 'password'" placeholder="Password"
                  formControlName="password">
                <mat-error *ngIf="!loginControls.password.value && loginForm.get('password').touched"
                  class="text-danger">Password is required</mat-error>
              </mat-form-field>
              <mat-error *ngIf="loginControls.password.value && loginForm.get('password').hasError('maxlength')"
                class="text-danger">
                Maximum 60 characters are allowed
              </mat-error>
            </div>

            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-spinner *ngIf="isProcessing" class="m-auto" [diameter]="30"></mat-spinner>
              <button (click)="submitLoginForm(loginForm.value)" mat-raised-button color="primary"
                class="btn-block btn-lg m-t-20 m-b-20" type="submit"
                [disabled]="!loginForm.valid || isProcessing">Login</button>
            </div>

            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="text-center">
              <a [routerLink]="['/authentication/forgot']" class="link">Forgot pwd?</a>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
