import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { CommonConstants } from 'src/app/shared/common-constants';
import { Smell } from 'src/app/shared/models/smell';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { SmellService } from 'src/app/shared/services/smell/smell.service';
import { SmellsComponent } from '../smells/smells.component';

@Component({
  selector: 'app-smell-dialog',
  templateUrl: './smell-dialog.component.html',
  styleUrls: ['./smell-dialog.component.scss'],
})
export class SmellDialogComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public smellForm: FormGroup;
  public categories: any = [];
  public baseSmells: Smell[] = [];
  public smellBlock: any = [];
  public smellBlockColumns: any = ['key', 'value'];
  public isProcessing: boolean = false;
  public baseSmellSearch: FormControl = new FormControl();
  filteredBaseSmells: any;

  public categorySearch: FormControl = new FormControl();
  public filteredCategories: any = [];

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private smellService: SmellService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: SmellsComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';

    if (!!this.local_data) {
      this.smellForm = new FormGroup({
        name: new FormControl(this.local_data.name, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        baseSmell: new FormControl(),
        additionalInformation: new FormControl(
          this.local_data.additional_information,
          Validators.maxLength(CommonConstants.maxLength5000)
        ),
        odorName: new FormControl(
          this.local_data.odor_name,
          Validators.maxLength(CommonConstants.maxLength100)
        ),
        tasteName: new FormControl(
          this.local_data.taste_name,
          Validators.maxLength(CommonConstants.maxLength100)
        ),
        category: new FormControl(this.local_data.category),
        parentSubstanceId: new FormControl(this.local_data.parent_substance_id),
      });
    } else {
      this.smellForm = new FormGroup({
        name: new FormControl(null, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        baseSmell: new FormControl(null),
        additionalInformation: new FormControl(
          null,
          Validators.maxLength(CommonConstants.maxLength5000)
        ),
        odorName: new FormControl(
          null,
          Validators.maxLength(CommonConstants.maxLength100)
        ),
        tasteName: new FormControl(
          null,
          Validators.maxLength(CommonConstants.maxLength100)
        ),
        category: new FormControl(null),
        parentSubstanceId: new FormControl(null),
      });
    }

    this.categories = [
      { id: 'Animalic', name: 'Animalic' },
      { id: 'Chemical', name: 'Chemical' },
      { id: 'Fatty', name: 'Fatty' },
      { id: 'Food and Beverage', name: 'Food and Beverage' },
      { id: 'Fruity', name: 'Fruity' },
      { id: 'Spicy', name: 'Spicy' },
      { id: 'Vegetable', name: 'Vegetable' },
    ];
  }
  ngOnInit() {
    if (this.action === 'View') {
      this.smellForm.disable();
      this.getSubstanceInfo();
    } else {
      this.getBaseSmells();
    }
    this.baseSmellSearch?.valueChanges.subscribe((val) => {
      this.filteredBaseSmells = this.baseSmells.filter((bs) =>
        bs.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.filteredCategories = this.categories;
    this.categorySearch.valueChanges.subscribe((val) => {
      this.filteredCategories = this.categories.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  private async getSubstanceInfo() {
    try {
      this.isProcessing = true;
      const res = await this.smellService.getSmellInfo(this.local_data.id);
      if (res) {
        this.local_data = res.data.substanceInfo;
        this.smellBlock = [
          {
            isDate: false,
            isLink: false,
            key: 'ID',
            value: this.local_data.id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Smell / Substance Name',
            value: this.local_data.name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Category',
            value: this.local_data.category || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Base Smell / Substance Name',
            value: this.local_data.parent_substance_name || '-',
          },
          {
            isDate: false,
            isLink: false,
            isTextArea: true,
            key: 'Odor Description',
            value: this.local_data.odor_name || '-',
          },
          {
            isDate: false,
            isLink: false,
            isTextArea: true,
            key: 'Taste Description',
            value: this.local_data.taste_name || '-',
          },
          {
            isDate: false,
            isLink: false,
            isTextArea: true,
            key: 'Additional Information',
            value: this.local_data.additional_information || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Public / Private',
            value: this.local_data.is_public ? 'Public' : 'Private',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Created By',
            value: this.local_data.user_name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Email',
            value: this.local_data.user_email,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: this.local_data.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: this.local_data.updatedAt,
          },
        ];
      } else {
        this.closeDialog();
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
      this.closeDialog();
    } finally {
      this.isProcessing = false;
    }
  }

  clearDropDown(dropdownName: string) {
    if (dropdownName === 'baseSmell') {
      this.smellForm.controls.baseSmell.patchValue(null);
    } else if (dropdownName === 'category') {
      this.smellForm.controls.category.patchValue(null);
    }
  }

  async getBaseSmells() {
    try {
      this.isProcessing = true;
      let baseSmellURL = `page=0&perPage=0&baseSubstance=true&isPublic=true`;
      if (!this.local_data.is_public && this.action === 'View') {
        baseSmellURL = `page=0&perPage=0&baseSubstance=true&isPublic=false`;
      }
      const res = await this.smellService.getBaseSmells(baseSmellURL);
      if (res) {
        this.isProcessing = false;
        this.baseSmells = res.data.substances;
        // Remove current substance id from the list of base substances
        const isPresent = this.baseSmells.findIndex((f) => {
          return f.id === this.local_data.id;
        });
        if (isPresent > -1) {
          this.baseSmells.splice(isPresent, 1);
        }
        if (!!this.local_data) {
          this.smellForm.controls.baseSmell.patchValue(
            this.baseSmells.find(
              (bs) => bs.id === this.local_data.parent_substance_id
            )
          );
        }
        this.filteredBaseSmells = this.baseSmells;
      }
    } catch (err: any) {
      this.isProcessing = false;
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  async doAction() {
    await this.submitSmellForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitSmellForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const res = await this.smellService.removeSmell(this.local_data.id);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else {
      if (this.smellForm.valid) {
        try {
          this.isProcessing = true;
          const smellInfo = {} as Smell;
          smellInfo.name = this.smellForm.value.name;
          smellInfo.odor_name = this.smellForm.value.odorName || null;
          smellInfo.taste_name = this.smellForm.value.tasteName || null;
          smellInfo.category = this.smellForm.value.category
            ? this.smellForm.value.category
            : null;
          smellInfo.additional_information =
            this.smellForm.value.additionalInformation || null;
          smellInfo.parent_substance_id = this.smellForm.value.baseSmell
            ? this.smellForm.value.baseSmell.id
            : null;
          if (this.action === 'Update') {
            smellInfo.id = this.local_data.id;
          }
          let res: any;
          if (this.action === 'Add') {
            res = await this.smellService.addSmell(smellInfo);
          } else if (this.action === 'Update') {
            res = await this.smellService.updateSmell(smellInfo);
          }
          if (res) {
            this.helperService.showAlert(res.msg || 'Success', 'success');
          }
          this.dialogRef.close({
            event: this.action,
            data: { ...this.local_data, ...res.data },
            success: true,
          });
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        } finally {
          this.isProcessing = false;
        }
      }
    }
  }
}
