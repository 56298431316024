import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { ClassifiersService } from 'src/app/shared/services/classifier/classifier.service';
import { AddDetectorTypeComponent } from '../add-detector-type/add-detector-type.component';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import * as moment from 'moment';

@Component({
  selector: 'app-detector-types',
  templateUrl: './detector-types.component.html',
  styleUrls: ['./detector-types.component.scss'],
})
export class DetectorTypesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'functionalization',
    'upper_bound',
    'lower_bound',
    'createdAt',
    'updatedAt',
  ];

  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  public dataSource: any = [];
  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public toDate: any = '';
  public fromDate: any = '';
  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = [];
  public futureDate = new Date();

  // Tooltip
  public tooltip = {
    view_detector_type: 'View Detector Type',
    update_detector_type: 'Update Detector Type',
    delete_detector_type: 'Delete Detector Type',
  };

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild('detectorTypeSearch') detectorTypeSearch: ElementRef | any;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.getDetectorTypes();
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getDetectorTypes();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getDetectorTypes(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      let detectorTypeURL = '';
      // SEARCH
      if (this.searchedText) {
        detectorTypeURL += `&search=${this.searchedText}`;
      }
      // ORDER
      if (this.orderOn && this.orderBy) {
        detectorTypeURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        detectorTypeURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      detectorTypeURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      this.isProcessing = true;
      const res = await this.deviceService.getDetectorTypes(detectorTypeURL);
      if (res) {
        this.data = res.data.detectorTypes;
        this.dataSource = res.data.detectorTypes;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.detectorTypeSearch && this.detectorTypeSearch.nativeElement) {
        this.detectorTypeSearch.nativeElement.focus();
      }
    });
  }

  searchWithTerm() {
    if (this.searchedText) {
      this.getDetectorTypes();
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.showHideSearch();
    this.getDetectorTypes();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isFilterApplied() {
    return !this.toDate;
  }

  clearFilter() {
    this.fromDate = '';
    this.toDate = '';
    this.getDetectorTypes();
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getDetectorTypes();
    }
  }
  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(AddDetectorTypeComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          this.getDetectorTypes();
        }
      }
    });
  }

  addRowData(row_obj: any): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [row_obj, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: any): boolean | void {
    this.dataSource = this.dataSource.filter((value: any) => {
      if (value.id === row_obj.id) {
        value.name = row_obj.name;
        value.functionalization = row_obj.functionalization;
        value.upper_bound = row_obj.upper_bound;
        value.lower_bound = row_obj.lower_bound;
        value.updatedAt = row_obj.updatedAt;
      }
      return true;
    });
    this.table.renderRows();
  }
}
