<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="overlay" *ngIf="isProcessing">
            <mat-spinner class="loader"></mat-spinner>
          </div>

          <div class="">

            <div fxLayout="row nowrap">
              <mat-card fxFlex.gt-sm="100" fxFlex="100">
                <mat-card-content>
                  <div>
                    <table mat-table [dataSource]="classifierBlock" class="table employee-list td-ht-sm no-wrap">

                      <ng-container matColumnDef="key">
                        <td class="" mat-cell *matCellDef="let element">
                          {{ element.key }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="value">
                        <td class="custom-txt"
                          [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                          mat-cell *matCellDef="let element">
                          <div *ngIf="element.isDate">
                            {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                          </div>
                          <div class="td-lg" *ngIf="element.isTextArea">
                            <textarea readonly [ngModelOptions]="{standalone: true}" rows="3" cols="40"
                              [(ngModel)]="element.value" #description matInput class="scrollbar"
                              placeholder="Description"></textarea>
                          </div>
                          <div class="td-lg" *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                            {{ element.value || '-' }}
                          </div>
                          <div class="td-lg" *ngIf="element.isLink">
                            <a href="{{element.value}}">{{element.value}}</a>
                          </div>
                        </td>
                      </ng-container>

                      <tr mat-row *matRowDef="let row; columns: classifierBlockColumns"></tr>
                    </table>
                  </div>
                  <br>
                  <div>
                    <mat-tab-group #tabGroup>

                      <mat-tab label="Smell/Substance">
                        <table mat-table [dataSource]="classifierInfo.ml_classifier_substances"
                          class="table employee-list no-wrap">

                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td class="might-overflow" [matTooltip]="element.substance_id || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.substance_id || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td class="might-overflow" [matTooltip]="element?.ml_classifier_substance_info?.name || '-'"
                              mat-cell *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element?.ml_classifier_substance_info?.name || '-' }}
                              </p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef> Category </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_substance_info?.category || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_substance_info?.category || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="odor_name">
                            <th mat-header-cell *matHeaderCellDef> Odor Name </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_substance_info?.odor_name || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_substance_info?.odor_name || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="taste_name">
                            <th mat-header-cell *matHeaderCellDef> Taste Name </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_substance_info?.taste_name || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_substance_info?.taste_name || '-' }}</p>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="substanceColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: substanceColumns;"></tr>
                        </table>
                        <div class="no-record"
                          *ngIf='classifierInfo.ml_classifier_substances &&  classifierInfo.ml_classifier_substances.length === 0'>
                          No record found </div>
                      </mat-tab>

                      <mat-tab label="Used User">
                        <table mat-table [dataSource]="classifierInfo.ml_classifier_used_users"
                          class="table employee-list no-wrap">

                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td class="might-overflow" [matTooltip]="element.user_id || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.user_id || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="first_name">
                            <th mat-header-cell *matHeaderCellDef> First Name </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_used_user_info?.first_name || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_used_user_info?.first_name || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="last_name">
                            <th mat-header-cell *matHeaderCellDef> Last Name </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_used_user_info?.last_name || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_used_user_info?.last_name || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef> Email </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_used_user_info?.email || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element?.ml_classifier_used_user_info?.email || '-' }}
                              </p>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: userColumns;"></tr>
                        </table>
                        <div class="no-record"
                          *ngIf='classifierInfo.ml_classifier_used_users &&  classifierInfo.ml_classifier_used_users.length === 0'>
                          No record found </div>
                      </mat-tab>

                      <mat-tab label="Detector Type">
                        <table mat-table [dataSource]="classifierInfo.ml_classifier_detector_types"
                          class="table employee-list no-wrap">

                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.id }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td class="might-overflow" [matTooltip]="element.name || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.name || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="functionalization">
                            <th mat-header-cell *matHeaderCellDef> Functionalization </th>
                            <td class="" [matTooltip]="element.functionalization || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.functionalization || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="lower_bound">
                            <th mat-header-cell *matHeaderCellDef> Lower Bound </th>
                            <td class="might-overflow" [matTooltip]="element.lower_bound" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.lower_bound }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="upper_bound">
                            <th mat-header-cell *matHeaderCellDef> Upper Bound </th>
                            <td class="might-overflow" [matTooltip]="element.upper_bound" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.upper_bound }}</p>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="detectorColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: detectorColumns;"></tr>
                        </table>
                        <div class="no-record"
                          *ngIf='classifierInfo.ml_classifier_detector_types &&  classifierInfo.ml_classifier_detector_types.length === 0'>
                          No record found </div>
                      </mat-tab>

                      <mat-tab label="Device Type">
                        <table mat-table [dataSource]="classifierInfo.ml_classifier_device_types"
                          class="table employee-list no-wrap">

                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td class="might-overflow" [matTooltip]="element.id || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.id }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td class="might-overflow" [matTooltip]="element.name || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.name || '-' }}-{{ element.version || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="version">
                            <th mat-header-cell *matHeaderCellDef> Version </th>
                            <td class="might-overflow" [matTooltip]="element.version || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.version || '-' }}</p>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="deviceTypeColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: deviceTypeColumns;"></tr>
                        </table>
                        <div class="no-record"
                          *ngIf='classifierInfo.ml_classifier_device_types &&  classifierInfo.ml_classifier_device_types.length === 0'>
                          No record found </div>
                      </mat-tab>

                      <mat-tab label="Firmware">
                        <table mat-table [dataSource]="classifierInfo.ml_classifier_firmwares"
                          class="table employee-list no-wrap">

                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td class="might-overflow" [matTooltip]="element.id || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.id }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td class="might-overflow" [matTooltip]="element.name || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.name || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="version">
                            <th mat-header-cell *matHeaderCellDef> Version </th>
                            <td class="might-overflow" [matTooltip]="element.version || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.version || '-' }}</p>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="firmwareColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: firmwareColumns;"></tr>
                        </table>
                        <div class="no-record"
                          *ngIf='classifierInfo.ml_classifier_firmwares &&  classifierInfo.ml_classifier_firmwares.length === 0'>
                          No record found </div>
                      </mat-tab>

                      <mat-tab label="Category">
                        <table mat-table [dataSource]="classifierInfo.ml_classifier_category_info"
                          class="table employee-list no-wrap">

                          <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td class="might-overflow" [matTooltip]="element.category || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.category || '-' }}</p>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="categoryColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: categoryColumns;"></tr>
                        </table>
                        <div class="no-record"
                          *ngIf='classifierInfo.ml_classifier_category_info &&  classifierInfo.ml_classifier_category_info.length === 0'>
                          No record found </div>
                      </mat-tab>

                      <mat-tab label="Use Case">
                        <table mat-table [dataSource]="classifierInfo.ml_classifier_usecases"
                          class="table employee-list no-wrap">

                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td class="might-overflow" [matTooltip]="element.usecase_id || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element.usecase_id || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td class="might-overflow" [matTooltip]="element?.ml_classifier_usecase_info?.name || '-'"
                              mat-cell *matCellDef="let element">
                              <p class="m-b-0 m-t-0">{{ element?.ml_classifier_usecase_info?.name || '-' }}
                              </p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef> Description </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_usecase_info?.description || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_usecase_info?.description || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="unknown_substance">
                            <th mat-header-cell *matHeaderCellDef> Unknown Substance </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_usecase_info?.unknown_substance || '-'" mat-cell
                              *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_usecase_info?.unknown_substance || '-' }}</p>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="monitoring_short_measurement">
                            <th mat-header-cell *matHeaderCellDef> Measurement Type </th>
                            <td class="might-overflow"
                              [matTooltip]="element?.ml_classifier_usecase_info?.monitoring_short_measurement || '-'"
                              mat-cell *matCellDef="let element">
                              <p class="m-b-0 m-t-0">
                                {{ element?.ml_classifier_usecase_info?.monitoring_short_measurement || '-' }}</p>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="usecaseColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: usecaseColumns;"></tr>
                        </table>
                        <div class="no-record"
                          *ngIf='classifierInfo.ml_classifier_usecases &&  classifierInfo.ml_classifier_usecases.length === 0'>
                          No record found </div>
                      </mat-tab>

                    </mat-tab-group>
                  </div>

                </mat-card-content>
              </mat-card>
            </div>

          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>