<!-- Breadcrumb -->

<div class="page-breadcrumb p-t-10 p-l-30 p-r-30">
	<div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div class="align-self-center">
            <h4 class="page-title m-0">{{pageInfo?.title}}</h4>
		</div>
		<div class="align-self-center">
            <div class="breadcrumb d-flex align-items-center float-right float-sm-left">
                <ul class="list-style-none d-flex align-items-center">
                    <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                        <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                            <a [routerLink]='url.url'>{{url.title}}</a>
                        </li>
                        <li class="breadcrumb-item active" *ngIf="last">{{url.title}}</li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>
</div>