<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Device Type</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <form [formGroup]="addDeviceTypeForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input type="text" matInput required id="name" name="name" [(ngModel)]="local_data.name" placeholder="Name"
              formControlName="name">
            <mat-error *ngIf="!addDeviceControls.name.value && addDeviceTypeForm.get('name').touched"
              class="text-danger">Name is required</mat-error>
            <mat-error
              *ngIf="addDeviceControls.name.value && addDeviceTypeForm.get('name').touched && addDeviceTypeForm.get('name').hasError('whitespace')">
              Please enter valid name
            </mat-error>

            <mat-error *ngIf="addDeviceControls.name.value && addDeviceTypeForm.get('name').hasError('maxlength')"
              class="text-danger">
              Maximum 60 characters are allowed
            </mat-error>

          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input type="text" matInput required id="version" name="version" [(ngModel)]="local_data.version"
              placeholder="Version" formControlName="version">
            <mat-error *ngIf="!addDeviceControls.version.value && addDeviceTypeForm.get('version').touched"
              class="text-danger">Version is required</mat-error>
            <mat-error
              *ngIf="addDeviceControls.version.value && addDeviceTypeForm.get('version').touched && addDeviceTypeForm.get('version').hasError('whitespace')">
              Please enter valid version
            </mat-error>

            <mat-error *ngIf="addDeviceControls.version.value && addDeviceTypeForm.get('version').hasError('maxlength')"
              class="text-danger">
              Maximum 60 characters are allowed
            </mat-error>

          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <mat-label>Supported Firmwares</mat-label>
            <mat-select placeholder="Firmware" multiple formControlName="firmwares" [(ngModel)]="selectedFirmwares">
              <mat-option>
                <ngx-mat-select-search [formControl]="firmwareSearch" placeholderLabel="Search Firmware..."
                  noEntriesFoundLabel="No matching Firmware found">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option [disabled]="firmware.is_firmware_occupied" *ngFor="let firmware of filteredFirmwares"
                [value]="firmware.id">
                {{ firmware.name }}-{{ firmware.version }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">

          <div fxFlex="100" class="m-l-15">
            <div class="m-b-15">
              <mat-checkbox color="primary" formControlName="is_contain_sensor_board" class="m-r-10"
                [(ngModel)]="selectedIsContainSensorBoard">
                Is Contain Sensor Board</mat-checkbox>
            </div>
          </div>
        </div>

      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !addDeviceTypeForm.valid">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.name}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>