import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DetectorTypesComponent } from '../detector-types/detector-types.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { CustomValidators } from 'src/app/validators/customValidator';
import { CommonConstants } from 'src/app/shared/common-constants';

@Component({
  selector: 'app-add-detector-type',
  templateUrl: './add-detector-type.component.html',
  styleUrls: ['./add-detector-type.component.scss'],
})
export class AddDetectorTypeComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public addDetectorTypeForm: any;
  public isProcessing: boolean = false;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DetectorTypesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddDetectorType();
    this.setPatchValues();
  }

  // convenience getter for easy access to form fields
  get addDetectorTypeControls() {
    return this.addDetectorTypeForm.controls;
  }

  resetAddDetectorType() {
    const groupObj: any = {
      name: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
      functionalization: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidfunctionalization,
        ]),
      ],
      lower_bound: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.maxIntValidator,
        ]),
      ],
      upper_bound: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.maxIntValidator,
        ]),
      ],
    };
    const validateBoundries = {
      validator: CustomValidators.boundsValidator('lower_bound', 'upper_bound'),
    };
    this.addDetectorTypeForm = this.fb.group(groupObj, validateBoundries);
  }

  async getDetectorTypeDetails(detector_type_id: string) {
    try {
      this.isProcessing = true;
      const result = await this.deviceService.getDetectorTypeDetails(
        detector_type_id
      );
      return result;
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async setPatchValues() {
    if (this.action === 'Update') {
      const detector_type_id = this.local_data.id;
      const { data } = await this.getDetectorTypeDetails(detector_type_id);
      this.addDetectorTypeForm.patchValue({
        name: data.name,
        functionalization: data.functionalization,
        upper_bound: data.upper_bound,
        lower_bound: data.lower_bound,
      });
    }
  }

  async doAction() {
    await this.submitDetectorTypeForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitDetectorTypeForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const deleteURL = `detector_type_id=${this.local_data.id}`;
        const res = await this.deviceService.deleteDetectorType(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else if (this.addDetectorTypeForm.valid) {
      try {
        this.isProcessing = true;
        let res: any;
        const reqData: any = {
          name: this.addDetectorTypeForm.controls.name.value,
          functionalization:
            this.addDetectorTypeForm.controls.functionalization.value,
          upper_bound: this.addDetectorTypeForm.controls.upper_bound.value,
          lower_bound: this.addDetectorTypeForm.controls.lower_bound.value,
        };
        if (this.action === 'Add') {
          res = await this.deviceService.createDetectorType(reqData);
          this.local_data.id = res.data.id;
          this.local_data.functionalization = res.data.functionalization;
          this.local_data.createdAt = res.data.createdAt;
          this.local_data.updatedAt = res.data.updatedAt;
        } else if (this.action === 'Update') {
          const detector_type_id = this.local_data.id;
          reqData.detector_type_id = detector_type_id;
          res = await this.deviceService.editDetectorType(reqData);
          const { data } = await this.getDetectorTypeDetails(detector_type_id);
          this.local_data.functionalization = data.functionalization;
          this.local_data.updatedAt = data.updatedAt;
        }
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }

  validNumber(event: any, key: string) {
    let value: any = +event.target.value;
    if (value) {
      if (value < 0) {
        value = value * -1;
      }
      value = parseInt(value, 10);
      event.target.value = value;
      this.local_data[key] = value;
    }
  }
}
