import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ActivatedRoute } from '@angular/router';
import { UserResetPasswordComponent } from '../user-reset-password/user-reset-password.component';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  profile_picture: string;
  user_type: string;
  available_storage_space: number;
  total_storage_space: number;
  createdAt: any;
}

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit, AfterViewInit {
  public userInfo: any = {};
  public devices: any = [];
  public defaultImage = 'assets/images/users/default-profile.png';
  public isProcessing = false;
  public smellColumns: string[] = [
    'index',
    'id',
    'name',
    'is_public',
    'category',
    'odor_name',
    'taste_name',
    'additional_information',
    'createdAt',
  ];
  public usecaseColumns: string[] = [
    'index',
    'id',
    'name',
    'is_public',
    'description',
    'unknown_substance',
    'monitoring_short_measurement',
    'createdAt',
  ];
  public measurementSetupColumns: string[] = [
    'index',
    'id',
    'name',
    'is_public',
    'description',
    'indoor_outdoor',
    'createdAt',
  ];
  public measurementColumns: string[] = [
    'index',
    'id',
    'name',
    'smells',
    'usecases',
    'measurement_setup',
    'connection_type',
    'measurement_type',
    'measurement_size',
    'measurement_duration',
    'fan_state',
    'started_at',
    'stopped_at',
    'measurement_indoor_outdoor',
    'recognize_rating',
    'recognize_comments',
    'createdAt',
  ];
  public deviceColumns: string[] = [
    'index',
    'id',
    'mac_address',
    'serial_number',
    'device_type',
    'device_firmware',
    'detector_info',
    'createdAt',
    'updatedAt',
  ];
  public tabs = [
    'Smells',
    'Usecases',
    'Measurement Setups',
    'Measurements',
    'Devices',
  ];
  public types: any = {
    'Smells/Substances': 'smells',
    'Use Cases': 'usecases',
    'Measurement Setups': 'measurementsetups',
    Measurements: 'measurements',
    Devices: 'devices',
  };
  public currentTab: any = 'Measurements';
  public userBlock: any = [];
  public countBlock: any = [];
  public countBlockColumns: any = ['key', 'value'];
  public userBlockColumns: any = ['key', 'value'];

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  public available_storage_space_mb: string = '0 MB';
  public total_storage_space_mb: string = '0 MB';

  public pageIndex: number = 0;
  public pageSize: number = 15;

  // Smells
  public smellsPageIndex: number = 0;
  public smellsPageSize: number = 15;
  public smellsTotalCount: number = 0;
  public smellsPageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public smellsDataProcessing = false;
  // Usecases
  public usecasesPageIndex: number = 0;
  public usecasesPageSize: number = 15;
  public usecasesTotalCount: number = 0;
  public usecasesPageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public usecasesDataProcessing = false;
  // Measurement  Setups
  public measurementsetupsPageIndex: number = 0;
  public measurementsetupsPageSize: number = 15;
  public measurementsetupsTotalCount: number = 0;
  public measurementsetupsPageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public measurementsetupsDataProcessing = false;
  // Measurements
  public measurementsPageIndex: number = 0;
  public measurementsPageSize: number = 15;
  public measurementsTotalCount: number = 0;
  public measurementsPageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public measurementsDataProcessing = false;
  // Devices
  public devicesPageIndex: number = 0;
  public devicesPageSize: number = 15;
  public devicesTotalCount: number = 0;
  public devicesPageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public devicesDataProcessing = false;

  public smellsData: any = [];
  public smellsCount: number = 0;
  public usecasesData: any = [];
  public usecasesCount: number = 0;
  public measurementsetupsData: any = [];
  public measurementsetupsCount: number = 0;
  public measurementsData: any = [];
  public measurementsCount: number = 0;
  public devicesData: any = [];
  public devicesCount: number = 0;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getUserDetails();
    this.getUserData('Measurements');
  }

  resetPassword() {
    this.dialog.open(UserResetPasswordComponent, {
      minWidth: '700px',
      maxHeight: '90vh',
      data: this.userInfo,
    });
  }

  async getUserDetails() {
    try {
      const userId = this.route.snapshot.paramMap.get('userId');
      this.isProcessing = true;
      const res = await this.userService.getUserDetails(userId);
      if (res) {
        this.userInfo = res.data.user;
        this.userInfo.measurementSetupCount = res.data.measurementSetupCount;
        this.userInfo.smellCount = res.data.smellCount;
        this.userInfo.usecaseCount = res.data.usecaseCount;
        this.userInfo.measurementCount = res.data.measurementCount;
        this.available_storage_space_mb = this.helperService.kbToMBConvertor(
          this.userInfo.available_storage_space
        );
        this.total_storage_space_mb = this.helperService.kbToMBConvertor(
          this.userInfo.total_storage_space
        );
        let username = ``;
        if (this.userInfo.first_name) {
          username += `${this.userInfo.first_name}`;
        }
        if (this.userInfo.last_name) {
          username += ` ${this.userInfo.last_name}`;
        }
        this.userInfo.username = username;
        this.userBlock = [
          {
            isDate: false,
            isLink: false,
            key: 'ID',
            value: this.userInfo.id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'First Name',
            value: this.userInfo.first_name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Last Name',
            value: this.userInfo.last_name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'User Type',
            value: this.userInfo.user_type,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Email',
            value: this.userInfo.email,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Phone',
            value: this.userInfo.phone_number || '-',
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: this.userInfo.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: this.userInfo.updatedAt,
          },
        ];
        this.countBlock = [
          {
            isDate: false,
            isLink: false,
            iconName: 'air',
            iconColor: 'warn',
            className: 'bg-inverse bg-success',
            key: 'Smells/Substances Information',
            value: this.userInfo.smellCount,
          },
          {
            isDate: false,
            isLink: false,
            iconName: 'developer_board',
            iconColor: 'warn',
            className: 'bg-warning',
            key: 'Use Cases',
            value: this.userInfo.usecaseCount,
          },
          {
            isDate: false,
            isLink: false,
            iconName: 'add_chart',
            iconColor: 'accent',
            className: '',
            key: 'Measurement Setups',
            value: this.userInfo.measurementSetupCount,
          },
          {
            isDate: false,
            isLink: false,
            iconName: 'stacked_bar_chart',
            iconColor: 'warn',
            className: 'bg-danger',
            key: 'Measurements',
            value: this.userInfo.measurementCount,
          },
          {
            isDate: false,
            isLink: false,
            iconName: 'storage',
            iconColor: 'warn',
            className: 'bg-primary',
            key: 'Available Space',
            value: this.available_storage_space_mb,
          },
          {
            isDate: false,
            isLink: false,
            iconName: 'storage',
            iconColor: 'warn',
            className: 'bg-info',
            key: 'Total Space',
            value: this.total_storage_space_mb,
          },
        ];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(UserDialogComponent, {
      minWidth: '50%',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            // this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            // this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            // this.deleteRowData(result.data);
          }
        }
      }
    });
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getUserData(this.currentTab);
  }

  tabChanged(event: any) {
    if (event && event.tab && event.tab.textLabel) {
      const { textLabel: label } = event.tab;
      this.currentTab = label;
      this.getUserData(this.currentTab);
    }
  }

  async getUserData(type: string, event?: PageEvent) {
    try {
      let page = 0;
      let perPage = 10;
      if (type === 'Smells/Substances') {
        this.smellsDataProcessing = true;
        if (event) {
          this.smellsPageIndex = event.pageIndex;
          this.smellsPageSize = event.pageSize;
        }
        page = this.smellsPageIndex + 1;
        perPage = this.smellsPageSize;
      } else if (type === 'Use Cases') {
        this.usecasesDataProcessing = true;
        if (event) {
          this.usecasesPageIndex = event.pageIndex;
          this.usecasesPageSize = event.pageSize;
        }
        page = this.usecasesPageIndex + 1;
        perPage = this.usecasesPageSize;
      } else if (type === 'Measurement Setups') {
        this.measurementsetupsDataProcessing = true;
        if (event) {
          this.measurementsetupsPageIndex = event.pageIndex;
          this.measurementsetupsPageSize = event.pageSize;
        }
        page = this.measurementsetupsPageIndex + 1;
        perPage = this.measurementsetupsPageSize;
      } else if (type === 'Measurements') {
        this.measurementsDataProcessing = true;
        if (event) {
          this.measurementsPageIndex = event.pageIndex;
          this.measurementsPageSize = event.pageSize;
        }
        page = this.measurementsPageIndex + 1;
        perPage = this.measurementsPageSize;
      } else if (type === 'Devices') {
        this.devicesDataProcessing = true;
        if (event) {
          this.devicesPageIndex = event.pageIndex;
          this.devicesPageSize = event.pageSize;
        }
        page = this.devicesPageIndex + 1;
        perPage = this.devicesPageSize;
      }
      const userId = this.route.snapshot.paramMap.get('userId');
      const currentType = this.types[type];
      let userURL = `page=${page}&perPage=${perPage}&type=${currentType}`;

      // ORDER
      if (this.orderOn && this.orderBy) {
        userURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
      }

      const res = await this.userService.getUserData(userId, userURL);
      if (res) {
        if (type === 'Smells/Substances') {
          this.smellsCount = res.data.count;
          this.smellsData = new MatTableDataSource(res.data.data);
          this.smellsTotalCount = res.data.count;
        } else if (type === 'Use Cases') {
          this.usecasesCount = res.data.count;
          this.usecasesData = new MatTableDataSource(res.data.data);
          this.usecasesTotalCount = res.data.count;
        } else if (type === 'Measurement Setups') {
          this.measurementsetupsCount = res.data.count;
          this.measurementsetupsData = new MatTableDataSource(res.data.data);
          this.measurementsetupsTotalCount = res.data.count;
        } else if (type === 'Measurements') {
          this.measurementsCount = res.data.count;
          res.data.data.forEach((d: any) => {
            d.measurement_size_mb = this.helperService.kbToMBConvertor(
              d.measurement_size
            );
            d.mst_duration = this.helperService.secondsToDhms(
              d.measurement_duration
            );
          });
          this.measurementsData = new MatTableDataSource(res.data.data);
          this.measurementsTotalCount = res.data.count;
        } else if (type === 'Devices') {
          this.devicesCount = res.data.count;
          this.devicesData = new MatTableDataSource(res.data.data);
          this.devicesTotalCount = res.data.count;
        }
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      if (type === 'Smells/Substances') {
        this.smellsDataProcessing = false;
      } else if (type === 'Use Cases') {
        this.usecasesDataProcessing = false;
      } else if (type === 'Measurement Setups') {
        this.measurementsetupsDataProcessing = false;
      } else if (type === 'Measurements') {
        this.measurementsDataProcessing = false;
      } else if (type === 'Devices') {
        this.devicesDataProcessing = false;
      }
    }
  }
}
