import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';
@Injectable({
  providedIn: 'root'
})

export class ClassifiersService {

  constructor(
    private apiService: APIService,
  ) { }

  getClassifiers(userId: string) {
    const url = `/ml-classifier/private/${userId}`;
    return this.apiService.getWithAuthHandler(url, true, 'getClassifiers');
  }

  assignClassifiers(userId: string, params: object) {
    const url = `/ml-classifier/assign/${userId}`;
    return this.apiService.postWithAuthHandler(url, params);
  }

  getMLClassifierFilters() {
    const url = '/ml-classifier/filters';
    return this.apiService.getWithAuthHandler(url, true, 'getMLClassifierFilters');
  }

  getMLClassifiers(params: any) {
    const url = `/ml-classifier?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getMLClassifiers');
  }

  getMLClassifierDetail(classifierId: any) {
    const url = `/ml-classifier/${classifierId}`;
    return this.apiService.getWithAuthHandler(url, true, 'getMLClassifierDetail');
  }

  addMLClassifier(params: object) {
    const url = '/ml-classifier';
    return this.apiService.postWithAuthHandler(url, params);
  }

  editMLClassifier(params: object, classifierId: string) {
    const url = `/ml-classifier/${classifierId}`;
    return this.apiService.putWithAuthHandler(url, params);
  }

  getPreSignedURL(params: any): any {
    const url = `/ml-classifier/presigned?${params}`;
    return this.apiService.getWithAuthHandler(url);
  }

  uploadFiles3Sync(fileuploadurl: string, file: any): any {
    return new Promise((resolve, reject) => {
      const url = fileuploadurl;
      const headers = {};
      this.apiService.putTPWithHeader(url, file, headers)
        .subscribe((res: any) => {
          return resolve(res);
        }, (err: any) => {
          reject(err);
        });
    });
  }

}
