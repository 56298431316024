import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DetectorBoardsComponent } from '../detector-boards/detector-boards.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { CustomValidators } from 'src/app/validators/customValidator';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-add-detector-board',
  templateUrl: './add-detector-board.component.html',
  styleUrls: ['./add-detector-board.component.scss'],
})
export class AddDetectorBoardComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public action: string = 'Add';
  public local_data: any;
  public addDeviceForm: any;
  public isProcessing: boolean = false;
  public deviceTypes: any = [];
  public firmwares: any = [];
  public detectors: any = [];
  public detector_types: any = [];
  public detector_options: any = [];
  public detectorPositions = [
    {
      detector_position: 'X1',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
    {
      detector_position: 'X2',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
    {
      detector_position: 'X3',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
    {
      detector_position: 'X4',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
  ];
  public detectorPositionTable: any = {
    X1: [],
    X2: [],
    X3: [],
    X4: [],
  };
  public detectorPositionColumns: any = ['key', 'value'];
  public deviceBlock: any = [];
  public deviceBlockColumns: any = ['key', 'value'];
  public deviceTypeDropDownSearch: FormControl = new FormControl();
  public filteredDeviceTypes: any = [];
  public filteredFirmwares: any = [];
  public firmwareSearch: FormControl = new FormControl();
  public filteredDetectorPositions: any = [];
  public detectorPositionSearch: FormControl = new FormControl();
  public positionErrors: any = {
    X1: {
      error: null,
    },
    X2: {
      error: null,
    },
    X3: {
      error: null,
    },
    X4: {
      error: null,
    },
  };
  public detectorTypeSearch: any = new FormControl();
  public filteredDetectorTypes: any = [];

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DetectorBoardsComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }

  ngOnInit() {
    this.resetAddDeviceType();
    this.setPatchValues();

    this.detectorPositionSearch.valueChanges.subscribe((val) => {
      this.filteredDetectorPositions = this.detector_options.filter(
        (option: any) =>
          option.batch_id?.toLowerCase().includes(val?.toLowerCase()) ||
          option.slot_id?.toLowerCase().includes(val?.toLowerCase()) ||
          option.production_detector_id
            ?.toLowerCase()
            .includes(val?.toLowerCase()) ||
          option.detector_type?.name?.toLowerCase().includes(val?.toLowerCase())
      );
    });

    this.detectorTypeSearch.valueChanges.subscribe((val: any) => {
      this.filteredDetectorTypes = this.detector_types.filter((option: any) => {
        return option.name.toLowerCase().includes(val?.toLowerCase());
      });
    });
  }

  // convenience getter for easy access to form fields
  get addDeviceControls() {
    return this.addDeviceForm.controls;
  }

  resetPositions() {
    this.detectorPositions.forEach((d: any) => {
      d.detector_id = null;
      d.batch_id = null;
      d.slot_id = null;
      d.production_detector_id = null;
      d.detector_info = null;
      d.isExist = false;
      d.detectorType = null;
    });
    Object.values(this.positionErrors).forEach((f: any) => {
      f.error = null;
    });
  }

  change(event: any, key: string) {
    this.filteredDetectorTypes = [...this.detector_types];
    this.detectorTypeSearch.value = null;
  }

  resetAddDeviceType() {
    const groupObj: any = {
      detector_board_id: [null, Validators.compose([Validators.required])],
      X1: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X1_DETECTOR_TYPE: [null, Validators.compose([])],
      X2: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X2_DETECTOR_TYPE: [null, Validators.compose([])],
      X3: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X3_DETECTOR_TYPE: [null, Validators.compose([])],
      X4: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X4_DETECTOR_TYPE: [null, Validators.compose([])],
    };
    this.addDeviceForm = this.fb.group(groupObj);
  }

  async getDetectorTypes() {
    try {
      let detectorTypeURL = `page=${0}&perPage=${0}`;
      const result = await this.deviceService.getDetectorTypes(detectorTypeURL);
      if (result) {
        this.detector_types = result.data.detectorTypes;
        this.filteredDetectorTypes = [...this.detector_types];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  isPositionPlaced() {
    return (
      this.detectorPositions.findIndex((d: any) => {
        return !!d.detector_info;
      }) === -1
    );
  }

  async getDetectorBoardDetails(boardId: string) {
    try {
      this.isProcessing = true;
      const params = `boardId=${boardId}&byId=true`;
      const result = await this.deviceService.getDetectorBoardDetails(params);
      return result;
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async setPatchValues() {
    try {
      if (this.action === 'View') {
        const boardId = this.local_data.id;
        const { data } = await this.getDetectorBoardDetails(boardId);
        this.deviceBlock = [
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'ID',
            value: data.boardInfo.id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Sensor Board Id',
            value: data.boardInfo.detector_board_id,
          },
          {
            isDate: false,
            showTooltip: false,
            isLink: false,
            isObjectValue: true,
            key: 'Detector Position: X1',
            value: {
              detectorInfo: `${data.detectorInfo[0].detector.batch_id} ${data.detectorInfo[0].detector.slot_id} ${data.detectorInfo[0].detector.production_detector_id}`,
              detectorType: data.detectorInfo[0].detector.detector_type.name,
            },
          },
          {
            isDate: false,
            showTooltip: false,
            isLink: false,
            isObjectValue: true,
            key: 'Detector Position: X2',
            value: {
              detectorInfo: `${data.detectorInfo[1].detector.batch_id} ${data.detectorInfo[1].detector.slot_id} ${data.detectorInfo[1].detector.production_detector_id}`,
              detectorType: data.detectorInfo[1].detector.detector_type.name,
            },
          },
          {
            isDate: false,
            showTooltip: false,
            isLink: false,
            isObjectValue: true,
            key: 'Detector Position: X3',
            value: {
              detectorInfo: `${data.detectorInfo[2].detector.batch_id} ${data.detectorInfo[2].detector.slot_id} ${data.detectorInfo[2].detector.production_detector_id}`,
              detectorType: data.detectorInfo[2].detector.detector_type.name,
            },
          },
          {
            isDate: false,
            showTooltip: false,
            isLink: false,
            isObjectValue: true,
            key: 'Detector Position: X4',
            value: {
              detectorInfo: `${data.detectorInfo[3].detector.batch_id} ${data.detectorInfo[3].detector.slot_id} ${data.detectorInfo[3].detector.production_detector_id}`,
              detectorType: data.detectorInfo[3].detector.detector_type.name,
            },
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: data.boardInfo.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: data.boardInfo.updatedAt,
          },
        ];
      } else {
        this.isProcessing = true;
        await this.getDetectorTypes();
        if (this.action === 'Update') {
          const boardId = this.local_data.id;
          const { data } = await this.getDetectorBoardDetails(boardId);
          this.addDeviceForm.patchValue({
            detector_board_id: data.boardInfo.detector_board_id,
          });
          data.detectorInfo
            .sort((a: any, b: any) => {
              return a.id - b.id;
            })
            .forEach((d: any) => {
              const i = this.detectorPositions.findIndex((dp: any) => {
                return dp.detector_position === d.detector_position;
              });
              if (i !== -1) {
                const detectorInfo = d.detector;
                this.detectorPositions[i].detector_id = detectorInfo.id;
                this.detectorPositions[i].batch_id = detectorInfo.batch_id;
                this.detectorPositions[i].slot_id = detectorInfo.slot_id;
                this.detectorPositions[i].production_detector_id =
                  detectorInfo.production_detector_id;
                const detector_info: any = `${detectorInfo.batch_id} ${detectorInfo.slot_id} ${detectorInfo.production_detector_id}`;
                this.detectorPositions[i].detector_info = null;
                this.detectorPositions[i].isExist = true;
                this.detectorPositions[i].detector_info = detector_info;
                this.detectorPositions[i].detectorType =
                  detectorInfo.detector_type;
                const selectedDetectType = this.filteredDetectorTypes.findIndex(
                  (f: any) => {
                    return f.id === detectorInfo.detector_type.id;
                  }
                );
                this.detectorPositions[i].selectedDetectorType =
                  this.filteredDetectorTypes[selectedDetectType];
                this.addDeviceForm.patchValue({
                  [d.detector_position]: detector_info,
                });
              }
            });
        }
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async doAction() {
    await this.submitDetectorBoardForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitDetectorBoardForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const deleteURL = `boardId=${this.local_data.id}`;
        const res = await this.deviceService.deleteDetectorBoard(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else if (this.addDeviceForm.valid) {
      try {
        const newDetectors = this.detectorPositions.filter((s: any) => {
          return !s.detector_id;
        });
        if (newDetectors.length) {
          const detectorIds: any = [];
          newDetectors.map((m: any) => {
            const reqData: any = {
              detector_info: m.detector_info.replace(/\s/g, ''),
              detector_type_id: m.selectedDetectorType.id,
            };
            detectorIds.push(this.deviceService.createDetector(reqData));
          });
          const result = await Promise.all(detectorIds);
          if (result && result.length) {
            result.map((r: any) => {
              const dInfo = `${r.data.batch_id} ${r.data.slot_id} ${r.data.production_detector_id}`;
              const atIndex = this.detectorPositions.findIndex((f: any) => {
                return f.detector_info === dInfo;
              });
              if (atIndex !== -1) {
                this.detectorPositions[atIndex].detector_id = r.data.id;
                this.detectorPositions[atIndex].detectorType =
                  r.data.detector_type.name;
              }
            });
          }
        }
        this.isProcessing = true;
        let res: any;
        let board_detectors: any = this.detectorPositions.map((d: any) => {
          return {
            detector_info: d.detector_info.replace(/\s/g, ''),
            detector_type_id: d.detectorType.id,
            detector_position: d.detector_position,
            detector_id: d.detector_id,
          };
        });

        const reqData: any = {
          detector_board_id:
            this.addDeviceForm.controls.detector_board_id.value,
          board_detectors,
        };
        if (this.action === 'Add') {
          res = await this.deviceService.createDetectorBoard(reqData);
          this.local_data.data = res.data;
        } else if (this.action === 'Update') {
          const boardId = this.local_data.id;
          reqData.boardId = boardId;
          res = await this.deviceService.editDetectorBoard(reqData);
          this.local_data.data = res.data;
        }
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }

  async validateDetector(event: any, key: any) {
    if (event && event.target.value) {
      event.target.value = event.target.value.replace(/\s/g, '');
      if (event.target.value.length > 14) {
        event.target.value = event.target.value.substr(0, 14);
        event.target.value = `${event.target.value.substr(
          0,
          7
        )} ${event.target.value.substr(7, 2)} ${event.target.value.substr(9)}`;
      } else {
        if (event.target.value.length > 7 && event.target.value.length <= 9) {
          event.target.value = `${event.target.value.substr(
            0,
            7
          )} ${event.target.value.substr(7, 2)}`;
        } else if (
          event.target.value.length > 7 &&
          event.target.value.length <= 14
        ) {
          event.target.value = `${event.target.value.substr(
            0,
            7
          )} ${event.target.value.substr(7, 2)} ${event.target.value.substr(
            9
          )}`;
        }
      }
      if (this.addDeviceControls[key].status === 'VALID') {
        let senitizedValue = event.target.value;
        senitizedValue = senitizedValue.replace(/\s/g, '');
        if (senitizedValue.length === 14) {
          this.positionErrors[key].error = null;
          const isAlreadyOccupied = this.detectorPositions.findIndex(
            (d: any) => {
              return (
                d.detector_info === event.target.value &&
                d.detector_position !== key
              );
            }
          );
          if (isAlreadyOccupied === -1) {
            const batch_id = senitizedValue.substr(0, 7);
            const slot_id = senitizedValue.substr(7, 2);
            const production_detector_id = senitizedValue.substr(9);
            let URL = `detector_info=${senitizedValue}`;
            if (this.action === 'Update') {
              URL += `&boardId=${this.local_data.id}`;
            }
            const res = await this.deviceService.validateDetectorExistency(URL);
            if (res && res.data && res.data.isAssociated) {
              this.addDeviceControls[`${key}`].setErrors({ incorrect: true });
              return (this.positionErrors[
                key
              ].error = `${res.data.errorReason}`);
            }
            const i = this.detectorPositions.findIndex((d: any) => {
              return d.detector_position === key;
            });
            this.detectorPositions[i].detector_info = event.target.value;
            this.detectorPositions[i].batch_id = batch_id;
            this.detectorPositions[i].slot_id = slot_id;
            this.detectorPositions[i].production_detector_id =
              production_detector_id;
            // Reset OLD data
            this.detectorPositions[i].detector_id = null;
            this.detectorPositions[i].detectorType = null;
            this.detectorPositions[i].isExist = false;
            // Reset OLD validations
            Object.keys(this.positionErrors).forEach((f) => {
              const { error } = this.positionErrors[f];
              if (error && error.includes(`${key}`)) {
                this.positionErrors[`${f}`].error = null;
                this.addDeviceControls[`${f}`].setErrors(null);
              }
            });
            if (
              res &&
              res.data &&
              res.data.isExist &&
              res.data.detectorDetail
            ) {
              this.detectorPositions[i].detector_id =
                res.data.detectorDetail.id;
              this.detectorPositions[i].isExist = true;
              this.detectorPositions[i].detectorType =
                res.data.detectorDetail.detector_type;
              const selectedDetectType = this.filteredDetectorTypes.findIndex(
                (f: any) => {
                  return f.id === res.data.detectorDetail.detector_type.id;
                }
              );
              this.detectorPositions[i].selectedDetectorType =
                this.filteredDetectorTypes[selectedDetectType];
              // const detectorIndex = this.detectorPositionTable[key].findIndex(
              //   (d: any) => {
              //     return d.key === 'Detector Type';
              //   }
              // );
              // this.detectorPositionTable[key][detectorIndex].value =
              //   res.data.detectorDetail.detector_type.name;
            } else {
              this.detectorPositions[i].detector_id = null;
              this.detectorPositions[i].detectorType = null;
              this.detectorPositions[i].isExist = false;
              this.detectorPositions[i].selectedDetectorType = null;
            }
          } else {
            this.addDeviceControls[`${key}`].setErrors({ incorrect: true });
            // Remove Selected Detector Type If It's Already Selected At Another Place
            const keyIndex = this.detectorPositions.findIndex((f: any) => {
              return f.detector_position === key;
            });
            this.detectorPositions[keyIndex].selectedDetectorType = null;
            this.positionErrors[
              key
            ].error = `This detector is already occupied at position ${this.detectorPositions[isAlreadyOccupied].detector_position}`;
          }
        }
      }
    }
  }

  resetSelectedDetectorType(key: string) {
    const positionIndex = this.detectorPositions.findIndex((d: any) => {
      return d.detector_position === key;
    });
    this.detectorPositions[positionIndex].detectorType = null;
    this.detectorPositions[positionIndex].selectedDetectorType = null;
  }

  isAllDetectorSelected() {
    return (
      this.detectorPositions.length ===
      this.detectorPositions.filter((d: any) => {
        return d.selectedDetectorType;
      }).length
    );
  }
}
