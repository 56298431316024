import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppVersionUpdatesComponent } from './app-version-updates/app-version-updates.component';

const routes: Routes = [
  {
    path: '',
    component: AppVersionUpdatesComponent,
    data: {
      title: 'App Version Updates',
      urls: [
        { title: 'Dashboard', url: '/' },
        { title: 'App Version Updates' },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppVersionUpdateRoutingModule {}
