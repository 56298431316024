import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';
@Injectable({
  providedIn: 'root',
})
export class MeasurementService {
  constructor(private apiService: APIService) {}

  getMeasurements(params: any) {
    const url = `/measurement?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getMeasurements');
  }

  getMeasurementFilters() {
    const url = '/measurement/filters';
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getMeasurementFilters'
    );
  }

  getMeasurementDetails(measurementId: any) {
    const url = `/measurement/${measurementId}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getMeasurementDetails'
    );
  }

  downloadFile(url: string): any {
    return this.apiService.getTPBlob(url);
  }
}
