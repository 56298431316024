import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ClassifiersComponent } from '../classifiers/classifiers.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ClassifiersService } from 'src/app/shared/services/classifier/classifier.service';
import { CustomValidators } from 'src/app/validators/customValidator';
import { CommonConstants } from 'src/app/shared/common-constants';
import { DeviceService } from 'src/app/shared/services/device/device.service';

@Component({
  selector: 'app-add-classifier',
  templateUrl: './add-classifier.component.html',
  styleUrls: ['./add-classifier.component.scss'],
})
export class AddClassifierComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public selectedImage: any = '';
  public joiningDate: any = '';
  public addClassifierForm: any;
  public categories: any = [];
  public allowedExtension = ['tflite'];
  public isProcessing: boolean = false;
  public fileName: string = '';
  public mlFile: any = '';
  public fileSize: number = 0;
  public encrypedFile: any = '';
  public decryptedFile: any = '';
  public showFile: boolean = false;
  public selectedFileName: string = '';

  public selectedCategory: any = [];
  public selectedDetectorTypeIds: any = [];
  public selectedOrderedDetectorTypeIds: any = [];
  public selectedDeviceTypeIds: any = [];
  public selectedFirmwareIds: any = [];
  public selectedUsecaseIds: any = [];
  public selectedSubstanceIds: any = [];
  public selectedOrderedSubstanceIds: any = [];
  public selectedMeasurementType: string = '';
  public selectedisPublic: boolean = false;
  public selectedIsActive: boolean = false;

  public isCategoryChanged = false;
  public isDetectorTypesChanged = false;
  public isDeviceTypesChanged = false;
  public isFirmwaresChanged = false;
  public isUsecasesChanged = false;
  public isSubstancesChanged = false;

  public listCategory: any = [];
  public listDetectorTypeIds: any = [];
  public listDeviceTypeIds: any = [];
  public listFirmwareIds: any = [];
  public listUsecaseIds: any = [];
  public listSubstanceIds: any = [];
  public listMeasurementTypes: any = [];

  public MLClassifierInfo: any = {};
  public deviceTypeDropDownSearch: FormControl = new FormControl();
  public filteredDeviceTypes: any = [];
  public categorySearch: FormControl = new FormControl();
  public filteredCategories: any = [];
  public filteredDetectorTypes: any = [];
  public detectorTypeSearch: FormControl = new FormControl();
  public filteredSmells: any = [];
  public smellDropDownSearch: FormControl = new FormControl();
  public filteredUsecases: any = [];
  public usecaseDropDownSearch: FormControl = new FormControl();
  public filteredFirmwares: any = [];
  public firmwareSearch: FormControl = new FormControl();
  public invalidFile: boolean = false;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private classifierService: ClassifiersService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ClassifiersComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddContentForm();
    this.getMLClassifierFilters();
    this.setPatchValues();
    this.deviceTypeDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredDeviceTypes = this.listDeviceTypeIds.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.categorySearch.valueChanges.subscribe((val) => {
      this.filteredCategories = this.listCategory.filter((option: any) =>
        option.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.detectorTypeSearch.valueChanges.subscribe((val) => {
      this.filteredDetectorTypes = this.listDetectorTypeIds.filter(
        (option: any) => {
          return (
            option.name.toLowerCase().includes(val?.toLowerCase()) ||
            `${option.id}`.includes(val)
          );
        }
      );
    });
    this.smellDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredSmells = this.listSubstanceIds.filter((option: any) => {
        return (
          option.name.toLowerCase().includes(val?.toLowerCase()) ||
          `${option.id}`.includes(val)
        );
      });
    });
    this.usecaseDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredUsecases = this.listUsecaseIds.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.firmwareSearch.valueChanges.subscribe((val) => {
      this.filteredFirmwares = this.listFirmwareIds.filter((option: any) =>
        option.supported_firmware?.name
          ?.toLowerCase()
          .includes(val?.toLowerCase())
      );
    });
  }

  // convenience getter for easy access to form fields
  get addClassifierControls() {
    return this.addClassifierForm.controls;
  }

  resetAddContentForm() {
    const groupObj: any = {
      name: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
      version: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
      description: new FormControl(
        this.local_data.description,
        Validators.maxLength(CommonConstants.maxLength5000)
      ),
      category: [null, Validators.compose([Validators.required])],
      detectorTypeIds: [null, Validators.compose([Validators.required])],
      deviceTypeIds: [null, Validators.compose([Validators.required])],
      firmwareIds: [null, Validators.compose([Validators.required])],
      usecaseIds: [null, Validators.compose([])],
      substanceIds: [null, Validators.compose([Validators.required])],
      monitoringShortMeasurement: [null, Validators.compose([])],
      isPublic: [null, Validators.compose([Validators.required])],
      model_type: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
      pb_formatted_model_path: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength5000),
        ]),
      ],
      sg_endpoint: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength5000),
        ]),
      ],
      is_active: [null, Validators.compose([Validators.required])],
      model_functionalization: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength5000),
        ]),
      ],
      model_information: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidJSONString,
        ]),
      ],
      window_size: [null, Validators.compose([Validators.required])],
      model_requirement_info: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidJSONString,
        ]),
      ],
    };
    if (this.action === 'Add') {
      groupObj.mlModel = [null, Validators.compose([Validators.required])];
    } else if (this.action === 'Update') {
      groupObj.mlModel = [null, Validators.compose([])];
    }

    this.addClassifierForm = this.fb.group(groupObj);
  }

  onSelectChange(eve: any, name: string, value?: any) {
    if (eve && eve.isUserInput) {
      if (name === 'category') {
        this.isCategoryChanged = true;
      } else if (name === 'detectorTypeIds') {
        this.isDetectorTypesChanged = true;
        if (value) {
          if (eve.source._selected) {
            this.selectedOrderedDetectorTypeIds.push(value);
          } else {
            const atIndex = this.selectedOrderedDetectorTypeIds.findIndex(
              (d: any) => {
                return d === value;
              }
            );
            if (atIndex > -1) {
              this.selectedOrderedDetectorTypeIds.splice(atIndex, 1);
            }
          }
          this.setDetectorTypeIdsinOrder();
        }
      } else if (name === 'deviceTypeIds') {
        this.isDeviceTypesChanged = true;
        this.isFirmwaresChanged = true;
      } else if (name === 'firmwareIds') {
        this.isFirmwaresChanged = true;
      } else if (name === 'usecaseIds') {
        this.isUsecasesChanged = true;
      } else if (name === 'substanceIds') {
        this.isSubstancesChanged = true;
        if (value) {
          if (eve.source._selected) {
            this.selectedOrderedSubstanceIds.push(value);
          } else {
            const atIndex = this.selectedOrderedSubstanceIds.findIndex(
              (d: any) => {
                return d === value;
              }
            );
            if (atIndex > -1) {
              this.selectedOrderedSubstanceIds.splice(atIndex, 1);
            }
          }
          this.setSubstanceIdsinOrder();
        }
      }
    }
  }

  async getMLClassifierDetails(classifierId: string) {
    const result = await this.classifierService.getMLClassifierDetail(
      classifierId
    );
    return result;
  }

  setSubstanceIdsinOrder() {
    this.selectedOrderedSubstanceIds.map((id: any, currentIndex: any) => {
      const i = this.filteredSmells.findIndex((f: any) => {
        return f.id === id;
      });

      if (i > -1) {
        const element = this.filteredSmells[i];
        this.filteredSmells.splice(i, 1);
        this.filteredSmells.splice(currentIndex, 0, element);
      }

      const j = this.listSubstanceIds.findIndex((f: any) => {
        return f.id === id;
      });

      if (j > -1) {
        const element = this.listSubstanceIds[j];
        this.listSubstanceIds.splice(j, 1);
        this.listSubstanceIds.splice(currentIndex, 0, element);
      }
    });
  }

  setDetectorTypeIdsinOrder() {
    this.selectedOrderedDetectorTypeIds.map((id: any, currentIndex: any) => {
      const i = this.filteredDetectorTypes.findIndex((f: any) => {
        return f.id === id;
      });

      if (i > -1) {
        const element = this.filteredDetectorTypes[i];
        this.filteredDetectorTypes.splice(i, 1);
        this.filteredDetectorTypes.splice(currentIndex, 0, element);
      }

      const j = this.listDetectorTypeIds.findIndex((f: any) => {
        return f.id === id;
      });

      if (j > -1) {
        const element = this.listDetectorTypeIds[j];
        this.listDetectorTypeIds.splice(j, 1);
        this.listDetectorTypeIds.splice(currentIndex, 0, element);
      }
    });
  }

  async setPatchValues() {
    const { data } = await this.deviceService.getDeviceTypeFilter();
    if (data) {
      this.listDeviceTypeIds = data;
      this.filteredDeviceTypes = [...this.listDeviceTypeIds];
    }
    if (this.action === 'Update') {
      const classifierId = this.local_data.id;
      const getDetail = await this.getMLClassifierDetails(classifierId);
      this.MLClassifierInfo = getDetail.data.mlClassifierInfo;
      if (
        this.MLClassifierInfo.ml_classifier_device_types &&
        this.MLClassifierInfo.ml_classifier_device_types.length > 0
      ) {
        const devicetypeIds = this.MLClassifierInfo.ml_classifier_device_types
          .map((c: any) => {
            return c.id;
          })
          .join(',');

        const firmwares = await this.deviceService.getDeviceTypeFilter(
          `device_type_id=${devicetypeIds}`
        );
        this.listFirmwareIds = firmwares.data;
        this.filteredFirmwares = [...this.listFirmwareIds];
      }
      this.local_data = getDetail.data.mlClassifierInfo;
      this.local_data.window_size =
        this.local_data.model_information.window_size;
      this.local_data.model_information_str = JSON.stringify(
        this.local_data.model_information
      );
      this.local_data.model_requirement_info_str = JSON.stringify(
        this.local_data.model_requirement_info
      );
      this.addClassifierForm.patchValue({
        name: this.MLClassifierInfo.name,
        description: this.MLClassifierInfo.description,
        version: this.MLClassifierInfo.version,
      });
      this.selectedFileName = `...${this.MLClassifierInfo.model_path.substr(
        this.MLClassifierInfo.model_path.length - 40,
        40
      )}`;
      this.selectedCategory =
        this.MLClassifierInfo.ml_classifier_category_info.map((c: any) => {
          return c.category;
        });
      this.selectedDetectorTypeIds = this.MLClassifierInfo.detector_type_ids;
      this.selectedOrderedDetectorTypeIds =
        this.MLClassifierInfo.detector_type_ids;
      this.setDetectorTypeIdsinOrder();
      this.selectedDeviceTypeIds =
        this.MLClassifierInfo.ml_classifier_device_types.map((c: any) => {
          return c.id;
        });
      if (
        this.MLClassifierInfo.ml_classifier_firmwares &&
        this.MLClassifierInfo.ml_classifier_firmwares.length > 0
      ) {
        this.selectedFirmwareIds =
          this.MLClassifierInfo.ml_classifier_firmwares.map((c: any) => {
            return c.id;
          });
      }
      if (
        this.MLClassifierInfo.ml_classifier_usecases &&
        this.MLClassifierInfo.ml_classifier_usecases.length > 0
      ) {
        this.selectedUsecaseIds =
          this.MLClassifierInfo.ml_classifier_usecases.map((c: any) => {
            return c.usecase_id;
          });
      }
      this.selectedSubstanceIds =
        this.MLClassifierInfo.ml_classifier_substances.map((c: any) => {
          return c.substance_id;
        });
      this.selectedOrderedSubstanceIds =
        this.MLClassifierInfo.ml_classifier_substances.map((c: any) => {
          return c.substance_id;
        });
      this.setSubstanceIdsinOrder();
      if (this.MLClassifierInfo.monitoring_short_measurement) {
        this.selectedMeasurementType =
          this.MLClassifierInfo.monitoring_short_measurement;
      }
      this.selectedisPublic = this.MLClassifierInfo.is_public;
      this.selectedIsActive = this.MLClassifierInfo.is_active;
    }
  }

  async doAction() {
    await this.submitClassifierForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async getMLClassifierFilters() {
    try {
      this.isProcessing = true;
      const res = await this.classifierService.getMLClassifierFilters();
      if (res && res.data.filterTerms) {
        const { filterTerms } = res.data;
        this.listCategory = filterTerms.categories;
        this.filteredCategories = [...this.listCategory];

        this.listDetectorTypeIds = filterTerms.detectorTypes;
        this.filteredDetectorTypes = [...this.listDetectorTypeIds];

        // this.listDeviceTypeIds = filterTerms.deviceTypes;
        // this.filteredDeviceTypes = [...this.listDeviceTypeIds];

        // this.listFirmwareIds = filterTerms.firmwares;
        // this.filteredFirmwares = [...this.listFirmwareIds];

        this.listUsecaseIds = filterTerms.usecases;
        this.filteredUsecases = [...this.listUsecaseIds];

        this.listSubstanceIds = filterTerms.smells;
        this.filteredSmells = [...this.listSubstanceIds];

        this.listMeasurementTypes = filterTerms.measurementTypes;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async onSelect(data: any) {
    this.invalidFile = false;
    this.fileName = '';
    this.fileSize = 0;
    this.mlFile = null;
    const fileObj = data[0];
    if (fileObj) {
      this.mlFile = fileObj;
      // const fileExtension: any = fileObj.name.split('.').pop();
      // if (!this.allowedExtension.includes(fileExtension)) {
      //   this.addClassifierControls.mlModel.status = 'INVALID';
      //   this.invalidFile = true;
      // }
      this.fileName = fileObj.name;
      this.fileSize = +(fileObj.size / 1024).toFixed(2);
      // Below code will encrypt file
      // const base64data = await this.helperService.fileToBase64(fileObj);
      // this.encrypedFile = this.helperService.encryptData(
      //   JSON.stringify(base64data)
      // );
      // this.decryptedFile = base64data;
    }
  }

  async submitClassifierForm() {
    if (this.addClassifierForm.valid) {
      try {
        this.isProcessing = true;
        let res: any;
        const reqData: any = {
          name: this.addClassifierForm.controls.name.value,
          version: this.addClassifierForm.controls.version.value,
          model_type: this.addClassifierForm.controls.model_type.value,
          pb_formatted_model_path:
            this.addClassifierForm.controls.pb_formatted_model_path.value,
          sg_endpoint: this.addClassifierForm.controls.sg_endpoint.value,
          model_functionalization:
            this.addClassifierForm.controls.model_functionalization.value,
          model_information: {
            ...JSON.parse(
              this.addClassifierForm.controls.model_information.value
            ),
            window_size: this.addClassifierForm.controls.window_size.value,
          },
          model_requirement_info: {
            ...JSON.parse(
              this.addClassifierForm.controls.model_requirement_info.value
            ),
          },
          is_public: this.selectedisPublic,
          is_active: this.selectedIsActive,
          category: this.selectedCategory,
          detector_type_ids: this.selectedOrderedDetectorTypeIds,
          device_type_ids: this.selectedDeviceTypeIds,
          substanceIds: this.selectedOrderedSubstanceIds,
        };
        // Optional fields will be add if it has value
        if (this.addClassifierForm.controls.description.value) {
          reqData.description =
            this.addClassifierForm.controls.description.value;
        }
        if (this.addClassifierForm.controls.monitoringShortMeasurement.value) {
          reqData.monitoring_short_measurement =
            this.addClassifierForm.controls.monitoringShortMeasurement.value;
        }
        if (this.selectedUsecaseIds && this.selectedUsecaseIds.length) {
          reqData.usecaseIds = this.selectedUsecaseIds;
        }
        if (this.selectedFirmwareIds && this.selectedFirmwareIds.length) {
          reqData.firmware_ids = this.selectedFirmwareIds;
        }
        if (this.addClassifierForm.controls.model_requirement_info.value) {
          reqData.model_requirement_info = {
            ...JSON.parse(
              this.addClassifierForm.controls.model_requirement_info.value
            ),
          };
        }
        if (this.action === 'Add') {
          const params = `fileName=${this.fileName}&mimeType=application/octet-stream`;
          const getThumbUrl = await this.classifierService.getPreSignedURL(
            params
          );
          const thumbURL = getThumbUrl.data.url;
          const fileURL = getThumbUrl.data.fileName;
          reqData.fileLink = fileURL;
          reqData.model_path = fileURL;
          reqData.file_size = this.fileSize;
          await this.classifierService.uploadFiles3Sync(thumbURL, this.mlFile);
          res = await this.classifierService.addMLClassifier(reqData);
          this.local_data.id = res.data.id;
          this.local_data.is_public = res.data.is_public;
          this.local_data.createdAt = res.data.createdAt;
          this.local_data.updatedAt = res.data.updatedAt;
        } else if (this.action === 'Update') {
          if (this.fileName && this.fileSize) {
            const params = `fileName=${this.fileName}&mimeType=application/octet-stream`;
            const getThumbUrl = await this.classifierService.getPreSignedURL(
              params
            );
            const thumbURL = getThumbUrl.data.url;
            const fileURL = getThumbUrl.data.fileName;
            reqData.fileLink = fileURL;
            reqData.model_path = fileURL;
            reqData.file_size = this.fileSize;
            await this.classifierService.uploadFiles3Sync(
              thumbURL,
              this.mlFile
            );
          } else {
            reqData.fileLink = this.MLClassifierInfo.file_link;
            reqData.model_path = this.MLClassifierInfo.model_path;
            reqData.file_size = this.MLClassifierInfo.file_size;
          }
          reqData.isCategoryChanged = this.isCategoryChanged;
          reqData.isDetectorTypesChanged = this.isDetectorTypesChanged;
          reqData.isDeviceTypesChanged = this.isDeviceTypesChanged;
          reqData.isFirmwaresChanged = this.isFirmwaresChanged;
          reqData.isUsecasesChanged = this.isUsecasesChanged;
          reqData.isSubstancesChanged = this.isSubstancesChanged;

          const classifierId = this.local_data.id;
          res = await this.classifierService.editMLClassifier(
            reqData,
            classifierId
          );
          const { data } = await this.getMLClassifierDetails(classifierId);
          this.local_data.data = data.mlClassifierInfo;
        }
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }

  validNumber(event: any) {
    let value: any = +event.target.value;
    if (value) {
      if (value < 0) {
        value = value * -1;
      }
      value = parseInt(value, 10);
      event.target.value = value;
      this.local_data.window_size = value;
    }
  }

  validJson(eve: any, name: string) {
    if (eve.target.value) {
      const isInValid = CustomValidators.isValidJSONString(
        this.addClassifierControls[name]
      );
      if (isInValid) {
        this.addClassifierControls[`${name}`].setErrors({ invalidJSON: true });
      } else {
        this.addClassifierControls[`${name}`].setErrors(null);
      }
    }
  }

  validDescription(eve: any, name: string) {
    if (eve.target.value) {
      const iswhiteSpace = CustomValidators.noWhitespaceValidator(
        this.addClassifierControls[name]
      );
      if (
        iswhiteSpace &&
        eve.target.value.length > CommonConstants.maxLength5000
      ) {
        this.addClassifierControls[`${name}`].setErrors({ invalidStr: true });
      } else if (iswhiteSpace) {
        this.addClassifierControls[`${name}`].setErrors({ invalidStr: true });
      } else if (eve.target.value.length > CommonConstants.maxLength5000) {
        this.addClassifierControls[`${name}`].setErrors({ maxLength: true });
      } else {
        this.addClassifierControls[`${name}`].setErrors(null);
      }
    }
  }

  async getFirmwares(event: any) {
    try {
      if (event.value && event.value.length > 0) {
        this.isProcessing = true;
        const firmwares = await this.deviceService.getDeviceTypeFilter(
          `device_type_id=${event.value}`
        );
        this.listFirmwareIds = firmwares.data;
        this.filteredFirmwares = [...this.listFirmwareIds];
        this.selectedFirmwareIds = this.listFirmwareIds.map((l: any) => {
          return l.supported_firmware.id;
        });
      } else if (event.value && event.value.length === 0) {
        // Here it comes when admin deselect all devicetype
        this.listFirmwareIds = [];
        this.filteredFirmwares = [...this.listFirmwareIds];
        this.selectedFirmwareIds = [];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  cancelFileUpload() {
    this.showFile = !this.showFile;
    if (this.action === 'Update') {
      this.addClassifierControls.mlModel.setErrors(null);
      this.addClassifierControls.mlModel.markAsUntouched();
      this.addClassifierControls.mlModel.markAsPristine();
    }
  }
}
