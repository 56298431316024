export class CommonMessages {
  static USE_CASE_ADDED = 'Use case added successfully.';
  static USE_CASE_UPDATED = 'Use case updated successfully.';
  static USE_CASE_DELETD = 'Use case deleted successfully.';
  static MEASUREMENT_SETUP_ADDED = 'Measurement Setup added successfully.';
  static MEASUREMENT_SETUP_UPDATED = 'Measurement Setup updated successfully.';
  static MEASUREMENT_SETUP_DELETD = 'Measurement Setup deleted successfully.';
  static NO_ADMIN_USER =
    'There is no account associated with the entered email address. Please check that you have typed your email correctly.';
}
