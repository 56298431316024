import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { CategoriesComponent } from '../categories/categories.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FaqService } from 'src/app/shared/services/faq/faq.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/customValidator';
import { CommonConstants } from 'src/app/shared/common-constants';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss'],
})
export class CategoryDialogComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public selectedImage: any = '';
  public joiningDate: any = '';
  public addCategoryForm: any;
  public isProcessing = false;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private faqService: FaqService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CategoriesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddContentForm();
  }

  // convenience getter for easy access to form fields
  get addCategoryControls() {
    return this.addCategoryForm.controls;
  }

  resetAddContentForm() {
    this.addCategoryForm = this.fb.group({
      category: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
    });
  }

  async doAction() {
    await this.submitCategoryForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitCategoryForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const deleteURL = `faqCategoryId=${this.local_data.id}`;
        const res = await this.faqService.faqDeleteCategory(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else {
      if (this.addCategoryForm.valid) {
        try {
          this.isProcessing = true;
          const reqData: any = {
            category: this.local_data.category,
          };
          if (this.action === 'Update') {
            reqData['faqCategoryId'] = this.local_data.id;
          }
          let res: any;
          if (this.action === 'Add') {
            res = await this.faqService.faqAddCategory(reqData);
            this.local_data.id = res.data.id;
            this.local_data.createdAt = res.data.createdAt;
          } else if (this.action === 'Update') {
            res = await this.faqService.faqUpdateCategory(reqData);
          }
          if (res) {
            this.helperService.showAlert(res.msg || 'Success', 'success');
          }
          this.dialogRef.close({
            event: this.action,
            data: this.local_data,
            success: true,
          });
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        } finally {
          this.isProcessing = false;
        }
      }
    }
  }
}
