import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DemoMaterialModule } from '../demo-material-module';

import { RouterModule } from '@angular/router';
import { ChartistModule } from 'ng-chartist';
import { ChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ImportDetectorBoardRoutingModule } from './import-detector-board-routing.module';

import { MatSelectModule } from '@angular/material/select';
import { ImportedDetectorBoardsComponent } from './imported-detector-boards/imported-detector-boards.component';
import { ImportDetectorBoardsComponent } from './import-detector-boards/import-detector-boards.component';

@NgModule({
  declarations: [
    ImportedDetectorBoardsComponent,
    ImportDetectorBoardsComponent,
  ],
  imports: [
    CommonModule,
    ImportDetectorBoardRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule,
  ],
})
export class ImportDetectorBoardModule {}
