<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>Reset Password</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3">
  <form [formGroup]="userResetPwdForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">

        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-30">
            <input required matInput [type]="hidePwd ? 'password' : 'text'" placeholder="New Password"
              formControlName="password">
            <mat-icon style="cursor: pointer;" matSuffix (click)="hidePwd = !hidePwd">
              {{hidePwd ? 'visibility' : 'visibility_off'}}
            </mat-icon>
            <mat-error *ngIf="!userResetPasswordControls.password.value && userResetPwdForm.get('password').touched"
              class="text-danger">Password is required</mat-error>
            <mat-error
              *ngIf="userResetPwdForm.get('password').invalid && !userResetPasswordControls.password.errors.required && userResetPwdForm.get('password').touched"
              class="text-danger">Please enter a password at least 8 character and contain at least one
              uppercase, at least one lower case and at least one special character!</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field class="m-b-30">
            <input required matInput [type]="hideCfmPwd ? 'password' : 'text'" placeholder="Confirm Password"
              formControlName="confPassword">
            <mat-icon style="cursor: pointer;" matSuffix (mousedown)="hideCfmPwd = !hideCfmPwd"
              (keyup.enter)="$event.preventDefault()">
              {{hideCfmPwd ? 'visibility' : 'visibility_off'}}
            </mat-icon>
            <mat-error
              *ngIf="!userResetPasswordControls.confPassword.value && userResetPwdForm.get('confPassword').touched"
              class="text-danger">Confirm password is required</mat-error>
            <mat-error
              *ngIf="userResetPwdForm.get('confPassword').invalid && !userResetPasswordControls.confPassword.errors.required && userResetPwdForm.get('confPassword').touched"
              class="text-danger">Password and Confirm Password must be match.</mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>
    <button mat-button (click)="submitResetPwdForm(userResetPwdForm.value)" mat-flat-button color="primary"
      [disabled]="!userResetPwdForm.valid">Submit</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
