export class CommonConstants {
  static maxLength60 = 60;
  static maxIntLength = 2147483647;
  static maxLength62 = 62;
  static maxLength100 = 100;
  static maxLength250 = 250;
  static maxLength5000 = 5000;
  static FUNCTIONALISATIONVALUES =
    // eslint-disable-next-line max-len
    '999;999;69;69;69;41;41;41;40;40;40;33;33;33;999;999;999;999;999;999;999;61;61;61;47;47;47;43;43;43;999;999;999;999;90;90;90;67;67;67;53;53;53;42;42;42;999;999;999;999;94;94;94;89;89;89;85;85;85;59;59;59;999;999';
  static TOTALCHECKINMAXFAILUREATTEMPT = 5;
  static issueStatus = {
    OPEN: 'open',
    INPROGRESS: 'in_progress',
    CLOSED: 'closed',
  };
}
