import { Injectable } from '@angular/core';
import { LocalStorageEnums } from './localStorageEnums';

@Injectable(
  {providedIn: 'root'}
)
export class LocalStorageService {

  constructor() { }

  clear() {
    localStorage.clear();
  }

  getUserToken() {
    return localStorage.getItem('token');
  }

  getUserDetails() {
    return localStorage.getItem('userDetails');
  }

  setUserDetails(userInfo: any) {
    return localStorage.setItem(LocalStorageEnums.userInfo, JSON.stringify(userInfo));
  }

  setLoginInfo(loginInfo: any) {
    return localStorage.setItem(LocalStorageEnums.loginInfo, JSON.stringify(loginInfo));
  }

  getLoginInfo() {
    const loginInfo = localStorage.getItem(LocalStorageEnums.loginInfo);
    return JSON.parse(loginInfo || '{}');
  }

  // tslint:disable-next-line:typedef
  getSessionId() {
    const userDetails = this.getUserToken();
    if (userDetails) {
      return userDetails;
    }
    return false;
  }

}
