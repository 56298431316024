import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class UsecaseService {
  constructor(private apiService: APIService) {}

  getUsecases(params: any) {
    const url = `/usecase?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getUsecases');
  }

  getUsecaseInfo(params: any): any {
    const url = `/usecase/${params}`;
    return this.apiService.getWithAuthHandler(url);
  }

  getUsecaseFilters() {
    const url = '/usecase/filters';
    return this.apiService.getWithAuthHandler(url, true, 'getUsecaseFilters');
  }

  addUsecase(body: any): any {
    const url = '/usecase';
    return this.apiService.postWithAuthHandler(url, body);
  }

  updateUsecase(body: any): any {
    const url = `/usecase/${body.id}`;
    return this.apiService.putWithAuthHandler(url, body);
  }

  removeUsecase(params: any): any {
    const url = `/usecase/${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }
}
