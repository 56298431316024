import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DevicesComponent } from '../devices/devices.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { CustomValidators } from 'src/app/validators/customValidator';
import { CommonConstants } from 'src/app/shared/common-constants';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public action: string = 'Add';
  public local_data: any;
  public addDeviceForm: any;
  public isProcessing: boolean = false;
  public deviceTypes: any = [];
  public firmwares: any = [];
  public detectors: any = [];
  public detector_types: any = [];
  public detector_options: any = [];
  public detectorPositions = [
    {
      detector_position: 'X1',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
    {
      detector_position: 'X2',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
    {
      detector_position: 'X3',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
    {
      detector_position: 'X4',
      detector_id: null,
      batch_id: null,
      slot_id: null,
      production_detector_id: null,
      detector_info: null,
      isExist: false,
      detectorType: null,
      selectedDetectorType: null,
    },
  ];
  public detectorPositionTable: any = {
    X1: [],
    X2: [],
    X3: [],
    X4: [],
  };
  public detectorPositionColumns: any = ['key', 'value'];
  public deviceBlock: any = [];
  public deviceBlockColumns: any = ['key', 'value'];
  public deviceTypeDropDownSearch: FormControl = new FormControl();
  public filteredDeviceTypes: any = [];
  public filteredFirmwares: any = [];
  public firmwareSearch: FormControl = new FormControl();
  public filteredDetectorPositions: any = [];
  public detectorPositionSearch: FormControl = new FormControl();
  public positionErrors: any = {
    X1: {
      error: null,
    },
    X2: {
      error: null,
    },
    X3: {
      error: null,
    },
    X4: {
      error: null,
    },
    detector_board_id: {
      error: null,
    },
  };
  public detectorTypeSearch: any = new FormControl();
  public filteredDetectorTypes: any = [];
  public showDetectorBoard: boolean = false;
  public isBoardData: boolean = false;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private deviceService: DeviceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DevicesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }

  ngOnInit() {
    this.resetAddDeviceType();
    this.setPatchValues();
    this.deviceTypeDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredDeviceTypes = this.deviceTypes.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });

    this.firmwareSearch.valueChanges.subscribe((val) => {
      this.filteredFirmwares = this.firmwares.filter((option: any) =>
        option.supported_firmware?.name
          ?.toLowerCase()
          .includes(val?.toLowerCase())
      );
    });

    this.detectorPositionSearch.valueChanges.subscribe((val) => {
      this.filteredDetectorPositions = this.detector_options.filter(
        (option: any) =>
          option.batch_id?.toLowerCase().includes(val?.toLowerCase()) ||
          option.slot_id?.toLowerCase().includes(val?.toLowerCase()) ||
          option.production_detector_id
            ?.toLowerCase()
            .includes(val?.toLowerCase()) ||
          option.detector_type?.name?.toLowerCase().includes(val?.toLowerCase())
      );
    });

    this.detectorTypeSearch.valueChanges.subscribe((val: any) => {
      this.filteredDetectorTypes = this.detector_types.filter((option: any) => {
        return option.name.toLowerCase().includes(val?.toLowerCase());
      });
    });
  }

  // convenience getter for easy access to form fields
  get addDeviceControls() {
    return this.addDeviceForm.controls;
  }

  resetPositions() {
    this.detectorPositions.forEach((d: any) => {
      d.detector_id = null;
      d.batch_id = null;
      d.slot_id = null;
      d.production_detector_id = null;
      d.detector_info = null;
      d.isExist = false;
      d.detectorType = null;
    });
    Object.values(this.positionErrors).forEach((f: any) => {
      f.error = null;
    });
  }

  change(event: any, key: string) {
    this.filteredDetectorTypes = [...this.detector_types];
    this.detectorTypeSearch.value = null;
    const detectorTypeIndex = this.detectorPositionTable[key].findIndex(
      (d: any) => {
        return d.key === 'Detector Type';
      }
    );
    this.detectorPositionTable[key][detectorTypeIndex].value = event.value.name;
    const isNewIndex = this.detectorPositionTable[key].findIndex((d: any) => {
      return d.key === 'Is New';
    });
    this.detectorPositionTable[key][isNewIndex].value = true;
  }

  resetAddDeviceType() {
    const groupObj: any = {
      mac_address: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidMacAdderess,
        ]),
      ],
      serial_number: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength60),
        ]),
      ],
      device_type_id: [null, Validators.compose([Validators.required])],
      firmware_id: [null, Validators.compose([Validators.required])],
      X1: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X1_DETECTOR_TYPE: [null, Validators.compose([])],
      X2: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X2_DETECTOR_TYPE: [null, Validators.compose([])],
      X3: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X3_DETECTOR_TYPE: [null, Validators.compose([])],
      X4: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.isValidDetectorInfo,
        ]),
      ],
      X4_DETECTOR_TYPE: [null, Validators.compose([])],
      detector_board_id: [null, Validators.compose([])],
    };
    this.addDeviceForm = this.fb.group(groupObj);
  }

  async getDetectorTypes() {
    try {
      let detectorTypeURL = `page=${0}&perPage=${0}`;
      const result = await this.deviceService.getDetectorTypes(detectorTypeURL);
      if (result) {
        this.detector_types = result.data.detectorTypes;
        this.filteredDetectorTypes = [...this.detector_types];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  isPositionPlaced() {
    return (
      this.detectorPositions.findIndex((d: any) => {
        return !!d.detector_info;
      }) === -1
    );
  }

  async getDeviceDetails(deviceId: string) {
    try {
      this.isProcessing = true;
      const result = await this.deviceService.getDeviceDetails(deviceId);
      return result;
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getFirmwares(event: any) {
    try {
      const deviceTypeIndex = this.filteredDeviceTypes.findIndex((f: any) => {
        return f.id === event.value;
      });
      this.showDetectorBoard =
        this.filteredDeviceTypes[deviceTypeIndex].is_contain_sensor_board;
      // If already selected a detctor board and then change the device_type
      if (this.isBoardData && !this.showDetectorBoard) {
        this.detectorPositions = [
          {
            detector_position: 'X1',
            detector_id: null,
            batch_id: null,
            slot_id: null,
            production_detector_id: null,
            detector_info: null,
            isExist: false,
            detectorType: null,
            selectedDetectorType: null,
          },
          {
            detector_position: 'X2',
            detector_id: null,
            batch_id: null,
            slot_id: null,
            production_detector_id: null,
            detector_info: null,
            isExist: false,
            detectorType: null,
            selectedDetectorType: null,
          },
          {
            detector_position: 'X3',
            detector_id: null,
            batch_id: null,
            slot_id: null,
            production_detector_id: null,
            detector_info: null,
            isExist: false,
            detectorType: null,
            selectedDetectorType: null,
          },
          {
            detector_position: 'X4',
            detector_id: null,
            batch_id: null,
            slot_id: null,
            production_detector_id: null,
            detector_info: null,
            isExist: false,
            detectorType: null,
            selectedDetectorType: null,
          },
        ];
        this.isBoardData = false;
        this.local_data.detector_board_id = null;
        this.addDeviceForm.controls.detector_board_id.value = null;
        this.positionErrors.detector_board_id.error = null;
        this.addDeviceControls.detector_board_id.setErrors(null);
      }
      // Set sensor board id is required or not based on selected device type
      if (this.showDetectorBoard && !this.isBoardData) {
        // add control
        this.addDeviceForm.addControl(
          'detector_board_id',
          new FormControl(Validators.required)
        );
      }
      if (!this.showDetectorBoard) {
        // remove control
        this.addDeviceForm.get('detector_board_id').reset();
        this.addDeviceForm.removeControl('detector_board_id');
      }
      this.addDeviceForm.controls.firmware_id.value = null;
      this.isProcessing = true;
      const firmwares = await this.deviceService.getDeviceTypeFilter(
        `device_type_id=${event.value}`
      );
      this.firmwares = firmwares.data;
      this.filteredFirmwares = [...this.firmwares];
      if (firmwares.data.length) {
        const latestFirmwareId = this.firmwares[0].supported_firmware.id;
        this.addDeviceForm.patchValue({
          firmware_id: latestFirmwareId,
        });
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async setPatchValues() {
    try {
      this.isProcessing = true;
      if (this.action !== 'View') {
        await this.getDetectorTypes();
        const { data } = await this.deviceService.getDeviceTypeFilter();
        this.deviceTypes = data;
        this.filteredDeviceTypes = [...this.deviceTypes];
        const detectors = await this.deviceService.getDeviceTypeFilter(
          'getDetector=true'
        );
        this.detectors = detectors.data;
        this.detector_options = detectors.data;
        this.filteredDetectorPositions = [...this.detector_options];
      }
      if (this.action === 'Add') {
        const latestDeviceTypeId = this.deviceTypes[0].id;
        this.showDetectorBoard = this.deviceTypes[0].is_contain_sensor_board;
        const firmwares = await this.deviceService.getDeviceTypeFilter(
          `device_type_id=${latestDeviceTypeId}`
        );
        this.firmwares = firmwares.data;
        this.filteredFirmwares = [...this.firmwares];
        if (firmwares.data.length) {
          const latestFirmwareId = this.firmwares[0].supported_firmware.id;
          this.addDeviceForm.patchValue({
            device_type_id: latestDeviceTypeId,
            firmware_id: latestFirmwareId,
          });
        }
      } else if (this.action === 'Update' || this.action === 'View') {
        const device_id = this.local_data.id;
        const { data } = await this.getDeviceDetails(device_id);
        const pactchValues: any = {
          mac_address: data.deviceInfo.mac_address,
          serial_number: data.deviceInfo.serial_number,
          device_type_id: data.deviceInfo.device_type_id,
          firmware_id: data.deviceInfo.firmware_id,
        };
        if (data && data.boardInfo && Object.keys(data.boardInfo).length > 0) {
          pactchValues.detector_board_id = data.boardInfo.detector_board_id;
          this.local_data.detector_board_id = data.boardInfo.detector_board_id;
          this.isBoardData = true;
          this.showDetectorBoard = true;
        }
        if (this.action !== 'View') {
          const firmwares = await this.deviceService.getDeviceTypeFilter(
            `device_type_id=${data.deviceTypeInfo.id}`
          );
          this.firmwares = firmwares.data;
          this.filteredFirmwares = [...this.firmwares];
          this.addDeviceForm.patchValue(pactchValues);
        }
        data.detectorInfo
          .sort((a: any, b: any) => {
            return a.id - b.id;
          })
          .forEach((d: any) => {
            const i = this.detectorPositions.findIndex((dp: any) => {
              return dp.detector_position === d.detector_position;
            });
            if (i !== -1) {
              const detectorInfo = d.detector;
              this.detectorPositions[i].detector_id = detectorInfo.id;
              this.detectorPositions[i].batch_id = detectorInfo.batch_id;
              this.detectorPositions[i].slot_id = detectorInfo.slot_id;
              this.detectorPositions[i].production_detector_id =
                detectorInfo.production_detector_id;
              const detector_info: any = `${detectorInfo.batch_id} ${detectorInfo.slot_id} ${detectorInfo.production_detector_id}`;
              this.detectorPositions[i].detector_info = null;
              this.detectorPositions[i].isExist = true;
              this.detectorPositions[i].detector_info = detector_info;
              this.detectorPositions[i].detectorType =
                detectorInfo.detector_type;
              const selectedDetectType = this.filteredDetectorTypes.findIndex(
                (f: any) => {
                  return f.id === detectorInfo.detector_type.id;
                }
              );
              this.detectorPositions[i].selectedDetectorType =
                this.filteredDetectorTypes[selectedDetectType];
              this.detectorPositionTable[d.detector_position] = [
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Batch ID',
                  value: detectorInfo.batch_id,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Slot ID',
                  value: detectorInfo.slot_id,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Production Detector ID',
                  value: detectorInfo.production_detector_id,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Detector Type',
                  isNew: false,
                  value: detectorInfo.detector_type.name,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Is New',
                  value: false,
                },
              ];
              this.addDeviceForm.patchValue({
                [d.detector_position]: detector_info,
              });
            }
          });
        const x1Data = [
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Batch ID',
            value: data.detectorInfo[0].detector.batch_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Slot ID',
            value: data.detectorInfo[0].detector.slot_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Production Detector ID',
            value: data.detectorInfo[0].detector.production_detector_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Detector Type',
            value: data.detectorInfo[0].detector.detector_type.name,
          },
        ];
        const x2Data = [
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Batch ID',
            value: data.detectorInfo[1].detector.batch_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Slot ID',
            value: data.detectorInfo[1].detector.slot_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Production Detector ID',
            value: data.detectorInfo[1].detector.production_detector_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Detector Type',
            value: data.detectorInfo[1].detector.detector_type.name,
          },
        ];
        const x3Data = [
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Batch ID',
            value: data.detectorInfo[2].detector.batch_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Slot ID',
            value: data.detectorInfo[2].detector.slot_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Production Detector ID',
            value: data.detectorInfo[2].detector.production_detector_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Detector Type',
            value: data.detectorInfo[2].detector.detector_type.name,
          },
        ];
        const x4Data = [
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Batch ID',
            value: data.detectorInfo[3].detector.batch_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Slot ID',
            value: data.detectorInfo[3].detector.slot_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Production Detector ID',
            value: data.detectorInfo[3].detector.production_detector_id,
          },
          {
            isDate: false,
            showTooltip: true,
            isLink: false,
            key: 'Detector Type',
            value: data.detectorInfo[3].detector.detector_type.name,
          },
        ];
        if (this.action === 'View') {
          this.deviceBlock = [
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'ID',
              value: data.deviceInfo.id,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Mac Adderss',
              value: data.deviceInfo.mac_address,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Serial Number',
              value: data.deviceInfo.serial_number,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Device Type',
              value: `${data.deviceTypeInfo.name}-${data.deviceTypeInfo.version}`,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Firmware',
              value: `${data.firmwareInfo.name}-${data.firmwareInfo.version}`,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Sensor Board Id',
              value: data?.boardInfo?.detector_board_id || '-',
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isObjectValue: true,
              key: 'Detector Position: X1',
              value: {
                detectorInfo: `${data.detectorInfo[0].detector.batch_id} ${data.detectorInfo[0].detector.slot_id} ${data.detectorInfo[0].detector.production_detector_id}`,
                detectorType: data.detectorInfo[0].detector.detector_type.name,
              },
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isObjectValue: true,
              key: 'Detector Position: X2',
              value: {
                detectorInfo: `${data.detectorInfo[1].detector.batch_id} ${data.detectorInfo[1].detector.slot_id} ${data.detectorInfo[1].detector.production_detector_id}`,
                detectorType: data.detectorInfo[1].detector.detector_type.name,
              },
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isObjectValue: true,
              key: 'Detector Position: X3',
              value: {
                detectorInfo: `${data.detectorInfo[2].detector.batch_id} ${data.detectorInfo[2].detector.slot_id} ${data.detectorInfo[2].detector.production_detector_id}`,
                detectorType: data.detectorInfo[2].detector.detector_type.name,
              },
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isObjectValue: true,
              key: 'Detector Position: X4',
              value: {
                detectorInfo: `${data.detectorInfo[3].detector.batch_id} ${data.detectorInfo[3].detector.slot_id} ${data.detectorInfo[3].detector.production_detector_id}`,
                detectorType: data.detectorInfo[3].detector.detector_type.name,
              },
            },
            {
              isDate: true,
              isLink: false,
              key: 'Created At',
              value: data.deviceInfo.createdAt,
            },
            {
              isDate: true,
              isLink: false,
              key: 'Last Updated At',
              value: data.deviceInfo.updatedAt,
            },
          ];
        } else {
          this.deviceBlock = [
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'ID',
              value: data.deviceInfo.id,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Mac Adderss',
              value: data.deviceInfo.mac_address,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Serial Number',
              value: data.deviceInfo.serial_number,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Device Type',
              value: `${data.deviceTypeInfo.name}-${data.deviceTypeInfo.version}`,
            },
            {
              isDate: false,
              showTooltip: true,
              isLink: false,
              key: 'Firmware',
              value: `${data.firmwareInfo.name}-${data.firmwareInfo.version}`,
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isNested: true,
              key: 'Detector Position: X1',
              value: x1Data,
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isNested: true,
              key: 'Detector Position: X2',
              value: x2Data,
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isNested: true,
              key: 'Detector Position: X3',
              value: x3Data,
            },
            {
              isDate: false,
              showTooltip: false,
              isLink: false,
              isNested: true,
              key: 'Detector Position: X4',
              value: x4Data,
            },
            {
              isDate: true,
              isLink: false,
              key: 'Created At',
              value: data.deviceInfo.createdAt,
            },
            {
              isDate: true,
              isLink: false,
              key: 'Last Updated At',
              value: data.deviceInfo.updatedAt,
            },
          ];
        }
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async doAction() {
    await this.submitDeviceTypeForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitDeviceTypeForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const deleteURL = `deviceId=${this.local_data.id}`;
        const res = await this.deviceService.deleteDevice(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else if (this.addDeviceForm.valid) {
      try {
        const newDetectors = this.detectorPositions.filter((s: any) => {
          return !s.detector_id;
        });
        if (newDetectors.length) {
          const detectorIds: any = [];
          newDetectors.map((m: any) => {
            const reqData: any = {
              detector_info: m.detector_info.replace(/\s/g, ''),
              detector_type_id: m.selectedDetectorType.id,
            };
            detectorIds.push(this.deviceService.createDetector(reqData));
          });
          const result = await Promise.all(detectorIds);
          if (result && result.length) {
            result.map((r: any) => {
              const dInfo = `${r.data.batch_id} ${r.data.slot_id} ${r.data.production_detector_id}`;
              const atIndex = this.detectorPositions.findIndex((f: any) => {
                return f.detector_info === dInfo;
              });
              if (atIndex !== -1) {
                this.detectorPositions[atIndex].detector_id = r.data.id;
                this.detectorPositions[atIndex].detectorType =
                  r.data.detector_type.name;
              }
            });
          }
        }
        this.isProcessing = true;
        let res: any;
        const reqData: any = {
          mac_address: this.addDeviceForm.controls.mac_address.value,
          serial_number: this.addDeviceForm.controls.serial_number.value,
          device_type_id: this.addDeviceForm.controls.device_type_id.value,
          firmware_id: this.addDeviceForm.controls.firmware_id.value,
          device_detector_info: this.detectorPositions,
        };
        if (
          this.addDeviceForm.controls.detector_board_id &&
          this.addDeviceForm.controls.detector_board_id.value
        ) {
          reqData.detector_board_id =
            this.addDeviceForm.controls.detector_board_id.value;
        }
        if (this.action === 'Add') {
          res = await this.deviceService.createDevice(reqData);
          this.local_data.data = res.data;
        } else if (this.action === 'Update') {
          const deviceId = this.local_data.id;
          reqData.deviceId = deviceId;
          res = await this.deviceService.editDevice(reqData);
          this.local_data.data = res.data;
        }
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
      } finally {
        this.isProcessing = false;
      }
    }
  }

  async validateDetector(event: any, key: any) {
    if (!this.isBoardData) {
      if (event && event.target.value) {
        event.target.value = event.target.value.replace(/\s/g, '');
        if (event.target.value.length > 14) {
          event.target.value = event.target.value.substr(0, 14);
          event.target.value = `${event.target.value.substr(
            0,
            7
          )} ${event.target.value.substr(7, 2)} ${event.target.value.substr(
            9
          )}`;
        } else {
          if (event.target.value.length > 7 && event.target.value.length <= 9) {
            event.target.value = `${event.target.value.substr(
              0,
              7
            )} ${event.target.value.substr(7, 2)}`;
          } else if (
            event.target.value.length > 7 &&
            event.target.value.length <= 14
          ) {
            event.target.value = `${event.target.value.substr(
              0,
              7
            )} ${event.target.value.substr(7, 2)} ${event.target.value.substr(
              9
            )}`;
          }
        }
        if (this.addDeviceControls[key].status === 'VALID') {
          let senitizedValue = event.target.value;
          senitizedValue = senitizedValue.replace(/\s/g, '');
          if (senitizedValue.length === 14) {
            this.positionErrors[key].error = null;
            const isAlreadyOccupied = this.detectorPositions.findIndex(
              (d: any) => {
                return (
                  d.detector_info === event.target.value &&
                  d.detector_position !== key
                );
              }
            );
            if (isAlreadyOccupied === -1) {
              const batch_id = senitizedValue.substr(0, 7);
              const slot_id = senitizedValue.substr(7, 2);
              const production_detector_id = senitizedValue.substr(9);
              let URL = `detector_info=${senitizedValue}`;
              if (this.action === 'Update') {
                URL += `&deviceId=${this.local_data.id}`;
              }
              const res = await this.deviceService.validateDetectorExistency(
                URL
              );
              if (res && res.data && res.data.isAssociated) {
                this.addDeviceControls[`${key}`].setErrors({ incorrect: true });
                return (this.positionErrors[
                  key
                ].error = `${res.data.errorReason}`);
              }
              const i = this.detectorPositions.findIndex((d: any) => {
                return d.detector_position === key;
              });
              this.detectorPositions[i].detector_info = event.target.value;
              this.detectorPositions[i].batch_id = batch_id;
              this.detectorPositions[i].slot_id = slot_id;
              this.detectorPositions[i].production_detector_id =
                production_detector_id;
              // Reset OLD data
              this.detectorPositions[i].detector_id = null;
              this.detectorPositions[i].detectorType = null;
              this.detectorPositions[i].isExist = false;
              // Reset OLD validations
              Object.keys(this.positionErrors).forEach((f) => {
                const { error } = this.positionErrors[f];
                if (error && error.includes(`${key}`)) {
                  this.positionErrors[`${f}`].error = null;
                  this.addDeviceControls[`${f}`].setErrors(null);
                }
              });
              // Make a table here
              this.detectorPositionTable[key] = [
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Batch ID',
                  value: batch_id,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Slot ID',
                  value: slot_id,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Production Detector ID',
                  value: production_detector_id,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Detector Type',
                  value: null,
                },
                {
                  isDate: false,
                  showTooltip: true,
                  isLink: false,
                  key: 'Is New',
                  value: false,
                },
              ];
              if (
                res &&
                res.data &&
                res.data.isExist &&
                res.data.detectorDetail
              ) {
                this.detectorPositions[i].detector_id =
                  res.data.detectorDetail.id;
                this.detectorPositions[i].isExist = true;
                this.detectorPositions[i].detectorType =
                  res.data.detectorDetail.detector_type;
                const selectedDetectType = this.filteredDetectorTypes.findIndex(
                  (f: any) => {
                    return f.id === res.data.detectorDetail.detector_type.id;
                  }
                );
                this.detectorPositions[i].selectedDetectorType =
                  this.filteredDetectorTypes[selectedDetectType];
                const detectorIndex = this.detectorPositionTable[key].findIndex(
                  (d: any) => {
                    return d.key === 'Detector Type';
                  }
                );
                this.detectorPositionTable[key][detectorIndex].value =
                  res.data.detectorDetail.detector_type.name;
              } else {
                this.detectorPositions[i].detector_id = null;
                this.detectorPositions[i].detectorType = null;
                this.detectorPositions[i].isExist = false;
                this.detectorPositions[i].selectedDetectorType = null;
              }
            } else {
              this.addDeviceControls[`${key}`].setErrors({ incorrect: true });
              // Remove Selected Detector Type If It's Already Selected At Another Place
              const keyIndex = this.detectorPositions.findIndex((f: any) => {
                return f.detector_position === key;
              });
              this.detectorPositions[keyIndex].selectedDetectorType = null;
              this.positionErrors[
                key
              ].error = `This detector is already occupied at position ${this.detectorPositions[isAlreadyOccupied].detector_position}`;
            }
          }
        }
      }
    }
  }

  resetSelectedDetectorType(key: string) {
    const positionIndex = this.detectorPositions.findIndex((d: any) => {
      return d.detector_position === key;
    });
    this.detectorPositions[positionIndex].detectorType = null;
    this.detectorPositions[positionIndex].selectedDetectorType = null;
  }

  isAllDetectorSelected() {
    return (
      this.detectorPositions.length ===
      this.detectorPositions.filter((d: any) => {
        return d.selectedDetectorType;
      }).length
    );
  }

  async validateDetectorBoard(event: any) {
    if (event && event.target.value) {
      // reset detector positions
      this.detectorPositions = [
        {
          detector_position: 'X1',
          detector_id: null,
          batch_id: null,
          slot_id: null,
          production_detector_id: null,
          detector_info: null,
          isExist: false,
          detectorType: null,
          selectedDetectorType: null,
        },
        {
          detector_position: 'X2',
          detector_id: null,
          batch_id: null,
          slot_id: null,
          production_detector_id: null,
          detector_info: null,
          isExist: false,
          detectorType: null,
          selectedDetectorType: null,
        },
        {
          detector_position: 'X3',
          detector_id: null,
          batch_id: null,
          slot_id: null,
          production_detector_id: null,
          detector_info: null,
          isExist: false,
          detectorType: null,
          selectedDetectorType: null,
        },
        {
          detector_position: 'X4',
          detector_id: null,
          batch_id: null,
          slot_id: null,
          production_detector_id: null,
          detector_info: null,
          isExist: false,
          detectorType: null,
          selectedDetectorType: null,
        },
      ];
      if (this.addDeviceControls.detector_board_id.status === 'VALID') {
        this.isBoardData = false;
        let senitizedValue = event.target.value;
        senitizedValue = senitizedValue.replace(/\s/g, '');
        this.positionErrors.detector_board_id.error = null;
        let URL = `detector_board_id=${senitizedValue}`;
        if (this.action === 'Update') {
          URL += `&deviceId=${this.local_data.id}`;
        }
        const res = await this.deviceService.validateDetectorBoardExistency(
          URL
        );
        if (res && res.data) {
          if (res.data.isAssociated) {
            this.addDeviceControls.detector_board_id.setErrors({
              incorrect: true,
            });
            this.positionErrors.detector_board_id.error =
              res.data.errorReason ||
              'This sensor board is already associated with a device';
          } else if (
            res.data.isExist &&
            res.data.detectorBoardDetail &&
            res.data.detectorInfo
          ) {
            // Set all positions and make disable detector position inputs
            res.data.detectorInfo
              .sort((a: any, b: any) => {
                return a.id - b.id;
              })
              .forEach((d: any) => {
                const i = this.detectorPositions.findIndex((dp: any) => {
                  return dp.detector_position === d.detector_position;
                });
                if (i !== -1) {
                  const detectorInfo = d.detector;
                  this.detectorPositions[i].detector_id = detectorInfo.id;
                  this.detectorPositions[i].batch_id = detectorInfo.batch_id;
                  this.detectorPositions[i].slot_id = detectorInfo.slot_id;
                  this.detectorPositions[i].production_detector_id =
                    detectorInfo.production_detector_id;
                  const detector_info: any = `${detectorInfo.batch_id} ${detectorInfo.slot_id} ${detectorInfo.production_detector_id}`;
                  this.detectorPositions[i].detector_info = null;
                  this.detectorPositions[i].isExist = true;
                  this.detectorPositions[i].detector_info = detector_info;
                  this.detectorPositions[i].detectorType =
                    detectorInfo.detector_type;
                  const selectedDetectType =
                    this.filteredDetectorTypes.findIndex((f: any) => {
                      return f.id === detectorInfo.detector_type.id;
                    });
                  this.detectorPositions[i].selectedDetectorType =
                    this.filteredDetectorTypes[selectedDetectType];
                  this.addDeviceForm.patchValue({
                    [d.detector_position]: detector_info,
                  });
                }
              });
            this.isBoardData = true;
          }
        }
      } else {
        this.isBoardData = false;
      }
    }
  }
}
