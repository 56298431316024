import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetectorTypesComponent } from './detector-types/detector-types.component';

const routes: Routes = [
  {
    path: '',
    component: DetectorTypesComponent,
    data: {
      title: 'Detector Type',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'Detector Type' }]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DetectorTypeRoutingModule {

}
