<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Device</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content style="max-height: inherit;" class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <div *ngIf="action != 'View'">
    <form [formGroup]="addDeviceForm">
      <div fxLayout="row wrap" class="align-items-center">
        <div fxFlex="100" fxFlex.gt-md="100">
          <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
            <div fxFlex="50" class="m-l-15">
              <mat-form-field>
                <input [readonly]="action ==='View'" type="text" matInput required id="mac_address"
                  [(ngModel)]="local_data.mac_address" placeholder="Mac Address" formControlName="mac_address">
                <mat-error *ngIf="!addDeviceControls.mac_address.value && addDeviceForm.get('mac_address').touched"
                  class="text-danger">Mac Address is required</mat-error>
                <mat-error
                  *ngIf="addDeviceForm.get('mac_address').invalid && !addDeviceControls.mac_address.errors.required && addDeviceForm.get('mac_address').touched"
                  class="text-danger">Mac Address is invalid</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50" class="m-r-15">
              <mat-form-field>
                <input [readonly]="action ==='View'" type="text" matInput required id="serial_number"
                  [(ngModel)]="local_data.serial_number" placeholder="Serial Number" formControlName="serial_number">
                <mat-error *ngIf="!addDeviceControls.serial_number.value && addDeviceForm.get('serial_number').touched"
                  class="text-danger">Serial Number is required</mat-error>
                <mat-error
                  *ngIf="addDeviceControls.serial_number.value && addDeviceForm.get('serial_number').touched && addDeviceForm.get('serial_number').hasError('whitespace')">
                  Please enter valid serial number
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutGap="15px" class="m-b-5">
            <div fxFlex="50" class="m-l-15">
              <mat-form-field>
                <mat-label>Device Type</mat-label>
                <mat-select required [disabled]="action !== 'Add' " placeholder="Device Type"
                  formControlName="device_type_id" (selectionChange)="getFirmwares($event)">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="deviceTypeDropDownSearch"
                      placeholderLabel="Search Device Type ..." noEntriesFoundLabel="No matching Device Type found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let deviceType of filteredDeviceTypes" [value]="deviceType.id">
                    {{ deviceType.name }}-{{ deviceType.version }}
                  </mat-option>
                </mat-select>
                <mat-error
                  *ngIf="!addDeviceControls.device_type_id.value && addDeviceForm.get('device_type_id').touched"
                  class="text-danger">Device Type is required</mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50" class="m-r-15">
              <mat-form-field>
                <mat-label>Firmware</mat-label>
                <mat-select required [disabled]="action ==='View'" placeholder="Firmware" formControlName="firmware_id">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="firmwareSearch" placeholderLabel="Search Firmware..."
                      noEntriesFoundLabel="No matching Firmware found">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let firmware of filteredFirmwares" [value]="firmware.supported_firmware.id">
                    {{ firmware.supported_firmware.name }}-{{ firmware.supported_firmware.version }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!addDeviceControls.firmware_id.value && addDeviceForm.get('firmware_id').touched"
                  class="text-danger">Firmware is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <mat-label>Detector Positions</mat-label>

          <div *ngIf="showDetectorBoard" class="m-r-15 m-l-15">
            <mat-form-field>
              <input [required]="showDetectorBoard" (keyup)="validateDetectorBoard($event)"
                [readonly]="action ==='View'" type="text" matInput id="detector_board_id"
                [(ngModel)]="local_data.detector_board_id" placeholder="Sensor Board Id"
                formControlName="detector_board_id">
              <mat-error
                *ngIf="addDeviceControls.detector_board_id && !addDeviceControls.detector_board_id.value && addDeviceForm.get('detector_board_id').touched"
                class="text-danger">
                Sensor Board Id is required</mat-error>
              <mat-error
                *ngIf="addDeviceControls.detector_board_id && addDeviceForm.get('detector_board_id').invalid && positionErrors['detector_board_id'].error"
                class="text-danger">
                {{ positionErrors.detector_board_id.error }}</mat-error>
            </mat-form-field>
          </div>

          <div [disabled]="isBoardData" class="m-r-15 m-l-15 disabledbutton">
            <mat-form-field>
              <mat-label>X1</mat-label>
              <input [readonly]="isBoardData" #X1 class="detector-info" (keyup)="validateDetector($event, 'X1')"
                type="text" matInput required id="X1" [(ngModel)]="detectorPositions[0].detector_info" placeholder="X1"
                formControlName="X1">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X1').invalid && !positionErrors['X1'].error && detectorPositions[0].detector_info && detectorPositions[0].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[0].isExist" (selectionChange)="change($event, 'X1')"
                    placeholder="select detector type" formControlName="X1_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[0].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <!-- <div *ngIf="detectorPositions[0].detectorType && !addDeviceForm.get('X1').invalid">
                <table mat-table [dataSource]="detectorPositionTable.X1"
                  class="table employee-list td-ht-sm b-all no-wrap my-5">

                  <ng-container matColumnDef="key">
                    <td class="might-overflow" mat-cell *matCellDef="let element">
                      {{ element.key }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <td class="might-overflow-link"
                      [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                      mat-cell *matCellDef="let element">
                      <div *ngIf="element.isDate">
                        {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                      </div>
                      <div *ngIf="element.isTextArea">
                        <div *ngIf="element.value.length > 15">
                          <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                            [(ngModel)]="element.value" #description matInput class="scrollbar"
                            placeholder="Description"></textarea>
                        </div>
                        <div *ngIf="element.value.length < 16">
                          {{ element.value }}
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between"
                        *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                        {{ element.value }}
                        <a [matTooltip]="'Cancel'"
                          *ngIf="element.key == 'Detector Type' && !detectorPositions[0].isExist"
                          (click)="resetSelectedDetectorType('X1')">
                          <mat-icon class="cursor-pointer">cancel</mat-icon>
                        </a>
                      </div>
                      <div *ngIf="element.isLink">
                        <a href="{{element.value}}">{{element.value}}</a>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: detectorPositionColumns"></tr>
                </table>
              </div> -->
              <mat-error *ngIf="!addDeviceControls.X1.value && addDeviceForm.get('X1').touched" class="text-danger">
                Detector for position X1 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X1').invalid && !positionErrors['X1'].error && !addDeviceControls.X1.errors.required && addDeviceForm.get('X1').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X1').invalid && addDeviceControls.X1.value && addDeviceControls.X1.value.length > 15 && positionErrors['X1'].error"
                class="text-danger"> {{ positionErrors.X1.error }}</mat-error>
            </mat-form-field>
          </div>

          <div [disabled]="isBoardData" class="m-r-15 m-l-15 disabledbutton">
            <mat-form-field>
              <mat-label>X2</mat-label>
              <input [readonly]="isBoardData" #X2 class="detector-info" (keyup)="validateDetector($event, 'X2')"
                type="text" matInput required id="X2" [(ngModel)]="detectorPositions[1].detector_info" placeholder="X2"
                formControlName="X2">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X2').invalid && !positionErrors['X2'].error && detectorPositions[1].detector_info && detectorPositions[1].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[1].isExist" (selectionChange)="change($event, 'X2')"
                    placeholder="select detector type" formControlName="X2_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[1].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <!-- <div *ngIf="detectorPositions[1].detectorType && !addDeviceForm.get('X2').invalid">
                <table mat-table [dataSource]="detectorPositionTable.X2"
                  class="table employee-list td-ht-sm no-wrap b-all my-5">

                  <ng-container matColumnDef="key">
                    <td class="might-overflow" mat-cell *matCellDef="let element">
                      {{ element.key }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <td class="might-overflow-link"
                      [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                      mat-cell *matCellDef="let element">
                      <div *ngIf="element.isDate">
                        {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                      </div>
                      <div *ngIf="element.isTextArea">
                        <div *ngIf="element.value.length > 15">
                          <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                            [(ngModel)]="element.value" #description matInput class="scrollbar"
                            placeholder="Description"></textarea>
                        </div>
                        <div *ngIf="element.value.length < 16">
                          {{ element.value }}
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between"
                        *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                        {{ element.value }}
                        <a [matTooltip]="'Cancel'"
                          *ngIf="element.key == 'Detector Type' && !detectorPositions[1].isExist"
                          (click)="resetSelectedDetectorType('X2')">
                          <mat-icon class="cursor-pointer">cancel</mat-icon>
                        </a>
                      </div>
                      <div *ngIf="element.isLink">
                        <a href="{{element.value}}">{{element.value}}</a>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: detectorPositionColumns"></tr>
                </table>
              </div> -->
              <mat-error *ngIf="!addDeviceControls.X2.value && addDeviceForm.get('X2').touched" class="text-danger">
                Detector for position X2 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X2').invalid && !positionErrors['X2'].error && !addDeviceControls.X2.errors.required && addDeviceForm.get('X2').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X2').invalid && addDeviceControls.X2.value && addDeviceControls.X2.value.length > 15 && positionErrors['X2'].error"
                class="text-danger">{{ positionErrors.X2.error }}</mat-error>
            </mat-form-field>
          </div>

          <div [disabled]="isBoardData" class="m-r-15 m-l-15 disabledbutton">
            <mat-form-field>
              <mat-label>X3</mat-label>
              <input [readonly]="isBoardData" #X3 class="detector-info" (keyup)="validateDetector($event, 'X3')"
                type="text" matInput required id="X3" [(ngModel)]="detectorPositions[2].detector_info" placeholder="X3"
                formControlName="X3">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X3').invalid && !positionErrors['X3'].error && detectorPositions[2].detector_info && detectorPositions[2].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[2].isExist" (selectionChange)="change($event, 'X3')"
                    placeholder="select detector type" formControlName="X3_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[2].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <!-- <div *ngIf="detectorPositions[2].detectorType && !addDeviceForm.get('X3').invalid">
                <table mat-table [dataSource]="detectorPositionTable.X3"
                  class="table employee-list td-ht-sm b-all no-wrap my-5">

                  <ng-container matColumnDef="key">
                    <td class="might-overflow" mat-cell *matCellDef="let element">
                      {{ element.key }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <td class="might-overflow-link"
                      [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                      mat-cell *matCellDef="let element">
                      <div *ngIf="element.isDate">
                        {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                      </div>
                      <div *ngIf="element.isTextArea">
                        <div *ngIf="element.value.length > 15">
                          <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                            [(ngModel)]="element.value" #description matInput class="scrollbar"
                            placeholder="Description"></textarea>
                        </div>
                        <div *ngIf="element.value.length < 16">
                          {{ element.value }}
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between"
                        *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                        {{ element.value }}
                        <a [matTooltip]="'Cancel'"
                          *ngIf="element.key == 'Detector Type' && !detectorPositions[2].isExist"
                          (click)="resetSelectedDetectorType('X3')">
                          <mat-icon class="cursor-pointer">cancel</mat-icon>
                        </a>
                      </div>
                      <div *ngIf="element.isLink">
                        <a href="{{element.value}}">{{element.value}}</a>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: detectorPositionColumns"></tr>
                </table>
              </div> -->
              <mat-error *ngIf="!addDeviceControls.X3.value && addDeviceForm.get('X3').touched" class="text-danger">
                Detector for position X3 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X3').invalid && !positionErrors['X3'].error && !addDeviceControls.X3.errors.required && addDeviceForm.get('X3').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X3').invalid && addDeviceControls.X3.value && addDeviceControls.X3.value.length > 15 && positionErrors['X3'].error"
                class="text-danger">{{ positionErrors.X3.error }}</mat-error>
            </mat-form-field>
          </div>

          <div [disabled]="isBoardData" class="m-r-15 m-l-15 m-b-20 disabledbutton">
            <mat-form-field>
              <mat-label>X4</mat-label>
              <input [readonly]="isBoardData" #X4 class="detector-info" (keyup)="validateDetector($event, 'X4')"
                type="text" matInput required id="X4" [(ngModel)]="detectorPositions[3].detector_info" placeholder="X4"
                formControlName="X4">
              <div class="detector-position"
                *ngIf="!addDeviceForm.get('X4').invalid && !positionErrors['X4'].error && detectorPositions[3].detector_info && detectorPositions[3].detector_info.length === 16 && detector_types.length">
                <mat-form-field>
                  <mat-label>Detector Type</mat-label>
                  <mat-select [disabled]="detectorPositions[3].isExist" (selectionChange)="change($event, 'X4')"
                    placeholder="select detector type" formControlName="X4_DETECTOR_TYPE"
                    [(ngModel)]="detectorPositions[3].selectedDetectorType">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let detectorType of filteredDetectorTypes" [value]="detectorType">
                      {{ detectorType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="detector-disp">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
              </div>
              <!-- <div *ngIf="detectorPositions[3].detectorType && !addDeviceForm.get('X4').invalid">
                <table mat-table [dataSource]="detectorPositionTable.X4"
                  class="table employee-list td-ht-sm b-all no-wrap my-5">

                  <ng-container matColumnDef="key">
                    <td class="might-overflow" mat-cell *matCellDef="let element">
                      {{ element.key }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="value">
                    <td class="might-overflow-link"
                      [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                      mat-cell *matCellDef="let element">
                      <div *ngIf="element.isDate">
                        {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                      </div>
                      <div *ngIf="element.isTextArea">
                        <div *ngIf="element.value.length > 15">
                          <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                            [(ngModel)]="element.value" #description matInput class="scrollbar"
                            placeholder="Description"></textarea>
                        </div>
                        <div *ngIf="element.value.length < 16">
                          {{ element.value }}
                        </div>
                      </div>
                      <div class="d-flex align-items-center justify-content-between"
                        *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                        {{ element.value }}
                        <a [matTooltip]="'Cancel'"
                          *ngIf="element.key == 'Detector Type' && !detectorPositions[3].isExist"
                          (click)="resetSelectedDetectorType('X4')">
                          <mat-icon class="cursor-pointer">cancel</mat-icon>
                        </a>
                      </div>
                      <div *ngIf="element.isLink">
                        <a href="{{element.value}}">{{element.value}}</a>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-row *matRowDef="let row; columns: detectorPositionColumns"></tr>
                </table>
              </div> -->
              <mat-error *ngIf="!addDeviceControls.X4.value && addDeviceForm.get('X4').touched" class="text-danger">
                Detector for position X4 is required</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X4').invalid && !positionErrors['X4'].error && !addDeviceControls.X4.errors.required && addDeviceForm.get('X4').touched"
                class="text-danger">Detector is invalid</mat-error>
              <mat-error
                *ngIf="addDeviceForm.get('X4').invalid && addDeviceControls.X4.value && addDeviceControls.X4.value.length > 15 && positionErrors['X4'].error"
                class="text-danger">{{ positionErrors.X4.error }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <button *ngIf="action != 'View'" mat-button (click)="doAction()" mat-flat-button color="primary"
        [disabled]="isProcessing || !addDeviceForm.valid || !isAllDetectorSelected()">{{action}}</button>
      <button *ngIf="!isBoardData" mat-button mat-flat-button color="primary" class="m-l-10" (click)="resetPositions()"
        [disabled]="isPositionPlaced()">Reset Position</button>
      <button mat-button class="m-l-10" (click)="closeDialog()"> {{action != 'View' ? 'Cancel' : 'Close'}}</button>
    </form>
  </div>
  <div *ngIf="action === 'View'">
    <div class="">
      <table mat-table [dataSource]="deviceBlock" class="table employee-list td-ht-sm no-wrap">

        <ng-container matColumnDef="key">
          <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
            {{ element.key }}
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <td class="might-overflow-link"
            [matTooltip]="element.showTooltip ? (element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value) : null"
            mat-cell *matCellDef="let element">
            <div *ngIf="element.isDate">
              {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
            </div>
            <div *ngIf="element.isTextArea">
              <div *ngIf="element.value.length > 15">
                <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40" [(ngModel)]="element.value"
                  #description matInput class="scrollbar" placeholder="Description"></textarea>
              </div>
              <div *ngIf="element.value.length < 16">
                {{ element.value }}
              </div>
            </div>
            <div
              *ngIf="!element.isDate && !element.isLink && !element.isTextArea && !element.isNested && !element.isObjectValue">
              {{ element.value }}
            </div>
            <div *ngIf="element.isObjectValue" class="detector-info td-lg">
              {{ element.value.detectorInfo }}
              <!-- <div class="last-text">
                <span>{{ element.value.detectorType }}</span>
              </div> -->
              <div class="last-text">
                <span>Type: {{ element.value.detectorType }}</span>
              </div>


              <div class="detector-disp ">
                <div class="detector-disp__batch">
                  <div class="empty-bx"></div>
                  Batch
                </div>
                <div class="detector-disp__slot">
                  <div class="empty-bx"></div>
                  Slot
                </div>
                <div class="detector-disp__detector">
                  <div class="empty-bx"></div>
                  Detector
                </div>
                <!-- <div class="detector-disp__type">
                  <div class="empty-bx"></div>
                  Type
                </div> -->
              </div>
            </div>
            <!-- <div class="td-lg" *ngIf="element.isNested">
              <table mat-table [dataSource]="element.value" class="table employee-list td-ht-sm b-all no-wrap">

                <ng-container matColumnDef="key">
                  <td class="might-overflow" mat-cell *matCellDef="let ele">
                    {{ ele.key }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="value">
                  <td class="might-overflow-link"
                    [matTooltip]="ele.isDate ? (ele.value | date: 'dd MMM YYY, HH.mm.ss') : ele.value" mat-cell
                    *matCellDef="let ele">
                    <div *ngIf="ele.isDate">
                      {{ ele.value | date: 'dd MMM YYY, HH.mm.ss' }}
                    </div>
                    <div *ngIf="ele.isTextArea">
                      <div *ngIf="ele.value.length > 15">
                        <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                          [(ngModel)]="ele.value" #description matInput class="scrollbar"
                          placeholder="Description"></textarea>
                      </div>
                      <div *ngIf="ele.value.length < 16">
                        {{ ele.value }}
                      </div>
                    </div>
                    <div *ngIf="!ele.isDate && !ele.isLink && !ele.isTextArea">
                      {{ ele.value }}
                    </div>
                    <div *ngIf="ele.isLink">
                      <a href="{{ele.value}}">{{ele.value}}</a>
                    </div>
                  </td>
                </ng-container>

                <tr mat-row *matRowDef="let row; columns: deviceBlockColumns"></tr>
              </table>
            </div> -->

            <div *ngIf="element.isLink">
              <a href="{{element.value}}">{{element.value}}</a>
            </div>
          </td>


        </ng-container>

        <tr mat-row *matRowDef="let row; columns: deviceBlockColumns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.mac_address}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>