import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  constructor() {}

  successResponse(body: any): any {
    let obj: any = {};
    if (body && !!body.status) {
      obj = { ...body };
      if (body.message) {
        obj.msg = body.message;
      }
    }
    return obj;
  }

  noInternet() {
    return this.errorResponse({
      message: 'No internet connection',
      code: 500,
    });
  }

  errorResponse(obj: any): any {
    const errorObj = { error: 'Something went wrong', code: 400 };
    if (obj && obj.error && typeof obj.error === 'object') {
      if (obj && obj.error && obj.error.message) {
        errorObj.error = obj.error.message;
      } else if (obj.error.error) {
        errorObj.error = obj.error.error;
      }
    } else if (obj && obj.message) {
      errorObj.error = obj.message;
    }
    return errorObj;
  }

  responseBuilder(body: any): any {
    const response = { hasError: false };
    if (body && !!body.status) {
      Object.assign(response, this.successResponse(body));
    } else {
      response.hasError = true;
      Object.assign(response, this.errorResponse(body));
    }
    return response;
  }
}
