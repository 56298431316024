import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonConstants } from 'src/app/shared/common-constants';
import { Firmware } from 'src/app/shared/models/firmware';
import { DeviceService } from 'src/app/shared/services/device/device.service';
import { FirmwareService } from 'src/app/shared/services/firmware/firmware.service';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FirmwaresComponent } from '../firmwares/firmwares.component';

@Component({
  selector: 'app-firmware-dialog',
  templateUrl: './firmware-dialog.component.html',
  styleUrls: ['./firmware-dialog.component.scss'],
})
export class FirmwareDialogComponent implements OnInit {
  firmwareForm: FormGroup;
  public deviceTypes: any = [];
  public action: string = 'Add';
  public local_data: any;
  public isProcessing: boolean = false;
  public firmwareFile: any;
  public deviceTypeDropDownSearch: FormControl = new FormControl();
  public filteredDeviceTypes: any = [];
  public invalidFile: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private firmwareService: FirmwareService,
    private deviceService: DeviceService,
    @Inject(MAT_DIALOG_DATA) public data: FirmwaresComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';

    this.firmwareForm = new FormGroup({
      name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(CommonConstants.maxLength60),
        this.helperService.isFieldValueBlank.bind(this),
      ]),
      version: new FormControl(null, [
        Validators.required,
        Validators.maxLength(CommonConstants.maxLength60),
        this.helperService.isFieldValueBlank.bind(this),
      ]),
      file: new FormControl(null, Validators.required),
      deviceTypeIds: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.action === 'View') {
      this.firmwareForm.disable();
    }
    if (this.action === 'View' || this.action === 'Update') {
      this.getFirmware();
    }
    if (this.action === 'Add') {
      this.getDeviceTypes();
    }
    this.deviceTypeDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredDeviceTypes = this.deviceTypes.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  async getFirmware() {
    try {
      this.isProcessing = true;
      await this.getDeviceTypes();
      const res = await this.firmwareService.getFirmware(this.local_data.id);
      const deviceTypeIds = res.data.firmwareInfo.supported_firmware.map(
        (sf: any) => sf.device_type_id
      );
      this.firmwareForm = new FormGroup({
        name: new FormControl(res.data.firmwareInfo.name, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        version: new FormControl(res.data.firmwareInfo.version, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        deviceTypeIds: new FormControl(deviceTypeIds, Validators.required),
      });
    } catch (err: any) {
      this.isProcessing = false;
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getDeviceTypes() {
    try {
      if (this.action === 'Add') {
        this.isProcessing = true;
      }
      const res = await this.deviceService.getDeviceTypes('page=0&perPage=0');
      if (!!res && res.data.deviceTypes) {
        this.deviceTypes = res.data.deviceTypes;
        this.filteredDeviceTypes = [...this.deviceTypes];
        if (this.deviceTypes.length == 1) {
          this.firmwareForm.controls.deviceTypeIds.patchValue([
            this.deviceTypes[0].id,
          ]);
        }
      }
    } catch (err: any) {
      this.isProcessing = false;
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      if (this.action === 'Add') {
        this.isProcessing = false;
      }
    }
  }

  async doAction() {
    await this.submitFirmwareForm();
  }

  isOptionDisabled(opt: any): boolean {
    return (
      this.firmwareForm.value.deviceTypeIds?.length >= 10 &&
      !this.firmwareForm.value.deviceTypeIds?.find((el: any) => el == opt)
    );
  }

  async onSelect(data: any) {
    this.firmwareFile = data[0];
    this.invalidFile = false;
    const fileExtension: any = data[0].name.split('.').pop();
    if (fileExtension !== 'bin') {
      this.invalidFile = true;
      this.firmwareForm.controls.file.patchValue(null);
      this.helperService.showAlert('Only .bin file is supported', 'error');
    }
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitFirmwareForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const res = await this.firmwareService.removeFirmware(
          this.local_data.id
        );
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else {
      if (this.firmwareForm.valid) {
        try {
          this.isProcessing = true;
          const firmware = {} as Firmware;
          firmware.name = this.firmwareForm.value.name;
          firmware.version = this.firmwareForm.value.version;
          firmware.deviceTypeIds = this.firmwareForm.value.deviceTypeIds;
          if (this.action === 'Update') {
            firmware.id = this.local_data.id;
          }
          let res: any;
          if (this.action === 'Add') {
            const chars = this.firmwareFile.name.split('.');
            chars.pop(); // it will remove an extension from the file name
            const fileName = chars.join('.');
            const params = `fileName=${fileName}&mimeType=application/octet-stream`;
            const getThumbUrl = await this.firmwareService.getPreSignedURL(
              params
            );
            const thumbURL = getThumbUrl.data.url;
            const fileURL = getThumbUrl.data.fileName;
            firmware.fileLink = fileURL;
            await this.firmwareService.uploadFiles3Sync(
              thumbURL,
              this.firmwareFile
            );
            res = await this.firmwareService.addFirmware(firmware);
            this.local_data.id = res.data.id;
            this.local_data.createdAt = res.data.createdAt;
            this.local_data.updatedAt = res.data.updatedAt;
          } else if (this.action === 'Update') {
            res = await this.firmwareService.updateFirmware(firmware);
          }
          if (res) {
            this.helperService.showAlert(res.msg || 'Success', 'success');
          }
          this.dialogRef.close({
            event: this.action,
            data: res.data,
            success: true,
          });
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        } finally {
          this.isProcessing = false;
        }
      }
    }
  }
}
