import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'user',
    name: 'User',
    type: 'link',
    icon: 'account_circle',
  },
  {
    state: 'measurements',
    name: 'Measurements',
    type: 'link',
    icon: 'stacked_bar_chart',
  },
  {
    state: 'measurement',
    name: 'Measurement Info',
    type: 'sub',
    icon: 'table_chart',
    children: [
      { state: 'smell', name: 'Smell/Substance', type: 'link' },
      { state: 'usecase', name: 'Use Case', type: 'link' },
      { state: 'measurement-setup', name: 'Measurement Setup', type: 'link' },
    ],
  },
  {
    state: 'classifier',
    name: 'Classifier',
    type: 'link',
    icon: 'psychology',
  },
  {
    state: 'device',
    name: 'Device Management',
    type: 'sub',
    icon: 'tap_and_play',
    children: [
      { state: 'detector-type', name: 'Detector Type', type: 'link' },
      { state: 'detector', name: 'Detector', type: 'link' },
      { state: 'device-type', name: 'Device Type', type: 'link' },
      { state: 'devices', name: 'Device', type: 'link' },
      { state: 'import-devices', name: 'Import Devices', type: 'link' },
      { state: 'sensor-boards', name: 'Sensor Board', type: 'link' },
      {
        state: 'import-sensor-boards',
        name: 'Import Sensor Boards',
        type: 'link',
      },
    ],
  },
  {
    state: 'firmware',
    name: 'Firmware',
    type: 'link',
    icon: 'memory',
  },
  {
    state: 'issue',
    name: 'Issue',
    type: 'sub',
    icon: 'report_problem',
    children: [
      { state: 'issue-type', name: 'Issue Type', type: 'link' },
      { state: 'list', name: 'Issue', type: 'link' },
    ],
  },
  {
    state: 'faq',
    name: 'FAQ',
    type: 'sub',
    icon: 'help_center',
    children: [
      { state: 'category', name: 'Category', type: 'link' },
      { state: 'list', name: 'FAQ', type: 'link' },
    ],
  },
  {
    state: 'app-version-update',
    name: 'App Version Update',
    type: 'link',
    icon: 'system_update',
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
