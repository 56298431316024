<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="d-flex">
              <button mat-raised-button color="primary" (click)="openDialog('Add',{})">Import
                Devices</button>
            </div>

            <div *ngIf='length !== 0' class="example-container example-container--lg ex-c-imported-devices">
              <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                [matSortActive]="orderOn" [matSortDirection]="orderBy" class="table employee-list no-wrap">

                <ng-container matColumnDef="index">
                  <th disableClear mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id || '-'}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="csv_file_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> CSV File Name </th>
                  <td class="might-overflow" [matTooltip]="element.csv_file_name" mat-cell *matCellDef="let element">
                    {{ element.csv_file_name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th disableClear mat-header-cell *matHeaderCellDef> Status </th>
                  <td class="might-overflow" [matTooltip]="element.status" mat-cell *matCellDef="let element">
                    {{ element.status }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="output_csv_file_link">
                  <th disableClear mat-header-cell *matHeaderCellDef> Output CSV File </th>
                  <td class="might-overflow" mat-cell *matCellDef="let element">
                    <div *ngIf="element.output_csv_file_link" [matTooltip]="'Download'">
                      <u><a href="{{element.output_csv_file_link}}">Download</a></u>
                    </div>
                    <div *ngIf="!element.output_csv_file_link" [matTooltip]="element.value"> - </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td class="might-overflow" mat-cell *matCellDef="let element">
                    <div *ngIf="element.createdAt" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'">
                      {{ element.createdAt | date: 'dd MMM
                      YYY, HH.mm.ss' }}
                    </div>
                    <div *ngIf="!element.createdAt">-</div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated At </th>
                  <td class="might-overflow" mat-cell *matCellDef="let element">
                    <div *ngIf="element.updatedAt" [matTooltip]="element.updatedAt | date: 'dd MMM YYY, HH.mm.ss'">
                      {{ element.updatedAt | date: 'dd MMM
                      YYY, HH.mm.ss' }}
                    </div>
                    <div *ngIf="!element.updatedAt">-</div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <a [matTooltip]="tooltip.view_device" class="m-r-10 cursor-pointer eye"
                      (click)="openDialog('View',element)">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                    <a [matTooltip]="tooltip.update_device" (click)="openDialog('Update',element)"
                      [ngClass]="element.is_associated ? 'disabled' : ''" class="m-r-10 cursor-pointer"><i
                        class="fa fa-pencil editFont"></i></a>
                    <a [matTooltip]="tooltip.delete_device"
                      [ngClass]="element.is_associated ? 'disabled' : 'text-danger'"
                      (click)="openDialog('Delete',element)" class="m-r-10 cursor-pointer">
                      <i class="fa fa-trash deleteFont"></i>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf='length === 0'> No record found </div>
          </div>
        </div>
        <mat-paginator class="m-t-0" #paginator [pageSizeOptions]="pageSizeOptions" [length]="length"
          [pageIndex]="pageIndex" [pageSize]="pageSize" showFirstLastButtons (page)="getDevices($event)">
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>