import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class IssueService {
  constructor(private apiService: APIService) {}

  getIssues(params: any) {
    const url = `/issue/?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getIssues');
  }

  getIssueTypes(params: any) {
    const url = `/issue/issue-type?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getIssueTypes');
  }

  addIssueNote(body: any): any {
    const url = '/issue/issue-note';
    return this.apiService.postWithAuthHandler(url, body);
  }

  addIssueType(body: any): any {
    const url = '/issue/issue-type';
    return this.apiService.postWithAuthHandler(url, body);
  }

  updateIssue(body: any): any {
    const url = `/issue/${body.id}`;
    return this.apiService.putWithAuthHandler(url, body);
  }

  updateIssueType(body: any): any {
    const url = `/issue/issue-type`;
    return this.apiService.putWithAuthHandler(url, body);
  }

  removeIssue(params: any): any {
    const url = `/issue/${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  removeIssueType(params: any): any {
    const url = `/issue/issue-type?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

  getIssueInfo(params: any): any {
    const url = `/issue/${params}`;
    return this.apiService.getWithAuthHandler(url);
  }

  uploadFiles3Sync(fileuploadurl: string, file: any): any {
    return new Promise((resolve, reject) => {
      const url = fileuploadurl;
      const headers = {};
      this.apiService.putTPWithHeader(url, file, headers).subscribe(
        (res: any) => {
          return resolve(res);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  downloadFile(url: string): any {
    return this.apiService.getTPBlob(url);
  }
}
