import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class MeasurementSetupService {
  constructor(private apiService: APIService) {}

  getMeasurementTypes(params: any) {
    const url = `/measurement/measurement-type/?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getMeasurementTypes');
  }

  getMeasurementSetupInfo(params: any): any {
    const url = `/measurement/measurement-type/${params}`;
    return this.apiService.getWithAuthHandler(url);
  }

  getMeasurementTypeFilters() {
    const url = '/measurement/measurement-type/filters';
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getMeasurementTypeFilters'
    );
  }

  addMeasurementType(body: any): any {
    const url = '/measurement/measurement-type';
    return this.apiService.postWithAuthHandler(url, body);
  }

  updateMeasurementType(body: any): any {
    const url = `/measurement/measurement-type/${body.id}`;
    return this.apiService.putWithAuthHandler(url, body);
  }

  removeMeasurementType(params: any): any {
    const url = `/measurement/measurement-type/${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }
}
