import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class AppVersionUpdateService {
  constructor(private apiService: APIService) {}

  getAppVersionUpdates(params: any) {
    const url = `/app-version-update?${params}`;
    return this.apiService.getWithAuthHandler(
      url,
      true,
      'getAppVersionUpdates'
    );
  }

  addAppVersionUpdate(body: any): any {
    const url = '/app-version-update';
    return this.apiService.postWithAuthHandler(url, body);
  }
}
