import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { MeasurementType } from 'src/app/shared/models/measurement-setup';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { MeasurementSetupService } from 'src/app/shared/services/measurement-setup/measurement-setup.service';
import { MeasurementSetupDialogComponent } from '../measurement-setup-dialog/measurement-setup-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-measurement-setups',
  templateUrl: './measurement-setups.component.html',
  styleUrls: ['./measurement-setups.component.scss'],
})
export class MeasurementSetupsComponent implements OnInit {
  public data = {};
  public isProcessing = false;
  public pageIndex: number = 0;
  public pageSize: number = 15;
  displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'total_measurement_count',
    'indoor_outdoor',
    'is_public',
    'user_name',
    'user_email',
    'createdAt',
  ];
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  indoorOutdoorMenu: any = {};
  public searchText: any;
  public is_public = null;
  public indoorOutdoor = null;
  public toDate: any = '';
  public fromDate: any = '';
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  @ViewChild('memtSetupSearch') memtSetupSearch: ElementRef | any;

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';
  public futureDate = new Date();

  // Tooltip
  public tooltip = {
    view_measurement_setup: 'View Measurement Setup',
    update_measurement_setup: 'Update Measurement Setup',
    delete_measurement_setup: 'Delete Measurement Setup',
  };

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = ['name'];
  public searchOn = [];
  public length: number = 0;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  constructor(
    private measurementSetupService: MeasurementSetupService,
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private route: ActivatedRoute
  ) {
    this.indoorOutdoorMenu = { indoor: 'Indoor', outdoor: 'Outdoor' };
  }

  ngOnInit(): void {
    this.setDefaultDates();
    this.getMeasurementSetups();
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
    if (params.value && params.value.measurement_setup_id) {
      this.openDialog('View', {
        id: params.value.measurement_setup_id,
      });
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getMeasurementSetups();
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getMeasurementSetups();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getMeasurementSetups(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let mesSetupURL = '';

      // SEARCH
      if (this.searchedText) {
        mesSetupURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }
      if (this.searchOn.length) {
        mesSetupURL += `&searchOn=${JSON.stringify(this.searchOn)}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        mesSetupURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS

      if (this.is_public != null) {
        mesSetupURL += `&isPublic=${this.is_public}`;
        this.setDefaultPage(event);
      }

      if (this.indoorOutdoor != null) {
        mesSetupURL += `&indoorOutdoor=${this.indoorOutdoor}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        mesSetupURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      mesSetupURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.measurementSetupService.getMeasurementTypes(
        mesSetupURL
      );
      if (res) {
        this.data = res.data.measurementTypes;
        this.dataSource = res.data.measurementTypes;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getMeasurementSetups();
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.memtSetupSearch && this.memtSetupSearch.nativeElement) {
        this.memtSetupSearch.nativeElement.focus();
      }
    });
  }

  isFilterApplied() {
    return !(
      this.indoorOutdoor != null ||
      this.is_public != null ||
      this.toDate
    );
  }

  clearFilter() {
    this.is_public = null;
    this.indoorOutdoor = null;
    this.toDate = '';
    this.fromDate = '';
    this.getMeasurementSetups();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(MeasurementSetupDialogComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            this.deleteRowData(result.data);
          }
        }
      }
    });
  }

  addRowData(row_obj: MeasurementType): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [
      { ...row_obj, total_measurement_count: 0 },
      ...this.dataSource,
    ];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: MeasurementType): boolean | void {
    const index = this.dataSource.findIndex((d: any) => d.id === row_obj.id);
    if (index != -1) {
      this.dataSource[index] = row_obj;
    }
    this.table.renderRows();
  }

  deleteRowData(row_obj: MeasurementType) {
    this.getMeasurementSetups();
  }
}
