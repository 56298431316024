import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UsersComponent } from '../users/users.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/customValidator';

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss'],
})
export class UserResetPasswordComponent implements OnInit {
  action: string = 'Add';
  local_data: any;
  public userResetPwdForm: any;
  public categories: any = [];
  public hidePwd = true;
  public hideCfmPwd = true;

  public isProcessing = false;
  public errorReason = false;
  public showError = false;
  public isSubmitted = false;
  public IsResetFormValid = true;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private userService: UserService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: UsersComponent
  ) {
    this.local_data = { ...data };
  }
  ngOnInit() {
    this.userResetPasswordForm();
  }

  // convenience getter for easy access to form fields
  get userResetPasswordControls() {
    return this.userResetPwdForm.controls;
  }

  userResetPasswordForm() {
    this.userResetPwdForm = this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.noWhitespace,
            CustomValidators.passwordValidate,
          ]),
        ],
        confPassword: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.noWhitespace,
            CustomValidators.passwordValidate,
          ]),
        ],
      },
      {
        validator: CustomValidators.ConfirmedValidator(
          'password',
          'confPassword'
        ),
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitResetPwdForm(value: any) {
    this.isSubmitted = true;
    if (this.userResetPwdForm.valid && value.confPassword === value.password) {
      this.IsResetFormValid = true;
      try {
        this.isProcessing = true;
        const reqObj = {
          password: value.password,
          password_confirmation: value.confPassword,
        };
        const res = await this.userService.userResetPassword(
          this.local_data.id,
          reqObj
        );
        if (res) {
          this.helperService.showAlert(res.message || 'Success', 'success');
        }
      } catch (err: any) {
        this.errorReason = err.error || 'Something went wrong';
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.showError = true;
      } finally {
        this.userResetPasswordForm();
        this.isSubmitted = true;
        this.isProcessing = false;
        this.closeDialog();
      }
    } else {
      this.IsResetFormValid = false;
    }
  }
}
