<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Measurement Setup</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <div *ngIf="action != 'View'">
    <form [formGroup]="measurementTypeForm">
      <div fxLayout="row wrap" class="align-items-center">
        <div fxFlex="100" fxFlex.gt-md="100">
          <div class="overlay" *ngIf="isProcessing">
            <mat-spinner class="loader"></mat-spinner>
          </div>
          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label> Measurement Setup Name</mat-label>
              <input type="text" required matInput formControlName="name" name="name" class="form-control" id="name" />
              <mat-error *ngIf="
                          measurementTypeForm.controls['name'].invalid &&
                          (measurementTypeForm.controls['name'].errors.required || measurementTypeForm.controls['name'].errors?.isItBlank) &&
                          (measurementTypeForm.controls['name'].dirty || measurementTypeForm.controls['name'].touched)"
                class="text-danger">
                Measurement Setup Name is required</mat-error>
              <mat-error
                *ngIf="measurementTypeForm.controls['name'].invalid && measurementTypeForm.controls['name'].errors.maxlength"
                class="text-danger">
                Maximum 60 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <textarea rows="4" cols="40" formControlName="description" name="description" #description matInput
                placeholder="Description"></textarea>
              <mat-error
                *ngIf="measurementTypeForm.controls['description'].invalid && measurementTypeForm.controls['description'].errors.maxlength"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label>Indoor or Outdoor</mat-label>
              <mat-select placeholder="Category" formControlName="indoorOutdoor" name="indoorOutdoor">
                <mat-option *ngFor="let inoutdoor of indoorOutdoorMenu" [value]="inoutdoor.id">
                  {{ inoutdoor.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Public/Private : {{ local_data?.is_public ? 'Public' : 'Private' }}</h5>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Created By : {{local_data?.user_name || 'NA' }}</h5>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Email : {{local_data?.user_email || 'NA' }}</h5>
          </div>
        </div>
      </div>
      <button *ngIf="action != 'View'" mat-button (click)="doAction()" mat-flat-button color="primary"
        [disabled]="isProcessing || !measurementTypeForm.valid">{{action}}</button>
      <button mat-button class="m-l-10" (click)="closeDialog()"> {{action != 'View' ? 'Cancel' : 'Close'}}</button>
    </form>
  </div>
  <div *ngIf="action === 'View'">
    <div class="">
      <table mat-table [dataSource]="measurementSetupBlock" class="table employee-list td-ht-sm no-wrap">

        <ng-container matColumnDef="key">
          <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
            {{ element.key }}
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <td class="might-overflow-link"
            [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value" mat-cell
            *matCellDef="let element">
            <div *ngIf="element.isDate">
              {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
            </div>
            <div class="td-lg" *ngIf="element.isTextArea">
              <div class="td-lg" *ngIf="element.value.length > 15">
                <textarea readonly [ngModelOptions]="{standalone: true}" rows="3" cols="40" [(ngModel)]="element.value"
                  #description matInput class="scrollbar" placeholder="Description"></textarea>
              </div>
              <div *ngIf="element.value.length < 16">
                {{ element.value }}
              </div>
            </div>
            <div *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
              {{ element.value }}
            </div>
            <div *ngIf="element.isLink">
              <a href="{{element.value}}">{{element.value}}</a>
            </div>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: measurementSetupBlockColumns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<ng-template #elseTemplate>
  <p>Sure to delete measurement setup <b>{{local_data.name}} </b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>