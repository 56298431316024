import { Injectable } from '@angular/core';
import { APIService } from '../api/api.service';
@Injectable({
  providedIn: 'root'
})

export class FaqService {
  
  constructor(
    private apiService: APIService,
  ) { }

  faqAddCategory(body: any): any {
    const url = '/faq/faq-category';
    return this.apiService.postWithAuthHandler(url, body);
  }

  faqGetCategories(params: any) {
    const url = `/faq/faq-category?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'faqGetCategories');
  }

  faqUpdateCategory(body: any): any {
    const url = '/faq/faq-category';
    return this.apiService.putWithAuthHandler(url, body);
  }

  faqDeleteCategory(params: any): any {
    const url = `/faq/faq-category?${params}`;
    return this.apiService.deleteWithAuthHandler(url);
  }

  getFaqs(params: any) {
    const url = `/faq?${params}`;
    return this.apiService.getWithAuthHandler(url, true, 'getFaqs');
  }

  addFaq(body: any): any {
    const url = '/faq';
    return this.apiService.postWithAuthHandler(url, body);
  }

  editFaq(body: any): any {
    const url = '/faq';
    return this.apiService.putWithAuthHandler(url, body);
  }

  removeFaq(params: any): any {
    const url = `/faq?${params}`;
    return this.apiService.deleteWithAuthHandler(url, params);
  }

}
