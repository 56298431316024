import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import * as CryptoJS from 'crypto-js';
import { FormControl, ValidationErrors } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class HelperService {
  public emailReg = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  public encryptSecretKey = 'encrypt_test';
  public horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  public verticalPosition: MatSnackBarVerticalPosition = 'top';
  constructor(private _snackBar: MatSnackBar) {}

  isFieldValueBlank(c: FormControl): ValidationErrors | null {
    return c.value != null && c.value.toString().trim() !== ''
      ? null
      : {
          isItBlank: true,
        };
  }

  public capitalizeFirstLetter(s: string) {
    return s[0].toUpperCase() + s.slice(1);
  }

  empty(mixedVar: any) {
    const undef = '';
    let key = '';

    const emptyValues = [undef, null, false, '', undefined, 'undefined'];

    for (let i = 0, len = emptyValues.length; i < len; i++) {
      if (mixedVar === emptyValues[i]) {
        return true;
      }
    }
    if (typeof mixedVar === 'object') {
      // tslint:disable-next-line:forin
      for (key in mixedVar) {
        // TODO: should we check for own properties only?
        // if (mixed_var.hasOwnProperty(key)) {
        return false;
        // }
      }
      return true;
    }
    return false;
  }

  setPREF(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  getPREF(key: string) {
    if (this.empty(localStorage.getItem(key))) {
      return null;
    }
    return localStorage.getItem(key);
  }

  setPREFString(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  getPREFString(key: string) {
    return window.localStorage.getItem(key);
  }

  delPREF(key: string) {
    return window.localStorage.removeItem(key);
  }

  delAllPREF() {
    return window.localStorage.clear();
  }

  isDigit(digit: string) {
    const regex = /^[0-9]+$/;
    if (digit !== '') {
      if (digit.match(regex)) {
        return true;
      }
    }
    return false;
  }

  isDefined(variable: string | null) {
    if (typeof variable === 'boolean') {
      return true;
    }
    return (
      typeof variable !== undefined && variable !== null && variable !== ''
    );
  }

  showAlert(message: string, promptType: string, duration: number = 2000) {
    const type = promptType === 'error' ? 'snack_error' : 'snack_success';
    this._snackBar.open(message);
    const argues = {
      duration,
      panelClass: [type],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    };
    this._snackBar.open(message, '', argues);
  }

  kbToMBConvertor(val: number | string | any) {
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0,
      n = parseInt(val, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    //include a decimal point and a tenths-place digit if presenting
    //less than ten of KB or greater units
    return n.toFixed(2) + ' ' + units[l];
  }

  encryptData(data: any) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.encryptSecretKey
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  fileToBase64(data: any) {
    try {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    } catch (e) {
      console.log(e);
    }
  }

  secondsToDhms(secs: any) {
    const seconds = Number(secs);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? 'd, ' : 'd, ') : '';
    var hDisplay = h > 0 ? h + (h == 1 ? 'h, ' : 'h, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? 'm ' : 'm ') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? 's' : 's') : '';
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }
}
