<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <div>
                <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <div class="search" *ngIf="showSearch">
                  <mat-form-field class="search-box">
                    <mat-label>Search</mat-label>
                    <input matInput #issueSearch [(ngModel)]="searchedText"
                      [placeholder]="searchOn.length ? searchOn : 'Search'" (keyup)="getIssues()" />
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Issue Status</mat-label>
                  <mat-select (selectionChange)="getIssues()" multiple placeholder="Issue Status"
                    [(ngModel)]="selectedStatus">

                    <mat-option value="open">
                      Open
                    </mat-option>

                    <mat-option value="in_progress">
                      In Progress
                    </mat-option>

                    <mat-option value="closed">
                      Closed
                    </mat-option>

                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="issueTypes?.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Issue Type</mat-label>
                  <mat-select (selectionChange)="getIssues()" placeholder="Issue Type" multiple
                    [(ngModel)]="selectedIssueTypes">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="issueTypeDropDownSearch"
                        placeholderLabel="Search Issue Type ..." noEntriesFoundLabel="No matching Issue Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let issueType of filteredIssueTypes" [value]="issueType.id">
                      {{ issueType.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div (click)="picker.open()" class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>

              <!-- <div class="d-flex">
                <button *ngIf="!showSearch" mat-icon-button color="primary" class="m-l-10" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                  (click)="clearFilter()">
                  Clear Filters
                </button>
              </div> -->
            </div>
            <div class="d-flex">
              <button mat-raised-button color="primary" [disabled]="isFilterApplied()" (click)="clearFilter()">
                Clear Filters
              </button>
            </div>

            <!-- <div class="search" *ngIf="showSearch">
              <mat-form-field class="search-box">
                <mat-label>Search</mat-label>
                <input matInput #issueSearch [(ngModel)]="searchedText"
                  [placeholder]="searchOn.length ? searchOn : 'Search'" (keyup)="getIssues()" />
                <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div> -->

            <div *ngIf="length > 0" class="example-container ex-c-general">
              <table mat-table [dataSource]="dataSource" [matSortActive]="orderOn" [matSortDirection]="orderBy"
                class="table employee-list no-wrap" matSort (matSortChange)="sortData($event)">

                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="issue_identifier">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Issue Id </th>
                  <td class="might-overflow" [matTooltip]="element.issue_identifier" mat-cell *matCellDef="let element">
                    {{ element.issue_identifier || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="issue_title">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
                  <td class="might-overflow" [matTooltip]="element.issue_title" mat-cell *matCellDef="let element">
                    {{ element.issue_title || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                  <td class="might-overflow" [matTooltip]="this.issueStatus[element.status]" mat-cell
                    *matCellDef="let element">
                    {{ this.issueStatus[element.status] || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="issue_type">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Issue type </th>
                  <td class="might-overflow" [matTooltip]="element.issue_type.name" mat-cell *matCellDef="let element">
                    {{ element.issue_type.name || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="issue_user">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Reported By </th>
                  <td class="might-overflow" [matTooltip]="element.issue_user.email || '-' " mat-cell
                    *matCellDef="let element">
                    {{ element.issue_user.email || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <a [matTooltip]="tooltip.view_issue" [routerLink]="['/issue/detail/', element.id]"
                      class="m-r-10 cursor-pointer eye">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                    <a [matTooltip]="tooltip.delete_issue" (click)="openDialog('Delete',element)"
                      class="m-r-10 cursor-pointer">
                      <i class="fa text-danger fa-trash deleteFont"></i>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf="length === 0">No record found</div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getIssues($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>