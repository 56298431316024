import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonConstants } from 'src/app/shared/common-constants';
import { CommonMessages } from 'src/app/shared/common-messages';
import { Smell } from 'src/app/shared/models/smell';
import { Usecase } from 'src/app/shared/models/usecase';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { SmellService } from 'src/app/shared/services/smell/smell.service';
import { UsecaseService } from 'src/app/shared/services/usecase/usecase.service';
import { UsecasesComponent } from '../usecases/usecases.component';
@Component({
  selector: 'app-usecase-dialog',
  templateUrl: './usecase-dialog.component.html',
  styleUrls: ['./usecase-dialog.component.scss'],
})
export class UsecaseDialogComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public usecaseForm: FormGroup;
  public measurementTypes: any = [];
  public substancesToRecognize: Smell[] = [];
  public isProcessing: boolean = false;
  public showSubstances = null;
  public smellDropDownSearch: FormControl = new FormControl();
  public filteredSmells: any = [];
  public usecaseBlock: any = [];
  public usecaseBlockColumns: any = ['key', 'value'];

  constructor(
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private smellService: SmellService,
    private usecaseService: UsecaseService,
    @Inject(MAT_DIALOG_DATA) public data: UsecasesComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
    if (!!this.local_data && this.local_data.name) {
      const substance_ids =
        this.local_data.usecase_related_substances_info?.map(
          (sub: any) => sub.substance_id
        );
      this.usecaseForm = new FormGroup({
        name: new FormControl(this.local_data.name, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        description: new FormControl(
          this.local_data.description,
          Validators.maxLength(CommonConstants.maxLength5000)
        ),
        unknownSubstance: new FormControl(this.local_data.unknown_substance),
        monitoringShortMeasurement: new FormControl(
          this.local_data.monitoring_short_measurement
        ),
        substancesToRecognize: new FormControl(substance_ids),
      });
    } else {
      this.usecaseForm = new FormGroup({
        name: new FormControl(null, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        description: new FormControl(
          null,
          Validators.maxLength(CommonConstants.maxLength5000)
        ),
        unknownSubstance: new FormControl(false),
        monitoringShortMeasurement: new FormControl('short_measurement'),
        substancesToRecognize: new FormControl(),
      });
    }

    this.measurementTypes = [
      { id: 'short_measurement', name: 'Short Measurement' },
      { id: 'monitoring', name: 'Monitoring Measurement' },
    ];
  }

  ngOnInit(): void {
    this.smellDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredSmells = this.substancesToRecognize.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    if (this.local_data.unknown_substance) {
      if (this.local_data.unknown_substance === 'Yes') {
        this.usecaseForm.patchValue({
          unknownSubstance: true,
        });
      } else if (this.local_data.unknown_substance === 'No') {
        this.usecaseForm.patchValue({
          unknownSubstance: false,
        });
      }
    }
    if (this.action === 'View') {
      this.usecaseForm.disable();
      this.showSubstances =
        this.local_data?.usecase_related_substances_info?.map(
          (s: any) => ' ' + s.name
        );
      this.getUsecaseInfo();
    } else {
      this.getSmells();
    }
  }

  private async getUsecaseInfo() {
    try {
      this.isProcessing = true;
      const res = await this.usecaseService.getUsecaseInfo(this.local_data.id);
      if (res) {
        this.local_data = res.data.usecaseInfo;
        if (this.local_data.monitoring_short_measurement) {
          const i = this.measurementTypes.findIndex((m: any) => {
            return m.id === this.local_data.monitoring_short_measurement;
          });
          if (i > -1) {
            this.local_data.measurement_type = this.measurementTypes[i].name;
          }
        }
        if (
          this.local_data.usecase_related_substances_info &&
          this.local_data.usecase_related_substances_info.length > 0
        ) {
          this.local_data.usecase_related_substances =
            this.local_data.usecase_related_substances_info
              .map((u: any) => {
                return u.substance_related_info.name;
              })
              .join(', ');
        }
        this.usecaseBlock = [
          {
            isDate: false,
            isLink: false,
            key: 'ID',
            value: this.local_data.id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Usecase Name',
            value: this.local_data.name,
          },
          {
            isDate: false,
            isLink: false,
            isTextArea: true,
            key: 'Description',
            value: this.local_data.description || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Smell / Substance to Recognize',
            value: this.local_data.usecase_related_substances || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Unknown Smell / Substance Present',
            value: this.local_data.unknown_substance ? 'Yes' : 'No',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Measurement Type',
            value: this.local_data.measurement_type || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Public / Private',
            value: this.local_data.is_public ? 'Public' : 'Private',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Created By',
            value: this.local_data.user_name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Email',
            value: this.local_data.user_email,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: this.local_data.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: this.local_data.updatedAt,
          },
        ];
      } else {
        this.closeDialog();
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
      this.closeDialog();
    } finally {
      this.isProcessing = false;
    }
  }

  isOptionDisabled(opt: any): boolean {
    return (
      this.usecaseForm.value.substancesToRecognize?.length >= 10 &&
      !this.usecaseForm.value.substancesToRecognize?.find(
        (el: any) => el == opt
      )
    );
  }

  async getSmells() {
    try {
      this.isProcessing = true;
      let baseSmellURL = `page=0&perPage=0&isPublic=true`;
      if (this.action === 'View' && !this.local_data.is_public) {
        baseSmellURL = `page=0&perPage=0&isPublic=false`;
      }
      const res = await this.smellService.getBaseSmells(baseSmellURL);
      if (res) {
        this.isProcessing = false;
        this.substancesToRecognize = res.data.substances;
        this.filteredSmells = this.substancesToRecognize;
        if (!!this.local_data) {
          //this.usecaseForm.controls.substancesToRecognize.patchValue(this.substancesToRecognize.find(bs => bs.id === this.local_data.parent_substance_id));
        }
      }
    } catch (err: any) {
      this.isProcessing = false;
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  async doAction() {
    await this.submitUsecaseForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  isBothArraysAreSame(arr1: any, arr2: any) {
    return (
      arr1?.length === arr2?.length &&
      arr1?.every((value: any, index: any) => value === arr2[index])
    );
  }

  async submitUsecaseForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const res = await this.usecaseService.removeUsecase(this.local_data.id);
        if (res) {
          this.helperService.showAlert(
            CommonMessages.USE_CASE_DELETD,
            'success'
          );
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else {
      if (this.usecaseForm.valid) {
        try {
          this.isProcessing = true;
          const usecase = {} as Usecase;
          usecase.name = this.usecaseForm.value.name;
          usecase.description = this.usecaseForm.value.description || null;
          usecase.monitoring_short_measurement =
            this.usecaseForm.value.monitoringShortMeasurement?.toLowerCase();
          usecase.unknown_substance = this.usecaseForm.value.unknownSubstance;
          usecase.substance_ids =
            this.usecaseForm.value.substancesToRecognize?.length > 0
              ? this.usecaseForm.value.substancesToRecognize
              : null;

          if (this.action === 'Update') {
            usecase.id = this.local_data.id;
            usecase.is_substances_changed = !this.isBothArraysAreSame(
              this.local_data.substance_ids,
              usecase.substance_ids
            );
          }
          let res: any;
          if (this.action === 'Add') {
            res = await this.usecaseService.addUsecase(usecase);
          } else if (this.action === 'Update') {
            res = await this.usecaseService.updateUsecase(usecase);
          }
          if (res) {
            this.helperService.showAlert(
              this.action === 'Add'
                ? CommonMessages.USE_CASE_ADDED
                : CommonMessages.USE_CASE_UPDATED,
              'success'
            );
          }
          this.dialogRef.close({
            event: this.action,
            data: { ...this.local_data, ...res.data },
            success: true,
          });
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        } finally {
          this.isProcessing = false;
        }
      }
    }
  }
}
