<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Use Case</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3 usecase-dialog-content" *ngIf="action != 'Delete'; else elseTemplate">
  <div *ngIf="action != 'View'">
    <form [formGroup]="usecaseForm">
      <div fxLayout="row wrap" class="align-items-center form-wrapper">
        <div fxFlex="100" fxFlex.gt-md="100">
          <div class="overlay" *ngIf="isProcessing">
            <mat-spinner class="loader"></mat-spinner>
          </div>
          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label> Usecase Name</mat-label>
              <input type="text" required matInput formControlName="name" name="name" class="form-control" id="name" />
              <mat-error *ngIf="
                          usecaseForm.controls['name'].invalid &&
                          (usecaseForm.controls['name'].errors.required || usecaseForm.controls['name'].errors?.isItBlank) &&
                          (usecaseForm.controls['name'].dirty || usecaseForm.controls['name'].touched)"
                class="text-danger">
                Usecase Name is required</mat-error>
              <mat-error *ngIf="usecaseForm.controls['name'].invalid && usecaseForm.controls['name'].errors.maxlength"
                class="text-danger">
                Maximum 60 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <textarea rows="4" cols="40" formControlName="description" name="description" #description matInput
                placeholder="Description"></textarea>
              <mat-error
                *ngIf="usecaseForm.controls['description'].invalid && usecaseForm.controls['description'].errors.maxlength"
                class="text-danger">
                Maximum 5000 characters are allowed
              </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="action !== 'View'" class="m-r-15 m-l-15 m-b-15">
            <mat-form-field class="example-full-width">
              <mat-label>Smell / Substance to Recognize</mat-label>
              <mat-select name="substancesToRecognize" formControlName="substancesToRecognize"
                placeholder="Smell / Substance to Recognize" multiple>
                <mat-option>
                  <ngx-mat-select-search [formControl]="smellDropDownSearch"
                    placeholderLabel="Search Smell / Substance ..."
                    noEntriesFoundLabel="No matching Smell / Substance found">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option [disabled]="isOptionDisabled(smell.id)" *ngFor="let smell of filteredSmells"
                  [value]="smell.id">
                  {{ smell.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>



          <div *ngIf="action === 'View'" class="m-r-15 m-l-15 m-b-15">
            <h5 *ngIf="showSubstances?.length>0">Smell / Substance to Recognize : {{showSubstances }}</h5>
            <h5 *ngIf="showSubstances?.length == 0">Smell / Substance to Recognize : No Smell / Substance Available</h5>
          </div>

          <div class="m-r-15 m-l-15 m-b-15">
            <mat-label> Unknown Smell / Substance Present</mat-label> <br>
            <mat-radio-group formControlName="unknownSubstance" name="unknownSubstance" aria-label="Select an option">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
            </mat-radio-group>
          </div>



          <div class="m-r-15 m-l-15">
            <mat-form-field>
              <mat-label>Measurement Type</mat-label>
              <mat-select placeholder="Category" formControlName="monitoringShortMeasurement"
                name="monitoringShortMeasurement">
                <mat-option *ngFor="let measurementType of measurementTypes" [value]="measurementType.id">
                  {{ measurementType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Public/Private : {{ local_data?.is_public ? 'Public' : 'Private' }}</h5>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Created By : {{local_data?.user_name || 'NA' }}</h5>
          </div>

          <div class="m-r-15 m-l-15" *ngIf="action ==='View'">
            <h5 class="m-t-0"> Email : {{local_data?.user_email || 'NA' }}</h5>
          </div>

        </div>
      </div>
      <button *ngIf="action != 'View'" mat-button (click)="doAction()" mat-flat-button color="primary"
        [disabled]="isProcessing || !usecaseForm.valid">{{action}}</button>
      <button mat-button class="m-l-10" (click)="closeDialog()"> {{action != 'View' ? 'Cancel' : 'Close'}}</button>
    </form>
  </div>
  <div *ngIf="action === 'View'">
    <div class="">
      <table mat-table [dataSource]="usecaseBlock" class="table employee-list td-ht-sm no-wrap">

        <ng-container matColumnDef="key">
          <td class="might-overflow might-overflow-sm" mat-cell *matCellDef="let element">
            {{ element.key }}
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <td class="might-overflow-link"
            [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value" mat-cell
            *matCellDef="let element">
            <div *ngIf="element.isDate">
              {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
            </div>
            <div class="td-lg" *ngIf="element.isTextArea">
              <div class="td-lg" *ngIf="element.value.length > 15">
                <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40" [(ngModel)]="element.value"
                  #description matInput class="scrollbar" placeholder="Description"></textarea>
              </div>
              <div *ngIf="element.value.length < 16">
                {{ element.value }}
              </div>
            </div>
            <div *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
              {{ element.value }}
            </div>
            <div *ngIf="element.isLink">
              <a href="{{element.value}}">{{element.value}}</a>
            </div>
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: usecaseBlockColumns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>

<ng-template #elseTemplate>
  <p>Sure to delete usecase <b>{{local_data.name}} </b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>