import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UsersComponent } from '../users/users.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-assign-user-storage',
  templateUrl: './assign-user-storage.component.html',
  styleUrls: ['./assign-user-storage.component.scss'],
})
export class AssignUserStorageComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public selectedImage: any = '';
  public joiningDate: any = '';
  public assignSpaceForm: any;
  public isProcessing: boolean = false;
  public new_total_storage_space = 0;
  public new_available_storage_space = 0;
  public new_total_storage_space_mb: string = '0 MB';
  public new_available_storage_space_mb: string = '0 MB';
  public isAllow: boolean = false;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private userService: UserService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: UsersComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddContentForm();
  }

  validNumber(event: any) {
    let value: any = +event.target.value;
    if (value !== undefined) {
      if (value < 0) {
        value = value * -1;
      }
      if (value === 0) {
        this.assignSpaceControls['storageSize'].setErrors({ incorrect: true });
        this.assignSpaceControls.storageSize.value = null;
        return (event.target.value = null);
      }
      if (value > 5024) {
        this.assignSpaceControls['storageSize'].setErrors({ incorrect: true });
        this.assignSpaceControls.storageSize.value = null;
        return (event.target.value = null);
      }

      value = parseInt(value, 10);
      event.target.value = value;
      this.local_data.storageSize = value;
      this.new_available_storage_space =
        +this.local_data.available_storage_space + +value * 1024;
      this.new_total_storage_space =
        +this.local_data.total_storage_space + +value * 1024;
      this.new_available_storage_space_mb = this.helperService.kbToMBConvertor(
        this.new_available_storage_space
      );
      this.new_total_storage_space_mb = this.helperService.kbToMBConvertor(
        this.new_total_storage_space
      );
    }
  }

  // convenience getter for easy access to form fields
  get assignSpaceControls() {
    return this.assignSpaceForm.controls;
  }

  resetAddContentForm() {
    this.assignSpaceForm = this.fb.group({
      storageSize: [null, Validators.compose([Validators.required])],
    });
  }

  async doAction() {
    await this.assignStorageForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async assignStorageForm() {
    if (this.assignSpaceForm.valid) {
      try {
        this.isProcessing = true;
        const reqData: any = {
          storageSize: +this.assignSpaceForm.controls.storageSize.value * 1024, // Convert MB to KB
        };
        const { userId } = this.local_data;
        this.isProcessing = true;
        const res = await this.userService.assignStorage(userId, reqData);
        this.local_data.id = res.data.result.id;
        this.local_data.storage_size = res.data.result.storage_size;
        this.local_data.createdAt = res.data.result.createdAt;
        this.local_data.storage_allocation_history_admin_user =
          res.data.assignedBy;
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    }
  }
}
