import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UserStorageComponent } from './user-storage/user-storage.component';

const routes: Routes = [
  {
    path: '',
    component: UsersComponent,
    data: {
      title: 'User',
      urls: [{ title: 'Dashboard', url: '/' }, { title: 'User' }]
    }
  },
  {
    path: 'detail/:userId',
    component: UserDetailComponent,
    data: {
      title: 'User Detail',
      urls: [
        { title: 'Dashboard', url: '/' },
        { title: 'User', url: '/user' },
        { title: 'User Detail' }
      ]
    }
  },
  {
    path: 'storage/:userId',
    component: UserStorageComponent,
    data: {
      title: 'User Storage',
      urls: [
        { title: 'Dashboard', url: '/' },
        { title: 'User', url: '/user' },
        { title: 'User Storage' }
      ]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
