import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmellRoutingModule } from './smell-routing.module';
import { SmellsComponent } from './smells/smells.component';
import { DemoMaterialModule } from '../demo-material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ChartsModule } from 'ng2-charts';
import { ChartistModule } from 'ng-chartist';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SmellDialogComponent } from './smell-dialog/smell-dialog.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
  declarations: [SmellsComponent, SmellDialogComponent],
  imports: [
    CommonModule,
    SmellRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgxDatatableModule,
    FlexLayoutModule,
    RouterModule,
    ChartistModule,
    NgApexchartsModule,
    ChartsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule 
  ]
})
export class SmellModule { }
