import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UserResetPasswordComponent } from '../user-reset-password/user-reset-password.component';
import { AssignClassifiersComponent } from '../assign-classifiers/assign-classifiers.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  profile_picture: string;
  user_type: string;
  available_storage_space: number;
  total_storage_space: number;
  createdAt: any;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'first_name',
    'last_name',
    'displayed_email',
    'user_type',
    'measurementSetupCount',
    'smellCount',
    'usecaseCount',
    'measurementCount',
    'available_storage_space',
    'total_storage_space',
    'createdAt',
  ];

  public i: number = 1;
  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public tooltip = {
    assignMLCalssifier: 'Assign ML Classifier',
    resetPassword: 'Reset Password',
    userDetail: 'User Detail',
    userStorage: 'User Storage',
  };
  public filteredSmells: any = [];
  public smellDropDownSearch: FormControl = new FormControl();
  @ViewChild('allSmellCheckBox') private allSmellCheckBox: MatOption =
    Object.create(null);
  public filteredUsecases: any = [];
  public usecaseDropDownSearch: FormControl = new FormControl();
  @ViewChild('allUsecaseCheckBox') private allUsecaseCheckBox: MatOption =
    Object.create(null);
  public filteredMeasSetups: any = [];
  public measSetupDropDownSearch: FormControl = new FormControl();
  @ViewChild('allMeasSetupCheckBox') private allMeasSetupCheckBox: MatOption =
    Object.create(null);
  @ViewChild('userSearch') userSearch: ElementRef | any;

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = [];
  public searchOn = [];
  public futureDate = new Date();

  // Filter mechanism
  public filterOptions = {
    measurementSetups: [],
    smells: [],
    usecases: [],
    userTypes: [],
  };
  public measurementSetups: any = [];
  public smells: any = [];
  public usecases: any = [];
  public userTypes = [];
  public toDate: any = '';
  public fromDate: any = '';
  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setDefaultDates();
    this.getUserFilters();
    this.getUsers();

    this.smellDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredSmells = this.filterOptions.smells.filter((option: any) =>
        option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.usecaseDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredUsecases = this.filterOptions.usecases.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.measSetupDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredMeasSetups = this.filterOptions.measurementSetups.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getUsers();
    }
  }
  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getUsers();
  }

  singleSelection(dropDownName: string) {
    if (dropDownName === 'smell') {
      if (this.allSmellCheckBox.selected) {
        this.allSmellCheckBox.deselect();
      }

      if (this.smells.length === this.filterOptions.smells.length) {
        this.allSmellCheckBox.select();
        this.smells = [
          ...this.filterOptions.smells.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'usecase') {
      if (this.allUsecaseCheckBox.selected) {
        this.allUsecaseCheckBox.deselect();
      }

      if (this.usecases.length === this.filterOptions.usecases.length) {
        this.allUsecaseCheckBox.select();
        this.usecases = [
          ...this.filterOptions.usecases.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'measSetup') {
      if (this.allMeasSetupCheckBox.selected) {
        this.allMeasSetupCheckBox.deselect();
      }

      if (
        this.measurementSetups.length ===
        this.filterOptions.measurementSetups.length
      ) {
        this.allMeasSetupCheckBox.select();
        this.measurementSetups = [
          ...this.filterOptions.measurementSetups.map((item: any) => item.id),
          -1,
        ];
      }
    }
    this.getUsers();
  }

  allSelection(dropDownName: string) {
    if (dropDownName === 'smell') {
      if (this.allSmellCheckBox.selected) {
        this.smells = [
          ...this.filterOptions.smells.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.smells = [];
      }
    } else if (dropDownName === 'usecase') {
      if (this.allUsecaseCheckBox.selected) {
        this.usecases = [
          ...this.filterOptions.usecases.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.usecases = [];
      }
    } else if (dropDownName === 'measSetup') {
      if (this.allMeasSetupCheckBox.selected) {
        this.measurementSetups = [
          ...this.filterOptions.measurementSetups.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.measurementSetups = [];
      }
    }
    this.getUsers();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getUsers(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let userURL = '';

      // SEARCH
      if (this.searchedText) {
        userURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }

      if (this.searchOn.length) {
        userURL += `&searchOn=${JSON.stringify(this.searchOn)}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        userURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS
      if (this.measurementSetups.length) {
        userURL += `&measurementSetups=[${this.measurementSetups}]`;
        this.setDefaultPage(event);
      }
      if (this.smells.length) {
        userURL += `&smells=[${this.smells}]`;
        this.setDefaultPage(event);
      }
      if (this.usecases.length) {
        userURL += `&usecases=[${this.usecases}]`;
        this.setDefaultPage(event);
      }
      if (this.userTypes.length) {
        userURL += `&userTypes=${JSON.stringify(this.userTypes)}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        userURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }

      userURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.userService.getUsers(userURL);
      if (res) {
        this.data = res.data.users;
        this.dataSource = res.data.users;
        this.dataSource.forEach((d: any) => {
          d.available_storage_space_mb = this.helperService.kbToMBConvertor(
            d.available_storage_space
          );
          d.total_storage_space_mb = this.helperService.kbToMBConvertor(
            d.total_storage_space
          );
        });
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getUserFilters() {
    try {
      const res = await this.userService.getUserFilters();
      if (res) {
        this.filterOptions = res.data.filterTerms;
        this.searchOptions = res.data.searchTerms;
        this.filteredSmells = this.filterOptions.smells;
        this.filteredMeasSetups = this.filterOptions.measurementSetups;
        this.filteredUsecases = this.filterOptions.usecases;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.userSearch && this.userSearch.nativeElement) {
        this.userSearch.nativeElement.focus();
      }
    });
  }

  searchWithTerm() {
    if (this.searchedText) {
      this.getUsers();
    }
  }

  isFilterApplied() {
    return !(
      this.measurementSetups.length ||
      this.smells.length ||
      this.usecases.length ||
      this.userTypes.length ||
      this.toDate
    );
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getUsers();
  }

  clearFilter() {
    this.measurementSetups = [];
    this.smells = [];
    this.usecases = [];
    this.userTypes = [];
    this.fromDate = '';
    this.toDate = '';
    this.getUsers();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openResetDialog(obj: any): void {
    this.dialog.open(UserResetPasswordComponent, {
      minWidth: '50%',
      maxHeight: '90vh',
      data: obj,
    });
  }

  assignClassifier(obj: any): void {
    this.dialog.open(AssignClassifiersComponent, {
      minWidth: '70%',
      maxHeight: '90vh',
      data: obj,
    });
  }
}
