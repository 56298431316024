import {
  Component,
  OnInit,
  Inject,
  Optional,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FaqService } from 'src/app/shared/services/faq/faq.service';
import { MatOption } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
export interface Faq {
  id: number;
  title: string;
  description: string;
  faq_category: any;
  createdAt: any;
}

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'title',
    'category',
    'createdAt',
  ];
  public i: number = 1;
  public data = {};
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  public dataSource: any = [];
  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public categories: any = [];
  public selectedCategories: any = [];
  public filteredCategories: any = [];
  public searchedText: string = '';
  public showSearch: boolean = false;
  public categorySearch: FormControl = new FormControl();
  public toDate: any = '';
  public fromDate: any = '';
  public futureDate = new Date();

  // Tooltip
  public tooltip = {
    view_faq: 'View FAQ',
    update_faq: 'Update FAQ',
    delete_faq: 'Delete FAQ',
  };

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild('allCategoryCheckbox') private allCategoryCheckbox: MatOption =
    Object.create(null);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator =
    Object.create(null);
  @ViewChild('faqSearch') faqSearch: ElementRef | any;

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private faqService: FaqService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getFaqs();
    this.faqGetCategories();
    this.categorySearch.valueChanges.subscribe((val) => {
      this.filteredCategories = this.categories.filter((option: any) =>
        option.category.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }
  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getFaqs();
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getFaqs();
  }

  async faqGetCategories() {
    try {
      const page = 1;
      const perPage = 100;
      const faqCategoryURL = `page=${page}&perPage=${perPage}`;
      const res = await this.faqService.faqGetCategories(faqCategoryURL);
      if (res) {
        this.categories = res.data.faqCategories;
        this.filteredCategories = this.categories;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  singleSelection(dropDownName: string) {
    if (dropDownName === 'category') {
      if (this.allCategoryCheckbox.selected) {
        this.allCategoryCheckbox.deselect();
      }

      if (this.selectedCategories.length === this.categories.length) {
        this.allCategoryCheckbox.select();
        this.selectedCategories = [
          ...this.categories.map((item: any) => item.id),
          -1,
        ];
      }
    }
    this.getFaqs();
  }

  allSelection(dropDownName: string) {
    if (dropDownName === 'category') {
      if (this.allCategoryCheckbox.selected) {
        this.selectedCategories = [
          ...this.categories.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.selectedCategories = [];
      }
    }
    this.getFaqs();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getFaqs(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let faqURL = '';
      if (this.searchedText) {
        faqURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }

      if (this.orderOn && this.orderBy) {
        faqURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      if (this.selectedCategories.length) {
        faqURL += `&categoryIds=${this.selectedCategories.join(',')}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        faqURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }
      faqURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.faqService.getFaqs(faqURL);
      if (res) {
        this.data = res.data.faqs;
        this.dataSource = res.data.faqs;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.faqSearch && this.faqSearch.nativeElement) {
        this.faqSearch.nativeElement.focus();
      }
    });
  }

  clearSearch() {
    this.searchedText = '';
    this.showHideSearch();
    this.getFaqs();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isFilterApplied() {
    return !(this.selectedCategories.length > 0 || this.toDate);
  }

  clearFilter() {
    this.fromDate = '';
    this.toDate = '';
    this.selectedCategories = [];
    this.getFaqs();
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(FaqDialogComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxWidth: '500px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        } else if (result.event === 'Update') {
          if (result.success) {
            this.updateRowData(result.data);
          }
        } else if (result.event === 'Delete') {
          if (result.success) {
            this.deleteRowData();
          }
        }
      }
    });
  }

  addRowData(row_obj: Faq): void {
    if (this.dataSource.length === this.pageSize) {
      this.dataSource.pop();
    }
    this.dataSource = [row_obj, ...this.dataSource];
    this.length += 1;
    this.table.renderRows();
  }

  updateRowData(row_obj: Faq): boolean | void {
    this.dataSource = this.dataSource.filter((value: any) => {
      if (value.id === row_obj.id) {
        value.title = row_obj.title;
        value.description = row_obj.description;
        value.faq_category = row_obj.faq_category;
        value.createdAt = row_obj.createdAt;
      }
      return true;
    });
    this.table.renderRows();
  }

  deleteRowData() {
    this.getFaqs();
  }
}
