<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Detector Type</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3" *ngIf="action != 'Delete'; else elseTemplate">
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <form [formGroup]="addDetectorTypeForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input type="text" matInput required id="name" name="name" [(ngModel)]="local_data.name" placeholder="Name"
              formControlName="name">
            <mat-error *ngIf="!addDetectorTypeControls.name.value && addDetectorTypeForm.get('name').touched"
              class="text-danger m-b-5">Name is required</mat-error>
            <mat-error
              *ngIf="addDetectorTypeControls.name.value && addDetectorTypeForm.get('name').touched && addDetectorTypeForm.get('name').hasError('whitespace')">
              Please enter valid name
            </mat-error>

            <mat-error
              *ngIf="addDetectorTypeControls.name.value && addDetectorTypeForm.get('name').hasError('maxlength')"
              class="text-danger">
              Maximum 60 characters are allowed
            </mat-error>

          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input type="text" matInput required [(ngModel)]="local_data.functionalization"
              placeholder="Functionalization" formControlName="functionalization">
            <mat-error
              *ngIf="!addDetectorTypeControls.functionalization.value && addDetectorTypeForm.get('functionalization').touched"
              class="text-danger">Functionalization is required</mat-error>
            <mat-error
              *ngIf="addDetectorTypeForm.get('functionalization').invalid && !addDetectorTypeControls.functionalization.errors.required && addDetectorTypeForm.get('functionalization').touched"
              class="text-danger">Functionalization is invalid</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input min="0" type="number" (keyup)="validNumber($event, 'lower_bound')" matInput required
              name="lower_bound" [(ngModel)]="local_data.lower_bound" placeholder="Lower Bound"
              formControlName="lower_bound">
            <mat-error
              *ngIf="!addDetectorTypeControls.lower_bound.value && addDetectorTypeForm.get('lower_bound').touched"
              class="text-danger">Lower Bound is required</mat-error>
            <mat-error *ngIf="addDetectorTypeForm.get('lower_bound').hasError('invalidInt')" class="text-danger">Please
              provide valid lower bound</mat-error>
          </mat-form-field>
        </div>

        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input min="0" type="number" (keyup)="validNumber($event, 'upper_bound')" matInput required
              name="upper_bound" [(ngModel)]="local_data.upper_bound" placeholder="Upper Bound"
              formControlName="upper_bound">
            <mat-error
              *ngIf="!addDetectorTypeControls.upper_bound.value && addDetectorTypeForm.get('upper_bound').touched && !addDetectorTypeForm.get('upper_bound').hasError('invalidUpperBound')"
              class="text-danger">Upper Bound is required</mat-error>
            <mat-error *ngIf="addDetectorTypeForm.get('upper_bound').hasError('invalidInt')" class="text-danger">Please
              provide valid upper bound</mat-error>
            <mat-error
              *ngIf="addDetectorTypeForm.get('upper_bound').hasError('invalidUpperBound') && addDetectorTypeForm.controls.upper_bound.status === 'INVALID'"
              class="text-danger">
              Upper bound should be greater than lower bound
            </mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="isProcessing || !addDetectorTypeForm.valid">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>
<ng-template #elseTemplate>
  <p>Sure to delete <b>{{local_data.name}}</b> ?</p>
  <div mat-dialog-actions align="end" class="pt-3">
    <button mat-button (click)="doAction()" mat-flat-button color="warn">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </div>
</ng-template>