import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuardService } from './shared/services/auth/auth-guard.service';
import { FaqModule } from './faq/faq.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { UserModule } from './user/user.module';
import { ClassifierModule } from './classifier/classifier.module';
import { DetectorTypeModule } from './detector-type/detector-type.module';
import { SmellModule } from './smell/smell.module';
import { UsecaseModule } from './usecase/usecase.module';
import { MeasurementSetupModule } from './measurement-setup/measurement-setup.module';
import { FirmwareModule } from './firmware/firmware.module';
import { DetectorModule } from './detector/detector.module';
import { DeviceTypeModule } from './device-type/device-type.module';
import { DeviceModule } from './device/device.module';
import { IssueModule } from './issue/issue.module';
import { MeasurementModule } from './measurement/measurement.module';
import { AppVersionUpdateModule } from './app-version-update/app-version-update.module';
import { ImportDeviceModule } from './import-device/import-device.module';
import { ImportDetectorBoardModule } from './import-detector-board/import-detector-board.module';
import { DetectorBoardModule } from './detector-board/detector-board.module';

export const AppRoutes: Routes = [
  {
    path: 'authentication',
    loadChildren: () => AuthenticationModule,
    // resolve: [AuthService]
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => DashboardsModule,
      },
      {
        path: 'faq',
        loadChildren: () => FaqModule,
      },
      {
        path: 'user',
        loadChildren: () => UserModule,
      },
      {
        path: 'measurements',
        loadChildren: () => MeasurementModule,
      },
      // {
      //   path: 'measurement/measurements',
      //   loadChildren: () => MeasurementModule,
      // },
      {
        path: 'measurement/smell',
        loadChildren: () => SmellModule,
      },
      {
        path: 'measurement/usecase',
        loadChildren: () => UsecaseModule,
      },
      {
        path: 'measurement/measurement-setup',
        loadChildren: () => MeasurementSetupModule,
      },
      {
        path: 'classifier',
        loadChildren: () => ClassifierModule,
      },
      {
        path: 'device/detector-type',
        loadChildren: () => DetectorTypeModule,
      },
      {
        path: 'firmware',
        loadChildren: () => FirmwareModule,
      },
      {
        path: 'app-version-update',
        loadChildren: () => AppVersionUpdateModule,
      },
      {
        path: 'issue',
        loadChildren: () => IssueModule,
      },
      {
        path: 'device/detector',
        loadChildren: () => DetectorModule,
      },
      {
        path: 'device/device-type',
        loadChildren: () => DeviceTypeModule,
      },
      {
        path: 'device/devices',
        loadChildren: () => DeviceModule,
      },
      {
        path: 'device/sensor-boards',
        loadChildren: () => DetectorBoardModule,
      },
      {
        path: 'device/import-devices',
        loadChildren: () => ImportDeviceModule,
      },
      {
        path: 'device/import-sensor-boards',
        loadChildren: () => ImportDetectorBoardModule,
      },
      {
        path: '**',
        loadChildren: () => DashboardsModule,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/login',
  },
];
