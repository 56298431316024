import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonConstants } from 'src/app/shared/common-constants';
import { CommonMessages } from 'src/app/shared/common-messages';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { MeasurementSetupService } from 'src/app/shared/services/measurement-setup/measurement-setup.service';
import { MeasurementSetupsComponent } from '../measurement-setups/measurement-setups.component';

@Component({
  selector: 'app-measurement-setup-dialog',
  templateUrl: './measurement-setup-dialog.component.html',
  styleUrls: ['./measurement-setup-dialog.component.scss'],
})
export class MeasurementSetupDialogComponent implements OnInit {
  indoorOutdoorMenu: any = [];
  measurementTypeForm: FormGroup;
  public action: string = 'Add';
  public local_data: any;
  public isProcessing: boolean = false;
  public measurementSetupBlock: any = [];
  public measurementSetupBlockColumns: any = ['key', 'value'];

  constructor(
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private measurementSetupService: MeasurementSetupService,
    @Inject(MAT_DIALOG_DATA) public data: MeasurementSetupsComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';

    if (!!this.local_data && this.local_data.name) {
      this.measurementTypeForm = new FormGroup({
        name: new FormControl(this.local_data.name, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        description: new FormControl(
          this.local_data.description,
          Validators.maxLength(CommonConstants.maxLength5000)
        ),
        indoorOutdoor: new FormControl(this.local_data.indoor_outdoor),
      });
    } else {
      this.measurementTypeForm = new FormGroup({
        name: new FormControl(null, [
          Validators.required,
          Validators.maxLength(CommonConstants.maxLength60),
          this.helperService.isFieldValueBlank.bind(this),
        ]),
        description: new FormControl(
          null,
          Validators.maxLength(CommonConstants.maxLength5000)
        ),
        indoorOutdoor: new FormControl('indoor'),
      });
    }

    this.indoorOutdoorMenu = [
      { id: 'indoor', name: 'Indoor' },
      { id: 'outdoor', name: 'Outdoor' },
    ];
  }

  ngOnInit(): void {
    if (this.action === 'View') {
      this.measurementTypeForm.disable();
      this.getMeasurementSetupInfo();
    }
  }

  private async getMeasurementSetupInfo() {
    try {
      this.isProcessing = true;
      const res = await this.measurementSetupService.getMeasurementSetupInfo(
        this.local_data.id
      );
      if (res) {
        this.local_data = res.data.measurementTypeInfo;
        this.measurementSetupBlock = [
          {
            isDate: false,
            isLink: false,
            key: 'ID',
            value: this.local_data.id,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Measurement Setup Name',
            value: this.local_data.name,
          },
          {
            isDate: false,
            isLink: false,
            isTextArea: true,
            key: 'Description',
            value: this.local_data.description || '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Indoor or Outdoor',
            value: this.local_data.indoor_outdoor
              ? this.local_data.indoor_outdoor[0].toUpperCase() +
                this.local_data.indoor_outdoor.substring(1)
              : '-',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Public / Private',
            value: this.local_data.is_public ? 'Public' : 'Private',
          },
          {
            isDate: false,
            isLink: false,
            key: 'Created By',
            value: this.local_data.user_name,
          },
          {
            isDate: false,
            isLink: false,
            key: 'Email',
            value: this.local_data.user_email,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Created At',
            value: this.local_data.createdAt,
          },
          {
            isDate: true,
            isLink: false,
            key: 'Last Updated At',
            value: this.local_data.updatedAt,
          },
        ];
      } else {
        this.closeDialog();
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
      this.closeDialog();
    } finally {
      this.isProcessing = false;
    }
  }

  async doAction() {
    await this.submitMeasurementTypeForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async submitMeasurementTypeForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const res = await this.measurementSetupService.removeMeasurementType(
          this.local_data.id
        );
        if (res) {
          this.helperService.showAlert(
            CommonMessages.MEASUREMENT_SETUP_DELETD,
            'success'
          );
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else {
      if (this.measurementTypeForm.valid) {
        try {
          this.isProcessing = true;
          const measurementType: any = {
            name: this.measurementTypeForm.value.name,
            indoor_outdoor:
              this.measurementTypeForm.value.indoorOutdoor?.toLowerCase(),
            description: this.measurementTypeForm.value.description || null,
          };

          if (this.action === 'Update') {
            measurementType.id = this.local_data.id;
          }
          let res: any;
          if (this.action === 'Add') {
            res = await this.measurementSetupService.addMeasurementType(
              measurementType
            );
          } else if (this.action === 'Update') {
            res = await this.measurementSetupService.updateMeasurementType(
              measurementType
            );
          }
          if (res) {
            this.helperService.showAlert(
              this.action === 'Add'
                ? CommonMessages.MEASUREMENT_SETUP_ADDED
                : CommonMessages.MEASUREMENT_SETUP_UPDATED,
              'success'
            );
          }
          this.dialogRef.close({
            event: this.action,
            data: { ...this.local_data, ...res.data },
            success: true,
          });
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        } finally {
          this.isProcessing = false;
        }
      }
    }
  }
}
