<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>Assign Storage</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3">
  <form [formGroup]="assignSpaceForm">
    <div fxLayout="row wrap" class="align-items-center modal-fs">
      <div fxFlex="100" fxFlex.gt-md="100">
        <div class="m-r-15 m-l-15">
          <mat-form-field>
            <input max="5024" min="1" #storageSize type="number" (keyup)="validNumber($event)"
              (change)="validNumber($event)" matInput required id="storageSize" name="storageSize"
              [(ngModel)]="local_data.storageSize" placeholder="Storage size in MB" formControlName="storageSize">
            <mat-error *ngIf="!assignSpaceControls.storageSize.value && assignSpaceForm.get('storageSize').touched"
              class="text-danger">Storage size is required</mat-error>
          </mat-form-field>
          <div *ngIf='storageSize.value'>
            <div>
              <mat-label>Before Assigning: </mat-label>
              <br>
              <span class="text-muted">Total Space:</span> {{ local_data.total_storage_space_mb }}
              <span class="text-muted">Available Space:</span> {{ local_data.available_storage_space_mb }}
            </div>
            <div class="m-b-20">
              <mat-label>After Assigning: </mat-label>
              <br>
              <span class="text-muted">Total Space:</span> {{ new_total_storage_space_mb }}
              <span class="text-muted">Available Space:</span> {{ new_available_storage_space_mb }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='storageSize.value' class="modal-fs">
        <mat-checkbox color="primary" class="m-r-10" (change)="isAllow = $event.checked">
          <b> This operation never be undone. are you sure want to proceed further?</b>
        </mat-checkbox>
        <br>
        <br>
      </div>
    </div>
    <div class="overlay" *ngIf="isProcessing">
      <mat-spinner class="loader"></mat-spinner>
    </div>
    <button mat-button (click)="doAction()" mat-flat-button color="primary"
      [disabled]="!isAllow || isProcessing || !assignSpaceForm.valid">Assign</button>
    <button mat-button class="m-l-10" (click)="closeDialog()" [disabled]="isProcessing">Cancel</button>
  </form>
</mat-dialog-content>