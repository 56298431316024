<div class="modal-header mb-1">
  <h2 class="font-medium" mat-dialog-title><strong>{{action}} Baseline</strong></h2>
  <mat-icon (click)="closeDialog()" class="cursor-pointer">close</mat-icon>
</div>
<mat-dialog-content class="pb-3">
  <form [formGroup]="addBaselineForm">
    <div fxLayout="row wrap" class="align-items-center">
      <div fxFlex="100" fxFlex.gt-md="100">
        <div class="m-r-15 m-l-15">
          <b>NOTE:</b> The nearest baseline will be considered from selected baseline.
          <br />
          <br />
          <span class="text-muted">Min Time:</span> {{ minLocal }}
          <br />
          <span class="text-muted">Max Time:</span> {{ maxLocal }}
          <br />
          <br />
          <mat-form-field class="m-b-30">
            <input matInput required formControlName="timestamp" placeholder="Date-Time" id="datetime"
              type="datetime-local" class="baseline-time" required step="1" [min]="min" [max]="max"
              (change)="timeChanged($event.target.value)" />
            <mat-error *ngIf="!addBaselineControls.timestamp.value && addBaselineForm.get('timestamp').touched"
              class="text-danger">Date & Time is required</mat-error>
            <mat-error
              *ngIf="!!addBaselineControls.timestamp.value && !!addBaselineForm.get('timestamp').touched && !!eventRangeError"
              class="text-danger error-msg">Make sure that
              the base line date and time are
              between the beginning and end of the measurement</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <button mat-button (click)=" doAction()" mat-flat-button color="primary"
      [disabled]="!addBaselineForm.valid || eventRangeError ">{{action}}</button>
    <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
  </form>
</mat-dialog-content>