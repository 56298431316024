<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <div>
                <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <div class="search" *ngIf="showSearch">
                  <mat-form-field class="search-box">
                    <mat-label>Search</mat-label>
                    <input matInput #appVersionUpdateSearch [(ngModel)]="searchedText"
                      [placeholder]="searchOn.length ? searchOn : 'Search'" (keyup)="getAppVersionUpdates()" />
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Platform</mat-label>
                  <mat-select (selectionChange)="getAppVersionUpdates()" multiple placeholder="Platform Type"
                    [(ngModel)]="selectedPlatform">
                    <mat-option value="android">
                      android
                    </mat-option>
                    <mat-option value="ios">
                      ios
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Force Update</mat-label>
                  <mat-select (selectionChange)="getAppVersionUpdates()" placeholder="Force Update"
                    [(ngModel)]="selectedForceUpdate">
                    <mat-option value="true">
                      Yes
                    </mat-option>
                    <mat-option value="false">
                      No
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div (click)="picker.open()" class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>
            </div>
            <div class="d-flex">
              <button mat-raised-button color="primary" (click)="openDialog('Add',{})">Add
                App Version</button>
              <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                (click)="clearFilter()">Clear Filters</button>
            </div>

            <div *ngIf='length !== 0' class="example-container ex-c-general m-t-20">
              <table mat-table [dataSource]="dataSource" [matSortActive]="orderOn" [matSortDirection]="orderBy"
                class="table employee-list no-wrap" matSort (matSortChange)="sortData($event)">

                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="platform_type">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Platform Type </th>
                  <td class="might-overflow" [matTooltip]="element.platform_type" mat-cell *matCellDef="let element">
                    {{ element.platform_type || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="version">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
                  <td class="might-overflow" [matTooltip]="element.version" mat-cell *matCellDef="let element">
                    {{ element.version || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="is_force_update">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Is-Force </th>
                  <td class="might-overflow" [matTooltip]="element.is_force_update ? 'Yes': 'No'" mat-cell
                    *matCellDef="let element">
                    {{ element.is_force_update ? 'Yes': 'No' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="release_notes">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Release Note </th>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="!element.release_notes"> - </div>
                    <div *ngIf="element.release_notes && element.release_notes.length <= 29">
                      {{ element.release_notes || '-' }}
                    </div>
                    <div *ngIf="element.release_notes && element.release_notes.length > 29">
                      <textarea readonly [ngModelOptions]="{standalone: true}" rows="2" cols="40"
                        [(ngModel)]="element.release_notes" #description matInput class="scrollbar"
                        placeholder="Release Notes"></textarea>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="created_by">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                  <td class="might-overflow" [matTooltip]="element?.app_version_updates_admin_user?.email || '-'"
                    mat-cell *matCellDef="let element">
                    {{ element?.app_version_updates_admin_user?.email || '-' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link">
                    <a [matTooltip]="tooltip.view_firmware" class="m-r-10 cursor-pointer eye"
                      (click)="openDialog('View',element)">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                    <a [matTooltip]="tooltip.update_firmware" (click)="openDialog('Update',element)"
                      class="m-r-10 cursor-pointer"><i class="fa fa-pencil editFont editFont"></i></a>
                    <a [matTooltip]="tooltip.delete_firmware" (click)="openDialog('Delete',element)"
                      class="m-r-10 cursor-pointer">
                      <i class="fa text-danger fa-trash deleteFont"></i>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf="length === 0">No record found</div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getAppVersionUpdates($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>