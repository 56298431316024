import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoriesComponent } from './categories/categories.component';
import { FaqsComponent } from './faqs/faqs.component';

const routes: Routes = [
  {
    path: 'category',
    component: CategoriesComponent,
    data: {
      title: 'Category',
      icon: 'category',
      urls: [
        { title: 'Dashboard', url: '/' },
        { title: 'Category' }
      ]
    },
  },
  {
    path: 'list',
    component: FaqsComponent,
    data: {
      title: "FAQ",
      urls: [
        { title: 'Dashboard', url: '/' },
        { title: "FAQ" }
      ]
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule {

 }
