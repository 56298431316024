<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="filter-section m-b-5" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.gt-sm="60" fxFlex.sm="100">
              <div class="social-widget">
                <div class="d-flex align-items-center">
                  <h3 class="m-0 no-wrap px-5"> {{ userName | titlecase }}</h3>
                </div>
                <div fxLayout="row" fxLayoutWrap="wrap" class="text-center">
                  <mat-card class="d-flex">
                    <mat-card-content>
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="text-muted">Total</span>
                        <h3 class="font-medium m-0 no-wrap px-5">{{ total_storage_space_mb }}</h3>
                      </div>
                    </mat-card-content>
                  </mat-card>

                  <mat-card class="d-flex">
                    <mat-card-content>
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="text-muted">Used</span>
                        <h3 class="font-medium m-0 no-wrap px-5">{{ used_storage_space_mb }}</h3>
                      </div>
                    </mat-card-content>
                  </mat-card>

                  <mat-card class="d-flex">
                    <mat-card-content>
                      <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="text-muted">Available</span>
                        <h3 class="font-medium m-0 no-wrap px-5">{{ available_storage_space_mb }}</h3>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <button mat-raised-button color="primary" (click)="assignSpace()">
                Assign Storage
              </button>
            </div>
          </div>

          <div *ngIf='userStorageHistory.length !== 0' class="example-container ex-c-general">
            <div class="overlay" *ngIf="isProcessing">
              <mat-spinner class="loader"></mat-spinner>
            </div>

            <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table [dataSource]="userStorageHistory"
              matSort (matSortChange)="sortData($event)" class="table employee-list no-wrap">

              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef> Index </th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                </td>
              </ng-container>

              <ng-container matColumnDef="id">
                <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                <td mat-cell class="might-overflow" [matTooltip]="element.id" *matCellDef="let element">
                  {{ element.id }}
                </td>
              </ng-container>

              <ng-container matColumnDef="storage_size">
                <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Storage Size </th>
                <td mat-cell class="might-overflow" [matTooltip]="element.storage_size_mb" *matCellDef="let element">
                  {{ element.storage_size_mb }}
                </td>
              </ng-container>

              <ng-container matColumnDef="assigned_by">
                <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Assigned By </th>
                <td mat-cell class="might-overflow"
                  [matTooltip]="element?.storage_allocation_history_admin_user.first_name + ' ' + element?.storage_allocation_history_admin_user.last_name"
                  *matCellDef="let element">
                  {{ element?.storage_allocation_history_admin_user.first_name }}
                  {{ element?.storage_allocation_history_admin_user.last_name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="createdAt">
                <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Created At </th>
                <td mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

          </div>
          <div class="no-record" *ngIf="userStorageHistory.length === 0">No record found</div>
          <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="spaceCount" [pageIndex]="pageIndex"
            [pageSize]="pageSize" showFirstLastButtons (page)="getUserSpaceHistory($event)"></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>