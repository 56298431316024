import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { FaqsComponent } from '../faqs/faqs.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FaqService } from 'src/app/shared/services/faq/faq.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/customValidator';
import { CommonConstants } from 'src/app/shared/common-constants';

@Component({
  selector: 'app-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['./faq-dialog.component.scss'],
})
export class FaqDialogComponent implements OnInit {
  public action: string = 'Add';
  public local_data: any;
  public selectedImage: any = '';
  public joiningDate: any = '';
  public addFaqForm: any;
  public categories: any = [];
  public isProcessing: boolean = false;
  public categorySearch: FormControl = new FormControl();
  public filteredCategories: any = [];
  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private faqService: FaqService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: FaqsComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action ? this.local_data.action : 'Add';
  }
  ngOnInit() {
    this.resetAddContentForm();
    this.faqGetCategories();
    this.setPatchValues();
    this.categorySearch.valueChanges.subscribe((val) => {
      this.filteredCategories = this.categories.filter((option: any) =>
        option.category.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  // convenience getter for easy access to form fields
  get addFaqControls() {
    return this.addFaqForm.controls;
  }

  resetAddContentForm() {
    this.addFaqForm = this.fb.group({
      title: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength250),
        ]),
      ],
      description: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespaceValidator,
          Validators.maxLength(CommonConstants.maxLength5000),
        ]),
      ],
      category_id: [null, Validators.compose([Validators.required])],
    });
  }

  setPatchValues() {
    if (this.action === 'Update') {
      this.addFaqForm.patchValue({
        title: this.local_data.title,
        description: this.local_data.description,
        category_id: this.local_data.faq_category.id,
      });
    }
  }

  async doAction() {
    await this.submitFaqForm();
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  async faqGetCategories() {
    try {
      const page = 1;
      const perPage = 100;
      const faqCategoryURL = `page=${page}&perPage=${perPage}`;
      const res = await this.faqService.faqGetCategories(faqCategoryURL);
      if (res) {
        this.categories = res.data.faqCategories;
        this.filteredCategories = [...this.categories];
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  async submitFaqForm() {
    if (this.action === 'Delete') {
      try {
        this.isProcessing = true;
        const deleteURL = `faqId=${this.local_data.id}`;
        const res = await this.faqService.removeFaq(deleteURL);
        if (res) {
          this.helperService.showAlert(res.msg || 'Success', 'success');
        }
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: true,
        });
      } catch (err: any) {
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.dialogRef.close({
          event: this.action,
          data: this.local_data,
          success: false,
        });
      } finally {
        this.isProcessing = false;
      }
    } else {
      if (this.addFaqForm.valid) {
        try {
          this.isProcessing = true;
          const category_id = this.addFaqForm.controls.category_id.value;
          const reqData: any = {
            title: this.local_data.title,
            description: this.local_data.description,
            category_id,
          };
          if (this.action === 'Update') {
            reqData['faqId'] = this.local_data.id;
          }
          let res: any;
          if (this.action === 'Add') {
            res = await this.faqService.addFaq(reqData);
            this.local_data.id = res.data.id;
            this.local_data.faq_category = res.data.faq_category;
            this.local_data.createdAt = res.data.createdAt;
          } else if (this.action === 'Update') {
            res = await this.faqService.editFaq(reqData);
            this.local_data.category = this.categories.find((c: any) => {
              return c.id === category_id;
            });
            this.local_data.faq_category = this.local_data.category;
          }
          if (res) {
            this.helperService.showAlert(res.msg || 'Success', 'success');
          }
          this.dialogRef.close({
            event: this.action,
            data: this.local_data,
            success: true,
          });
        } catch (err: any) {
          this.helperService.showAlert(
            err.error || 'Something went wrong',
            'error'
          );
        } finally {
          this.isProcessing = false;
        }
      }
    }
  }
}
