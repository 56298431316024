<div fxLayout="row wrap" class="users">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <div>
                <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <div class="search" *ngIf="showSearch">
                  <mat-form-field class="search-box">
                    <mat-label>Search</mat-label>
                    <input matInput #userSearch [(ngModel)]="searchedText"
                      [placeholder]="searchOn.length ? searchOn : 'Search'" (keyup)="getUsers()" />
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="searchbar" *ngIf="showSearch">
                <div *ngIf="searchOptions.length">
                  <mat-form-field>
                    <mat-select placeholder="Search with" multiple [(ngModel)]="searchOn"
                      (selectionChange)="searchWithTerm()">
                      <mat-option *ngFor="let search of searchOptions" [value]="search">
                        {{ search }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>



              <div class="filter-item" *ngIf="filterOptions.measurementSetups.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Measurement Setup</mat-label>
                  <mat-select placeholder="Measurement Setup" multiple [(ngModel)]="measurementSetups">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="measSetupDropDownSearch"
                        placeholderLabel="Search Measurement Setup ..."
                        noEntriesFoundLabel="No matching Measurement Setup found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredMeasSetups.length > 0" #allMeasSetupCheckBox
                      (click)="allSelection('measSetup')" [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('measSetup')"
                      *ngFor="let measurementSetup of filteredMeasSetups" [value]="measurementSetup.id">
                      {{ measurementSetup.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.smells.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Smell/Substance</mat-label>
                  <mat-select placeholder="Smell/Substance" multiple [(ngModel)]="smells">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="smellDropDownSearch"
                        placeholderLabel="Search Smell/Substance ..."
                        noEntriesFoundLabel="No matching Smell/Substance found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredSmells.length > 0" #allSmellCheckBox (click)="allSelection('smell')"
                      [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('smell')" *ngFor="let smell of filteredSmells"
                      [value]="smell.id">
                      {{ smell.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.usecases.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Use Case</mat-label>
                  <mat-select placeholder="Usecase" multiple [(ngModel)]="usecases">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="usecaseDropDownSearch"
                        placeholderLabel="Search Use Case ..." noEntriesFoundLabel="No matching Usecase found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredUsecases.length > 0" #allUsecaseCheckBox
                      (click)="allSelection('usecase')" [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('usecase')" *ngFor="let usecase of filteredUsecases"
                      [value]="usecase.id">
                      {{ usecase.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.userTypes.length">
                <mat-form-field class="example-full-width">
                  <mat-label>User Type</mat-label>
                  <mat-select (selectionChange)="getUsers()" placeholder="User Type" multiple [(ngModel)]="userTypes">
                    <mat-option *ngFor="let userType of filterOptions.userTypes" [value]="userType">
                      {{ userType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div (click)="picker.open()" class="filter-item">
                <mat-form-field class="example-full-width">
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>

              <!-- <div class="d-flex">
                <button *ngIf="!showSearch" mat-icon-button color="primary" class="m-l-10" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                  (click)="clearFilter()">
                  Clear Filters
                </button>
              </div> -->
            </div>
            <div class="d-flex">
              <button mat-raised-button color="primary" [disabled]="isFilterApplied()" (click)="clearFilter()">
                Clear Filters
              </button>
            </div>

            <!-- <div class="search" *ngIf="showSearch">
              <mat-form-field class="search-box">
                <mat-label>Search</mat-label>
                <input matInput #userSearch [(ngModel)]="searchedText"
                  [placeholder]="searchOn.length ? searchOn : 'Search'" (keyup)="getUsers()" />
                <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div> -->

            <div class="overlay" *ngIf="isProcessing">
              <mat-spinner class="loader"></mat-spinner>
            </div>

            <div *ngIf='length !== 0' class="example-container ex-c-general">
              <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table [dataSource]="dataSource" matSort
                (matSortChange)="sortData($event)" class="table employee-list no-wrap">

                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="first_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    First Name
                  </th>
                  <td class="might-overflow" [matTooltip]="element.first_name" mat-cell *matCellDef="let element">
                    {{ element.first_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="last_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Last Name
                  </th>
                  <td class="might-overflow" [matTooltip]="element.last_name" mat-cell *matCellDef="let element">
                    {{ element.last_name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="displayed_email">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                  <td class="might-overflow" [matTooltip]="element.displayed_email" mat-cell *matCellDef="let element">
                    {{ element.displayed_email }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="phone_number">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Phone Number
                  </th>
                  <td class="might-overflow" [matTooltip]="element.phone_number" mat-cell *matCellDef="let element">

                    {{ element.phone_number }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="profile_picture">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Profile Picture
                  </th>
                  <td class="might-overflow" [matTooltip]="element.profile_picture" mat-cell *matCellDef="let element">

                    {{ element.profile_picture }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="user_type">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    User Type
                  </th>
                  <td class="might-overflow" [matTooltip]="element.user_type" mat-cell *matCellDef="let element">
                    {{ element.user_type }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="industry_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Industry Name
                  </th>
                  <td class="might-overflow" [matTooltip]="element.industry_name" mat-cell *matCellDef="let element">

                    {{ element.industry_name || "-" }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="company_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Company Name
                  </th>
                  <td class="might-overflow" [matTooltip]="element.company_name" mat-cell *matCellDef="let element">

                    {{ element.company_name || "-" }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="university_name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    University Name
                  </th>
                  <td class="might-overflow" [matTooltip]="element.university_name" mat-cell *matCellDef="let element">

                    {{ element.university_name || "-" }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="measurementSetupCount">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    M. Setups
                  </th>
                  <td class="might-overflow" [matTooltip]="element.measurementSetupCount" mat-cell
                    *matCellDef="let element">

                    {{ element.measurementSetupCount }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="smellCount">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Smell/Substance
                  </th>
                  <td class="might-overflow" [matTooltip]="element.smellCount" mat-cell *matCellDef="let element">
                    {{ element.smellCount }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="usecaseCount">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Usecases
                  </th>
                  <td class="might-overflow" [matTooltip]="element.usecaseCount" mat-cell *matCellDef="let element">

                    {{ element.usecaseCount }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="measurementCount">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Measurements
                  </th>
                  <td class="might-overflow" [matTooltip]="element.measurementCount" mat-cell *matCellDef="let element">

                    {{ element.measurementCount }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="available_storage_space">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Available Space
                  </th>
                  <td class="might-overflow" [matTooltip]="element.available_storage_space_mb" mat-cell
                    *matCellDef="let element">

                    {{ element.available_storage_space_mb }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="total_storage_space">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Total Space
                  </th>
                  <td class="might-overflow" [matTooltip]="element.total_storage_space_mb" mat-cell
                    *matCellDef="let element">

                    {{ element.total_storage_space_mb }}

                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header>
                    Created At
                  </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss' " mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th class="text-center" mat-header-cell *matHeaderCellDef>Action</th>
                  <td mat-cell *matCellDef="let element" class="action-link ">
                    <a [class.disabled]="element.user_type == 'none' ? true : null"
                      [matTooltip]="tooltip.assignMLCalssifier" (click)="assignClassifier(element)"
                      class="m-r-10 cursor-pointer eye">
                      <mat-icon [ngClass]="element.user_type == 'none' ? 'disabled' : 'text-info'">assignment</mat-icon>
                    </a>
                    <a [matTooltip]="tooltip.userDetail" [routerLink]="['/user/detail/', element.id]"
                      class="m-r-10 cursor-pointer eye">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                    <a [class.disabled]="element.user_type == 'none' ? true : null" [matTooltip]="tooltip.userStorage"
                      [routerLink]="['/user/storage/', element.id]" class="m-r-10 cursor-pointer eye">
                      <mat-icon [ngClass]="element.user_type == 'none' ? 'disabled' : 'text-info'">backup</mat-icon>
                    </a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf="length === 0">No record found</div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getUsers($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>