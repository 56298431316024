import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage/local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
  }

  public isAuthenticated(): boolean {
    const token = this.localStorageService.getUserToken();
    if (!token) {
      return false;
    }
    return true;
  }

  resolve(): void {
    if (this.isAuthenticated()) {
      this.router.navigate(['/dashboards/dashboard']);
    }
  }

  logout() {
    this.localStorageService.clear();
    this.router.navigate(['/authentication/login']);
  }

}
