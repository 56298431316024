<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div class="table-responsive">
          <div class="inline-table-row">
            <div class="filter-section">
              <div>
                <button *ngIf="!showSearch" mat-icon-button color="primary" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <div class="search" *ngIf="showSearch">
                  <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #classifierSearch [(ngModel)]="searchedText"
                      [placeholder]="searchOn.length ? searchOn : 'Search' " (keyup)="getMLClassifiers()">
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>

              <div class="filter-item" *ngIf="filterOptions.smells.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Smell/Substance</mat-label>
                  <mat-select placeholder="Smell/Substance" multiple [(ngModel)]="smells">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="smellDropDownSearch"
                        placeholderLabel="Search Smell/Substance ..."
                        noEntriesFoundLabel="No matching Smell/Substance found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredSmells.length > 0" #allSmellCheckBox (click)="allSelection('smell')"
                      [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('smell')" *ngFor="let smell of filteredSmells"
                      [value]="smell.id">
                      {{ smell.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.usecases.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Use Case</mat-label>
                  <mat-select placeholder="Usecase" multiple [(ngModel)]="usecases">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="usecaseDropDownSearch"
                        placeholderLabel="Search Use Case ..." noEntriesFoundLabel="No matching Usecase found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredUsecases.length > 0" #allUsecaseCheckBox
                      (click)="allSelection('usecase')" [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('usecase')" *ngFor="let usecase of filteredUsecases"
                      [value]="usecase.id">
                      {{ usecase.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.accessModes.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Access Mode</mat-label>
                  <mat-select (selectionChange)="getMLClassifiers()" placeholder="Detector Type"
                    [(ngModel)]="accessMode">
                    <mat-option *ngFor="let accessMode of filterOptions.accessModes" [value]="accessMode">
                      {{ accessMode }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.detectorTypes.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Detector Type</mat-label>
                  <mat-select placeholder="Detector Type" multiple [(ngModel)]="detectorTypes">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="detectorTypeSearch"
                        placeholderLabel="Search Detector Type ..."
                        noEntriesFoundLabel="No matching Detector Type found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredDetectorTypes.length > 0" #allDetectorTypeCheckBox
                      (click)="allSelection('detectorType')" [value]="-1">All
                    </mat-option>
                    <mat-option (click)="singleSelection('detectorType')"
                      *ngFor="let detectortype of filteredDetectorTypes" [value]="detectortype.id">
                      {{ detectortype.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.firmwares.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Firmware</mat-label>
                  <mat-select placeholder="User Type" multiple [(ngModel)]="firmwares">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="firmwareSearch" placeholderLabel="Search Firmware ..."
                        noEntriesFoundLabel="No matching Firmware found">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="filteredFirmwares.length > 0" #allFirmwareCheckBox
                      (click)="allSelection('firmware')" [value]="-1">All</mat-option>
                    <mat-option (click)="singleSelection('firmware')" *ngFor="let firmware of filteredFirmwares"
                      [value]="firmware.id">
                      {{ firmware.name }}-{{ firmware.version }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="filter-item" *ngIf="filterOptions.measurementTypes.length">
                <mat-form-field class="example-full-width">
                  <mat-label>Measurement Type</mat-label>
                  <mat-select (selectionChange)="getMLClassifiers()" placeholder="User Type" multiple
                    [(ngModel)]="measurementTypes">
                    <mat-option *ngFor="let measurementType of filterOptions.measurementTypes"
                      [value]="measurementType">
                      {{ measurementType }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div (click)="picker.open()" class="filter-item">
                <mat-form-field>
                  <mat-label>Select a date range</mat-label>
                  <mat-date-range-input (click)="picker.open()" [rangePicker]="picker" [max]="futureDate">
                    <input readonly [(ngModel)]="fromDate" matStartDate placeholder="From date">
                    <input readonly [(ngModel)]="toDate" matEndDate placeholder="To date"
                      (dateChange)="onDateRangeChange()">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
              </div>

            </div>
            <div class="d-flex">
              <button mat-raised-button color="primary" (click)="openDialog('Add',{})">Add
                Classifier</button>
              <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                (click)="clearFilter()">Clear Filters</button>
            </div>
            <!-- <div class="d-flex">
              <div class="left-header-actions">
                <button mat-raised-button color="primary" (click)="openDialog('Add',{})">Add
                  Classifier</button>
                <button *ngIf="!showSearch" mat-icon-button color="primary" class="m-l-10" (click)="showHideSearch()">
                  <mat-icon>search</mat-icon>
                </button>
                <div class="search" *ngIf="showSearch">
                  <mat-form-field>
                    <mat-label>Search</mat-label>
                    <input matInput #classifierSearch [(ngModel)]="searchedText"
                      [placeholder]="searchOn.length ? searchOn : 'Search' " (keyup)="getMLClassifiers()">
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
              <div class="right-header-actions">
                <button mat-raised-button color="primary" class="m-l-10" [disabled]="isFilterApplied()"
                  (click)="clearFilter()">Clear Filters</button>
              </div>
            </div> -->



            <div *ngIf='length !== 0' class="example-container ex-c-general">
              <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table [dataSource]="dataSource" matSort
                (matSortChange)="sortData($event)" class="table employee-list no-wrap">

                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Index </th>
                  <td mat-cell *matCellDef="let element; let i = index">
                    {{!!pageIndex ? (i+1) + (pageIndex*pageSize) : i+1}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                  <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                    {{ element.id }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                  <td class="might-overflow" [matTooltip]="element.name" mat-cell *matCellDef="let element">
                    {{ element.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="version">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
                  <td class="might-overflow" [matTooltip]="element.version" mat-cell *matCellDef="let element">
                    {{ element.version }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="is_public">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> isPublic </th>
                  <td class="might-overflow" [matTooltip]="element.is_public ? 'Yes': 'No'" mat-cell
                    *matCellDef="let element">
                    {{ element.is_public ? 'Yes': 'No' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                  <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="updatedAt">
                  <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated At </th>
                  <td class="might-overflow" [matTooltip]="element.updatedAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
                    *matCellDef="let element"> {{ element.updatedAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                </ng-container>

                <ng-container matColumnDef="action">
                  <th disableClear mat-header-cell *matHeaderCellDef> Action </th>
                  <td mat-cell *matCellDef="let element" class="action-link ">
                    <a [matTooltip]="tooltip.view_classifier" [routerLink]="['/classifier/detail/', element.id]"
                      class="m-r-10 cursor-pointer eye">
                      <mat-icon class="text-info">remove_red_eye</mat-icon>
                    </a>
                    <a [matTooltip]="tooltip.update_classifier" (click)="openDialog('Update',element)"
                      class="m-r-10 cursor-pointer"><i class="fa fa-pencil editFont"></i></a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
            <div class="no-record" *ngIf='length === 0'> No record found </div>
          </div>
        </div>
        <mat-paginator #paginator [pageSizeOptions]="pageSizeOptions" [length]="length" [pageIndex]="pageIndex"
          [pageSize]="pageSize" showFirstLastButtons (page)="getMLClassifiers($event)"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div class="overlay" *ngIf="isProcessing">
  <mat-spinner class="loader"></mat-spinner>
</div>