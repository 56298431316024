<div class="assign-storage-header">
  <h2 class="font-medium m-0" mat-dialog-title><strong>Assign ML Classifier</strong></h2>
  <div class="overlay" *ngIf="isProcessing">
    <mat-spinner class="loader"></mat-spinner>
  </div>
  <div class="search">
    <mat-form-field class="search-box">
      <mat-label>Search</mat-label>
      <input autofocus matInput [(ngModel)]="searchedText" placeholder="Search" (keyup)="onKey()" />
      <button *ngIf="searchedText" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
</div>
<mat-dialog-content class="pb-3">
  <div class="selectable-table">
    <table mat-table [dataSource]="dataSource">

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox class="classifier-padding" (change)="$event ? masterToggle() : null; userInterference()"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td (click)="userInterference()" mat-cell *matCellDef="let row">
          <mat-checkbox class="classifier-padding" (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null; userInterference()" [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td class="might-overflow-classifier" [matTooltip]="element.name" mat-cell *matCellDef="let element">
          <u>
            <a target="_blank" (click)="$event.stopPropagation()"
              [routerLink]="['/classifier/detail/', element.id]">{{element.name}}</a>
          </u>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef> Version </th>
        <td class="might-overflow-classifier" [matTooltip]="element.version" mat-cell *matCellDef="let element">
          {{element.version}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="ml_classifier_category">
        <th mat-header-cell *matHeaderCellDef> Categories </th>
        <td class="might-overflow-classifier" [matTooltip]="element.ml_classifier_category" mat-cell
          *matCellDef="let element">
          {{element.ml_classifier_category}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="ml_classifier_substances">
        <th mat-header-cell *matHeaderCellDef> Substances </th>
        <td class="might-overflow-classifier" [matTooltip]="element.ml_classifier_substances" mat-cell
          *matCellDef="let element">
          {{element.ml_classifier_substances}} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> CreatedAt </th>
        <td class="might-overflow-classifier" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'" mat-cell
          *matCellDef="let element"> {{element.createdAt | date: 'dd MMM YYY, HH.mm.ss'}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td class="might-overflow-classifier" [matTooltip]="element.description" mat-cell *matCellDef="let element">
          {{element.description }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>
    <div class="no-record" *ngIf='dataSource.length === 0'> No record found </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button mat-button (click)="assignClassifier()" mat-flat-button color="primary"
    [disabled]="!userInteraction || dataSource.length === 0">Submit</button>
  <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>