import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { MeasurementService } from 'src/app/shared/services/measurement/measurement.service';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  profile_picture: string;
  user_type: string;
  available_storage_space: number;
  total_storage_space: number;
  createdAt: any;
}

@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss'],
})
export class MeasurementsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'action',
    'id',
    'name',
    'user_id',
    'mac_address',
    'measurement_duration',
    'measurement_size',
    'substances',
    'device_type',
    'connection_type',
    'recognize_rating',
    'comment',
    'started_at',
    'stopped_at',
    'createdAt',
  ];

  public i: number = 1;
  public data = [];
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public length: number = 0;
  dataSource: any = [];
  pageSizeOptions: number[] = [5, 10, 15, 25, 100];
  public isProcessing = false;
  public tooltip = {
    measurementDetail: 'Measurement Detail',
  };
  public filteredSmells: any = [];
  public smellDropDownSearch: FormControl = new FormControl();
  @ViewChild('allSmellCheckBox') private allSmellCheckBox: MatOption =
    Object.create(null);
  public filteredUsecases: any = [];
  public usecaseDropDownSearch: FormControl = new FormControl();
  @ViewChild('allUsecaseCheckBox') private allUsecaseCheckBox: MatOption =
    Object.create(null);
  public filteredMeasTypes: any = [];
  public measSetupDropDownSearch: FormControl = new FormControl();
  @ViewChild('allMeasSetupCheckBox') private allMeasSetupCheckBox: MatOption =
    Object.create(null);
  @ViewChild('measurementSearch') measurementSearch: ElementRef | any;

  // Search mechanism
  public searchedText: string = '';
  public showSearch: boolean = false;
  public searchOptions = [];
  public searchOn = [];

  // Filter mechanism
  public filterOptions = {
    measurementTypes: [],
    substances: [],
    usecases: [],
    userTypes: [],
  };
  public measurementTypes: any = [];
  public substances: any = [];
  public usecases: any = [];
  public userTypes = [];
  public toDate: any = '';
  public fromDate: any = '';
  public futureDate = new Date();
  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private userService: UserService,
    private measurementService: MeasurementService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.setDefaultDates();
    this.getMeasurementFilters();
    this.getMeasurements();

    this.smellDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredSmells = this.filterOptions.substances.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.usecaseDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredUsecases = this.filterOptions.usecases.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
    this.measSetupDropDownSearch.valueChanges.subscribe((val) => {
      this.filteredMeasTypes = this.filterOptions.measurementTypes.filter(
        (option: any) => option.name.toLowerCase().includes(val?.toLowerCase())
      );
    });
  }

  setDefaultDates() {
    const params: any = this.route.queryParams;
    if (params.value && params.value.fromDate) {
      this.fromDate = new Date(params.value.fromDate);
    }
    if (params.value && params.value.toDate) {
      this.toDate = new Date(params.value.toDate);
    }
  }

  onDateRangeChange() {
    if (!!this.fromDate && !!this.toDate) {
      this.getMeasurements();
    }
  }
  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getMeasurements();
  }

  singleSelection(dropDownName: string) {
    if (dropDownName === 'smell') {
      if (this.allSmellCheckBox.selected) {
        this.allSmellCheckBox.deselect();
      }

      if (this.substances.length === this.filterOptions.substances.length) {
        this.allSmellCheckBox.select();
        this.substances = [
          ...this.filterOptions.substances.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'usecase') {
      if (this.allUsecaseCheckBox.selected) {
        this.allUsecaseCheckBox.deselect();
      }

      if (this.usecases.length === this.filterOptions.usecases.length) {
        this.allUsecaseCheckBox.select();
        this.usecases = [
          ...this.filterOptions.usecases.map((item: any) => item.id),
          -1,
        ];
      }
    } else if (dropDownName === 'measSetup') {
      if (this.allMeasSetupCheckBox.selected) {
        this.allMeasSetupCheckBox.deselect();
      }

      if (
        this.measurementTypes.length ===
        this.filterOptions.measurementTypes.length
      ) {
        this.allMeasSetupCheckBox.select();
        this.measurementTypes = [
          ...this.filterOptions.measurementTypes.map((item: any) => item.id),
          -1,
        ];
      }
    }
    this.getMeasurements();
  }

  allSelection(dropDownName: string) {
    if (dropDownName === 'smell') {
      if (this.allSmellCheckBox.selected) {
        this.substances = [
          ...this.filterOptions.substances.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.substances = [];
      }
    } else if (dropDownName === 'usecase') {
      if (this.allUsecaseCheckBox.selected) {
        this.usecases = [
          ...this.filterOptions.usecases.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.usecases = [];
      }
    } else if (dropDownName === 'measSetup') {
      if (this.allMeasSetupCheckBox.selected) {
        this.measurementTypes = [
          ...this.filterOptions.measurementTypes.map((item: any) => item.id),
          -1,
        ];
      } else {
        this.measurementTypes = [];
      }
    }
    this.getMeasurements();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getMeasurements(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let measurementURL = ``;

      // SEARCH
      if (this.searchedText) {
        measurementURL += `&search=${this.searchedText}`;
        this.setDefaultPage(event);
      }

      // ORDER
      if (this.orderOn && this.orderBy) {
        measurementURL += `&sortBy=${
          this.orderOn
        }&sortDirection=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }

      // FILTERS
      if (this.measurementTypes.length) {
        measurementURL += `&measurementTypes=[${this.measurementTypes}]`;
        this.setDefaultPage(event);
      }
      if (this.substances.length) {
        measurementURL += `&substances=[${this.substances}]`;
        this.setDefaultPage(event);
      }
      if (this.usecases.length) {
        measurementURL += `&usecases=[${this.usecases}]`;
        this.setDefaultPage(event);
      }
      if (this.userTypes.length) {
        measurementURL += `&userTypes=${JSON.stringify(this.userTypes)}`;
        this.setDefaultPage(event);
      }
      if (this.fromDate && this.toDate) {
        measurementURL += `&fromDate=${moment(this.fromDate).format(
          'YYYY-MM-DD'
        )}&toDate=${moment(this.toDate).format('YYYY-MM-DD')}`;
        this.setDefaultPage(event);
      }

      measurementURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const res = await this.measurementService.getMeasurements(measurementURL);
      if (res) {
        this.data = res.data.measurements;
        this.data.forEach((d: any) => {
          d.measurement_size_mb = this.helperService.kbToMBConvertor(
            d.measurement_size
          );
          d.mst_duration = this.helperService.secondsToDhms(
            d.measurement_duration
          );
          if (
            d.measurement_substance_info &&
            d.measurement_substance_info.length
          ) {
            d.substances = d.measurement_substance_info
              .map((m: any) => {
                return m.name;
              })
              .join(', ');
          }
        });
        this.dataSource = res.data.measurements;
        this.length = res.data.count;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async getMeasurementFilters() {
    try {
      const res = await this.measurementService.getMeasurementFilters();
      if (res) {
        this.filterOptions = res.data;
        this.filteredMeasTypes = this.filterOptions.measurementTypes;
        this.filteredUsecases = this.filterOptions.usecases;
        this.filteredSmells = this.filterOptions.substances;
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    }
  }

  showHideSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      if (this.measurementSearch && this.measurementSearch.nativeElement) {
        this.measurementSearch.nativeElement.focus();
      }
    });
  }

  searchWithTerm() {
    if (this.searchedText) {
      this.getMeasurements();
    }
  }

  isFilterApplied() {
    return !(
      this.measurementTypes.length ||
      this.substances.length ||
      this.usecases.length ||
      this.userTypes.length ||
      this.toDate
    );
  }

  clearSearch() {
    this.searchedText = '';
    this.searchOn = [];
    this.showHideSearch();
    this.getMeasurements();
  }

  clearFilter() {
    this.measurementTypes = [];
    this.substances = [];
    this.usecases = [];
    this.userTypes = [];
    this.fromDate = '';
    this.toDate = '';
    this.getMeasurements();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openResetDialog(obj: any): void {
    // this.dialog.open(UserResetPasswordComponent, {
    //   minWidth: '50%',
    //   maxHeight: '90vh',
    //   data: obj,
    // });
  }

  assignClassifier(obj: any): void {
    // this.dialog.open(AssignClassifiersComponent, {
    //   minWidth: '70%',
    //   maxHeight: '90vh',
    //   data: obj,
    // });
  }
}
