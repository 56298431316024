import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssignUserStorageComponent } from '../assign-user-storage/assign-user-storage.component';

export interface Faq {
  id: number;
  userName: string;
  storage_size: number;
  storage_size_mb: number;
  createdAt: any;
}

@Component({
  selector: 'app-user-storage',
  templateUrl: './user-storage.component.html',
  styleUrls: ['./user-storage.component.scss'],
})
export class UserStorageComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any> = Object.create(null);
  public searchText: any;
  public displayedColumns: string[] = [
    'index',
    'id',
    'storage_size',
    'assigned_by',
    'createdAt',
  ];
  public userName: string = '';
  public available_storage_space: number = 0;
  public total_storage_space: number = 0;
  public available_storage_space_mb: string = '0 MB';
  public total_storage_space_mb: string = '0 MB';
  public used_storage_space_mb: string = '0 MB';

  // Pagination
  public pageIndex: number = 0;
  public pageSize: number = 15;
  public spaceCount: number = 0;
  public pageSizeOptions: number[] = [5, 10, 15, 25, 100];

  public userStorageHistory: any = [];
  public isProcessing = false;

  // Order
  public orderOn = 'id';
  public orderBy = 'desc';

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator = Object.create(null);

  constructor(
    public dialog: MatDialog,
    public datePipe: DatePipe,
    private helperService: HelperService,
    private userService: UserService,
    private route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.getUserSpaceHistory();
  }

  setDefaultPage(event?: PageEvent) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    } else {
      this.pageIndex = 0;
    }
  }

  async getUserSpaceHistory(event?: PageEvent) {
    try {
      if (event) {
        this.pageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
      }
      this.isProcessing = true;
      let spaceURL = '';
      // ORDER
      if (this.orderOn && this.orderBy) {
        spaceURL += `&orderOn=${
          this.orderOn
        }&orderBy=${this.orderBy.toUpperCase()}`;
        this.setDefaultPage(event);
      }
      spaceURL += `&page=${this.pageIndex + 1}&perPage=${this.pageSize}`;
      const userId = this.route.snapshot.paramMap.get('userId');
      const res = await this.userService.getStorageHistory(userId, spaceURL);
      if (res) {
        if (
          res.data &&
          res.data.userInfo &&
          res.data.userInfo.user_type &&
          res.data.userInfo.user_type === 'none'
        ) {
          this.router.navigate(['/users']);
        }
        this.userStorageHistory = res.data.history;
        this.userStorageHistory.forEach((u: any) => {
          u.storage_size_mb = this.helperService.kbToMBConvertor(
            u.storage_size
          );
          let userName = '';
          if (u.storage_allocation_history) {
            if (u.storage_allocation_history.first_name) {
              userName += `${u.storage_allocation_history.first_name}`;
            }
            if (u.storage_allocation_history.last_name) {
              userName += ` ${u.storage_allocation_history.last_name}`;
            }
            this.available_storage_space =
              u.storage_allocation_history.available_storage_space;
            this.total_storage_space =
              u.storage_allocation_history.total_storage_space;
          }
          u.userName = userName;
          this.userName = userName;
        });
        this.spaceCount = res.data.count;
        this.available_storage_space_mb = this.helperService.kbToMBConvertor(
          this.available_storage_space
        );
        this.total_storage_space_mb = this.helperService.kbToMBConvertor(
          this.total_storage_space
        );
        this.used_storage_space_mb = this.helperService.kbToMBConvertor(
          this.total_storage_space - this.available_storage_space
        );
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  sortData(event?: any) {
    const { active, direction } = event;
    this.orderOn = active;
    this.orderBy = direction;
    this.getUserSpaceHistory();
  }

  ngAfterViewInit(): void {
    this.userStorageHistory.paginator = this.paginator;
  }

  assignSpace() {
    const userId = this.route.snapshot.paramMap.get('userId');
    this.openDialog('Add', {
      userId,
      total_storage_space_mb: this.total_storage_space_mb,
      available_storage_space_mb: this.available_storage_space_mb,
      available_storage_space: this.available_storage_space,
      total_storage_space: this.total_storage_space,
    });
  }

  openDialog(action: string, obj: any): void {
    obj.action = action;
    const dialogRef = this.dialog.open(AssignUserStorageComponent, {
      minWidth: obj.action && obj.action === 'Delete' ? '500px' : '700px',
      maxHeight: '90vh',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.event) {
        if (result.event === 'Add') {
          if (result.success) {
            this.addRowData(result.data);
          }
        }
      }
    });
  }

  addRowData(row_obj: Faq): void {
    if (this.userStorageHistory.length === this.pageSize) {
      this.userStorageHistory.pop();
    }
    this.userStorageHistory = [
      {
        ...row_obj,
        storage_size_mb: this.helperService.kbToMBConvertor(
          row_obj.storage_size
        ),
      },
      ...this.userStorageHistory,
    ];
    this.available_storage_space =
      +this.available_storage_space + +row_obj.storage_size;
    this.total_storage_space =
      +this.total_storage_space + +row_obj.storage_size;
    this.available_storage_space_mb = this.helperService.kbToMBConvertor(
      this.available_storage_space
    );
    this.total_storage_space_mb = this.helperService.kbToMBConvertor(
      this.total_storage_space
    );
    this.table.renderRows();
  }

  deleteRowData(row_obj: Faq): boolean | void {
    const index = this.userStorageHistory.findIndex(
      (f: any) => f.id === row_obj.id
    );
    if (index > -1) {
      this.spaceCount = this.spaceCount - 1;
      this.userStorageHistory.splice(index, 1);
      this.table.renderRows();
    }
  }
}
