import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UsersComponent } from '../../user/users/users.component';
import { HelperService } from 'src/app/shared/services/helper/helper.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ClassifiersService } from 'src/app/shared/services/classifier/classifier.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-assign-classifiers',
  templateUrl: './assign-classifiers.component.html',
  styleUrls: ['./assign-classifiers.component.scss'],
})
export class AssignClassifiersComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'name',
    'version',
    'ml_classifier_category',
    'ml_classifier_substances',
    'createdAt',
    'description',
  ];
  dataSource: any = [];
  selection = new SelectionModel<any>(true, []);
  public local_data: any;
  public assignClassifierForm: any;
  public selectedClassifiers: any = [];
  public isProcessing: boolean = false;
  public classifiers: any = [];
  public searchedClassifiers: any = [];
  public searchedText: string = '';
  public userInteraction: boolean = false;

  constructor(
    public datePipe: DatePipe,
    public dialogRef: MatDialogRef<any>,
    private helperService: HelperService,
    private classsifierService: ClassifiersService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: UsersComponent
  ) {
    this.local_data = { ...data };
  }
  ngOnInit() {
    this.resetAddContentForm();
    this.getClassifiers();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    this.selectedClassifiers = this.selection.selected.map((c) => c.id);
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.selectedClassifiers = this.selection.selected.map((c) => c.id);
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  userInterference() {
    this.userInteraction = true;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  onKey() {
    this.dataSource = this.search(this.searchedText);
    if (this.dataSource.length === 0) {
      if (this.displayedColumns.includes('select')) {
        this.displayedColumns.splice(0, 1);
      }
    } else {
      this.addSelectbar();
    }
  }

  clearSearch() {
    this.searchedText = '';
    this.dataSource = [...this.classifiers];
    this.addSelectbar();
  }

  addSelectbar() {
    if (!this.displayedColumns.includes('select')) {
      this.displayedColumns.splice(0, 0, 'select');
    }
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.searchedClassifiers.filter(
      (option: any) =>
        option.name.toLowerCase().includes(filter) ||
        option.description.toLowerCase().includes(filter) ||
        option.ml_classifier_category.toLowerCase().includes(filter) ||
        option.ml_classifier_substances.toLowerCase().includes(filter) ||
        option.version.toLowerCase().includes(filter)
    );
  }

  // convenience getter for easy access to form fields
  get assignClassifierControls() {
    return this.assignClassifierForm.controls;
  }

  resetAddContentForm() {
    this.assignClassifierForm = this.fb.group({
      classifierIds: [null, Validators.compose([Validators.required])],
    });
  }

  closeDialog(): void {
    this.userInteraction = false;
    this.dialogRef.close({ event: 'Cancel' });
  }

  async getClassifiers() {
    try {
      this.isProcessing = true;
      const userId = this.local_data.id;
      const res = await this.classsifierService.getClassifiers(userId);
      if (res) {
        this.classifiers = res.data;
        this.dataSource = res.data;
        this.searchedClassifiers = res.data;
        this.selectedClassifiers = res.data
          .filter((s: any) => {
            return (
              s.ml_classifier_used_users && s.ml_classifier_used_users.length
            );
          })
          .map((m: any) => {
            this.selection.toggle(m);
            return m.id;
          });
        this.classifiers.forEach((c: any) => {
          if (
            c.ml_classifier_category_info &&
            c.ml_classifier_category_info.length
          ) {
            c.ml_classifier_category = c.ml_classifier_category_info
              .map((m: any) => {
                return m.category;
              })
              .join('; ');
          }
          if (c.ml_classifier_substances && c.ml_classifier_substances.length) {
            c.ml_classifier_substances = c.ml_classifier_substances
              .map((m: any) => {
                return m.name;
              })
              .join('; ');
          }
        });
      }
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
    } finally {
      this.isProcessing = false;
    }
  }

  async assignClassifier() {
    try {
      const reqData: any = {
        classifierIds: this.selectedClassifiers,
      };
      const userId = this.local_data.id;
      this.isProcessing = true;
      const res = await this.classsifierService.assignClassifiers(
        userId,
        reqData
      );
      if (res) {
        this.helperService.showAlert(res.msg || 'Success', 'success');
      }
      this.dialogRef.close({ success: true });
    } catch (err: any) {
      this.helperService.showAlert(
        err.error || 'Something went wrong',
        'error'
      );
      this.dialogRef.close({ success: false });
    } finally {
      this.isProcessing = false;
    }
  }
}
