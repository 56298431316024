<div fxLayout="row wrap">
  <div fxFlex="100">
    <mat-card>
      <mat-card-content>
        <div>
          <div class="overlay" *ngIf="isProcessing">
            <mat-spinner class="loader"></mat-spinner>
          </div>

          <div fxLayout="row" class="content-wrapper justify-content-between">
            <mat-card fxFlex.gt-sm="20" fxFlex.gt-xs="100" fxFlex="100"
              class="m-b-10 text-center oh  little-profile m-l-0">
              <!-- <div class="oh  little-profile">
                <div class="text-center">
                  <img mat-card-image src="assets/images/background/profile-bg.jpg" alt="Photo of a Shiba Inu">
                  <div class="pro-img">
                    <img src="{{ userInfo.profile_picture || defaultImage }}" width="100" alt="user">
                  </div>
                </div>
                <mat-card-action>
                  <button mat-button mat-flat-button color="primary" (click)="resetPassword()">Reset
                    Password</button>
                </mat-card-action>
              </div> -->
              <img mat-card-image src="assets/images/background/profile-bg.jpg" class="profile-bg-height"
                alt="Photo of a Shiba Inu">
              <mat-card-content>
                <div class="pro-img">
                  <img src="{{ userInfo.profile_picture || defaultImage }}" width="100" alt="user">
                </div>

                <mat-card-actions>
                  <button mat-button mat-flat-button color="primary" (click)="resetPassword()">Reset
                    Password</button>
                </mat-card-actions>

              </mat-card-content>
            </mat-card>
            <mat-card fxFlex.gt-sm="40" fxFlex.gt-xs="100" fxFlex="100" class="m-b-10">
              <mat-card-header class="course-header Web card-auto-height">
                <mat-card-title>
                  <span> Info</span>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="responsive-table">
                  <table mat-table [dataSource]="userBlock" class="table employee-list td-ht-sm no-wrap">

                    <ng-container matColumnDef="key">
                      <td class="" mat-cell *matCellDef="let element">
                        {{ element.key }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                      <td class="might-overflow"
                        [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                        mat-cell *matCellDef="let element">
                        <div *ngIf="element.isDate">
                          {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                        </div>
                        <div *ngIf="element.isTextArea">
                          <div *ngIf="element.value.length > 15">
                            <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                              [(ngModel)]="element.value" #description matInput class="scrollbar"
                              placeholder="Description"></textarea>
                          </div>
                          <div *ngIf="element.value.length < 16">
                            {{ element.value }}
                          </div>
                        </div>
                        <div *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                          {{ element.value }}
                        </div>
                        <div *ngIf="element.isLink">
                          <a href="{{element.value}}">{{element.value}}</a>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: userBlockColumns"></tr>
                  </table>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card fxFlex.gt-sm="40" fxFlex.gt-xs="100" fxFlex="100" class="m-b-10 m-r-0">
              <mat-card-header class="course-header Web card-auto-height">
                <mat-card-title>
                  <span> Counts</span>
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="responsive-table">
                  <table mat-table [dataSource]="countBlock" class="table employee-list measurement-table no-wrap">

                    <ng-container matColumnDef="key">
                      <td class="" mat-cell *matCellDef="let element">
                        <button [color]="element.iconColor" mat-fab mat-card-icon [ngClass]="element.className"
                          class="shadow-none">
                          <mat-icon>{{ element.iconName }}</mat-icon>
                        </button>
                        {{ element.key }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="value">
                      <td class="might-overflow"
                        [matTooltip]="element.isDate ? (element.value | date: 'dd MMM YYY, HH.mm.ss') : element.value"
                        mat-cell *matCellDef="let element">
                        <div *ngIf="element.isDate">
                          {{ element.value | date: 'dd MMM YYY, HH.mm.ss' }}
                        </div>
                        <div *ngIf="element.isTextArea">
                          <div *ngIf="element.value.length > 15">
                            <textarea readonly [ngModelOptions]="{standalone: true}" rows="5" cols="40"
                              [(ngModel)]="element.value" #description matInput class="scrollbar"
                              placeholder="Description"></textarea>
                          </div>
                          <div *ngIf="element.value.length < 16">
                            {{ element.value }}
                          </div>
                        </div>
                        <div *ngIf="!element.isDate && !element.isLink && !element.isTextArea">
                          {{ element.value }}
                        </div>
                        <div *ngIf="element.isLink">
                          <a href="{{element.value}}">{{element.value}}</a>
                        </div>
                      </td>
                    </ng-container>

                    <tr mat-row *matRowDef="let row; columns: countBlockColumns"></tr>
                  </table>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div>
            <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)">

              <mat-tab label="Measurements" class="ext">
                <div class="overlay" *ngIf="measurementsDataProcessing">
                  <mat-spinner class="loader"></mat-spinner>
                </div>
                <div class="mat-table-wrapper">
                  <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table
                    [dataSource]="measurementsData" class="table employee-list no-wrap" matSort
                    (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef> Index </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        {{!!measurementsPageIndex ? (i+1) + (measurementsPageIndex*measurementsPageSize) : i+1}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                      <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                        <u>
                          <a target="_blank" (click)="$event.stopPropagation()"
                            [routerLink]="['/measurements/detail/', element.id]">{{ element.id }}</a>
                        </u>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td [matTooltip]="element.name" mat-cell *matCellDef="let element">
                        {{ element.name }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="smells">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Smells/Substances </th>
                      <td class="might-overflow" [matTooltip]="element.smells" mat-cell *matCellDef="let element">
                        {{ element.smells || '-'}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="usecases">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Use Cases </th>
                      <td class="might-overflow" [matTooltip]="element.usecases" mat-cell *matCellDef="let element">
                        {{ element.usecases || '-'}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="measurement_setup">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> M. Setup </th>
                      <td class="might-overflow" [matTooltip]="element.measurement_setup_info_name" mat-cell
                        *matCellDef="let element">
                        {{ element.measurement_setup_info_name || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="connection_type">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Connection Type </th>
                      <td class="might-overflow" [matTooltip]="element.connection_type" mat-cell
                        *matCellDef="let element">
                        {{ element.connection_type || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="measurement_type">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> M. Type </th>
                      <td class="might-overflow" [matTooltip]="element.measurement_type" mat-cell
                        *matCellDef="let element">
                        {{ element.measurement_type || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="measurement_size">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> M. Size </th>
                      <td class="might-overflow" [matTooltip]="element.measurement_size_mb" mat-cell
                        *matCellDef="let element">
                        {{ element.measurement_size_mb }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="measurement_duration">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> M. Duration </th>
                      <td class="might-overflow" [matTooltip]="element.mst_duration" mat-cell *matCellDef="let element">
                        {{ element.mst_duration || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="fan_state">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Fan State </th>
                      <td class="might-overflow" [matTooltip]="element.fan_state" mat-cell *matCellDef="let element">
                        {{ element.fan_state || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="started_at">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Started At </th>
                      <td class="might-overflow" [matTooltip]="element.started_at | date: 'dd MMM YYY, HH.mm.ss'"
                        mat-cell *matCellDef="let element"> {{ element.started_at | date: 'dd MMM YYY, HH.mm.ss' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="stopped_at">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Stopped At </th>
                      <td class="might-overflow" [matTooltip]="element.stopped_at | date: 'dd MMM YYY, HH.mm.ss' "
                        mat-cell *matCellDef="let element"> {{ element.stopped_at | date: 'dd MMM YYY, HH.mm.ss' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="measurement_indoor_outdoor">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> M. Indoor Outdoor </th>
                      <td class="might-overflow" [matTooltip]="element.measurement_setup_info_indoor_outdoor" mat-cell
                        *matCellDef="let element">
                        {{ element.measurement_setup_info_indoor_outdoor || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="recognize_rating">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Recognize Rating </th>
                      <td class="might-overflow" [matTooltip]="element.recognize_rating" mat-cell
                        *matCellDef="let element">
                        {{ element.recognize_rating || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="recognize_comments">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Recognize Comments </th>
                      <td class="might-overflow" mat-cell *matCellDef="let element">
                        <div *ngIf="!element.comment"> - </div>
                        <div *ngIf="element.comment && element.comment.length < 10">
                          {{ element.comment || '-' }}
                        </div>
                        <div *ngIf="element.comment && element.comment.length > 10">
                          <textarea readonly [ngModelOptions]="{standalone: true}" rows="3" cols="40"
                            [(ngModel)]="element.comment" #description matInput class="scrollbar"
                            placeholder="Description"></textarea>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                      <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'"
                        mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="measurementColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: measurementColumns;"></tr>
                  </table>
                  <div class="no-record" *ngIf='measurementsTotalCount === 0'> No record found </div>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="measurementsPageSizeOptions"
                  [length]="measurementsTotalCount" [pageIndex]="measurementsPageIndex"
                  [pageSize]="measurementsPageSize" showFirstLastButtons (page)="getUserData('Measurements', $event)">
                </mat-paginator>

              </mat-tab>

              <mat-tab label="Smells/Substances" class="ext">
                <div class="overlay" *ngIf="smellsDataProcessing">
                  <mat-spinner class="loader"></mat-spinner>
                </div>
                <div class="mat-table-wrapper">
                  <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table [dataSource]="smellsData"
                    class="table employee-list no-wrap" matSort (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef> Index </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        {{!!smellsPageIndex ? (i+1) + (smellsPageIndex*smellsPageSize) : i+1}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                      <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                        <u>
                          <a target="_blank" (click)="$event.stopPropagation()" [routerLink]="['/measurement/smell']"
                            [queryParams]="{substance_id: element.id}">{{ element.id }}</a>
                        </u>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td class="might-overflow" [matTooltip]="element.name" mat-cell *matCellDef="let element">
                        {{ element.name || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="is_public">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Is Public </th>
                      <td class="might-overflow" [matTooltip]="element.is_public ? 'Yes' : 'No'" mat-cell
                        *matCellDef="let element">
                        {{ element.is_public ? 'Yes' : 'No' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="category">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
                      <td class="might-overflow" [matTooltip]="element.category" mat-cell *matCellDef="let element">
                        {{ element.category || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="odor_name">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Odor Name </th>
                      <td class="might-overflow" [matTooltip]="element.odor_name" mat-cell *matCellDef="let element">
                        {{ element.odor_name || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="taste_name">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Taste Name </th>
                      <td class="might-overflow" [matTooltip]="element.taste_name" mat-cell *matCellDef="let element">
                        {{ element.taste_name || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="additional_information">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Additional Information </th>
                      <td class="might-overflow" [matTooltip]="element.additional_information" mat-cell
                        *matCellDef="let element">
                        {{ element.additional_information || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                      <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'"
                        mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="smellColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: smellColumns;"></tr>
                  </table>
                  <div class="no-record" *ngIf='smellsTotalCount === 0'> No record found </div>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="smellsPageSizeOptions" [length]="smellsTotalCount"
                  [pageIndex]="smellsPageIndex" [pageSize]="smellsPageSize" showFirstLastButtons
                  (page)="getUserData('Smells/Substances', $event)"></mat-paginator>
              </mat-tab>

              <mat-tab label="Use Cases" class="ext">
                <div class="overlay" *ngIf="usecasesDataProcessing">
                  <mat-spinner class="loader"></mat-spinner>
                </div>
                <div class="mat-table-wrapper">
                  <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table [dataSource]="usecasesData"
                    class="table employee-list no-wrap" matSort (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef> Index </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        {{!!usecasesPageIndex ? (i+1) + (usecasesPageIndex*usecasesPageSize) : i+1}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                      <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                        <u>
                          <a target="_blank" (click)="$event.stopPropagation()" [routerLink]="['/measurement/usecase']"
                            [queryParams]="{usecase_id: element.id}">{{ element.id }}</a>
                        </u>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td class="might-overflow" [matTooltip]="element.name" mat-cell *matCellDef="let element">
                        {{ element.name || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="is_public">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Is Public </th>
                      <td class="might-overflow" [matTooltip]="element.is_public ? 'Yes' : 'No'" mat-cell
                        *matCellDef="let element">
                        {{ element.is_public ? 'Yes' : 'No' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                      <td class="might-overflow" [matTooltip]="element.description" mat-cell *matCellDef="let element">
                        {{ element.description || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="unknown_substance">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Unknown Substance </th>
                      <td class="might-overflow" [matTooltip]="element.unknown_substance" mat-cell
                        *matCellDef="let element">
                        {{ element.unknown_substance ? 'Yes' : 'No'}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="monitoring_short_measurement">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Monitoring Short Measurement
                      </th>
                      <td class="might-overflow" [matTooltip]="element.monitoring_short_measurement" mat-cell
                        *matCellDef="let element">
                        {{ element.monitoring_short_measurement || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                      <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'"
                        mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="usecaseColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: usecaseColumns;"></tr>
                  </table>
                  <div class="no-record" *ngIf='usecasesTotalCount === 0'> No record found </div>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="usecasesPageSizeOptions" [length]="usecasesTotalCount"
                  [pageIndex]="usecasesPageIndex" [pageSize]="usecasesPageSize" showFirstLastButtons
                  (page)="getUserData('Use Cases', $event)"></mat-paginator>
              </mat-tab>

              <mat-tab label="Measurement Setups" class="ext">
                <div class="overlay" *ngIf="measurementsetupsDataProcessing">
                  <mat-spinner class="loader"></mat-spinner>
                </div>
                <div class="mat-table-wrapper">
                  <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table
                    [dataSource]="measurementsetupsData" class="table employee-list no-wrap" matSort
                    (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef> Index </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        {{!!measurementsetupsPageIndex ? (i+1) + (measurementsetupsPageIndex*measurementsetupsPageSize)
                        :
                        i+1}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                      <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                        <u>
                          <a target="_blank" (click)="$event.stopPropagation()"
                            [routerLink]="['/measurement/measurement-setup']"
                            [queryParams]="{measurement_setup_id: element.id}">{{ element.id }}</a>
                        </u>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                      <td class="might-overflow" [matTooltip]="element.name" mat-cell *matCellDef="let element">
                        {{ element.name || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="is_public">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Is Public </th>
                      <td class="might-overflow" [matTooltip]="element.is_public ? 'Yes' : 'No'" mat-cell
                        *matCellDef="let element">
                        {{ element.is_public ? 'Yes' : 'No' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                      <td class="might-overflow" [matTooltip]="element.description" mat-cell *matCellDef="let element">
                        {{ element.description || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="indoor_outdoor">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Indoor Outdoor </th>
                      <td class="might-overflow" [matTooltip]="element.indoor_outdoor" mat-cell
                        *matCellDef="let element">
                        {{ element.indoor_outdoor || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                      <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'"
                        mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="measurementSetupColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: measurementSetupColumns;"></tr>
                  </table>
                  <div class="no-record" *ngIf='measurementsetupsTotalCount === 0'> No record found </div>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="measurementsetupsPageSizeOptions"
                  [length]="measurementsetupsTotalCount" [pageIndex]="measurementsetupsPageIndex"
                  [pageSize]="measurementsetupsPageSize" showFirstLastButtons
                  (page)="getUserData('Measurement Setups', $event)"></mat-paginator>
              </mat-tab>

              <mat-tab label="Devices" class="ext">
                <div class="overlay" *ngIf="devicesDataProcessing">
                  <mat-spinner class="loader"></mat-spinner>
                </div>
                <div class="mat-table-wrapper">
                  <table [matSortActive]="orderOn" [matSortDirection]="orderBy" mat-table [dataSource]="devicesData"
                    class="table employee-list no-wrap" matSort (matSortChange)="sortData($event)">

                    <ng-container matColumnDef="index">
                      <th mat-header-cell *matHeaderCellDef> Index </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        {{!!devicesPageIndex ? (i+1) + (devicesPageIndex*devicesPageSize) : i+1}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="id">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                      <td class="might-overflow" [matTooltip]="element.id" mat-cell *matCellDef="let element">
                        <u>
                          <a target="_blank" (click)="$event.stopPropagation()" [routerLink]="['/device/devices']"
                            [queryParams]="{device_id: element.id}">{{ element.id }}</a>
                        </u>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="mac_address">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Mac Address </th>
                      <td class="might-overflow" [matTooltip]="element.mac_address" mat-cell *matCellDef="let element">
                        {{ element.mac_address || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="serial_number">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Serial Number </th>
                      <td class="might-overflow" [matTooltip]="element.serial_number" mat-cell
                        *matCellDef="let element">
                        {{ element.serial_number || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="device_type">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Device Type </th>
                      <td class="might-overflow"
                        [matTooltip]='element.device_type.name + "-" + element.device_type.version' mat-cell
                        *matCellDef="let element">
                        {{ element.device_type.name || '-' }}-{{ element.device_type.version || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="device_type_version">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Device Type Version </th>
                      <td class="might-overflow" [matTooltip]="element.device_type.version " mat-cell
                        *matCellDef="let element">
                        {{ element.device_type.version || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="detector_info">
                      <th disableClear mat-header-cell *matHeaderCellDef> Detector Info</th>
                      <td class="might-overflow" [matTooltip]="element.detector_detail " mat-cell
                        *matCellDef="let element">
                        <div *ngFor="let d of element.detector_info">
                          {{ d.detector_position || '-' }}: {{ d.detector_info || '-' }}
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="device_firmware">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Device Firmware </th>
                      <td class="might-overflow" [matTooltip]='element.firmware.name + "-" + element.firmware.version'
                        mat-cell *matCellDef=" let element">
                        {{ element.firmware.name || '-' }}-{{ element.firmware.version || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="device_firmware_version">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Device Firmware Version </th>
                      <td class="might-overflow" [matTooltip]="element.firmware.version" mat-cell
                        *matCellDef="let element">
                        {{ element.firmware.version || '-' }}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="createdAt">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
                      <td class="might-overflow" [matTooltip]="element.createdAt | date: 'dd MMM YYY, HH.mm.ss'"
                        mat-cell *matCellDef="let element"> {{ element.createdAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                    </ng-container>

                    <ng-container matColumnDef="updatedAt">
                      <th disableClear mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated At </th>
                      <td class="might-overflow" [matTooltip]="element.updatedAt | date: 'dd MMM YYY, HH.mm.ss'"
                        mat-cell *matCellDef="let element"> {{ element.updatedAt | date: 'dd MMM YYY, HH.mm.ss' }} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="deviceColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: deviceColumns;"></tr>
                  </table>
                  <div class="no-record" *ngIf='devicesTotalCount === 0'> No record found </div>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="devicesPageSizeOptions" [length]="devicesTotalCount"
                  [pageIndex]="devicesPageIndex" [pageSize]="devicesPageSize" showFirstLastButtons
                  (page)="getUserData('Devices', $event)"></mat-paginator>
              </mat-tab>

            </mat-tab-group>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>