import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { CustomValidators } from 'src/app/validators/customValidator';
import { HelperService } from 'src/app/shared/services/helper/helper.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  public isProcessing = false;
  public errorReason = false;
  public showError = false;
  public showPassword = false;
  public isSubmitted = false;
  public forgetForm: any;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    private loginService: LoginService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.resetForgetForm();
  }

  // convenience getter for easy access to form fields
  get forgetControls() {
    return this.forgetForm.controls;
  }

  resetForgetForm() {
    this.forgetForm = this.fb.group({
      email: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.noWhitespace,
          CustomValidators.checkPatternEmail,
        ]),
      ],
    });
  }

  async submitForgetForm(value: any) {
    this.isSubmitted = true;
    this.errorReason = false;
    if (this.forgetForm.valid) {
      try {
        this.isProcessing = true;
        const reqObj = {
          email: value.email,
        };
        const res = await this.loginService.forget(reqObj);
        if (res) {
          this.helperService.showAlert(res.message || 'Success', 'success');
          this.router.navigate(['/authentication/login']);
        }
      } catch (err: any) {
        this.errorReason = err.error || 'Something went wrong';
        this.helperService.showAlert(
          err.error || 'Something went wrong',
          'error'
        );
        this.showError = true;
      } finally {
        this.isSubmitted = true;
        this.isProcessing = false;
      }
    }
  }
}
